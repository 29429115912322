import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import '../../../styles/verifyOtpInput.scss';
import ValidationMessage from '../../ui/ValidationMessage';
import { MUI_COLORS, REQUIRED_LENGTH, API_CODE } from '../../../constants/enum';
import axios from 'axios';
const Otp = ({ nextStep, resendOtpClicked }) => {
  const [otp, setOtp] = useState('');
  const [error, setOtpError] = useState('');

  const handleChange = async (otpValue) => {
    setOtpError(false);
    setOtp(otpValue);
    if (otpValue.length === REQUIRED_LENGTH.OTP_LENGTH) {
      let payload = {
        email: sessionStorage.getItem('email').replace(/ /g, '+'),
        otp: otpValue,
        type: resendOtpClicked ? 3 : 1
      };
      try {
        const otpResponse = await axios.put(`${process.env.BASE_API_URL}invoice/verifyIncorporationUserOtp`, payload);
        if (otpResponse?.status === API_CODE?.STATUS_200) {
          if (otpResponse?.data?.data?.token) {
            sessionStorage.setItem('token', otpResponse?.data?.data?.token);
            nextStep();
          }
        } else {
          setOtpError(otpResponse?.data?.message);
        }
      } catch (error) {
        setOtpError(error?.response?.data?.message);
      }
    }
  };
  const handleClickCapture = () => {
    if (error) {
      setOtp('');
      setOtpError('');
    }
  };
  return (
    <div onClickCapture={handleClickCapture}>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        shouldAutoFocus={true}
        separator={<span className="ml-2"></span>}
        inputStyle={error ? 'otp-input-error' : 'otp-input'}
        focusStyle={{
          border: error ? '1px solid #FF4F42' : '1px solid #2D0D45',
          background: error ? MUI_COLORS.LIGHT_CREAM : MUI_COLORS.WHITE,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          outline: 'none'
        }}
      />
      {error && <ValidationMessage title={error} />}
    </div>
  );
};

export default Otp;
