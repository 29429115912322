import * as actionTypes from './actionTypes';

let initialState = {
  companyDetails: {},
  getCompanyDetails: {},
  updatedCompanyDetails: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, companyDetails: { ...action.companyDetails }, loading: false, error: false };

    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, updatedCompanyDetails: { ...action.companyDetails }, loading: false, error: false };

    case actionTypes.GET_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, getCompanyDetails: { ...action.getCompanyDetails }, loading: false, error: false };

    default:
      return state;
  }
};
export default reducer;
