import React from 'react';
import XSText from './typography/XSText';
import IconArrowRight from '../../images/icons/arrow-right-1.svg';
import PropTypes from 'prop-types';
import '../../styles/option-bar.scss';
export default function OptionBar({ onClick, label, isFirstOption, isLastOption, isActivated, icon }) {
  return (
    <div
      className={`w-full py-4 ${isFirstOption ? 'first-card' : isLastOption ? 'last-card' : 'middle-card'} ${
        isActivated && 'active-option-card'
      } flex justify-between items-center pl-5 pr-5 cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <img alt="icon" src={icon} />
        <XSText className="ml-4 md:whitespace-pre-line" title={label} />
      </div>
      <img src={IconArrowRight} alt="arrow right" className="ml-5" />
    </div>
  );
}
OptionBar.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  isFirstOption: PropTypes.bool,
  isActivated: PropTypes.bool
};

OptionBar.defaultProps = {
  isActivated: false
};
