import React from 'react';
import { Snackbar, Alert, Container } from '@mui/material';
import PropTypes from 'prop-types';
function AlertComponenet(props) {
  const { open, handleClose, title, sx, icon, autoHideDuration } = props;

  return (
    <div>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={autoHideDuration}
        transitionDuration={{ enter: 1000, exit: 5000 }}
        TransitionProps={{ enter: true, exit: false }}
        sx={{ width: '100%', marginRight: '8px', marginBottom: '112px' }}
        onClose={handleClose}
      >
        <Alert
          className="snackbar"
          onClose={handleClose}
          icon={icon ?? false}
          sx={{ width: '364px', alignItems: 'center', borderRadius: '8px', ...sx }}
        >
          {title}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponenet;

AlertComponenet.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.any,
  autoHideDuration: PropTypes.number
};

AlertComponenet.defaultProps = {
  autoHideDuration: 3000
};
