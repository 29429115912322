import React, { useEffect, useState } from 'react';
import XSText from '../../components/ui/typography/XSText';
import { navigate } from 'gatsby';
import TextInput from '../../components/ui/inputs/TextInput';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { API_CODE, CHAR_CODE, MUI_COLORS } from '../../constants/enum';
import CustomisedCheckbox from '../../components/ui/Checkbox';
import { REGEX } from '../../constants/enum';
import BaseText from '../../components/ui/typography/BaseText';
import CheckByStreamLineHq from '../../images/icons/customSvg/checkByStreamLineHq';
import MSText from '../../components/ui/typography/MSText';
import ValidationMessage from '../../components/ui/ValidationMessage';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import * as SignupActions from './reduxStore/action';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import TermAndConditionModal from '../termAndCondition/TermAndConditionModal';
import PrivacyAndPolicyModal from '../privacyAndPolicy/PrivacyAndPolicy';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { isBrowser, sendHubspotRequest } from '../../helper/helpers';
import { SUCCESSFULL_SIGNUP } from '../../service/services';
import '../../styles/pages/company-signup.scss';
import { useLocation } from '@reach/router';
function CompanySignUpForm({ companySignup }) {
  const [termAndConditions, setTermAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordNotFocused, setIsPasswordNotFocused] = useState(false);
  const [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const { email, password, confirmPassword } = inputValue;
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState();
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    confirmPassword: false
  });
  const [passwordValidation, setPasswordValidation] = useState({
    tenCharacters: false,
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialCharacter: false,
    space: false,
    passwordMatch: false
  });

  const location = useLocation();

  const query = new URLSearchParams(location?.search);
  const emailFromUrl = query.get('email');
  const id = query.get('companyId');
  const emailOfUser = isBrowser() && emailFromUrl?.replace(/ /g, '+');

  const [openTermModal, setOpenTermModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [required, setRequired] = useState(false);

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      email: emailOfUser
    }
  });

  async function sendHubspot(data) {
    let hubspotPayload = {
      fields: [
        {
          name: 'email',
          value: data?.email
        },
        {
          name: 'firstname',
          value: data?.name
        },
        {
          name: 'lastname',
          value: data?.lastName
        },
        {
          name: 'product_usage',
          value: 'Invoicing Software'
        }
      ],
      context: {
        pageUri: `${process.env.SITE_URL}/signup`,
        pageName: 'Invoice signup'
      }
    };
    var finalData = JSON.stringify(hubspotPayload);
    sendHubspotRequest(finalData, SUCCESSFULL_SIGNUP?.HUBSPOT_URL, true);
  }

  const emailRegex = REGEX.EMAIL_REGEX;
  const onClickCheckbox = () => {
    setTermAndConditions(!termAndConditions);
  };
  const currentPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword?.currentPassword
    });
  };
  const confirmPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      confirmPassword: !showPassword?.confirmPassword
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValue((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === 'password') {
      if (value.length > 9) {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: false
        }));
      }

      let upperCaseRegex = REGEX.UPPER_CASE_REGEX;
      if (value.match(upperCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: false
        }));
      }

      let lowerCaseRegex = REGEX.LOWER_CASE_REGEX;
      if (value.match(lowerCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: false
        }));
      }

      let digitRegex = REGEX.DIGIT_CASE_REGEX;
      if (value.match(digitRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: false
        }));
      }

      let specialCharacterRegex = REGEX.SPECIAL_CASE_REGEX;
      if (value.match(specialCharacterRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: false
        }));
      }

      let spaceRegex = REGEX.SPACE_REGEX;
      if (!value.match(spaceRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          space: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          space: false
        }));
      }

      if (value === confirmPassword) {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: false
        }));
      }
    }
    if (name === 'confirmPassword') {
      if (value === password) {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          passwordMatch: false
        }));
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === CHAR_CODE.ENTER_KEY_CODE) {
      handleSubmit(onSubmit)();
    }
  };

  const isPasswordCriteriaValid = () => {
    if (
      passwordValidation.tenCharacters &&
      passwordValidation.upperCase &&
      passwordValidation.lowerCase &&
      passwordValidation.digit &&
      passwordValidation.space &&
      passwordValidation.specialCharacter
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = async (data) => {
    setRequired(true);
    if (isPasswordCriteriaValid() && passwordValidation.passwordMatch && termAndConditions) {
      setLoading(true);
      let payload = {
        email: emailOfUser,
        password: data?.password,
        companyId: id
      };
      const companySignupResponse = await companySignup(payload);
      if (companySignupResponse?.status === API_CODE.STATUS_200) {
        // await sendHubspot(data);
        setLoading(false);
        navigate(PATH_PAGE?.companyEmailVerify);
      } else {
        setLoading(false);
      }
      setShowConfirmPasswordError(false);
    }
    if (!isPasswordCriteriaValid()) {
      setShowPasswordError(true);
    }
    if (!passwordValidation.passwordMatch) {
      setShowConfirmPasswordError(true);
    }
    if (passwordValidation.passwordMatch) {
      setShowConfirmPasswordError(false);
    }
  };

  const onBlur = () => {
    setIsPasswordNotFocused(true);
  };
  const onFocus = () => {
    setIsPasswordNotFocused(false);
  };

  return (
    <div className="company-signup-layout whitespace-pre-line">
      <div className="bg-white rounded-md company-sign-up-container">
        <div className="p-8">
          <div className="login">
            <MDSubtitleText title={'Create an account to access \nyour company information'} fontWeight="text-bold" />
          </div>
          <form id="signup-form" className="personal-info-form w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6">
              <TextInput
                name="email"
                label="Email Address"
                variant="filled"
                disabled={true}
                value={emailOfUser}
                InputLabelProps={emailFromUrl ? { shrink: true } : ''}
                onChange={handleChange}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: emailRegex,
                    message: 'Please fill in a valid Email Address'
                  }
                })}
                error={errors?.email ? true : false}
                helperText={errors?.email && <ValidationMessage title={'Please fill in a valid Email Address'} />}
              />
              <div className="relative flex flex-col-reverse md:flex-row md:justify-center items-center">
                <div
                  className={`${
                    password && !isPasswordCriteriaValid() && !isPasswordNotFocused ? 'block mt-4' : 'hidden'
                  } p-4 md:absolute  md:bg-white bg-green-200 rounded-lg ${
                    showPasswordError
                      ? 'create-password-validation-container-on-error'
                      : 'create-password-validation-container'
                  }`}
                >
                  <BaseText className="mb-4" fontWeight="text-bold" title={'Your password must have:'} />
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.tenCharacters ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.tenCharacters ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.tenCharacters && 'text-bold'}
                      title={'At least 10 characters'}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.upperCase ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.upperCase ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.upperCase && 'text-bold'}
                      title={'At least one uppercase letter'}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.lowerCase ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.lowerCase ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.lowerCase && 'text-bold'}
                      title={'At least one lowercase letter'}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.digit ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.digit ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.digit && 'text-bold'}
                      title={'At least one digit number'}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.space ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.space ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.space && 'text-bold'}
                      title={'No spaces'}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-2">
                    {passwordValidation.specialCharacter ? (
                      <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                    ) : (
                      <CheckByStreamLineHq />
                    )}
                    <MSText
                      textColor={passwordValidation.specialCharacter ? 'text-green-signup' : 'text-light-gray'}
                      fontWeight={passwordValidation.specialCharacter && 'text-bold'}
                      title={'At least one special character ( # * ! etc. )'}
                    />
                  </div>
                  <div className="absolute w-full left-0 right-0 mx-auto mt-5 validation-div-triangle-down"></div>
                </div>
                <div className="w-full">
                  <Controller
                    control={control}
                    name="password"
                    render={({ value }) => (
                      <TextInput
                        type={showPassword?.currentPassword ? 'text' : 'password'}
                        label="Password"
                        name="password"
                        onRightSideAdornment={currentPasswordClicked}
                        value={value}
                        onChange={handleChange}
                        endAdornment={showPassword?.currentPassword ? <Visibility /> : <VisibilityOff />}
                        textFieldContainerClass="mt-4"
                        inputRef={register({
                          required: true
                        })}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        error={errors?.password ? true : false}
                        helperText={errors?.password && <ValidationMessage title={'Please fill in a valid password'} />}
                      />
                    )}
                  />
                </div>
              </div>

              <Controller
                control={control}
                name="confirmPassword"
                render={({ value }) => (
                  <TextInput
                    type={showPassword?.confirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    name="confirmPassword"
                    onRightSideAdornment={confirmPasswordClicked}
                    endAdornment={showPassword?.confirmPassword ? <Visibility /> : <VisibilityOff />}
                    textFieldContainerClass="mt-4"
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    inputRef={register({
                      required: true
                    })}
                    error={errors?.confirmPassword || showConfirmPasswordError ? true : false}
                    helperText={
                      (errors?.confirmPassword || showConfirmPasswordError) && (
                        <ValidationMessage title={'Both passwords must be same'} />
                      )
                    }
                  />
                )}
              />

              <div className="flex flex-row items-start gap-2 mt-6">
                <CustomisedCheckbox isChecked={termAndConditions} handleChange={onClickCheckbox} />
                <XSText
                  className="md:mb-3 mb-2"
                  color="text-grey-500"
                  title={
                    <span>
                      By registering, you confirm you have read and agree to the{' '}
                      <span
                        onClick={() => setOpenTermModal(true)}
                        className="acknowledge-content text-bold cursor-pointer"
                      >
                        terms and conditions{' '}
                      </span>
                      and the{' '}
                      <span
                        onClick={() => setOpenPrivacyModal(true)}
                        className="acknowledge-content text-bold cursor-pointer"
                      >
                        {' '}
                        privacy policy
                      </span>
                      .
                    </span>
                  }
                ></XSText>
              </div>
              {!termAndConditions && required && (
                <div className="md:mb-4 mb-3">
                  <ValidationMessage title={'In order to sign up you need to agree to these terms.'} />
                </div>
              )}
              <PrimaryButton
                id="sign-up-form-btn"
                bgColor="bg-coral-500"
                className="button"
                isLoading={loading}
                caption="Sign Up"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
      <TermAndConditionModal openModal={openTermModal} closeDynamicModal={() => setOpenTermModal(false)} />
      <PrivacyAndPolicyModal openPrivacyModal={openPrivacyModal} closeDynamicModal={() => setOpenPrivacyModal(false)} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    companySignup: (signupPayload) => dispatch(SignupActions.companySignup(signupPayload))
  };
};

export default connect(null, mapDispatchToProps)(CompanySignUpForm);

CompanySignUpForm.propTypes = {
  companySignup: PropTypes.func
};
