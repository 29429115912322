import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const sendEmailRequestStart = () => ({ type: actionTypes.SEND_EMAIL_REQUEST_START });
export const sendEmailRequestFail = (error) => ({
  type: actionTypes.SEND_EMAIL_REQUEST_FAIL,
  error: error
});
export const sendEmailRequestSuccess = (sendEmailPayload) => ({
  type: actionTypes.SEND_EMAIL_REQUEST_SUCCESS,
  sendEmailPayload: sendEmailPayload
});
export const sendEmail = (sendEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(sendEmailRequestStart());
      let sendEmailResponse = await Api.post(ENDPOINTS.PUBLIC_URL_EMAIL, sendEmailPayload);
      dispatch(sendEmailRequestSuccess(sendEmailResponse));
      return sendEmailResponse;
    } catch (error) {
      dispatch(sendEmailRequestFail(error.response));
    }
  };
};
