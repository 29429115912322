import React from 'react';
import BaseText from '../ui/typography/BaseText';

function DraftPublicInvoice() {
  return (
    <div className="bg-white rounded-xl">
      <div className="p-6 flex justify-center">
        <BaseText title="Something went wrong ! 404 No Invoice Found" />
      </div>
    </div>
  );
}

export default DraftPublicInvoice;
