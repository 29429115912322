import * as actionTypes from './actionTypes';

let initialState = {
  performanceInvoiceData: {}
};

const dashboardInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        performanceInvoiceData: { ...action.getPerformance },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};

export default dashboardInvoiceReducer;
