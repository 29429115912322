import * as actionTypes from './actionTypes';

let initialState = {
  jobId: {},
  loading: false,
  error: false
};

const OtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_CONTACTS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SEND_CONTACTS_REQUEST_SUCCESS:
      return {
        ...state,
        jobId: action.contacts,
        loading: false,
        error: false
      };
    case actionTypes.SEND_CONTACTS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default OtpReducer;
