import React, { useEffect, useState } from 'react';
import CompanyInformation from '../profile/CompanyInformation';
import CompanySecretary from '../profile/CompanySecretary';
import CapitalInfo from '../organization/CapitalInfo';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import DirectorsTable from '../organization/DirectorsTable';
import ShareHoldersTable from '../organization/ShareHoldersTable';
import CorporateShareHolders from '../organization/CorporateShareHolders';
import PropTypes from 'prop-types';
import PastStackHolders from './PastStackHolders';

function Organization({ stackHolderDetails, profileDetails, isStackHolderLoading }) {
  const [directorsDetails, setDirectorDetails] = useState([]);
  const [shareHoldersDetails, setShareHoldersDetails] = useState([]);
  const [pastShareHolderDetails, setPastShareholderDetails] = useState([]);

  const seprateStackHolderAndDirector = (stackHolderDetails) => {
    stackHolderDetails?.map((stackHolder) => {
      if (stackHolder?.isDirector && !stackHolder?.isDisabled) {
        setDirectorDetails((prevState) => [...prevState, stackHolder]);
      }
      if (stackHolder?.isShareholder && !stackHolder?.isDisabled) {
        setShareHoldersDetails((prevState) => [...prevState, stackHolder]);
      }
      if (stackHolder?.isDisabled) {
        setPastShareholderDetails((prevState) => [...prevState, stackHolder]);
      }
    });
  };

  useEffect(() => {
    if (stackHolderDetails?.length > 0) {
      seprateStackHolderAndDirector(stackHolderDetails);
    }
  }, [stackHolderDetails]);
  return (
    <div className="flex mt-8">
      <div className="md:mr-6">
        <CompanyInformation profileDetails={profileDetails} />
        <CapitalInfo capitalDetails={profileDetails?.capital} />
        <CompanySecretary />
      </div>
      <div className="w-full">
        <H4HeaderText className="mt-4 mb-6" title="Company structure" />
        <DirectorsTable directorsDetails={directorsDetails} isStackHolderLoading={isStackHolderLoading} />
        <div className="mt-6">
          <ShareHoldersTable shareHoldersDetails={shareHoldersDetails} isStackHolderLoading={isStackHolderLoading} />
        </div>
        <div className="mt-6">
          <CorporateShareHolders corporateShareHolders={profileDetails?.corporateIndividual} />
        </div>
        <div className="mt-6">
          <PastStackHolders
            pastShareHolderDetails={pastShareHolderDetails}
            isStackHolderLoading={isStackHolderLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default Organization;

Organization.propTypes = {
  companyProfileDetails: PropTypes.func
};
