import React, { useEffect, useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import ValidationMessage from '../../ui/ValidationMessage';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import CurrenciesList from '../../../data/invoice-currencies-list.json';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as accountSettingActions from '../../accountSetting/reduxStore/action';
import * as profileActions from '../../profile/reduxStore/action';
import { API_CODE } from '../../../constants/enum';
import XSText from '../../ui/typography/XSText';
import BackButton from '../../ui/buttons/BackButton';
import StatrysLoader from '../../ui/loaders/StatrysLoader';

function PreferredCurrency({ getCurrencyPreference, currencyPreference, nextStep, setStep }) {
  const [preferredCurrency, setPreferredCurrency] = useState();
  const [preferredCurrencyError, setPreferredCurrencyError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const setIndustry = (value) => {
    setPreferredCurrency(value);
    if (value?.name) {
      setPreferredCurrencyError(false);
    }
  };
  useEffect(() => {
    preferredCurrencyHandler();
  }, []);
  const preferredCurrencyHandler = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setPreferredCurrency(getCurrencyDataResponse?.data?.data?.currency);
      setIsLoading(false);
    }
  };
  const handlePreferredCurrencySubmit = async () => {
    if (preferredCurrency?.name) {
      await currencyPreference({ currency: preferredCurrency });
      setStep(6);
    } else {
      setPreferredCurrencyError(true);
    }
  };
  return (
    <div className="modal-step-container flex flex-col w-full justify-end">
      <div className=" mt-auto mb-auto flex flex-col">
        <MDSubtitleText className="md:whitespace-pre-line mb-6" fontWeight="text-bold" title={`Preferred currency`} />
        <XSText className="mb-2" title="Choose the currency you use the most" />
        {isLoading ? (
          <StatrysLoader />
        ) : (
          <SelectInputAutoComplete
            label="Currency"
            name="preferredCurrency"
            options={CurrenciesList}
            value={preferredCurrency}
            setSelectedValue={setIndustry}
            error={preferredCurrencyError}
            helperText={preferredCurrencyError && <ValidationMessage title="Please select a valid Currency" />}
          />
        )}
      </div>
      <div className="flex justify-between">
        <BackButton onClick={() => setStep(4)} />
        <PrimaryButton
          onClick={handlePreferredCurrencySubmit}
          className="company-details-modal-button"
          caption="Continue"
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.createCompanyDetails(companyDetailsPayload)),
    currencyPreference: (payload) => dispatch(profileActions.currencyPreference(payload)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(null, mapDispatchToProps)(PreferredCurrency);

PreferredCurrency.propTypes = {
  createCompanyDetails: PropTypes.func
};
