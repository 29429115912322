import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlineDownArrow from '../../images/icons/outlineDownArrow.svg';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { AppBar, Toolbar, Typography } from '@mui/material';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import { Link, navigate } from 'gatsby';
import { MUI_COLORS, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { INVOICE_DASHBOARD, INVOICE_LIST, PATH_PAGE, IMPORTING_INVOICE, COMPANY } from '../../routes/paths';
import Avatar from '../ui/Avatar';
import { getSecondLastPathName, getURLFirstPathName, stringTruncate } from '../../helper/helpers';
import MSText from '../ui/typography/MSText';
import { useLocation } from '@reach/router';
import { getUser, logOut } from '../../components/authService/auth';
import { connect } from 'react-redux';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import { getSvgIcon } from '../../helper/svgHelper';
import StatrysIcon from '../../images/icons/statrys-logo.svg';
import OverViewIcon from '../../images/icons/sideBar/overview.svg';
import InvoicesFileIcon from '../../images/icons/sideBar/file-icon.svg';
import SignaturesIcon from '../../images/icons/sideBar/page-with-signature.svg';
import IndustryIcon from '../../images/icons/sideBar/industry-with-smoke.svg';
import ContactBookIcon from '../../images/icons/sideBar/contact-book.svg';
import IdeaBuldIcon from '../../images/icons/sideBar/idea-bulb.svg';
import BaseText from '../ui/typography/BaseText';
import HeaderMenuIcon from '../../images/icons/header/header-menu-icon.svg';
import NavigationDropDown from '../Navigation/NavigationDropDown';
import XSText from '../ui/typography/XSText';
import CompanyManagementIcon from '../../images/icons/header/company-management.svg';
import InvoicingIcon from '../../images/icons/header/invoicing-icon.svg';
import AccountSwitcherMenu from '../Navigation/AccountSwitcherMenu';
import Buildings from '../../images/icons/sideBar/buildings.svg';
import Hierarchy from '../../images/icons/sideBar/hierarchy.svg';
import Mailbox from '../../images/icons/sideBar/mailbox.svg';
import BookLibrary from '../../images/icons/sideBar/book-library.svg';
import ActiveBuildings from '../../images/icons/sideBar/buildings-green.svg';
import ActiveHierarchy from '../../images/icons/sideBar/hierarchy-green.svg';
import ActiveMailbox from '../../images/icons/sideBar/mailbox-green.svg';
import ActiveBookLibrary from '../../images/icons/sideBar/book-library-green.svg';

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const companyList = [
  {
    companyName: 'Company 2 and a longer name here'
  },
  {
    companyName: 'Company 3 and a longer name here'
  },
  {
    companyName: 'ACME Pacific and dragon li Limit'
  }
];

const navigation = [
  {
    id: 1,
    pageName: 'Dashboard',
    icon: OverViewIcon,
    to: INVOICE_DASHBOARD
  },
  {
    id: 2,
    pageName: 'Get paid',
    icon: InvoicesFileIcon,
    to: INVOICE_LIST
  },
  {
    id: 3,
    pageName: 'Pay',
    icon: SignaturesIcon,
    to: PATH_PAGE.pay
  },
  {
    id: 4,
    pageName: 'Contacts',
    icon: IndustryIcon,
    to: PATH_PAGE.contact
  }
];

const companyNavigation = [
  {
    id: 1,
    pageName: 'Company Profile',
    icon: Buildings,
    activeIcon: ActiveBuildings,
    to: PATH_PAGE.companyProfile
  },
  {
    id: 2,
    pageName: 'Organization',
    icon: Hierarchy,
    activeIcon: ActiveHierarchy,
    to: PATH_PAGE.companyOrganization
  },
  {
    id: 3,
    pageName: 'Mailroom',
    icon: Mailbox,
    activeIcon: ActiveMailbox,
    to: PATH_PAGE.companyMailRoom
  },
  {
    id: 4,
    pageName: 'Documents',
    icon: BookLibrary,
    activeIcon: ActiveBookLibrary,
    to: PATH_PAGE.companyDocuments
  }
];

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.companyProfileSetting
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut
  }
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function NavigationBar({ children, getProfile, getUserProfile, getCompanyInfo, getCompanyDetails }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [companyFormdata, setCompanyFormdata] = useState();
  const user = getUser();
  const location = useLocation();
  const isIncorporation = getUser()?.isIncorporation;
  const isCompanyOwner = getUser()?.isCompanyOwner;

  const navigationMenu = isIncorporation ? companyNavigation : navigation;

  const handleClick = (value) => {
    navigate(value);
  };

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getUserProfileInfo();
    getCompanyDetailsHandler();
  }, []);

  const getCompanyDetailsHandler = async () => {
    await getCompanyDetails();
  };

  const getUserProfileInfo = async () => {
    await getUserProfile();
  };

  useEffect(() => {
    setProfileData(getProfile);
    setCompanyFormdata(getCompanyInfo);
  }, [getProfile, getCompanyInfo]);

  const mobileViewMenuOptions = navigation.concat(profileOptions);

  const firstName = profileData?.firstName ? profileData?.firstName : user?.email;
  const lastName = profileData?.firstName ? profileData?.lastName : '';
  const fullName = firstName + ' ' + lastName;

  return (
    <Box sx={{ display: 'flex' }} className="max-w-screen-2xl mx-auto">
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            paddingRight: '40px',
            paddingLeft: '40px',
            minHeight: '66px'
          }
        }}
      >
        <Toolbar className="max-w-screen-2xl w-full mx-auto">
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            <div className="flex justify-start gap-2 items-center">
              <img src={isIncorporation ? CompanyManagementIcon : InvoicingIcon} alt="company management" />
              <div>
                <BaseText fontSize="text-xl" title={isIncorporation ? 'Company Management' : 'Invoicing'} />
              </div>
            </div>
          </Typography>
          <div className="flex gap-8">
            <div className="flex items-center item-menu-dropdown dropdown">
              <div className="flex flex-row gap-2 items-center cursor-pointer">
                <div className="rounded-full">
                  <Avatar
                    avatarImage={companyFormdata?.logo}
                    avatarName={
                      companyFormdata?.companyName ? companyFormdata?.companyName?.charAt(0) : user?.email?.charAt(0)
                    }
                  />
                </div>
                <div>
                  {companyFormdata?.companyName && (
                    <MSText
                      title={stringTruncate(companyFormdata?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                      fontWeight="text-bold"
                    />
                  )}
                  <MSText
                    title={stringTruncate(user?.email, STRING_MAX_CHAR.TWENTY_NINE)}
                    className="menu-item-text"
                    textColor="text-gray-450"
                  />
                </div>
                <div>
                  <img src={OutlineDownArrow} alt={OutlineDownArrow} />
                </div>
              </div>
              <NavigationDropDown
                menuItems={profileOptions}
                isNavigation={true}
                // companyList={companyList}
                needSeparator={true}
                separtorPositions={[0, 1]}
              >
                <div className="mt-4 px-4">
                  <XSText
                    title={
                      profileData?.lastName && profileData?.firstName
                        ? stringTruncate(fullName, STRING_MAX_CHAR.THIRTY_FIVE)
                        : ''
                    }
                    fontWeight="text-bold"
                    lineHeight="leading-6"
                  />
                  <MSText
                    title={user?.email && stringTruncate(user?.email, STRING_MAX_CHAR.THIRTY_FIVE)}
                    textColor="text-gray-450"
                    className="dropDown-email-text whitespace-nowrap overflow-hidden"
                  />
                </div>
              </NavigationDropDown>
            </div>
            {/* <div className="flex items-center item-menu-dropdown dropdown">
              <div className="flex flex-row gap-2 items-center cursor-pointer">
                <img src={HeaderMenuIcon} alt="header menu" />
              </div>
              <AccountSwitcherMenu menuItems={profileOptions} />
            </div> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          '& .MuiDrawer-paper': {
            position: 'relative',
            zIndex: '100',
            backgroundColor: MUI_COLORS.LIGHT_GRAY,
            borderRight: 'none',
            paddingLeft: '32px',
            paddingRight: '32px'
          }
        }}
      >
        <div className="flex flex-col justify-between h-full mt-20">
          <div>
            <List>
              {navigationMenu.map((navigate) => (
                <ListItem key={navigate.id} disablePadding sx={{ display: 'block', marginBottom: '4px' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 56,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      '&:hover': { backgroundColor: 'white' }
                    }}
                    className={`${handleActiveTab(navigate?.to) && 'bg-white'} rounded-lg`}
                    onClick={() => handleClick(navigate?.to)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <img
                        className={`${handleActiveTab(navigate?.to) && 'text-green-500'}`}
                        src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                        alt={navigate?.pageName}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={`${handleActiveTab(navigate?.to) && 'text-gray-500 font-bold'}`}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiTypography-root': {
                          fontWeight: handleActiveTab(navigate?.to) && 700
                        }
                      }}
                    >
                      {navigate.pageName}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>

          <footer className="pb-5">
            <div className="flex justify-center">
              <img src={FooterLogo} alt={FooterLogo} />
            </div>
            <StatrysSupport />
          </footer>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, backgroundColor: MUI_COLORS.LIGHT_GRAY, height: '100vh' }}>
        <div className="layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
          <main className="py-14">{children}</main>
        </div>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data,
    getCompanyInfo: state?.accountSettings?.getCompanyDetails?.data?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
