import React, { useLayoutEffect, useState } from 'react';
import XSText from '../../components/ui/typography/XSText';
import { navigate } from 'gatsby';
import TextInput from '../../components/ui/inputs/TextInput';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { API_CODE, CHAR_CODE, MUI_COLORS } from '../../constants/enum';
import { REGEX } from '../../constants/enum';
import BaseText from '../../components/ui/typography/BaseText';
import CheckByStreamLineHq from '../../images/icons/customSvg/checkByStreamLineHq';
import MSText from '../../components/ui/typography/MSText';
import ValidationMessage from '../../components/ui/ValidationMessage';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import * as SignupActions from './reduxStore/action';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import TermAndConditionModal from '../termAndCondition/TermAndConditionModal';
import PrivacyAndPolicyModal from '../privacyAndPolicy/PrivacyAndPolicy';
import { useLocation } from '@reach/router';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import IconLock from '../../images/icons/lock.svg';
function SignupForm({ signup }) {
  const [termAndConditions, setTermAndConditions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPasswordNotFocused, setIsPasswordNotFocused] = useState(false);
  const [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    companyName: ''
  });
  const { email, password, companyName } = inputValue;
  const [showPasswordError, setShowPasswordError] = useState();
  const [showPassword, setShowPassword] = useState({
    currentPassword: false
  });
  const [passwordValidation, setPasswordValidation] = useState({
    tenCharacters: false,
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialCharacter: false,
    space: false,
    passwordMatch: true
  });

  const location = useLocation();

  const [openTermModal, setOpenTermModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [required, setRequired] = useState(false);

  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      email: location?.state?.tempInvoiceData?.companyDetails?.email
    }
  });
  const emailRegex = REGEX.EMAIL_REGEX;
  const onClickCheckbox = () => {
    setTermAndConditions(!termAndConditions);
  };
  const currentPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword?.currentPassword
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValue((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === 'password') {
      if (value.length > 9) {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          tenCharacters: false
        }));
      }

      let upperCaseRegex = REGEX.UPPER_CASE_REGEX;
      if (value.match(upperCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          upperCase: false
        }));
      }

      let lowerCaseRegex = REGEX.LOWER_CASE_REGEX;
      if (value.match(lowerCaseRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          lowerCase: false
        }));
      }

      let digitRegex = REGEX.DIGIT_CASE_REGEX;
      if (value.match(digitRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          digit: false
        }));
      }

      let specialCharacterRegex = REGEX.SPECIAL_CASE_REGEX;
      if (value.match(specialCharacterRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          specialCharacter: false
        }));
      }

      let spaceRegex = REGEX.SPACE_REGEX;
      if (!value.match(spaceRegex)) {
        setPasswordValidation((prev) => ({
          ...prev,
          space: true
        }));
      } else {
        setPasswordValidation((prev) => ({
          ...prev,
          space: false
        }));
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === CHAR_CODE.ENTER_KEY_CODE) {
      handleSubmit(onSubmit)();
    }
  };

  const isPasswordCriteriaValid = () => {
    if (
      passwordValidation.tenCharacters &&
      passwordValidation.upperCase &&
      passwordValidation.lowerCase &&
      passwordValidation.digit &&
      passwordValidation.space &&
      passwordValidation.specialCharacter
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = async (data) => {
    setRequired(true);
    if (isPasswordCriteriaValid() && passwordValidation.passwordMatch && termAndConditions) {
      setLoading(true);
      if (typeof window !== `undefined`) {
        await window?.heap?.identify(data?.email.trim());
      }
      let payload = {
        email: data?.email.trim(),
        password: data?.password.trim(),
        companyName: data?.companyName
      };
      const signupResponse = await signup(payload);
      if (signupResponse?.status === API_CODE.STATUS_200) {
        setLoading(false);
        navigate(PATH_PAGE?.verifyEmail, { state: { isTemporary: isTemporary, invoiceId: tempInvoiceData?._id } });
      } else {
        setLoading(false);
      }
    }
    if (!isPasswordCriteriaValid()) {
      setShowPasswordError(true);
    }
  };

  const onBlur = () => {
    setIsPasswordNotFocused(true);
  };
  const onFocus = () => {
    setIsPasswordNotFocused(false);
  };

  const [tempInvoiceData, setTempInvoiceData] = useState('');
  const [isTemporary, setIsTemporary] = useState(false);

  useLayoutEffect(() => {
    if (location?.state?.tempInvoiceData) {
      setTempInvoiceData(location?.state?.tempInvoiceData);
      setIsTemporary(true);
      setInputValue({ email: location?.state?.tempInvoiceData?.companyDetails?.email.trim() });
    }
  }, []);
  return (
    <div className="flex items-center justify-end signup-layout w-full">
      <div className="sign-up-container bg-white flex flex-col justify-center p-8 rounded-lg">
        <MDSubtitleText className="md:mt-0 mt-16" title="Experience professional invoicing!" fontWeight="text-bold" />
        <form id="signup-form" className="personal-info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <TextInput
              defaultValue={email}
              name="email"
              label="Email Address"
              variant="filled"
              InputLabelProps={isTemporary ? { shrink: true } : ''}
              onChange={handleChange}
              inputRef={register({
                required: 'Please fill in a valid Email Address',
                pattern: {
                  value: emailRegex,
                  message: 'Please fill in a valid Email Address'
                }
              })}
              error={errors?.email ? true : false}
              helperText={errors?.email && <ValidationMessage title={errors?.email?.message} />}
            />
            <TextInput
              defaultValue={companyName}
              name="companyName"
              className="mt-4"
              label="Company name"
              variant="filled"
              InputLabelProps={isTemporary ? { shrink: true } : ''}
              onChange={handleChange}
              inputRef={register({
                required: true
              })}
              error={errors?.companyName ? true : false}
              helperText={errors?.companyName && <ValidationMessage title={'Please fill in a valid Company name'} />}
            />
            <div className="relative flex flex-col-reverse md:flex-row md:justify-center items-center">
              <div
                className={`${
                  password && !isPasswordCriteriaValid() && !isPasswordNotFocused ? 'block mt-4' : 'hidden'
                } p-4 md:absolute  md:bg-white bg-green-200 rounded-lg ${
                  showPasswordError
                    ? 'create-password-validation-container-on-error'
                    : 'create-password-validation-container'
                }`}
              >
                <BaseText className="mb-4" fontWeight="text-bold" title={'Your password must have:'} />
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.tenCharacters ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.tenCharacters ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.tenCharacters && 'text-bold'}
                    title={'At least 10 characters'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.upperCase ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.upperCase ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.upperCase && 'text-bold'}
                    title={'At least one uppercase letter'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.lowerCase ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.lowerCase ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.lowerCase && 'text-bold'}
                    title={'At least one lowercase letter'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.digit ? <CheckByStreamLineHq fill={MUI_COLORS.GREEN} /> : <CheckByStreamLineHq />}
                  <MSText
                    textColor={passwordValidation.digit ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.digit && 'text-bold'}
                    title={'At least one digit number'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.space ? <CheckByStreamLineHq fill={MUI_COLORS.GREEN} /> : <CheckByStreamLineHq />}
                  <MSText
                    textColor={passwordValidation.space ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.space && 'text-bold'}
                    title={'No spaces'}
                  />
                </div>
                <div className="flex gap-2 items-center mt-2">
                  {passwordValidation.specialCharacter ? (
                    <CheckByStreamLineHq fill={MUI_COLORS.GREEN} />
                  ) : (
                    <CheckByStreamLineHq />
                  )}
                  <MSText
                    textColor={passwordValidation.specialCharacter ? 'text-green-signup' : 'text-light-gray'}
                    fontWeight={passwordValidation.specialCharacter && 'text-bold'}
                    title={'At least one special character ( # * ! etc. )'}
                  />
                </div>
                <div className="absolute w-full left-0 right-0 mx-auto mt-5 validation-div-triangle-down"></div>
              </div>
              <div className="w-full">
                <Controller
                  control={control}
                  name="password"
                  render={({ value }) => (
                    <TextInput
                      type={showPassword?.currentPassword ? 'text' : 'password'}
                      label="Password"
                      name="password"
                      onRightSideAdornment={currentPasswordClicked}
                      value={value}
                      onChange={handleChange}
                      endAdornment={showPassword?.currentPassword ? <Visibility /> : <VisibilityOff />}
                      textFieldContainerClass="mt-4"
                      inputRef={register({
                        required: true
                      })}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      error={errors?.password ? true : false}
                      helperText={errors?.password && <ValidationMessage title={'Please fill in a valid password'} />}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex items-center mt-6">
              <img src={IconLock} alt="IconLock" />
              <MSText textColor="text-gray-450" title="Your data will be stored in a Singapore-based data center." />
            </div>
            <div className="flex flex-row items-center gap-4">
              <XSText
                className="my-6"
                color="text-grey-500"
                title={
                  <span>
                    By registering, you confirm you have read and agree to <br />
                    the{' '}
                    <span
                      onClick={() => setOpenTermModal(true)}
                      className="acknowledge-content text-bold cursor-pointer"
                    >
                      terms and conditions
                    </span>{' '}
                    and the{' '}
                    <span
                      onClick={() => setOpenPrivacyModal(true)}
                      className="acknowledge-content text-bold cursor-pointer"
                    >
                      privacy policy
                    </span>
                    .
                  </span>
                }
              ></XSText>
            </div>
            {!termAndConditions && required && (
              <div className="md:mb-4 mb-3">
                <ValidationMessage title={'In order to sign up you need to agree to these terms.'} />
              </div>
            )}
            <PrimaryButton
              id="sign-up-form-btn"
              bgColor="bg-coral-500"
              className="button"
              isLoading={loading}
              caption="Sign Up"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
      <TermAndConditionModal openModal={openTermModal} closeDynamicModal={() => setOpenTermModal(false)} />
      <PrivacyAndPolicyModal openPrivacyModal={openPrivacyModal} closeDynamicModal={() => setOpenPrivacyModal(false)} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (signupPayload) => dispatch(SignupActions.signup(signupPayload))
  };
};

export default connect(null, mapDispatchToProps)(SignupForm);

SignupForm.propTypes = {
  signup: PropTypes.func
};
