import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DynamicModal from '../ui/modal/DynamicModal';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import HSBCPayMe from '../invoicePaymentDetails/HSBCPayMe';
import CustomPayment from '../invoicePaymentDetails/CustomPayment';
import InvoiceBankDetails from '../invoicePaymentDetails/InvoiceBankDetails';
import PaypalDetails from '../invoicePaymentDetails/PaypalDetails';
import '../../styles/pages/payment-details.scss';
import OptionBar from '../ui/OptionBar';
import Paypal from '../../images/icons/paypal.svg';
import Payme from '../../images/icons/payme.svg';
import HyperLink from '../../images/icons/hyper-link.svg';
import TypeCursor from '../../images/icons/TypeCursor.svg';
import SavingBankCash from '../../images/icons/saving-bank-cash.svg';
import * as paymentActions from '../invoicePaymentDetails/reduxStore/action';
import PaymentDetailsList from '../invoicePaymentDetails/PaymentDetailsList';
import CustomText from '../invoicePaymentDetails/CustomText';

function InvoicePayment({ openDynamicModal, closeDynamicModal, getBankDetailsList, getInvoiceDetails, isTemporary }) {
  const [value, setValue] = useState(0);
  const [isEditBankDetails, setIsEditBankDetails] = useState(false);
  const [bankId, setBankId] = useState(false);
  const onEditBankDetails = async (bankId) => {
    setIsEditBankDetails(true);
    setBankId(bankId);
    setValue(1);
  };

  const handleChange = (step) => {
    setValue(step);
  };

  const handleCloseModal = async () => {
    if (!isTemporary) {
      await bankDetailsList();
    }
    closeDynamicModal();
  };

  const handleSelectPaymentDetails = async () => {
    setValue(5);
  };
  const handleCancelbutton = async () => {
    if (!isTemporary) {
      const bankDetailsList = await getBankDetailsList();
      if (
        bankDetailsList?.data?.data?.length ||
        getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink ||
        getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink
      ) {
        setValue(5);
      } else setValue(0);
    } else {
      if (getInvoiceDetails?.paymentDetails) {
        setValue(5);
      } else {
        setValue(0);
      }
    }
  };
  useEffect(() => {
    if (isTemporary) {
      handleCancelbutton();
    }
  }, [getInvoiceDetails?.paymentDetails]);

  useEffect(() => {
    if (!isTemporary) {
      bankDetailsList();
    }
  }, []);
  const bankDetailsList = async () => {
    const bankDetailsList = await getBankDetailsList();
    if (
      bankDetailsList?.data?.data?.length ||
      getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink ||
      getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink
    ) {
      setValue(5);
    } else setValue(0);
  };
  const handleAddBankAccount = () => {
    setIsEditBankDetails(false);
    setValue(1);
  };
  return (
    <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={() => handleCloseModal()} minWidth="560px">
      <MDSubtitleText title={'Payment details'} className="mb-8" fontWeight="text-bold" />
      {value === 0 && (
        <div>
          <OptionBar
            onClick={() => handleAddBankAccount()}
            icon={SavingBankCash}
            label="Add bank account details"
            isFirstOption={true}
          />
          <OptionBar onClick={() => handleChange(2)} icon={Paypal} label="Add PayPal link" />
          <OptionBar onClick={() => handleChange(3)} icon={Payme} label="Add PayMe link" />
          <OptionBar onClick={() => handleChange(4)} icon={HyperLink} label="Custom payment link (Wise, Stripe, etc)" />
          <OptionBar onClick={() => handleChange(6)} icon={TypeCursor} label="Custom text" isLastOption={true} />
        </div>
      )}
      <div className="mt-8">
        {value === 1 && (
          <InvoiceBankDetails
            handleCancelbutton={handleCancelbutton}
            isEdit={isEditBankDetails}
            bankId={bankId}
            closeDynamicModal={closeDynamicModal}
            isTemporary={isTemporary}
          />
        )}
        {value === 2 && (
          <PaypalDetails
            handleSelectPaymentDetails={handleSelectPaymentDetails}
            closeDynamicModal={closeDynamicModal}
          />
        )}
        {value === 3 && (
          <HSBCPayMe handleSelectPaymentDetails={handleSelectPaymentDetails} closeDynamicModal={closeDynamicModal} />
        )}
        {value === 4 && (
          <CustomPayment
            handleSelectPaymentDetails={handleSelectPaymentDetails}
            closeDynamicModal={closeDynamicModal}
          />
        )}
        {value === 5 && (
          <PaymentDetailsList
            handleCancelbutton={handleCancelbutton}
            setStep={(step) => setValue(step)}
            closeDynamicModal={closeDynamicModal}
            onEditBankDetails={(bankId) => onEditBankDetails(bankId)}
            isTemporary={isTemporary}
          />
        )}
        {value === 6 && (
          <CustomText handleSelectPaymentDetails={handleSelectPaymentDetails} closeDynamicModal={closeDynamicModal} />
        )}
      </div>
    </DynamicModal>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBankDetailsList: () => dispatch(paymentActions.getBankDetailsList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayment);

InvoicePayment.propTypes = {};
