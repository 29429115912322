import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import PropTypes from 'prop-types';
import Snackbar from '../components/ErrorSnackbar';

const ReduxWrapper = ({ element }) => (
  <Provider store={store}>
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transitionDuration={{ enter: 1000, exit: 5000 }}
      TransitionProps={{ enter: true, exit: false }}
      sx={{ width: '100%' }}
    />
    {element}
  </Provider>
);

export default ReduxWrapper;

ReduxWrapper.propTypes = {
  element: PropTypes.string
};
