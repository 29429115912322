import React, { useEffect, useState } from 'react';
import Seo from '../components/seo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InvoiceDetails from '../components/invoice/InvoiceDetails';
import * as userActions from '../components/invoice/reduxStore/action';
import '../styles/pages/invoice.scss';

const InvoiceGenerator = ({ resetInvoiceDetails }) => {
  const [isTempDataStored, setIsTempDataStored] = useState(true);
  useEffect(() => {
    setIsTempDataStored(true);
    return () => {
      resetInvoiceDetails();
    };
  }, []);
  return (
    <div className="max-w-7xl layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
      {isTempDataStored && (
        <InvoiceDetails
          isTemporary={isTempDataStored}
          isEditDataStored={isTempDataStored}
          showGoodAndServices={false}
        />
      )}
    </div>
  );
};

export const Head = () => <Seo title="Create Your First Invoice" />;

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetInvoiceDetails: () => dispatch(userActions.resetInvoiceDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceGenerator);

InvoiceGenerator.propTypes = {
  resetInvoiceDetails: PropTypes.any
};
