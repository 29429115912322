import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const markAsPaidRequestStart = () => ({
  type: actionTypes.MARK_AS_PAID_REQUEST_START
});
export const markAsPaidRequestFail = (error) => ({
  type: actionTypes.MARK_AS_PAID_REQUEST_FAIL,
  error: error
});
export const markAsPaidRequestSuccess = (markAsPaidData) => ({
  type: actionTypes.MARK_AS_PAID_REQUEST_SUCCESS,
  markAsPaidData: markAsPaidData
});
export const markAsPaid = (markAsPaidPayload) => {
  return async (dispatch) => {
    try {
      dispatch(markAsPaidRequestStart());
      let markAsPaidResponse = await Api.post(ENDPOINTS.INVOICE_IN_MARK_AS_PAID, markAsPaidPayload);
      dispatch(markAsPaidRequestSuccess(markAsPaidResponse));
      return markAsPaidResponse;
    } catch (error) {
      dispatch(markAsPaidRequestFail(error.response));
    }
  };
};

export const updateMarkAsPaidRequestStart = () => ({
  type: actionTypes.UPDATE_MARK_AS_PAID_REQUEST_START
});
export const updateMarkAsPaidRequestFail = (error) => ({
  type: actionTypes.UPDATE_MARK_AS_PAID_REQUEST_FAIL,
  error: error
});
export const updateMarkAsPaidRequestSuccess = (markAsPaidData) => ({
  type: actionTypes.UPDATE_MARK_AS_PAID_REQUEST_SUCCESS,
  markAsPaidData: markAsPaidData
});
export const updateMarkAsPaid = (markAsPaidPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateMarkAsPaidRequestStart());
      let markAsPaidResponse = await Api.put(ENDPOINTS.UPDATE_MARK_AS_PAID, markAsPaidPayload);
      dispatch(updateMarkAsPaidRequestSuccess(markAsPaidResponse));
      return markAsPaidResponse;
    } catch (error) {
      dispatch(updateMarkAsPaidRequestFail(error.response));
    }
  };
};

export const deleteMarkAsPaidRequestStart = () => ({
  type: actionTypes.DELETE_MARK_AS_PAID_REQUEST_START
});
export const deleteMarkAsPaidRequestFail = (error) => ({
  type: actionTypes.DELETE_MARK_AS_PAID_REQUEST_FAIL,
  error: error
});
export const deleteMarkAsPaidRequestSuccess = (markAsPaidData) => ({
  type: actionTypes.DELETE_MARK_AS_PAID_REQUEST_SUCCESS,
  markAsPaidData: markAsPaidData
});
export const deleteMarkAsPaid = (paymentData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteMarkAsPaidRequestStart());
      let markAsPaidResponse = await Api.delete(ENDPOINTS.DELETE_MARK_AS_PAID, paymentData);
      dispatch(deleteMarkAsPaidRequestSuccess(markAsPaidResponse));
      return markAsPaidResponse;
    } catch (error) {
      dispatch(deleteMarkAsPaidRequestFail(error.response));
    }
  };
};

export const sendInvoiceEmailRequestStart = () => ({
  type: actionTypes.SEND_INVOICE_EMAIL_REQUEST_START
});
export const sendInvoiceEmailRequestFail = (error) => ({
  type: actionTypes.SEND_INVOICE_EMAIL_REQUEST_FAIL,
  error: error
});
export const sendInvoiceEmailRequestSuccess = (emailResponse) => ({
  type: actionTypes.SEND_INVOICE_EMAIL_REQUEST_SUCCESS,
  emailResponse: emailResponse
});
export const sendInvoiceEmail = (emailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(sendInvoiceEmailRequestStart());
      let sendInvoiceEmailResponse = await Api.post(ENDPOINTS.INVOICE_IN_SEND_EMAIL, emailPayload);
      dispatch(sendInvoiceEmailRequestSuccess(sendInvoiceEmailResponse));
      return sendInvoiceEmailResponse;
    } catch (error) {
      dispatch(sendInvoiceEmailRequestFail(error.response));
    }
  };
};

export const invoiceValidateRequestStart = () => ({
  type: actionTypes.INVOICE_VALIDATE_REQUEST_START
});
export const invoiceValidateRequestFail = (error) => ({
  type: actionTypes.INVOICE_VALIDATE_REQUEST_FAIL,
  error: error
});
export const invoiceValidateRequestSuccess = (validationResponse) => ({
  type: actionTypes.INVOICE_VALIDATE_REQUEST_SUCCESS,
  validationResponse: validationResponse
});
export const checkInvoiceValidation = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceValidateRequestStart());
      let validationResponse = await Api.put(ENDPOINTS.INVOICE_IN_VALIDATE, payload);
      dispatch(invoiceValidateRequestSuccess(validationResponse));
      return validationResponse;
    } catch (error) {
      dispatch(invoiceValidateRequestFail(error.response));
    }
  };
};
