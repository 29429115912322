import React from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TextInput from '../ui/inputs/TextInput';
import XSText from '../ui/typography/XSText';
import StudyLightIcon from '../../images/icons/green-study-light1.svg';
import { defaultValidationMessage } from '../../helper/helpers';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as userActions from '../invoice/reduxStore/action';
import PropTypes from 'prop-types';
function CustomPayment({ invoiceData, getInvoiceDetails, handleSelectPaymentDetails }) {
  const { register, handleSubmit, errors } = useForm();
  const onSubmitHsbcDetails = (data) => {
    invoiceData({
      paymentDetails: {
        ...getInvoiceDetails.paymentDetails,
        customPaymentDetails: {
          ...getInvoiceDetails?.paymentDetails?.customPaymentDetails,
          customLink: data.customLink,
          customLabel: data.customLabel.toUpperCase(),
          customLabel: data?.customLabel.charAt(0)?.toUpperCase() + data?.customLabel?.slice(1)
        }
      }
    });
    handleSelectPaymentDetails();
  };
  return (
    <div>
      <form id="paypal-details-form" onSubmit={handleSubmit(onSubmitHsbcDetails)}>
        <TextInput
          defaultValue={
            getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink &&
            getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink
          }
          margin="w-full"
          name="customLink"
          label="Custom link"
          inputRef={register({
            required: true,
            pattern: /^https:\/\/\S+/
          })}
          error={errors.customLink ? true : false}
          helperText={errors.customLink && <ValidationMessage title={defaultValidationMessage('link')} />}
        />
        <TextInput
          defaultValue={
            getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel &&
            getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel
          }
          margin="w-full mt-4"
          name="customLabel"
          label="Label"
          inputRef={register({
            required: true
          })}
          error={errors.customLabel ? true : false}
          helperText={errors.customLabel && <ValidationMessage title={defaultValidationMessage('label')} />}
        />
        <div>
          <XSText
            className="mt-4 mb-6"
            title={
              <span className="flex bg-green-200 p-6 rounded-lg">
                <span className="mr-4">
                  <img src={StudyLightIcon} alt="StudyLight icon" />
                </span>
                <span>
                  <div className="text-bold mb-1">Custom link</div>
                  Add a payment link to Wise, Stripe, or any payment gateway of your choice.
                  <div className="text-bold mt-4 mb-1">Label</div>
                  This label will be used on the Invoice link you share with your clients.
                  <div className="mt-4">
                    Statrys assumes no legal responsibility for the content of any external links provided by users
                    within the invoicing platform. All liability pertaining to the content and use of these links rests
                    solely with the user.
                  </div>
                </span>
              </span>
            }
          />
        </div>
        <PrimaryButton
          id="hsbc-pay-me-add-btn"
          onClick={handleSubmit(onSubmitHsbcDetails)}
          className="modal-save-button mt-4 mr-4"
          caption="Add to invoice"
          fontWeight="text-bold"
          type="small"
        />
        <PrimaryButton
          id="hsbc-pay-me-cancel-btn"
          onClick={handleSelectPaymentDetails}
          bgColor="bg-white"
          className="modal-cancel-button mt-4"
          color="text-gray-300"
          caption="Cancel"
          fontWeight="text-bold"
          type="small"
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPayment);

CustomPayment.propTypes = {
  invoiceData: PropTypes.func,
  handleSelectPaymentDetails: PropTypes.func
};
