import React from 'react';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import '../../styles/headerNavigation.scss';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import BaseText from '../../components/ui/typography/BaseText';
import { PATH_PAGE } from '../../routes/paths';
import { getUser } from '../../components/authService/auth';
import PrimaryButton from '../ui/buttons/PrimaryButton';

const navigation = [
  {
    id: 1,
    caption: 'Sign in',
    to: PATH_PAGE?.login,
    captionColor: 'text-coral-500',
    bgColor: 'bg-white'
  }
];

const mobileViewMenuOptions = navigation;

export default function HeaderNavigation({ hideSignInButton }) {
  const user = getUser();
  const firstName = user?.firstName ? user?.firstName : 'Profile';
  const lastName = user?.lastName ? user?.lastName : 'name';
  return (
    <div id="main-container">
      <div className="md:static flex flex-row justify-between  mb-4">
        <Link className="flex hover:no-underline" to={!hideSignInButton ? PATH_PAGE.invoiceList : ''}>
          <img src={StatrysLogo} alt="Statrys Logo" />
        </Link>
        <div className="items-center flex">
          <div className="menu-text-container flex gap gap-4">
            {!hideSignInButton &&
              navigation?.map((menuItem, index) => {
                return (
                  <PrimaryButton
                    id="public-header-navigation-btn"
                    type={true}
                    className="public-navigation-button"
                    color={menuItem?.captionColor}
                    bgColor={menuItem?.bgColor}
                    urlLink={menuItem?.to}
                    caption={menuItem?.caption}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
