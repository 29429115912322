export const GET_IMPORTING_INVOICE_DETAILS_REQUEST_START = 'GET_IMPORTING_INVOICE_DETAILS_REQUEST_START';
export const GET_IMPORTING_INVOICE_DETAILS_REQUEST_FAIL = 'GET_IMPORTING_INVOICE_DETAILS_REQUEST_FAIL';
export const GET_IMPORTING_INVOICE_DETAILS_REQUEST_SUCCESS = 'GET_IMPORTING_INVOICE_DETAILS_REQUEST_SUCCESS';

export const GET_IMPORTING_INVOICE_LIST_REQUEST_START = 'GET_IMPORTING_INVOICE_LIST_REQUEST_START';
export const GET_IMPORTING_INVOICE_LIST_REQUEST_FAIL = 'GET_IMPORTING_INVOICE_LIST_REQUEST_FAIL';
export const GET_IMPORTING_INVOICE_LIST_REQUEST_SUCCESS = 'GET_IMPORTING_INVOICE_LIST_REQUEST_SUCCESS';

export const CREATE_IMPORTING_INVOICE_REQUEST_START = 'CREATE_IMPORTING_INVOICE_REQUEST_START';
export const CREATE_IMPORTING_INVOICE_REQUEST_FAIL = 'CREATE_IMPORTING_INVOICE_REQUEST_FAIL';
export const CREATE_IMPORTING_INVOICE_REQUEST_SUCCESS = 'CREATE_IMPORTING_INVOICE_REQUEST_SUCCESS';

export const EDIT_IMPORTING_INVOICE_REQUEST_START = 'EDIT_IMPORTING_INVOICE_REQUEST_START';
export const EDIT_IMPORTING_INVOICE_REQUEST_FAIL = 'EDIT_IMPORTING_INVOICE_REQUEST_FAIL';
export const EDIT_IMPORTING_INVOICE_REQUEST_SUCCESS = 'EDIT_IMPORTING_INVOICE_REQUEST_SUCCESS';

export const DELETE_IMPORTED_INVOICE_REQUEST_START = 'DELETE_IMPORTED_INVOICE_REQUEST_START';
export const DELETE_IMPORTED_INVOICE_REQUEST_FAIL = 'DELETE_IMPORTED_INVOICE_REQUEST_FAIL';
export const DELETE_IMPORTED_INVOICE_REQUEST_SUCCESS = 'DELETE_IMPORTED_INVOICE_REQUEST_SUCCESS';

export const UPLOAD_IMPORTED_INVOICE_REQUEST_START = 'UPLOAD_IMPORTED_INVOICE_REQUEST_START';
export const UPLOAD_IMPORTED_INVOICE_REQUEST_FAIL = 'UPLOAD_IMPORTED_INVOICE_REQUEST_FAIL';
export const UPLOAD_IMPORTED_INVOICE_REQUEST_SUCCESS = 'UPLOAD_IMPORTED_INVOICE_REQUEST_SUCCESS';

export const GET_IMPORTING_PUBLIC_REQUEST_START = 'GET_IMPORTING_PUBLIC_REQUEST_START';
export const GET_IMPORTING_PUBLIC_REQUEST_FAIL = 'GET_IMPORTING_PUBLIC_REQUEST_FAIL';
export const GET_IMPORTING_PUBLIC_REQUEST_SUCCESS = 'GET_IMPORTING_PUBLIC_REQUEST_SUCCESS';

export const REQUEST_VALIDATION_INVOICE_REQUEST_START = 'REQUEST_VALIDATION_INVOICE_REQUEST_START';
export const REQUEST_VALIDATION_INVOICE_REQUEST_FAIL = 'REQUEST_VALIDATION_INVOICE_REQUEST_FAIL';
export const REQUEST_VALIDATION_INVOICE_REQUEST_SUCCESS = 'REQUEST_VALIDATION_INVOICE_REQUEST_SUCCESS';

export const GET_VALIDATORS_REQUEST_START = 'GET_VALIDATORS_REQUEST_START';
export const GET_VALIDATORS_REQUEST_FAIL = 'GET_VALIDATORS_REQUEST_FAIL';
export const GET_VALIDATORS_REQUEST_SUCCESS = 'GET_VALIDATORS_REQUEST_SUCCESS';

export const GET_IMPORTING_ANALYTICS_REQUEST_START = 'GET_IMPORTING_ANALYTICS_REQUEST_START';
export const GET_IMPORTING_ANALYTICS_REQUEST_FAIL = 'GET_IMPORTING_ANALYTICS_REQUEST_FAIL';
export const GET_IMPORTING_ANALYTICS_REQUEST_SUCCESS = 'GET_IMPORTING_ANALYTICS_REQUEST_SUCCESS';
