import React from 'react';
import SendEmail from '../../../images/icons/customSvg/SendEmail';
import XSText from '../../ui/typography/XSText';
import Arrow from '../../../images/icons/forwardArrow.svg';
import XMSText from '../../ui/typography/XMSText';
import MSText from '../../ui/typography/MSText';

function SendMessageButton({ openForm }) {
  return (
    <div className="flex flex-col" id="send-msg">
      <div className="border border-gray-200 rounded-2xl cursor-pointer" onClick={openForm}>
        <div className="p-4 flex flex-row justify-between items-center">
          <div className="bg-gray-150 rounded-lg w-10 h-10 flex justify-center items-center">
            <SendEmail />
          </div>
          <div className="flex flex-col gap gap-1">
            <XSText title="Send a message to support" fontWeight="text-medium" />
            <MSText title="Explain your query in a form" />
          </div>
          <img src={Arrow} alt="move forward" />
        </div>
      </div>
      <XMSText
        title={`Monday to Friday (except HK public holidays) \nFrom 9AM to 7PM HKT (GMT+8)`}
        className="md:whitespace-pre-line mt-6"
        textColor="text-gray-450"
        textAlign="text-center"
      />
    </div>
  );
}

export default SendMessageButton;
