import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import axios from 'axios';

const STATRYS_BASE_API_URL = process.env.STATRYS_BASE_API_URL;

export const fileUploadRequestStart = () => ({ type: actionTypes.FILE_UPLOAD_REQUEST_START });
export const fileUploadRequestFail = (error) => ({
  type: actionTypes.FILE_UPLOAD_REQUEST_FAILURE,
  error: error
});
export const fileUploadRequestSuccess = (uploadedFile) => ({
  type: actionTypes.FILE_UPLOAD_REQUEST_SUCCESS,
  uploadedFile: uploadedFile
});
export const fileUpload = (fileUploadPayload) => {
  return async (dispatch) => {
    try {
      dispatch(fileUploadRequestStart());
      let fileUploadResponse = await Api.put(ENDPOINTS.UPLOAD, fileUploadPayload);
      dispatch(fileUploadRequestSuccess(fileUploadResponse?.data));
      return fileUploadResponse;
    } catch (error) {
      dispatch(fileUploadRequestFail(error.response));
      return error?.response;
    }
  };
};

export const checkSendEmailRequestStart = () => ({ type: actionTypes.CHECK_SEND_EMAIL_REQUEST_START });
export const checkSendEmailRequestFail = (error) => ({
  type: actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE,
  error: error
});
export const checkSendEmailRequestSuccess = (checkSendEmail) => ({
  type: actionTypes.CHECK_SEND_EMAIL_REQUEST_SUCCESS,
  checkSendEmail: checkSendEmail
});
export const checkSendEmail = (checkSendEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(checkSendEmailRequestStart());
      let checkSendEmailResponse = await Api.post(ENDPOINTS.CHECK_SEND_EMAIL, { invoiceId: checkSendEmailPayload });
      dispatch(checkSendEmailRequestSuccess(checkSendEmailResponse?.data));
      return checkSendEmailResponse;
    } catch (error) {
      dispatch(checkSendEmailRequestFail(error.response));
      return error?.response;
    }
  };
};
export const checkValidateSendEmailRequestStart = () => ({ type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_START });
export const checkValidateSendEmailRequestFail = (error) => ({
  type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_FAILURE,
  error: error
});
export const checkValidateSendEmailRequestSuccess = (checkValidateSendEmail) => ({
  type: actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS,
  checkValidateSendEmail: checkValidateSendEmail
});
export const checkValidateSendEmail = (checkValidateSendEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(checkValidateSendEmailRequestStart());
      let checkValidateSendEmailResponse = await Api.post(ENDPOINTS.CHECK_VALIDATE_SEND_EMAIL, {
        invoiceId: checkValidateSendEmailPayload
      });
      dispatch(checkValidateSendEmailRequestSuccess(checkValidateSendEmailResponse?.data));
      return checkValidateSendEmailResponse;
    } catch (error) {
      dispatch(checkValidateSendEmailRequestFail(error.response));
      return error?.response;
    }
  };
};

export const getCountryRequestStart = () => ({ type: actionTypes.GET_COUNTRY_REQUEST_START });
export const getCountryRequestFail = (error) => ({
  type: actionTypes.GET_COUNTRY_REQUEST_FAILURE,
  error: error
});
export const getCountryRequestSuccess = (getCountryPayload) => ({
  type: actionTypes.GET_COUNTRY_REQUEST_SUCCESS,
  getCountryPayload: getCountryPayload
});
export const getCountry = (getCountryPayload) => {
  return async (dispatch) => {
    try {
      dispatch(getCountryRequestStart());
      let getCountryResponse = await axios.get(STATRYS_BASE_API_URL, getCountryPayload);
      dispatch(getCountryRequestSuccess(getCountryResponse?.data));
      return getCountryResponse;
    } catch (error) {
      dispatch(getCountryRequestFail(error.response));
      return error?.response;
    }
  };
};
