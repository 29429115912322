import React from 'react';
import PropTypes from 'prop-types';

function H4HeaderText({ title, className, textColor, fontWeight, textAlign }) {
  return (
    <div className={`${className}`}>
      <h4 className={`${fontWeight} whitespace-pre-line ${textColor} ${textAlign} letter-spacing-p`}>{title}</h4>
    </div>
  );
}

export default H4HeaderText;

H4HeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string
};

H4HeaderText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold'
};
