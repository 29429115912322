import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby'; // @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';
// routes
import { PATH_PAGE } from '../routes/paths';
// hooks
import useTabs from '../hooks/useTabs';
import useTable, { getComparator, emptyRows } from '../hooks/useTable';
// _mock_
import {
  API_CODE,
  INVOICE_STATUS,
  MUI_COLORS,
  PAGE_TITLE,
  ROW_PER_PAGE_OPTIONS,
  STATUS_COLORS,
  TYPE_OF_INVOICE
} from '../constants/enum';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../components/table';
import Layout from '../components/layout';
// sections
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import IconPlus from '../images/icons/plus-icon.svg';
import MSText from '../components/ui/typography/MSText';
import { getTimeDate } from '../utils/formatTime';
import XSText from '../components/ui/typography/XSText';
import Snackbar from '../components/Snackbar';
import Image from '../components/Image';
import Success from '../images/icons/success-delete-icon.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableStatrysLoader from '../components/table/TableStatrysLoader';
import { addCommas } from '../helper/helpers';
import AnalyticsGetpaid from '../sections/@dashboard/invoice/AnalyticsGetpaid';
import PayTableRow from '../sections/@dashboardPay/payList/PayTableRow';
import PayTableToolbar from '../sections/@dashboardPay/payList/PayTableToolbar';
import SettingToggleIcon from '../images/icons/Invoice/gear-icon.svg';
import * as importingInvoiceReducer from '../components/importedInvoices/reduxStore/action';
import ImportedInvoiceUploadModal from '../components/importedInvoices/ImportedInvoiceUploadModal';
import * as profileActions from '../components/profile/reduxStore/action';
import PayInvoiceBanner from '../components/importedInvoices/PayInvoiceBanner';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'All',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development'
];

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Client', align: 'left' },
  { id: 'createdAt', label: 'Create', align: 'left' },
  { id: 'dueDate', label: 'Due', align: 'left' },
  { id: 'totalAmount', label: 'Amount', align: 'left', width: 180 },
  { id: 'status', label: 'Status', align: 'left', width: 180 },
  { id: '' }
];

// ----------------------------------------------------------------------

function InvoiceSupplierList({
  getImportingInvoiceList,
  deleteImportedInvoice,
  getImportingDashboardData,
  getCurrencyPreference
}) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'dateCreated' });
  const [isLoading, setIsLoading] = useState(true);

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');
  const [currency, setCurrency] = useState('');

  const [filterService, setFilterService] = useState(INVOICE_STATUS.INVOICE_TOTAL);

  const [filterStartDate, setFilterStartDate] = useState(null);

  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dashboardDataCurrency, setDashboardDataCurrency] = useState('');

  const [uploadModal, setUploadModal] = useState(false);

  const closeDynamicModal = () => {
    setUploadModal(false);
  };

  const [open, setOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs(INVOICE_STATUS.INVOICE_TOTAL);
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setCurrency(getCurrencyDataResponse?.data?.data?.currency);
    }
  };

  const findTotalCharacter = (currency, amount) => {
    if (currency && amount) {
      const countOfChar = `${currency?.symbol ? currency?.symbol : currency?.name} ${amount}`;
      if (countOfChar.length > 12) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleDeleteRow = async (id, invoiceNumber) => {
    const deleteInvoiceResponse = await deleteImportedInvoice(id);
    if (deleteInvoiceResponse?.status === API_CODE.STATUS_200) {
      const deleteRow = tableData?.filter((row) => row?._id !== id);
      setSelected([]);
      setTableData(deleteRow);
      setSuccessMessage({
        ...successMessage,
        title: deleteInvoiceResponse?.data?.message,
        content: `Invoice ${invoiceNumber} was successfully deleted!`
      });
      setOpen(true);
    }
    totalInvoiceAmount();
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData?.filter((row) => !selected.includes(row?._id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id) => {
    navigate(PATH_PAGE.editImportingInvoice + `?id=${id}`);
  };

  const handleViewRow = (id) => {
    navigate(PATH_PAGE.viewIncomingInvoices + `?id=${id}`);
  };

  const onClickSelectedRowHandler = (id) => {
    navigate(PATH_PAGE.viewIncomingInvoices + `?id=${id}`);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate
  });

  const isNotFound =
    (!dataFiltered?.length && !!filterName) ||
    (!dataFiltered?.length && !!filterStatus) ||
    (!dataFiltered?.length && !!filterService) ||
    (!dataFiltered?.length && !!filterEndDate) ||
    (!dataFiltered?.length && !!filterStartDate);

  const getLengthByStatus = (status) => tableData?.filter((item) => item.status === status).length;

  const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData?.length) * 100;

  const TABS = [
    {
      value: INVOICE_STATUS.INVOICE_TOTAL,
      label: INVOICE_STATUS.INVOICE_TOTAL,
      color: 'error',
      count: tableData?.length
    },
    {
      value: INVOICE_STATUS.INVOICE_PAID,
      label: INVOICE_STATUS.INVOICE_PAID,
      color: 'success',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_PAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_UNPAID,
      label: INVOICE_STATUS.INVOICE_UNPAID,
      color: 'warning',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_OVERDUE,
      label: INVOICE_STATUS.INVOICE_OVERDUE,
      color: 'error',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_OVERDUE),
      variant: 'filled'
    },
    {
      value: INVOICE_STATUS.INVOICE_REVIEW,
      label: INVOICE_STATUS.INVOICE_REVIEW,
      color: 'default',
      count: getLengthByStatus(INVOICE_STATUS.INVOICE_REVIEW),
      variant: 'filled'
    }
  ];

  const handleAnalyticsTabs = () => {
    navigate(PATH_PAGE.profile, {
      state: { preferences: true }
    });
  };

  const totalInvoiceAmount = async () => {
    const payload = {
      dashboardType: TYPE_OF_INVOICE.IMPORTING_INVOICE
    };
    const invoiceDashboardDataResponse = await getImportingDashboardData({ params: { ...payload } });
    if (invoiceDashboardDataResponse?.status === API_CODE.STATUS_200) {
      setDashboardDataCurrency(invoiceDashboardDataResponse?.data?.data);
    }
  };

  const handleImportingInvoiceList = async () => {
    setIsLoading(true);
    const getInvoiceListResponse = await getImportingInvoiceList();
    setTableData(getInvoiceListResponse?.data?.data?.invoiceData);
    setIsLoading(false);
  };

  useEffect(() => {
    handleImportingInvoiceList();
    totalInvoiceAmount();
    getPreferedCurrency();
  }, []);

  const handleTabChange = (e, value) => {
    if (value !== INVOICE_STATUS.INVOICE_TOTAL) {
      onChangePage(e, 0);
    }
    onFilterStatus(e, value);
  };

  return (
    <Layout>
      <Page title="Invoice: List">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.PAY}
          links={[{ name: '' }]}
          action={
            <PrimaryButton
              id="pay-upload-invoice-btn"
              onClick={() => setUploadModal(true)}
              caption={
                <div className="flex gap-1 items-center">
                  <img src={IconPlus} alt={IconPlus} />
                  <MSText textColor="text-white" fontWeight="text-bold" title="Upload Invoice" />
                </div>
              }
              type="small"
            />
          }
        />
        <ImportedInvoiceUploadModal openDynamicModal={uploadModal} closeDynamicModal={closeDynamicModal} />
        <Scrollbar>
          <Stack direction="row" sx={{ py: 2 }}>
            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Unpaid"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_UNPAID)}
              currency={currency}
              isAmountHuge={findTotalCharacter(currency, dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              price={addCommas(dashboardDataCurrency?.Unpaid?.toFixed(2))}
              rootClass="bg-yellow-500 mr-2"
              titleClass="text-blue-100"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
            />

            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Review"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_REVIEW)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_REVIEW)}
              currency={currency}
              price={addCommas(dashboardDataCurrency?.Review?.toFixed(2))}
              isAmountHuge={findTotalCharacter(currency, dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              rootClass="bg-yellow-300 mr-2"
              titleClass="text-blue-100"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
            />
            <AnalyticsGetpaid
              analyticsClass="w-2/12"
              title="Overdue"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_OVERDUE)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_OVERDUE)}
              currency={currency}
              price={addCommas(dashboardDataCurrency?.Overdue?.toFixed(2))}
              isAmountHuge={findTotalCharacter(currency, dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              rootClass="bg-salmon-500 mr-2"
              titleClass="text-red-alert"
              totalClass="text-coral-200"
              invoiceClass="text-coral-200"
            />
            <AnalyticsGetpaid
              title="Paid"
              analyticsClass="w-2/12"
              total={getLengthByStatus(INVOICE_STATUS.INVOICE_PAID)}
              percent={getPercentByStatus(INVOICE_STATUS.INVOICE_PAID)}
              currency={currency}
              price={addCommas(dashboardDataCurrency?.Paid?.toFixed(2))}
              isAmountHuge={findTotalCharacter(currency, dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              rootClass="bg-green-500 mr-2 "
              titleClass="text-green-200"
              amountColor="text-white"
              totalClass="text-white"
              invoiceClass="text-green-200"
            />
            <AnalyticsGetpaid
              analyticsClass="w-4/12"
              title="Total"
              total={tableData?.length}
              currency={currency}
              price={addCommas(dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              isAmountHuge={findTotalCharacter(currency, dashboardDataCurrency?.TotalAmount?.toFixed(2))}
              rootClass="bg-white"
              titleClass="text-blue-100"
              amountColor="text-blue-100"
              totalClass="text-blue-100"
              invoiceClass="text-gray-700"
              icon={SettingToggleIcon}
              handleAnalyticsTabs={handleAnalyticsTabs}
            />
          </Stack>
        </Scrollbar>
        {isLoading ? (
          <StatrysLoader />
        ) : tableData?.length > 0 ? (
          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={handleTabChange}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {TABS?.map((tab) => (
                <Tab
                  disableRipple
                  key={tab?.value}
                  value={tab?.value}
                  icon={
                    <Label color={tab?.color} variant={tab?.variant}>
                      {' '}
                      {tab?.count}{' '}
                    </Label>
                  }
                  label={tab?.label}
                />
              ))}
            </Tabs>
            <Divider />
            <PayTableToolbar
              filterName={filterName}
              filterService={filterService}
              filterStartDate={filterStartDate}
              filterEndDate={filterEndDate}
              onFilterName={handleFilterName}
              onFilterService={handleFilterService}
              onFilterStartDate={(newValue) => {
                setFilterStartDate(newValue);
              }}
              onFilterEndDate={(newValue) => {
                setFilterEndDate(newValue);
              }}
              optionsService={SERVICE_OPTIONS}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={tableData?.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?._id)
                      )
                    }
                    actions={
                      <Stack spacing={1} direction="row">
                        <Tooltip title="Sent">
                          <IconButton color="primary">
                            <Iconify icon={'ic:round-send'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                          <IconButton color="primary">
                            <Iconify icon={'eva:download-outline'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Print">
                          <IconButton color="primary">
                            <Iconify icon={'eva:printer-fill'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                  />
                )}

                <Table>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?._id)
                      )
                    }
                  />
                  <TableBody>
                    {isLoading ? (
                      <TableStatrysLoader />
                    ) : (
                      <>
                        {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <PayTableRow
                            key={row?._id}
                            row={row}
                            selected={selected.includes(row?._id)}
                            onClickSelectedRow={() => onClickSelectedRowHandler(row?._id, row?.status)}
                            onSelectRow={() => onSelectRow(row?._id)}
                            onViewRow={() => handleViewRow(row?._id)}
                            onEditRow={() => handleEditRow(row?._id)}
                            onDeleteRow={() => handleDeleteRow(row?._id, row?.invoiceNumber)}
                          />
                        ))}
                      </>
                    )}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[
                  ROW_PER_PAGE_OPTIONS.TEN,
                  ROW_PER_PAGE_OPTIONS.TWENTY_FIVE,
                  ROW_PER_PAGE_OPTIONS.FIFTY
                ]}
                component="div"
                count={dataFiltered?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        ) : (
          <PayInvoiceBanner handleUploadInvoice={() => setUploadModal(true)} />
        )}
        <Snackbar
          open={open}
          handleClose={handleClose}
          sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
          title={
            <div className="flex">
              <div className="flex items-center">
                <Image className="w-5 h-5" src={Success} />
              </div>
              <div className="ml-4">
                <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
                <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
              </div>
            </div>
          }
        ></Snackbar>
      </Page>
    </Layout>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.supplier?.companyName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== INVOICE_STATUS.INVOICE_TOTAL) {
    tableData = tableData?.filter((item) => item.status === filterStatus);
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData?.filter(
      (item) =>
        getTimeDate(item.createdAt) >= filterStartDate.getTime() && getTimeDate(item.dueDate) <= filterEndDate.getTime()
    );
  }

  return tableData;
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getImportingInvoiceList: (id) => dispatch(importingInvoiceReducer.getImportingInvoiceList(id)),
    getImportingDashboardData: (id) => dispatch(importingInvoiceReducer.getImportingDashboardData(id)),
    deleteImportedInvoice: (invoiceId) => dispatch(importingInvoiceReducer.deleteImportedInvoice(invoiceId)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSupplierList);

InvoiceSupplierList.propTypes = {
  getImportingInvoiceList: PropTypes.func,
  deleteImportedInvoice: PropTypes.func,
  getImportingDashboardData: PropTypes.func,
  getCurrencyPreference: PropTypes.func
};
