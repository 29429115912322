import React, { useState, useEffect } from 'react';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { API_CODE, CONFIDENCE_TYPE, INVOICE_STATUS, REGEX } from '../../constants/enum';
import { invoiceInputBackgroundColor } from '../../helper/helpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import BaseText from '../ui/typography/BaseText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import ImportingSelectAutoComplete from '../ui/inputs/importingSelectAutoComplete';
import InvoiceCurrencies from '../../data/invoice-currencies-list.json';
import * as importingInvoiceReducer from './reduxStore/action';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import StudyLightIdeaIcon from '../../images/icons/study-light-idea.svg';
import { useLocation } from '@reach/router';
import * as supplierReduxAction from '../supplier/reduxStore/action';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import XSText from '../ui/typography/XSText';
import DownArrow from '../../images/icons/importingInvoice/line-arrow-down.svg';
import UpArrow from '../../images/icons/importingInvoice/line-arrow-up.svg';
import * as supplierReducer from '../supplier/reduxStore/action';
import ValidationMessage from '../ui/ValidationMessage';
import moment from 'moment';

function ImportedInvoiceFrom({
  uploadedFileExtractedData,
  createSupplier,
  createImportedInvoice,
  getSupplierDetails,
  getUserProfile,
  getSupplierList,
  updateSupplier
}) {
  let amountRegex = REGEX.IS_GREATER_THAN_ZERO;
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState();
  const [invoiceSupplierData, setInvoiceSupplierData] = useState(uploadedFileExtractedData?.data?.invoiceResponse);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isFullyMatched, setIsFullyMatched] = useState(uploadedFileExtractedData?.data?.isFullyMatched);
  const [seeMore, setSeeMore] = useState(false);
  const [allSupplierList, setAllSupplierList] = useState([]);
  const [isNewSupplier, setIsNewSupplier] = useState();
  const [isInputValueChanged, setIsInputValueChanged] = useState(false);
  const [newSupplierData, setNewSupplierData] = useState('');
  const [resetSelectedSupplier, setResetSelectedSupplier] = useState('');
  const [isCurrency, setIsCurrency] = useState(false);
  const [isAmountValue, setIsAmountValue] = useState(false);
  const [invoiceExistedSupplier, setInvoiceExistedSupplier] = useState(
    uploadedFileExtractedData?.data?.supplier ? [uploadedFileExtractedData?.data?.supplier] : []
  );
  const [userProfileData, setUserProfileData] = useState('');
  const [isDueDate, setIsDueDate] = useState(false);
  const [creatDate, setCreatDate] = useState('');

  const { register, handleSubmit, watch, errors, reset, setValue } = useForm({
    defaultValues: {
      invoiceNumber: invoiceSupplierData?.invoiceNumber?.value,
      createDate: invoiceSupplierData?.date?.value,
      currency: invoiceSupplierData?.locale?.currency,
      dueDate: invoiceSupplierData?.dueDate?.value,
      companyName: invoiceSupplierData?.supplierName?.value,
      registrationNumber: invoiceSupplierData?.supplierCompanyRegistrations?.[0]?.value,
      address: invoiceSupplierData?.supplierAddress?.value,
      invoiceAmount: invoiceSupplierData?.totalAmount?.value,
      taxes: invoiceSupplierData?.taxes[0]?.value,
      accountNumber: invoiceSupplierData?.supplierPaymentDetails?.[0]?.accountNumber,
      swift: invoiceSupplierData?.supplierPaymentDetails?.[0]?.swift,
      routingCode: invoiceSupplierData?.supplierPaymentDetails?.[0]?.routing_number,
      iban: invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban
    }
  });

  const watchAllFields = watch();

  const onSelectingCountry = (country) => {
    setSelectedCountry(country?.name);
  };

  const handleSelectedCurrency = (value) => {
    if (value) {
      setSelectedCurrency(value);
      setIsCurrency(false);
    } else {
      setSelectedCurrency(value);
      setIsCurrency(true);
    }
  };

  const getProfileDetailsHandler = async () => {
    const getUserProfileDetail = await getUserProfile();
    if (getUserProfileDetail?.status === API_CODE?.STATUS_200) {
      setUserProfileData(getUserProfileDetail?.data?.data);
    }
  };

  const findElementByCurrencyName = (currencyName) => {
    const getCurrency = InvoiceCurrencies?.filter((currency) => currency?.name === currencyName?.toUpperCase());
    return getCurrency[0];
  };

  function compareDates(date1, date2) {
    const convertedDate1 = new Date(date1);
    const convertedDate2 = new Date(date2);
    const isFutureDueDate = convertedDate1 >= convertedDate2;
    if (isFutureDueDate) {
      setIsDueDate(false);
      return true;
    } else {
      setIsDueDate(true);
      return false;
    }
  }

  const handleCurrencyCheck = (condition) => {
    if (condition) {
      setIsCurrency(false);
      return true;
    } else {
      setIsCurrency(true);
      return false;
    }
  };

  const handleDueDate = (event) => {
    compareDates(event.target.value, creatDate);
  };

  const onSubmitSupplierDetails = (data) => {
    setCreatDate(data?.createDate);
    if (
      handleCurrencyCheck(selectedCurrency !== 'Other' && !isCurrency) &&
      compareDates(data?.dueDate, data?.createDate)
    ) {
      if (Object.keys(...invoiceExistedSupplier).length === 0 && isNewSupplier) {
        createNewSupplier(data);
      } else {
        if (isInputValueChanged) {
          updateSupplierHandler(data, selectedSupplier);
        } else {
          createImportedInvoiceHandler(data, selectedSupplier);
        }
      }
      setResetSelectedSupplier(false);
    }
  };

  const updateSupplierHandler = async (data, selectedSupplier) => {
    const supplierPayload = {
      supplierId: selectedSupplier?._id,
      companyName: selectedSupplier?.companyName,
      registrationNumber: data?.registrationNumber,
      state: data?.state,
      website: data?.website,
      email: data?.email,
      address: data?.address,
      city: data?.city,
      country: selectedCountry ? selectedCountry : selectedSupplier?.country,
      postalCode: data?.postalCode
    };

    const updateSupplierResponse = await updateSupplier(supplierPayload);
    if (updateSupplierResponse?.status === API_CODE.STATUS_200) {
      createImportedInvoiceHandler(data, updateSupplierResponse?.data?.data);
    }
  };

  const createNewSupplier = async (data) => {
    const importedInvoicePayload = {
      companyName: selectedSupplier?.companyName
        ? selectedSupplier?.companyName
        : uploadedFileExtractedData?.data?.invoiceResponse?.supplierName?.value,
      registrationNumber: data?.registrationNumber,
      state: data?.state,
      website: data?.website,
      email: data?.email,
      address: data?.address,
      city: data?.city,
      country: selectedCountry,
      postalCode: data?.postalCode
    };

    const createSupplierResponse = await createSupplier(importedInvoicePayload);
    if (createSupplierResponse?.status === API_CODE.STATUS_200) {
      createImportedInvoiceHandler(data, createSupplierResponse?.data?.data);
    }
  };

  const createImportedInvoiceHandler = async (data, supplierData) => {
    const importedInvoicePayload = {
      invoiceNumber: data?.invoiceNumber,
      totalAmount: data?.invoiceAmount,
      taxWithPrice: [{ taxPrice: data?.taxes }],
      createDate: data?.createDate,
      status: INVOICE_STATUS.INVOICE_UNPAID,
      validationStatus: INVOICE_STATUS.NONE,
      dueDate: data?.dueDate,
      pdfURL: imageUrl && imageUrl,
      currency: selectedCurrency ? selectedCurrency : findElementByCurrencyName(invoiceSupplierData?.locale?.currency),
      uploadedBy: userProfileData?.firstName
        ? `${userProfileData?.firstName} ${userProfileData?.lastName}`
        : userProfileData?.email,
      paymentDetails: {
        iBan: data?.iban,
        swift: data?.swift,
        bankName: data?.bankName,
        accountNumber: data?.accountNumber,
        routingCode: data?.routingCode
      },
      supplier: { ...supplierData, supplierId: supplierData._id }
    };

    const createImportedInvoicesResponse = await createImportedInvoice(importedInvoicePayload);
    if (createImportedInvoicesResponse?.status === API_CODE.STATUS_200) {
      setSelectedSupplier('');
      navigate(PATH_PAGE.viewIncomingInvoices + `?id=${createImportedInvoicesResponse?.data?.data?._id}`);
    }
  };

  const findConfidanceInputedData = (confidence) => {
    if (confidence === CONFIDENCE_TYPE.HIGH) {
      return <span className="bg-green-500 text-white imported-invoice-form-label">High </span>;
    } else if (confidence < CONFIDENCE_TYPE.HIGH && confidence > CONFIDENCE_TYPE.LOW) {
      return <span className="bg-yellow-500 text-white imported-invoice-form-label">Low Confidence</span>;
    } else {
      return <span className="bg-coral-200 text-white imported-invoice-form-label">Very Low Confidence</span>;
    }
  };

  const handleInputedAmount = (e) => {
    setValue('invoiceAmount', e.target.value);
  };

  const moreSupplierDetails = () => {
    if (seeMore === false) {
      setSeeMore(true);
    } else {
      setSeeMore(false);
    }
  };

  const getSupplierListHandler = async () => {
    const getSupplierListResponse = await getSupplierList();
    if (getSupplierListResponse?.status === API_CODE.STATUS_200) {
      setAllSupplierList(getSupplierListResponse?.data?.data);
    }
  };

  const onSelectingSupplier = (values) => {
    setIsInputValueChanged(false);
    setResetSelectedSupplier(values);
    setSeeMore(true);
    if (values) {
      setIsNewSupplier(false);
      setSeeMore(false);
      setSelectedSupplier(values);
      setSelectedCountry(values?.country);
    } else {
      setSelectedSupplier('');
    }
  };

  const handleInputedValue = (value) => {
    if (isNewSupplier) {
      setSelectedSupplier({
        ...selectedSupplier,
        companyName: value
      });
      setNewSupplierData({
        ...newSupplierData,
        companyName: value
      });
    } else {
      setSelectedSupplier({
        ...selectedSupplier,
        companyName: value
      });
      setIsInputValueChanged(true);
      setIsNewSupplier(false);
    }
  };

  useEffect(() => {
    getProfileDetailsHandler();
    getSupplierListHandler();
  }, []);

  useEffect(() => {
    if (!uploadedFileExtractedData) {
      navigate(PATH_PAGE.pay);
    } else {
      setInvoiceSupplierData(uploadedFileExtractedData?.data?.invoiceResponse);
      setInvoiceExistedSupplier([uploadedFileExtractedData?.data?.supplier]);
      setCreatDate(uploadedFileExtractedData?.data?.invoiceResponse?.date?.value);
      setIsFullyMatched(uploadedFileExtractedData?.data?.isFullyMatched);
      if (invoiceSupplierData?.locale?.currency === 'Other') {
        setSelectedCurrency(invoiceSupplierData?.locale?.currency);
      }
      if (Object?.keys(uploadedFileExtractedData?.data?.supplier)?.length > 0) {
        setSeeMore(true);
        setIsNewSupplier(false);
        setSelectedSupplier(uploadedFileExtractedData?.data?.supplier);
        setSelectedCountry(uploadedFileExtractedData?.data?.supplier?.country);
      } else {
        setSeeMore(true);
        setIsNewSupplier(true);
        setNewSupplierData({
          ...newSupplierData,
          companyName: invoiceSupplierData?.data?.invoiceResponse?.supplierName?.value,
          registrationNumber: invoiceSupplierData?.supplierCompanyRegistrations?.[0]?.value,
          address: invoiceSupplierData?.supplierAddress?.value
        });
      }
    }
  }, []);

  useEffect(() => {
    reset(selectedSupplier);
  }, [resetSelectedSupplier]);

  useEffect(() => {
    if (location?.state?.urlOfImage) {
      setImageUrl(location?.state?.urlOfImage);
    }
  }, []);

  const invoiceSupplierListWithFullName = allSupplierList?.map((supplier) => ({
    ...supplier,
    name: supplier?.companyName,
    companyName: supplier?.companyName
  }));

  return (
    <>
      {!invoiceSupplierData ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="imported-invoice-form-container bg-white md:ml-6 pb-8">
            <div className="mx-8">
              <MDSubtitleText className="py-6" fontWeight="text-bold" textColor="text-gray-500" title="New Invoice" />
              <form id="imported-invoice-form" onSubmit={handleSubmit(onSubmitSupplierDetails)}>
                <BaseText
                  textColor="text-gray-600"
                  fontWeight="text-bold"
                  fontSize="text-lg"
                  title="Issuing company information"
                  className="mb-4"
                />
                <>
                  <div className="mb-6">
                    <ImportingSelectAutoComplete
                      label={
                        <div>
                          <span className="mr-2">Company Name</span>
                          {invoiceSupplierData?.supplierName?.confidence &&
                            findConfidanceInputedData(invoiceSupplierData?.supplierName?.confidence)}
                        </div>
                      }
                      isNewSupplier={isNewSupplier}
                      name="companyName"
                      options={invoiceSupplierListWithFullName}
                      disableClearable={true}
                      setAllSupplierList={setAllSupplierList}
                      value={
                        selectedSupplier
                          ? selectedSupplier
                          : uploadedFileExtractedData?.data?.invoiceResponse?.supplierName?.value
                      }
                      setCompanyName={handleInputedValue}
                      setSelectedValue={(values) => onSelectingSupplier(values)}
                      defaultValue={
                        selectedSupplier
                          ? selectedSupplier
                          : uploadedFileExtractedData?.data?.invoiceResponse?.supplierName?.value
                      }
                    />
                  </div>
                </>

                {seeMore && (
                  <>
                    <TextInput
                      defaultValue={
                        isNewSupplier ? newSupplierData?.registrationNumber : selectedSupplier?.registrationNumber
                      }
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.registrationNumber)}
                      margin="mt-4"
                      name="registrationNumber"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Registration number</span>
                          {invoiceSupplierData?.supplierCompanyRegistrations?.[0]?.value &&
                            findConfidanceInputedData(
                              invoiceSupplierData?.supplierCompanyRegistrations?.[0]?.confidence
                            )}
                        </div>
                      }
                      onChange={() => setIsInputValueChanged(true)}
                      inputRef={register({
                        required: false
                      })}
                    />
                    <div className="flex md:flex-row flex-col md:gap-4 w-full">
                      <TextInput
                        defaultValue={isNewSupplier ? '' : selectedSupplier?.email}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.email)}
                        margin="mt-4 w-full"
                        name="email"
                        label="Email Address"
                        inputRef={register({
                          required: false
                        })}
                        onChange={() => setIsInputValueChanged(true)}
                        error={errors.email ? true : false}
                      />
                      <TextInput
                        defaultValue={isNewSupplier ? '' : selectedSupplier?.website}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.website)}
                        margin="mt-4 w-full"
                        name="website"
                        label="Website"
                        onChange={() => setIsInputValueChanged(true)}
                        inputRef={register({
                          required: false
                        })}
                      />
                    </div>
                    <div className="flex md:flex-row flex-col md:gap-4 w-full">
                      <TextInput
                        defaultValue={isNewSupplier ? newSupplierData?.address : selectedSupplier?.address}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.address)}
                        margin="mt-4 w-full"
                        name="address"
                        onChange={() => setIsInputValueChanged(true)}
                        label={
                          <div className="flex items-start">
                            <span className="mr-2">Address</span>
                            {invoiceSupplierData?.supplierAddress?.value &&
                              findConfidanceInputedData(invoiceSupplierData?.supplierAddress?.confidence)}
                          </div>
                        }
                        inputRef={register({
                          required: false
                        })}
                      />
                      <TextInput
                        defaultValue={isNewSupplier ? '' : selectedSupplier?.city}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.city)}
                        margin="mt-4 w-full"
                        name="city"
                        label="City"
                        onChange={() => setIsInputValueChanged(true)}
                        inputRef={register({
                          required: false
                        })}
                      />
                    </div>
                    <div className="flex md:flex-row flex-col md:gap-4 w-full mb-4">
                      <TextInput
                        defaultValue={isNewSupplier ? '' : selectedSupplier?.state}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.state)}
                        margin="mt-4 w-full"
                        name="state"
                        label="State"
                        onChange={() => setIsInputValueChanged(true)}
                        inputRef={register({
                          required: false
                        })}
                      />
                      <TextInput
                        defaultValue={isNewSupplier ? '' : selectedSupplier?.postalCode}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.postalCode)}
                        margin="mt-4 w-full"
                        name="postalCode"
                        label="Zip / Postal Code"
                        onChange={() => setIsInputValueChanged(true)}
                        inputRef={register({
                          required: false
                        })}
                      />
                      <div className="mt-4 w-full">
                        <SelectCountryAutoComplete
                          defaultValue={selectedCountry}
                          setSelectedValue={onSelectingCountry}
                          label="Country"
                          name="country"
                        />
                      </div>
                    </div>

                    {Object?.keys(...invoiceExistedSupplier)?.length === 0 && isNewSupplier && (
                      <div>
                        <BaseText
                          className="bg-green-200 rounded-lg mb-6"
                          fontSize="text-base"
                          title={
                            <span className="flex items-center p-6">
                              <span className="mr-6">
                                <img src={StudyLightIdeaIcon} alt="StudyLightIdeaIcon circle" />
                              </span>
                              <span>
                                The information captured from this invoice will create this new contact under
                                “suppliers”.
                              </span>
                            </span>
                          }
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="flex justify-center cursor-pointer my-5" onClick={moreSupplierDetails}>
                  <XSText
                    className="mr-3"
                    textColor="text-gray-450"
                    title={seeMore ? 'See less supplier details' : 'See more supplier details'}
                  />
                  <img src={seeMore ? UpArrow : DownArrow} width="22" height="10" alt="Up Arrow" />
                </div>

                <BaseText
                  textColor="text-gray-600"
                  fontWeight="text-bold"
                  fontSize="text-lg"
                  title="Invoice information"
                  className="mb-4"
                />

                <div className="flex flex-col gap-4 w-full mb-6">
                  <div className="md:w-full flex md:flex-row flex-col items-baseline gap-4">
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={
                        invoiceSupplierData?.invoiceNumber?.value
                          ? invoiceSupplierData?.invoiceNumber?.value
                          : 'INV-0001'
                      }
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.invoiceNumber)}
                      name="invoiceNumber"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Invoice Number</span>
                          {invoiceSupplierData?.invoiceNumber?.value &&
                            findConfidanceInputedData(invoiceSupplierData?.invoiceNumber?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                    <TextInput
                      name="createDate"
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.date?.value && invoiceSupplierData?.date?.value}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.createDate)}
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Created Date</span>
                          {invoiceSupplierData?.date?.value &&
                            findConfidanceInputedData(invoiceSupplierData?.date?.confidence)}
                        </div>
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => setCreatDate(e.target.value)}
                      error={errors?.createDate ? true : false}
                      type="date"
                      inputRef={register({
                        required: true
                      })}
                      helperText={errors?.createDate && <ValidationMessage title={'Please select a create date.'} />}
                    />
                  </div>

                  <div className="md:w-full flex md:flex-row flex-col gap-4 items-baseline">
                    <div className="w-full">
                      <SelectInputAutoComplete
                        options={InvoiceCurrencies}
                        defaultValue={invoiceSupplierData?.locale?.currency}
                        backgroundColor={invoiceInputBackgroundColor(watchAllFields?.currency)}
                        label={
                          <div className="flex items-start">
                            <span className="mr-2">Currency</span>
                            {invoiceSupplierData?.locale?.currency &&
                              findConfidanceInputedData(invoiceSupplierData?.locale?.confidence)}
                          </div>
                        }
                        InputLabelProps={{
                          sx: {
                            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                              paddingTop: '8px'
                            }
                          }
                        }}
                        setSelectedValue={handleSelectedCurrency}
                        name="currency"
                        error={isCurrency ? true : false}
                        helperText={isCurrency && <ValidationMessage title={'Please select a valid currency.'} />}
                      />
                    </div>
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.dueDate?.value && invoiceSupplierData?.dueDate?.value}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.dueDate)}
                      name="dueDate"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Due Date</span>
                          {invoiceSupplierData?.dueDate?.value &&
                            findConfidanceInputedData(invoiceSupplierData?.dueDate?.confidence)}
                        </div>
                      }
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputRef={register({
                        required: true
                      })}
                      onChange={(e) => handleDueDate(e)}
                      error={errors?.dueDate || isDueDate ? true : false}
                      helperText={
                        (errors?.dueDate && <ValidationMessage title={'Please select a due date.'} />,
                        isDueDate && <ValidationMessage title={'Due Date should not be less than Created Date'} />)
                      }
                    />
                  </div>
                  <div className="md:w-full flex md:flex-row flex-col items-baseline gap-4">
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.totalAmount?.value}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.invoiceAmount)}
                      name="invoiceAmount"
                      onChange={(e) => handleInputedAmount(e)}
                      type="number"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Invoice Amount</span>
                          {invoiceSupplierData?.totalAmount?.value &&
                            findConfidanceInputedData(invoiceSupplierData?.totalAmount?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: true,
                        pattern: {
                          value: amountRegex
                        }
                      })}
                      error={errors?.invoiceAmount || isAmountValue ? true : false}
                      helperText={
                        (errors?.invoiceAmount || isAmountValue) && (
                          <ValidationMessage title={'Invoice amount should be greater than 0.'} />
                        )
                      }
                    />
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.taxes?.[0]?.value}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.taxes)}
                      name="taxes"
                      type="number"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Taxes</span>
                          {invoiceSupplierData?.taxes?.[0]?.value &&
                            findConfidanceInputedData(invoiceSupplierData?.taxes?.[0]?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  </div>
                </div>

                <BaseText
                  textColor="text-gray-600"
                  fontWeight="text-bold"
                  fontSize="text-lg"
                  title="Banking details"
                  className="mb-4"
                />
                {!invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban && (
                  <TextInput
                    textFieldContainerClass="w-full mb-4"
                    // defaultValue={invoiceSupplierData}
                    backgroundColor={invoiceInputBackgroundColor(watchAllFields?.bankName)}
                    name="bankName"
                    label="Bank name"
                    inputRef={register({
                      required: false
                    })}
                    error={errors?.invoiceNumber ? true : false}
                  />
                )}
                <div className="flex md:flex-row flex-col md:gap-4 w-full mb-4">
                  {!invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban && (
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.supplierPaymentDetails?.[0]?.accountNumber}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.accountNumber)}
                      name="accountNumber"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Account Number</span>
                          {invoiceSupplierData?.supplierPaymentDetails?.[0]?.accountNumber &&
                            findConfidanceInputedData(invoiceSupplierData?.supplierPaymentDetails?.[0]?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  )}
                  {invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban && (
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.iban)}
                      name="iban"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">IBAN</span>
                          {invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban &&
                            findConfidanceInputedData(invoiceSupplierData?.supplierPaymentDetails?.[0]?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  )}
                  <TextInput
                    textFieldContainerClass="w-full"
                    defaultValue={invoiceSupplierData?.supplierPaymentDetails?.[0]?.swift}
                    backgroundColor={invoiceInputBackgroundColor(watchAllFields?.swift)}
                    name="swift"
                    label={
                      <div className="flex items-start">
                        <span className="mr-2">SWIFT/BIC</span>
                        {invoiceSupplierData?.supplierPaymentDetails?.[0]?.swift &&
                          findConfidanceInputedData(invoiceSupplierData?.supplierPaymentDetails?.[0]?.confidence)}
                      </div>
                    }
                    inputRef={register({
                      required: false
                    })}
                    error={errors?.invoiceNumber ? true : false}
                  />
                </div>
                {!invoiceSupplierData?.supplierPaymentDetails?.[0]?.iban && (
                  <div className="imported-invoice-form-routing">
                    <TextInput
                      textFieldContainerClass="w-full"
                      defaultValue={invoiceSupplierData?.supplierPaymentDetails?.[0]?.routing_number}
                      backgroundColor={invoiceInputBackgroundColor(watchAllFields?.routingCode)}
                      name="routingCode"
                      label={
                        <div className="flex items-start">
                          <span className="mr-2">Routing code</span>
                          {invoiceSupplierData?.supplierPaymentDetails?.[0]?.routing_number &&
                            findConfidanceInputedData(invoiceSupplierData?.supplierPaymentDetails?.[0]?.confidence)}
                        </div>
                      }
                      inputRef={register({
                        required: false
                      })}
                      error={errors?.invoiceNumber ? true : false}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    uploadedFileExtractedData: state?.importingInvoiceReducer?.importedInvoiceData?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSupplier: (invoiceData) => dispatch(supplierReduxAction.createSupplier(invoiceData)),
    createImportedInvoice: (invoiceData) => dispatch(importingInvoiceReducer.createImportingInvoice(invoiceData)),
    getSupplierDetails: (id) => dispatch(supplierReduxAction.getSupplierDetails(id)),
    getSupplierList: () => dispatch(supplierReduxAction.getSupplierList()),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    updateSupplier: (invoiceData) => dispatch(supplierReducer.editSupplier(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportedInvoiceFrom);

ImportedInvoiceFrom.propTypes = {
  createSupplier: PropTypes.func,
  uploadedFileExtractedData: PropTypes.any,
  createImportedInvoices: PropTypes.func,
  getSupplierDetails: PropTypes.func,
  getUserProfile: PropTypes.func,
  getSupplierList: PropTypes.func,
  updateSupplier: PropTypes.func
};
