import React from "react";
import PropTypes from "prop-types";

function XXSText({title, className, textColor, textAlign, fontWeight,link }) {
  return ( 
    <div className={`${className}`}>
      <p className={`${fontWeight} text-lg ${textColor} ${textAlign} ${link ? "link-class" : "no-link" } letter-spacing-p leading-5`}>{title}</p>
    </div>
  );
}

export default XXSText;

XXSText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  link: PropTypes.bool
};

XXSText.defaultProps = {
  textColor: "text-gray-300",
  textAlign: "text-left",
  fontWeight: "text-regular"
};


