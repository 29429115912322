import React from 'react';
import XSText from '../../ui/typography/XSText';
import ArrowRight from '../../../images/icons/arrow-right-1.svg';
import '../../../styles/pages/company-set-up/owners-and-directors.scss';
import BackButton from '../../ui/buttons/BackButton';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import { COMPANY_SETUP_SIDEBAR_STEP, CONSTANT_NUMBER, OWNER_DIRECTOR_TYPES } from '../../../constants/enum';
import EditOwnerDirectorDetails from './EditOwnerDetails';

const ownerDirectorData = {
  firstName: 'Sunil',
  lastName: 'Prajapati',
  type: 'OWNER_DIRECTOR',
  code: '---'
};

function CompanyRelationSelect({
  prevSubStep,
  prevStep,
  setActiveStep,
  nextStep,
  isAddingOwner,
  setStep,
  isOwnerDirectorEdit,
  stackHolderType
}) {
  const back = () => {
    if (isAddingOwner || isOwnerDirectorEdit) {
      setStep(CONSTANT_NUMBER.THIRTEEN);
      setActiveStep(COMPANY_SETUP_SIDEBAR_STEP.OWNERS_AND_DIRECTORS);
    } else {
      prevSubStep();
      prevStep();
      setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.COMPANY_INFORMATION);
    }
  };

  const handleOwnerDirectorSelection = (value) => {
    if (value) {
      if (isOwnerDirectorEdit) {
        setStep(CONSTANT_NUMBER.TEN);
      } else {
        nextStep();
        stackHolderType(value);
      }
    }
  };

  return (
    <div className="flex flex-col gap-8 justify-between w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      <div>
        {isOwnerDirectorEdit && (
          <EditOwnerDirectorDetails
            firstName={ownerDirectorData.firstName}
            lastName={ownerDirectorData.lastName}
            type={ownerDirectorData.type}
            cardCode={ownerDirectorData.code}
          />
        )}
        <XSText
          textColor="text-gray-500"
          title={`What is ${isAddingOwner ? 'their' : 'your'} relationship with the company?`}
          fontWeight="text-medium"
          className="mt-6 mb-2"
        />
        <div
          className="other-business-incorporated-div cursor-pointer mt-1"
          onClick={() => handleOwnerDirectorSelection(OWNER_DIRECTOR_TYPES?.OWNER_DIRECTOR)}
        >
          <div className="w-full py-6 flex items-center justify-between pl-6 pr-4">
            <XSText textColor="text-gray-500" title="Owner and director" fontWeight="text-medium" />
            <img src={ArrowRight} alt="arrow-right" width="16" height="16" />
          </div>
        </div>
        <div
          className="mt-2 other-business-incorporated-div cursor-pointer"
          onClick={() => handleOwnerDirectorSelection(OWNER_DIRECTOR_TYPES.OWNER)}
        >
          <div className="w-full py-6 flex items-center justify-between pl-6 pr-4">
            <XSText title="Owner" textColor="text-gray-500" fontWeight="text-medium" />
            <img src={ArrowRight} alt="arrow-right" width="16" height="16" />
          </div>
        </div>
        <div
          className="mt-2 other-business-incorporated-div cursor-pointer"
          onClick={() => handleOwnerDirectorSelection(OWNER_DIRECTOR_TYPES.DIRECTOR)}
        >
          <div className="w-full py-6 flex items-center justify-between pl-6 pr-4">
            <XSText textColor="text-gray-500" title="Director" fontWeight="text-medium" />
            <img src={ArrowRight} alt="arrow-right" width="16" height="16" />
          </div>
        </div>
      </div>

      <BackButton onClick={back} />
    </div>
  );
}

export default CompanyRelationSelect;
