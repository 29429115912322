import React from 'react';
import AuthSideContainer from '../components/common/AuthSideContainer';
import ResetPasswordForm from '../components/login/ResetPasswordForm';
import PersonWithCoin from '../images/graphics/PersonWithCoins.png';
import PropTypes from 'prop-types';

export default function ResetPassword({ location }) {
  const { state } = location;
  return (
    <div className="flex flex-col md:flex-row">
      <ResetPasswordForm
        emailForResetPassword={state?.emailForResetPassword}
        otpForResetPassword={state?.otpForResetPassword}
      />
      <AuthSideContainer image={PersonWithCoin} />
    </div>
  );
}
ResetPassword.propTypes = {
  location: PropTypes.any
};
