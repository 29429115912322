import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const createContactRequestStart = () => ({ type: actionTypes.CREATE_CONTACT_REQUEST_START });
export const createContactRequestFail = (error) => ({ type: actionTypes.CREATE_CONTACT_REQUEST_FAILURE, error: error });
export const createContactRequestSuccess = (createContactPayload) => ({
  type: actionTypes.CREATE_CONTACT_REQUEST_SUCCESS,
  createContactPayload: createContactPayload
});
export const createContact = (createContactPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createContactRequestStart());
      let createContactResponse = await Api.post(ENDPOINTS.CREATE_CONTACT, createContactPayload);
      dispatch(createContactRequestSuccess(createContactResponse?.data));
      return createContactResponse;
    } catch (error) {
      dispatch(createContactRequestFail(error.response));
      return error?.response;
    }
  };
};

export const contactListRequestStart = () => ({ type: actionTypes.CONTACT_LIST_REQUEST_START });
export const contactListRequestFail = (error) => ({ type: actionTypes.CONTACT_LIST_REQUEST_FAILURE, error: error });
export const contactListRequestSuccess = (contactListPayload) => ({
  type: actionTypes.CONTACT_LIST_REQUEST_SUCCESS,
  contactListPayload: contactListPayload
});
export const contactList = () => {
  return async (dispatch) => {
    try {
      dispatch(contactListRequestStart());
      let contactListResponse = await Api.get(ENDPOINTS.CONTACT_LIST);
      dispatch(contactListRequestSuccess(contactListResponse?.data));
      return contactListResponse;
    } catch (error) {
      dispatch(contactListRequestFail(error.response));
      return error?.response;
    }
  };
};

export const allContactListRequestStart = () => ({ type: actionTypes.GET_ALL_CONTACT_REQUEST_START });
export const allContactListRequestFail = (error) => ({
  type: actionTypes.GET_ALL_CONTACT_REQUEST_FAILURE,
  error: error
});
export const allContactListRequestSuccess = (contactListPayload) => ({
  type: actionTypes.GET_ALL_CONTACT_REQUEST_SUCCESS,
  allContactListPayload: contactListPayload
});
export const allContactList = () => {
  return async (dispatch) => {
    try {
      dispatch(allContactListRequestStart());
      let allContactListResponse = await Api.post(ENDPOINTS.ALL_CONTACTS);
      dispatch(allContactListRequestSuccess(allContactListResponse?.data));
      return allContactListResponse;
    } catch (error) {
      dispatch(allContactListRequestFail(error.response));
      return error?.response;
    }
  };
};

export const editContactRequestStart = () => ({ type: actionTypes.EDIT_CONTACT_REQUEST_START });
export const editContactRequestFail = (error) => ({ type: actionTypes.EDIT_CONTACT_REQUEST_FAILURE, error: error });
export const editContactRequestSuccess = (editContactPayload) => ({
  type: actionTypes.EDIT_CONTACT_REQUEST_SUCCESS,
  editContactPayload: editContactPayload
});
export const editContact = (editContactPayload) => {
  return async (dispatch) => {
    try {
      dispatch(editContactRequestStart());
      let editContactResponse = await Api.put(ENDPOINTS.EDIT_CONTACT, editContactPayload);
      dispatch(editContactRequestSuccess(editContactResponse?.data));
      return editContactResponse;
    } catch (error) {
      dispatch(editContactRequestFail(error.response));
      return error?.response;
    }
  };
};

export const deleteContactRequestStart = () => ({ type: actionTypes.DELETE_CONTACT_REQUEST_START });
export const deleteContactRequestFail = (error) => ({ type: actionTypes.DELETE_CONTACT_REQUEST_FAILURE, error: error });
export const deleteContactRequestSuccess = (deleteContactPayload) => ({
  type: actionTypes.DELETE_CONTACT_REQUEST_SUCCESS,
  deleteContactPayload: deleteContactPayload
});
export const deleteContact = (deleteContactPayload) => {
  return async (dispatch) => {
    try {
      dispatch(deleteContactRequestStart());
      let deleteContactResponse = await Api.delete(ENDPOINTS.DELETE_CONTACT, deleteContactPayload);
      dispatch(deleteContactRequestSuccess(deleteContactResponse?.data));
      return deleteContactResponse;
    } catch (error) {
      dispatch(deleteContactRequestFail(error.response));
      return error?.response;
    }
  };
};

export const viewContactRequestStart = () => ({ type: actionTypes.VIEW_CONTACT_REQUEST_START });
export const viewContactRequestFail = (error) => ({
  type: actionTypes.VIEW_CONTACT_REQUEST_FAILURE,
  error: error
});
export const viewContactRequestSuccess = (viewContactPayload) => ({
  type: actionTypes.VIEW_CONTACT_REQUEST_SUCCESS,
  viewContactPayload: viewContactPayload
});
export const viewContact = (viewContactPayload) => {
  return async (dispatch) => {
    try {
      dispatch(viewContactRequestStart());
      let viewContactResponse = await Api.get(ENDPOINTS.VIEW, viewContactPayload);
      dispatch(viewContactRequestSuccess(viewContactResponse?.data));
      return viewContactResponse;
    } catch (error) {
      dispatch(viewContactRequestFail(error.response));
      return error?.response;
    }
  };
};

export const getInvoiceContactListRequestStart = () => ({ type: actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_START });
export const getInvoiceContactListRequestFail = (error) => ({
  type: actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_FAILURE,
  error: error
});
export const getInvoiceContactListRequestSuccess = (getInvoiceContact) => ({
  type: actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_SUCCESS,
  getInvoiceContact: getInvoiceContact
});
export const getInvoiceContactList = (invoiceContactId) => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceContactListRequestStart());
      let invoiceListResponse = await Api.get(`${ENDPOINTS.GET_INVOICE_LIST}?contactId=${invoiceContactId}`);
      dispatch(getInvoiceContactListRequestSuccess(invoiceListResponse));
      return invoiceListResponse;
    } catch (error) {
      dispatch(getInvoiceContactListRequestFail(error.response));
    }
  };
};

export const resetContactForm = (error) => ({
  type: actionTypes.RESET_CONTACT_MODAL_FORM,
  error: error
});
