import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const getCompanyIncorporationRequestStart = () => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_START
});
export const getCompanyIncorporationRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const getCompanyIncorporationRequestSuccess = (getCompanyIncorporationResponse) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_SUCCESS,
  getCompanyIncorporationData: getCompanyIncorporationResponse
});
export const getCompanyIncorporation = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyIncorporationRequestStart());
      let getCompanyIncorporationResponse = await Api.get(
        `${ENDPOINTS.GET_COMPANY_SET_UP_DETAILS}?companyId=${companyId}`
      );
      dispatch(getCompanyIncorporationRequestSuccess(getCompanyIncorporationResponse));
      return getCompanyIncorporationResponse;
    } catch (error) {
      dispatch(getCompanyIncorporationRequestFail(error.response));
    }
  };
};

export const companyIncorporationRequestStart = () => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_START
});
export const companyIncorporationRequestFail = (error) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const companyIncorporationRequestSuccess = (companyIncorporationResponse) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_SUCCESS,
  companyIncorporationData: companyIncorporationResponse
});
export const updateCompanyIncorporation = (companyIncorporationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyIncorporationRequestStart());
      let companyIncorporationResponse = await Api.put(
        ENDPOINTS?.GET_COMPANY_SET_UP_UPDATE,
        companyIncorporationPayload
      );
      dispatch(companyIncorporationRequestSuccess(companyIncorporationResponse));
      return companyIncorporationResponse;
    } catch (error) {
      dispatch(companyIncorporationRequestFail(error.response));
    }
  };
};

export const uploadedPassportRequestStart = () => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_START
});
export const uploadedPassportRequestFail = (error) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_FAIL,
  error: error
});
export const uploadedPassportRequestSuccess = (uploadedPassportResponse) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_SUCCESS,
  uploadedPassportData: uploadedPassportResponse
});
export const uploadedPassport = (uploadedPassportPayload) => {
  return async (dispatch) => {
    try {
      dispatch(uploadedPassportRequestStart());
      let uploadedPassportResponse = await Api.post(ENDPOINTS.PASSPORT_UPLOAD, uploadedPassportPayload);
      dispatch(uploadedPassportRequestSuccess(uploadedPassportResponse));
      return uploadedPassportResponse;
    } catch (error) {
      dispatch(uploadedPassportRequestFail(error.response));
    }
  };
};
