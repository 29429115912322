import * as React from 'react';

import PropTypes from 'prop-types';
// @mui
import { Box, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import { Link } from 'gatsby';
import XSText from './ui/typography/XSText';
// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  activeLast: PropTypes.bool,
  links: PropTypes.array.isRequired
};

export default function Breadcrumbs({ links, activeLast = false, ...other }) {
  const currentLink = links[links.length - 1].name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);

  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? <LinkItem link={link} /> : <XSText textColor="text-gray-300" title={currentLink} />}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={<Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />}
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

LinkItem.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string
  })
};

function LinkItem({ link }) {
  const { href, name, icon } = link;
  return (
    <Link key={name} to={href || '#'}>
      {icon && <Box sx={{ mr: 1, '& svg': { width: 20, height: 20 } }}>{icon}</Box>}
      <XSText textColor="hover:text-coral-500 text-gray-300" title={name} fontWeight="text-bold" />
    </Link>
  );
}
