import React from 'react';
import AuthSideContainer from '../components/common/AuthSideContainer';
import ConfirmEmailAccountForm from '../components/confirmEmailAccount/ConfirmEmailAccountForm';
import PersonWithCoin from '../images/graphics/PersonWithCoins.png';
import PropTypes from 'prop-types';
export default function ConfirmEmailAccount({ location }) {
  const { state } = location;
  return (
    <>
      <div className="flex flex-col md:flex-row">
        <ConfirmEmailAccountForm emailFromLogin={state?.email} path={state?.path} />
        <AuthSideContainer image={PersonWithCoin} />
      </div>
    </>
  );
}
ConfirmEmailAccount.propTypes = {
  location: PropTypes.any
};
