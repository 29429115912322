export const GET_SUPPLIER_DETAILS_REQUEST_START = 'GET_SUPPLIER_DETAILS_REQUEST_START';
export const GET_SUPPLIER_DETAILS_REQUEST_FAIL = 'GET_SUPPLIER_DETAILS_REQUEST_FAIL';
export const GET_SUPPLIER_DETAILS_REQUEST_SUCCESS = 'GET_SUPPLIER_DETAILS_REQUEST_SUCCESS';

export const CREATE_SUPPLIER_REQUEST_START = 'CREATE_SUPPLIER_REQUEST_START';
export const CREATE_SUPPLIER_REQUEST_FAIL = 'CREATE_SUPPLIER_REQUEST_FAIL';
export const CREATE_SUPPLIER_REQUEST_SUCCESS = 'CREATE_SUPPLIER_REQUEST_SUCCESS';

export const EDIT_SUPPLIER_REQUEST_START = 'EDIT_SUPPLIER_REQUEST_START';
export const EDIT_SUPPLIER_REQUEST_FAIL = 'EDIT_SUPPLIER_REQUEST_FAIL';
export const EDIT_SUPPLIER_REQUEST_SUCCESS = 'EDIT_SUPPLIER_REQUEST_SUCCESS';

export const DELETE_SUPPLIER_REQUEST_START = 'DELETE_SUPPLIER_REQUEST_START';
export const DELETE_SUPPLIER_REQUEST_FAIL = 'DELETE_SUPPLIER_REQUEST_FAIL';
export const DELETE_SUPPLIER_REQUEST_SUCCESS = 'DELETE_SUPPLIER_REQUEST_SUCCESS';

export const GET_SUPPLIER_LIST_REQUEST_START = 'GET_SUPPLIER_LIST_REQUEST_START';
export const GET_SUPPLIER_LIST_REQUEST_FAIL = 'GET_SUPPLIER_LIST_REQUEST_FAIL';
export const GET_SUPPLIER_LIST_REQUEST_SUCCESS = 'GET_SUPPLIER_LIST_REQUEST_SUCCESS';
