import React from 'react';
import '../../styles/auth-side-container.scss';
import PropTypes from 'prop-types';

export default function AuthSideContainer({ image }) {
  return (
    <div className="auth-side-container mx-auto bg-salmon-500 w-full">
      <div className="my-auto">
        <div className="flex flex-row items-center justify-center">
          <img src={image} alt="auth side img" />
        </div>
      </div>
    </div>
  );
}

AuthSideContainer.propTypes = {
  image: PropTypes.array
};
