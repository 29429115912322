import React from 'react';
import '../../styles/get-started-banner.scss';
import '../../styles/pages/getPaid.scss';
import LGSubtitleText from '../ui/typography/LGSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import InvoiceBannerImg from '../../images/graphics/getpaid/getpaid-listing.png';
import { PATH_PAGE } from '../../routes/paths';
export default function () {
  return (
    <div className="send-invoice-container rounded-2xl md:px-8 px-4 box-shadow flex md:flex-row flex-col md:justify-between overflow-hidden">
      <div className="py-8 w-full">
        <div>
          <LGSubtitleText
            className="whitespace-pre-line"
            textWeight="text-bold"
            textColor="text-gray-500"
            title={'Get started by creating \n your first invoice'}
          />
          <XSText
            fontWeight="text-medium"
            className="whitespace-pre-line mt-4"
            textColor="text-gray-500"
            title={
              'Simplify your invoicing process by creating and\n sending invoices to your clients.\n\n Keep track of your invoices and easily manage\n payments to ensure your invoices get paid on time.'
            }
          />
        </div>
        <div className="send-invoice-button">
          <PrimaryButton
            id="create-invoice-get-started-banner"
            className="mr-2"
            isBorderRequired="true"
            color="text-white"
            fontSize="text-base"
            fontWeight="text-medium"
            bgColor="bg-green-500"
            caption="Create an invoice"
            urlLink={PATH_PAGE.createInvoice}
          />
        </div>
      </div>

      <div className="md:mr-8 md:mt-8 flex md:justify-end justify-center relative ">
        <div className="green-background-shadow-effect md:block hidden"></div>
        <div className="overflow-hidden image-z-index flex justify-end">
          <img
            className="send-invoice-image getPaid-invoice overflow-hidden"
            src={InvoiceBannerImg}
            alt="Invoice"
          ></img>
        </div>
      </div>
    </div>
  );
}
