import React from 'react';
import XSText from '../ui/typography/XSText';
import XXSText from '../ui/typography/XXSText';
import InvoiceDiscount from './InvoiceAmount/InvoiceDiscount';
import InvoiceTaxes from './InvoiceAmount/InvoiceTaxes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import {
  itemsTotalPrice,
  tasksTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  concatenateSelectedCurrency
} from '../../helper/helpers';

function InvoiceAmount({ getInvoiceDetails, selectedCurrency, isEdit }) {
  const subtotal =
    itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
    tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
    newItemsTotalPrice(getInvoiceDetails?.items);
  const discountPrice = priceCalculatorThroughPercentage(
    subtotal,
    getInvoiceDetails?.invoiceDiscount?.discountPercentage
  );
  const totalAfterDiscount = discountPrice ? subtotal - discountPrice : subtotal;
  const taxWithPrice = getInvoiceDetails?.invoiceTaxes?.map((taxDetails) => ({
    ...taxDetails,
    taxPrice: priceCalculatorThroughPercentage(totalAfterDiscount, taxDetails?.taxPercentage)
  }));
  let totalTaxAmount = taxWithPrice?.reduce((accumulator, taxDetails) => {
    return accumulator + taxDetails?.taxPrice;
  }, 0);
  let totalAmount = totalTaxAmount ? totalTaxAmount + totalAfterDiscount : totalAfterDiscount;
  return (
    <div className="w-full flex flex-col gap-4 mt-4">
      <div className="flex justify-between items-center h-8 invoice-right-padding">
        <XSText title="Subtotal" />
        <XSText title={concatenateSelectedCurrency(selectedCurrency, subtotal)} />
      </div>
      <InvoiceDiscount selectedCurrency={selectedCurrency} isEdit={isEdit} />
      <InvoiceTaxes selectedCurrency={selectedCurrency} isEdit={isEdit} />
      <div className="flex justify-between items-center h-8 invoice-right-padding">
        <XXSText fontWeight="text-bold" title={`Total (${selectedCurrency?.name})`} />
        <XXSText fontWeight="text-bold" title={concatenateSelectedCurrency(selectedCurrency, totalAmount)} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAmount);

InvoiceAmount.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  closeDynamicModal: PropTypes.func,
  type: PropTypes.func,
  selectedCurrency: PropTypes.any,
  isEdit: PropTypes.bool
};
