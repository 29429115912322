import * as actionTypes from './actionTypes';

let initialState = {
  userProfileDetails: {},
  getUserProfileDetails: {},
  changePasswordDetails: {},
  currencyPreferenceDetails: {},
  getCurrencyPreferenceDetails: {},
  loading: false,
  error: false
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.USER_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        userProfileDetails: action.profileData,
        loading: false,
        error: false
      };
    case actionTypes.USER_PROFILE_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_USER_PROFILE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_USER_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        getUserProfileDetails: action.userProfileData,
        loading: false,
        error: false
      };
    case actionTypes.GET_USER_PROFILE_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHANGE_PASSWORD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePasswordDetails: action.changePasswordData,
        loading: false,
        error: false
      };
    case actionTypes.CHANGE_PASSWORD_REQUEST_FAILURE:

    case actionTypes.CURRENCY_PREFERENCE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CURRENCY_PREFERENCE_REQUEST_SUCCESS:
      return {
        ...state,
        currencyPreferenceDetails: action.currencyPreferenceData,
        loading: false,
        error: false
      };
    case actionTypes.CURRENCY_PREFERENCE_REQUEST_FAILURE:

    case actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_SUCCESS:
      return {
        ...state,
        getCurrencyPreferenceDetails: action.getCurrencyPreferenceData,
        loading: false,
        error: false
      };
    case actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default UserProfileReducer;
