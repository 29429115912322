import React, { useState, useEffect } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import Description from './Description';
import Countries from './Countries';
import { COMPANY_DESCRIPTION_STEP } from '../../../constants/enum';
import TurnOver from './TurnOver';
import '../../../styles/pages/company-set-up/common.scss';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { connect } from 'react-redux';

const BusinessDescription = ({ prevSubStep, prevStep, nextStep, setActiveStep, setSubStep, getCompanyDetails }) => {
  const [companyDescriptionStep, setCompanyDescriptionStep] = useState(1);
  let nextDescriptionStep = () => {
    setCompanyDescriptionStep(companyDescriptionStep + 1);
  };

  let prevDescriptionStep = () => {
    setCompanyDescriptionStep(companyDescriptionStep - 1 > 0 ? companyDescriptionStep - 1 : 1);
  };
  async function companyInformation() {
    await getCompanyDetails(sessionStorage.getItem('companyId'));
  }
  useEffect(() => {
    companyInformation();
  }, [companyDescriptionStep]);

  let selectedCase = () => {
    switch (companyDescriptionStep) {
      default:
        return <h1>something wrong</h1>;
      case COMPANY_DESCRIPTION_STEP?.DESCRIPTION:
        return <Description prevSubStep={prevSubStep} nextDescriptionStep={nextDescriptionStep} prevStep={prevStep} />;
      case COMPANY_DESCRIPTION_STEP?.COUNTRIES:
        return <Countries prevDescriptionStep={prevDescriptionStep} nextDescriptionStep={nextDescriptionStep} />;
      case COMPANY_DESCRIPTION_STEP?.TURNOVER:
        return (
          <TurnOver
            prevDescriptionStep={prevDescriptionStep}
            nextStep={nextStep}
            setActiveStep={setActiveStep}
            setSubStep={setSubStep}
          />
        );
    }
  };

  return (
    <div className="flex flex-col justify-between gap-8 w-full h-full main-wrapper-container">
      <MDSubtitleText title="Business description" fontWeight="text-bold" />
      {selectedCase()}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(BusinessDescription);
