import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import MSText from '../typography/MSText';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '../Avatar';
const filter = createFilterOptions();

export default function SelectInputAutoComplete({
  isNewSupplier,
  setCompanyName,
  label,
  value,
  defaultValue,
  options,
  setSelectedValue,
  multiple,
  disabled,
  inputRef,
  error,
  helperText,
  onClose
}) {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <Autocomplete
        multiple={multiple}
        defaultValue={defaultValue}
        disabled={disabled}
        onClose={onClose}
        value={value}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setCompanyName(newValue);
          } else if (newValue && newValue.inputValue) {
            setCompanyName(newValue.inputValue);
          } else {
            newValue && setSelectedValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              companyName: `Add "${params.inputValue}"`
            });
          }
          return filtered;
        }}
        onInputChange={(event, newInputedValue) => {
          setInputValue(newInputedValue);
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.companyName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => {
          return (
            <div className="flex gap-4 items-center" {...props}>
              <div className="ml-2 mr-4">
                {!option?.inputValue && (
                  <Avatar avatarImage={option?.profileImage} avatarName={option?.companyName?.charAt(0)} />
                )}
              </div>
              <div>
                <MSText
                  title={
                    <div className="text-bold py-3">
                      <span>{option?.companyName}</span>
                    </div>
                  }
                />
              </div>
            </div>
          );
        }}
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField
            value={value}
            {...params}
            label={!value && label}
            sx={
              value && {
                '& .MuiInputBase-input': {
                  marginTop: '5px',
                  marginBottom: '5px'
                },
                '& .MuiInputAdornment-outlined': {
                  marginTop: '0px'
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: '8px 6px 7px 5px'
                }
              }
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: inputValue && value && (
                <>
                  <InputAdornment position="start">
                    <div className="flex gap-4 items-center">
                      <div className="ml-2 mr-4">
                        <Avatar
                          avatarImage={value?.profileImage}
                          avatarName={
                            inputValue?.split(', ')[1] ? inputValue?.split(', ')[1]?.charAt(0) : inputValue?.charAt(0)
                          }
                        />
                      </div>
                    </div>
                  </InputAdornment>
                </>
              ),
              endAdornment: (
                <React.Fragment>
                  {isNewSupplier && (
                    <div className="flex gap-4 items-center">
                      <div className="flex">
                        <span className="bg-green-500 text-white imported-invoice-form-label">New Supplier</span>
                      </div>
                    </div>
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            inputRef={inputRef}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </>
  );
}

SelectInputAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedValue: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  hideBorder: PropTypes.bool,
  onClose: PropTypes.bool,
  disableClearable: PropTypes.bool,
  isNewSupplier: PropTypes.bool
};

SelectInputAutoComplete.defaultProps = {
  multiple: false,
  disabled: false,
  hideBorder: false,
  disableClearable: false
};
