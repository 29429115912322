import React from 'react';
import '../styles/pages/signup.scss';
import StatrysLogo from '../images/icons/statrysLogo.svg';
import { Link } from 'gatsby';
import { PATH_PAGE } from '../routes/paths';
import XSText from '../components/ui/typography/XSText';
import SignUpBanner from '../components/company/common/SignUpBanner';
import CompanySignUpForm from '../components/signup/CompanySignUpForm';

var items = [
  {
    title: 'Fully Online',
    description: `Access to your company information and documents anytime, from anywhere you need them.`
  },
  {
    title: 'All your company documents in one place',
    description: `View and download company documents, information, and correspondence sent to your registered company address.`
  },
  {
    title: 'Direct Support',
    description: `Simply contact our team directly through the Company Management Portal support chat.`
  }
];

function CompanySignUp({ location }) {
  return (
    <div className="bg-customBg-secondaryBg  overflow-hidden relative h-screen">
      <div className="flex flex-row justify-between p-6">
        <img src={StatrysLogo} className="sign-up-logo" alt="statrys-logo" />
        <div className="flex items-center mt-2">
          <XSText
            title={
              <>
                <span>Already have an account?</span>
                <span>
                  <Link to={PATH_PAGE.login} className="ml-1 text-coral-500">
                    Log in here
                  </Link>
                </span>
              </>
            }
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center md:flex-row gap-2 h-4/5">
        <CompanySignUpForm location={location} />
        <SignUpBanner data={items} />
      </div>
      <div className="company-signup-filter-blur absolute"></div>
    </div>
  );
}
export default CompanySignUp;
