import React from 'react';

export default function SendEmail() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1378_43218)">
        <path
          d="M1.40009 3.74301C1.24396 3.46365 1.18127 3.14168 1.22119 2.82415C1.2611 2.50662 1.40153 2.21018 1.62194 1.97815C1.84235 1.74612 2.13119 1.59067 2.44625 1.53451C2.76131 1.47835 3.08608 1.52443 3.37309 1.66601L22.3871 11.325C22.5105 11.3878 22.6141 11.4834 22.6864 11.6014C22.7588 11.7194 22.7971 11.8551 22.7971 11.9935C22.7971 12.1319 22.7588 12.2676 22.6864 12.3856C22.6141 12.5036 22.5105 12.5993 22.3871 12.662L3.37309 22.334C3.08608 22.4756 2.76131 22.5217 2.44625 22.4655C2.13119 22.4094 1.84235 22.2539 1.62194 22.0219C1.40153 21.7898 1.2611 21.4934 1.22119 21.1759C1.18127 20.8583 1.24396 20.5364 1.40009 20.257L6.45809 11.993L1.40009 3.74301Z"
          stroke="#2F2F2F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7971 11.993H6.45312"
          stroke="#2F2F2F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1378_43218">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
