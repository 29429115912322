import React from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XXSText from '../../ui/typography/XXSText';
import NormalStepper from '../Steppers/NormalStepper';
import StartImg from '../../../images/graphics/company-incorporation/start.png';
import PrimaryButton from '../../ui/buttons/PrimaryButton';

const Start = ({ nextStep, completedStep, currentStep, buttonText }) => {
  const verifyEmailSteps = [
    {
      number: 1,
      name: 'Create your account',
      time: '2 min'
    },
    {
      number: 2,
      name: 'Fill in your company information',
      time: '2 min'
    },
    {
      number: 3,
      name: 'Add Owners and directors',
      time: '5 min'
    },
    {
      number: 4,
      name: 'Confirm and sign',
      time: '1 day'
    }
  ];
  return (
    <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full">
      <div className="md:w-1/2 w-full">
        <LGSubtitleText
          className="md:whitespace-pre-line"
          title={`Let’s Get Your Company \nUp and Running!`}
          textWeight="text-medium"
        />
        <XXSText
          title="We'll guide you step by step during the next 10 min to ensure your company meets all the requirements"
          className="mt-4"
        />
        <NormalStepper completedStep={completedStep} currentStep={currentStep} verifyEmailSteps={verifyEmailSteps} />
        <PrimaryButton
          id="lets-start-cc-account"
          caption={buttonText}
          color="text-white"
          bgColor="bg-green-500"
          isBorderRequired={true}
          onClick={() => {
            nextStep();
          }}
        />
      </div>
      <div className="md:w-1/2 w-full flex justify-center">
        <img src={StartImg} alt="let's start company" width="368" className="object-contain" />
      </div>
    </div>
  );
};

export default Start;
