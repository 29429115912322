import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const getPerformanceRequestStart = () => ({
  type: actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_START
});
export const getPerformanceRequestFail = (error) => ({
  type: actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_FAIL,
  error: error
});
export const getPerformanceRequestSuccess = (getPerformance) => ({
  type: actionTypes.GET_PERFORMANCE_DETAILS_REQUEST_SUCCESS,
  getPerformance: getPerformance
});
export const getPerformance = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(getPerformanceRequestStart());
      let performanceResponse = await Api.post(ENDPOINTS.DASHBOARD_INVOICE_PERFORMANCE, payload);
      dispatch(getPerformanceRequestSuccess(performanceResponse));
      return performanceResponse;
    } catch (error) {
      dispatch(getPerformanceRequestFail(error.response));
    }
  };
};
