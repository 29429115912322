import React from 'react';
import PropTypes from 'prop-types';

function LGSubtitleText({ title, className, textColor, textAlign, textWeight }) {
  return (
    <div className={`${className}`}>
      <p className={`lg-subtitle-text ${textWeight} text-4xl ${textColor} ${textAlign} letter-spacing-p`}>{title}</p>
    </div>
  );
}

export default LGSubtitleText;

LGSubtitleText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  textWeight: PropTypes.string
};

LGSubtitleText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  textWeight: 'text-light'
};
