import * as actionTypes from './actionTypes';

let initialState = {
  invoiceData: {},
  invoiceById: {},
  invoiceList: {},
  deleteInvoiceDetails: {},
  createInvoiceDetails: {},
  editInvoiceDetails: {},
  getPublicInvoice: {},
  sendEmailInvoiceDetails: {},
  totalInvoiceCurrencyDetails: {},
  invoiceGeneratorDetails: {},
  updateUserInvoiceGeneratorDetails: {},
  getInvoiceGeneratorDetails: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INVOICE_DATA_REQUEST_SUCCESS:
      return { ...state, invoiceData: { ...state.invoiceData, ...action.invoiceData }, loading: false, error: false };

    case actionTypes.GET_INVOICE_BY_ID_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_INVOICE_BY_ID_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_INVOICE_BY_ID_REQUEST_SUCCESS:
      return { ...state, invoiceById: { ...action.invoiceById }, loading: false, error: false };

    case actionTypes.GET_INVOICE_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_INVOICE_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_INVOICE_LIST_REQUEST_SUCCESS:
      return { ...state, invoiceList: { ...action.invoiceList }, loading: false, error: false };

    case actionTypes.CREATE_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CREATE_INVOICE_REQUEST_SUCCESS:
      return { ...state, createInvoiceDetails: { ...action.createInvoicePayload }, loading: false, error: false };

    case actionTypes.DELETE_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.DELETE_INVOICE_REQUEST_SUCCESS:
      return { ...state, deleteInvoiceDetails: { ...action.deleteInvoice }, loading: false, error: false };

    case actionTypes.EDIT_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.EDIT_INVOICE_REQUEST_SUCCESS:
      return { ...state, editInvoiceDetails: { ...action.editInvoicePayload }, loading: false, error: false };

    case actionTypes.GET_PUBLIC_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_PUBLIC_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_PUBLIC_INVOICE_REQUEST_SUCCESS:
      return { ...state, getPublicInvoice: { ...action.getPublicInvoice }, loading: false, error: false };
    case actionTypes.SEND_EMAIL_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SEND_EMAIL_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.SEND_EMAIL_INVOICE_REQUEST_SUCCESS:
      return { ...state, sendEmailInvoiceDetails: { ...action.sendEmailInvoicePayload }, loading: false, error: false };

    case actionTypes.INVOICE_COUNT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_COUNT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_COUNT_REQUEST_SUCCESS:
      return { ...state, countInvoiceDetails: { ...action.invoiceCountPayload }, loading: false, error: false };

    case actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_SUCCESS:
      return {
        ...state,
        totalInvoiceCurrencyDetails: { ...action.invoiceCurrencyPayload },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_GENERATOR_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_GENERATOR_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_GENERATOR_REQUEST_SUCCESS:
      return {
        ...state,
        invoiceGeneratorDetails: { ...action.invoiceGeneratorPayload },
        loading: false,
        error: false
      };

    case actionTypes.GET_INVOICE_GENERATOR_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_INVOICE_GENERATOR_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_INVOICE_GENERATOR_REQUEST_SUCCESS:
      return {
        ...state,
        getInvoiceGeneratorDetails: { ...action.getInvoiceGeneratorPayload },
        loading: false,
        error: false
      };

    case actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_SUCCESS:
      return {
        ...state,
        updateUserInvoiceGeneratorDetails: { ...action.updateUserInvoiceGeneratorPayload },
        loading: false,
        error: false
      };

    case actionTypes.RESET_INVOICE_DETAILS_SUCCESS:
      return { ...state, invoiceData: {} };
    default:
      return state;
  }
};
export default reducer;
