import React from "react"

const AccountInvoice = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55339 0.5H10.4734C10.7385 0.500233 10.9927 0.605743 11.1801 0.793333L15.0934 4.70667C15.281 4.89402 15.3865 5.14821 15.3867 5.41333V14.5C15.3867 14.7652 15.2814 15.0196 15.0938 15.2071C14.9063 15.3946 14.6519 15.5 14.3867 15.5H3.38672C3.1215 15.5 2.86715 15.3946 2.67961 15.2071C2.49208 15.0196 2.38672 14.7652 2.38672 14.5V1.5C2.38672 1.23478 2.49208 0.98043 2.67961 0.792893C2.86715 0.605357 3.1215 0.5 3.38672 0.5H4.22005"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3867 5.5H11.3867C11.1215 5.5 10.8671 5.39464 10.6796 5.20711C10.4921 5.01957 10.3867 4.76522 10.3867 4.5V0.5"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.64676 5.72673C4.79076 5.91733 4.97875 6.07024 5.19469 6.1724C5.41062 6.27456 5.64807 6.32294 5.88676 6.3134C6.64676 6.3134 7.26009 5.8534 7.26009 5.28006C7.26009 4.70673 6.64676 4.2534 5.88676 4.2534C5.12676 4.2534 4.51343 3.78673 4.51343 3.22006C4.51343 2.6534 5.12676 2.18673 5.88676 2.18673C6.12545 2.17719 6.3629 2.22556 6.57884 2.32772C6.79477 2.42988 6.98276 2.5828 7.12676 2.7734"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.88672 6.31348V7.00014"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.88672 1.5V2.18667"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0534 8.8335H4.72005C4.53596 8.8335 4.38672 8.98273 4.38672 9.16683V13.1668C4.38672 13.3509 4.53596 13.5002 4.72005 13.5002H13.0534C13.2375 13.5002 13.3867 13.3509 13.3867 13.1668V9.16683C13.3867 8.98273 13.2375 8.8335 13.0534 8.8335Z"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.38672 11.1665H13.3867"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38672 8.8335V13.5002"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3867 8.8335V13.5002"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default AccountInvoice
