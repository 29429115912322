import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const confirmEmailRequestStart = () => ({ type: actionTypes.CONFIRM_EMAIL_REQUEST_START });
export const confirmEmailRequestFail = (error) => ({ type: actionTypes.CONFIRM_EMAIL_REQUEST_FAILURE, error: error });
export const confirmEmailRequestSuccess = (confirmEmailPayload) => ({
  type: actionTypes.CONFIRM_EMAIL_REQUEST_SUCCESS,
  confirmEmailPayload: confirmEmailPayload
});
export const confirmEmail = (confirmEmailPayload) => {
  return async (dispatch) => {
    try {
      dispatch(confirmEmailRequestStart());
      let confirmEmailResponse = await Api.put(ENDPOINTS.FORGOT_PASSWORD, confirmEmailPayload);
      dispatch(confirmEmailRequestSuccess(confirmEmailResponse?.data));
      return confirmEmailResponse;
    } catch (error) {
      dispatch(confirmEmailRequestFail(error.response));
      return error?.response;
    }
  };
};

export const resendCodeRequestStart = () => ({ type: actionTypes.RESEND_CODE_REQUEST_START });
export const resendCodeRequestFail = (error) => ({ type: actionTypes.RESEND_CODE_REQUEST_FAILURE, error: error });
export const resendCodeRequestSuccess = (resendCodePayload) => ({
  type: actionTypes.RESEND_CODE_REQUEST_SUCCESS,
  resendCodeData: resendCodePayload
});
export const resendCode = (resendCodePayload) => {
  return async (dispatch) => {
    try {
      dispatch(resendCodeRequestStart());
      let resendCodeResponse = await Api.post(ENDPOINTS.RESEND_OTP, resendCodePayload);
      dispatch(resendCodeRequestSuccess(resendCodeResponse?.data));
      return resendCodeResponse;
    } catch (error) {
      dispatch(resendCodeRequestFail(error.response));
      return error?.response;
    }
  };
};
