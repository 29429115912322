import React from 'react';
import PropTypes from 'prop-types';
import XSText from '../../ui/typography/XSText';
import SuccessIcon from '../../../images/icons/success-icon.svg';
import '../../../styles/pages/company-signup.scss';
import BaseText from '../../ui/typography/BaseText';

export default function SignUpBanner({ data }) {
  return (
    <div className="auth-right-container relative z-10">
      <div className="right-side-auto flex flex-col gap-4 p-10">
        <BaseText title="Company Management Portal" fontWeight="text-bold" lineHeight="text-xl" fontSize="text-3xl" />
        {data?.map((point, index) => {
          return (
            <div className="flex flex-row items-start gap-2" key={index}>
              <img src={SuccessIcon} className="py-2" />
              <div className="flex flex-col gap-2 p-2">
                <XSText title={point?.title} fontWeight="text-medium" />
                <XSText className="md:whitespace-pre-line" title={point.description} textColor="text-gray-450" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SignUpBanner.propTypes = {
  data: PropTypes.array
};
