export const INVOICE_DATA_REQUEST_SUCCESS = 'INVOICE_DATA_REQUEST_SUCCESS';

export const GET_INVOICE_BY_ID_REQUEST_START = 'GET_INVOICE_BY_ID_REQUEST_START';
export const GET_INVOICE_BY_ID_REQUEST_FAIL = 'GET_INVOICE_BY_ID_REQUEST_FAIL';
export const GET_INVOICE_BY_ID_REQUEST_SUCCESS = 'GET_INVOICE_BY_ID_REQUEST_SUCCESS';

export const GET_INVOICE_LIST_REQUEST_START = 'GET_INVOICE_LIST_REQUEST_START';
export const GET_INVOICE_LIST_REQUEST_FAIL = 'GET_INVOICE_LIST_REQUEST_FAIL';
export const GET_INVOICE_LIST_REQUEST_SUCCESS = 'GET_INVOICE_LIST_REQUEST_SUCCESS';

export const CREATE_INVOICE_REQUEST_START = 'CREATE_INVOICE_REQUEST_START';
export const CREATE_INVOICE_REQUEST_FAIL = 'CREATE_INVOICE_REQUEST_FAIL';
export const CREATE_INVOICE_REQUEST_SUCCESS = 'CREATE_INVOICE_REQUEST_SUCCESS';

export const DELETE_INVOICE_REQUEST_START = 'DELETE_INVOICE_REQUEST_START';
export const DELETE_INVOICE_REQUEST_FAIL = 'DELETE_INVOICE_REQUEST_FAIL';
export const DELETE_INVOICE_REQUEST_SUCCESS = 'DELETE_INVOICE_REQUEST_SUCCESS';

export const EDIT_INVOICE_REQUEST_START = 'EDIT_INVOICE_REQUEST_START';
export const EDIT_INVOICE_REQUEST_FAIL = 'EDIT_INVOICE_REQUEST_FAIL';
export const EDIT_INVOICE_REQUEST_SUCCESS = 'EDIT_INVOICE_REQUEST_SUCCESS';

export const GET_PUBLIC_INVOICE_REQUEST_START = 'GET_PUBLIC_INVOICE_REQUEST_START';
export const GET_PUBLIC_INVOICE_REQUEST_FAIL = 'GET_PUBLIC_INVOICE_REQUEST_FAIL';
export const GET_PUBLIC_INVOICE_REQUEST_SUCCESS = 'GET_PUBLIC_INVOICE_REQUEST_SUCCESS';

export const SEND_EMAIL_INVOICE_REQUEST_START = 'SEND_EMAIL_INVOICE_REQUEST_START';
export const SEND_EMAIL_INVOICE_REQUEST_FAIL = 'SEND_EMAIL_INVOICE_REQUEST_FAIL';
export const SEND_EMAIL_INVOICE_REQUEST_SUCCESS = 'SEND_EMAIL_INVOICE_REQUEST_SUCCESS';

export const INVOICE_COUNT_REQUEST_START = 'INVOICE_COUNT_REQUEST_START';
export const INVOICE_COUNT_REQUEST_FAIL = 'INVOICE_COUNT_REQUEST_FAIL';
export const INVOICE_COUNT_REQUEST_SUCCESS = 'INVOICE_COUNT_REQUEST_SUCCESS';

export const TOTAL_INVOICE_CURRENCY_REQUEST_START = 'TOTAL_INVOICE_CURRENCY_REQUEST_START';
export const TOTAL_INVOICE_CURRENCY_REQUEST_FAIL = 'TOTAL_INVOICE_CURRENCY_REQUEST_FAIL';
export const TOTAL_INVOICE_CURRENCY_REQUEST_SUCCESS = 'TOTAL_INVOICE_CURRENCY_REQUEST_SUCCESS';

export const INVOICE_GENERATOR_REQUEST_START = 'INVOICE_GENERATOR_REQUEST_START';
export const INVOICE_GENERATOR_REQUEST_FAIL = 'INVOICE_GENERATOR_REQUEST_FAIL';
export const INVOICE_GENERATOR_REQUEST_SUCCESS = 'INVOICE_GENERATOR_REQUEST_SUCCESS';

export const GET_INVOICE_GENERATOR_REQUEST_START = 'GET_INVOICE_GENERATOR_REQUEST_START';
export const GET_INVOICE_GENERATOR_REQUEST_FAIL = 'GET_INVOICE_GENERATOR_REQUEST_FAIL';
export const GET_INVOICE_GENERATOR_REQUEST_SUCCESS = 'GET_INVOICE_GENERATOR_REQUEST_SUCCESS';

export const UPDATE_USER_INVOICE_GENERATOR_REQUEST_START = 'UPDATE_USER_INVOICE_GENERATOR_REQUEST_START';
export const UPDATE_USER_INVOICE_GENERATOR_REQUEST_FAIL = 'UPDATE_USER_INVOICE_GENERATOR_REQUEST_FAIL';
export const UPDATE_USER_INVOICE_GENERATOR_REQUEST_SUCCESS = 'UPDATE_USER_INVOICE_GENERATOR_REQUEST_SUCCESS';

export const RESET_INVOICE_DETAILS_SUCCESS = 'RESET_INVOICE_DETAILS_SUCCESS';
