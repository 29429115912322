import React, { useState } from 'react';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import countries from '../../../constants/countries.json';
import ValidationMessage from '../../ui/ValidationMessage';
import { useSelector, connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE, COMPANY_SETUP_STEP } from '../../../constants/enum';

const Countries = ({ prevDescriptionStep, nextDescriptionStep, updateCompanyIncorporation }) => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const [country, setCountry] = useState(companyAllInformation?.countries ? companyAllInformation?.countries : '');
  const [error, setError] = useState(false);

  const onContinue = async () => {
    if (country) {
      let payload = {
        countries: country,
        companyId: companyAllInformation?._id,
        completedStep: COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextDescriptionStep();
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="flex md:flex-row flex-col gap-12">
        <div className="flex flex-col gap-2">
          <XSText title="List all the countries with which your company business will be connected." />
          <SelectInputAutoComplete
            defaultValue={companyAllInformation?.countries}
            label="Countries"
            id="countries"
            InputLabelProps={{
              shrink: true
            }}
            options={countries}
            value={country}
            disableClearable={true}
            multiple={true}
            setSelectedValue={setCountry}
            error={error ? true : false}
            helperText={error ? <ValidationMessage title="Please fill the countries" /> : null}
          />
        </div>
        <div className="flex flex-col gap-4 business-details-text-container">
          <MSText title="Things to know" fontWeight="text-bold" />
          <MSText
            className="mr-2"
            title={
              <div>
                The <span className="text-bold">countries connected with your company</span> business are all the
                countries where your clients, suppliers, employees or other business partners will be located. Select
                all that apply.
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-row justify-between ">
        <BackButton onClick={prevDescriptionStep} />
        <PrimaryButton caption="Continue" color="text-white" isBorderRequired={true} onClick={onContinue} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(Countries);
