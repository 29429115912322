import React from 'react';
import StatrysLogo from '../images/icons/statrysLogo.svg';
import MDSubtitleText from '../components/ui/typography/MDSubtitleText';
import XSText from '../components/ui/typography/XSText';
import { Link } from 'gatsby';
import AuthSideContainer from '../components/common/AuthSideContainer';
import TooManyAttemptsImg from '../images/graphics/tooManyAttempts.png';

export default function TooManyAttempts() {
  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="flex lg:px-32 md:px-20 px-8 w-full">
          <div className="flex flex-col justify-center h-full w-full">
            <img src={StatrysLogo} className="login-logo" alt="statrys-logo" />
            <MDSubtitleText className="md:whitespace-pre-line mt-8" title={`Verification took \ntoo many attempts`} />
            <XSText
              title={`For security reasons, your password \nreset process has been locked for 24 hours.`}
              className="mt-4 md:whitespace-pre-line"
            />
            <div className="flex items-center mt-8">
              <XSText title="Having issue" />
              <Link to="/cotactus" className="ml-1 text-coral-500">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <AuthSideContainer image={TooManyAttemptsImg} />
      </div>
    </>
  );
}
