import * as React from 'react';

import PropTypes from 'prop-types';
import { fShortenNumber, fCurrency, IsCurrency } from '../../utils/formatNumber';
// components
import CircularLoader from '../../components/ui/loaders/CircularLoader';
import { concatenateCurrencyAmountWithoutSpace } from '../../helper/helpers';
import XXSText from '../../components/ui/typography/XXSText';
import BaseText from '../../components/ui/typography/BaseText';
import MSText from '../../components/ui/typography/MSText';

// ----------------------------------------------------------------------

InvoiceAnalytic.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  percent: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number
};

export default function InvoiceAnalytic({
  title,
  total,
  titleClass,
  rootClass,
  totalClass,
  price,
  amountColor,
  currency,
  invoiceClass,
  analyticsClass,
  icon,
  handleAnalyticsTabs,
  isAmountHuge
}) {
  return (
    <div className={analyticsClass}>
      <div className={`${rootClass} p-4 rounded-lg`}>
        <XXSText
          fontWeight="text-bold"
          textColor={`${titleClass} mb-1`}
          title={
            <span className="flex justify-between">
              <span>{title}</span>
              {icon && (
                <span className="cursor-pointer" onClick={() => handleAnalyticsTabs(title)}>
                  <img src={icon} width="22" height="22" alt="analytics" />
                </span>
              )}
            </span>
          }
        />
        <BaseText
          className="mb-1"
          textColor={amountColor}
          fontWeight="text-bold"
          fontSize={isAmountHuge ? 'text-base' : 'text-xl'}
          title={
            <span>
              {price ? (
                <span>{currency ? concatenateCurrencyAmountWithoutSpace(currency, price) : fCurrency(price)}</span>
              ) : (
                <span className="inline-block">
                  <CircularLoader />
                </span>
              )}
            </span>
          }
        />
        <MSText
          className="mb-1"
          title={
            <span>
              <span className={`${totalClass} text-bold mr-1`}>{fShortenNumber(total)}</span>
              <span className={invoiceClass}>invoices</span>
            </span>
          }
        />
      </div>
    </div>
  );
}
