import React, { useState, useEffect } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import InfoCard from '../../common/InfoCard';
import Bulb from '../../../images/icons/bulb.svg';
import XSText from '../../ui/typography/XSText';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import MSText from '../../ui/typography/MSText';
import Avatar from '../../ui/Avatar';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import currencies from '../../../constants/currencies.json';
import { addCommas, isDecimal } from '../../../helper/helpers';
import { COMPANY_SETUP_SIDEBAR_STEP, CONSTANT_NUMBER, COMPANY_SETUP_STEP } from '../../../constants/enum';
import { connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';

const Capital = ({
  prevStep,
  nextStep,
  setSubStep,
  setActiveStep,
  companyAllInformation,
  updateCompanyIncorporation
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors
  } = useForm();
  const [currency, setCurrency] = useState(companyAllInformation?.capital?.currency);
  const [states, setStates] = useState({
    noOfShares: companyAllInformation?.capital?.totalNumberOfShares
      ? companyAllInformation?.capital?.totalNumberOfShares
      : '',
    valueOfShares: companyAllInformation?.capital?.valuePerShare ? companyAllInformation?.capital?.valuePerShare : '',
    capitalErrorMessage: ''
  });
  const [ownership, setOwnership] = useState(
    companyAllInformation?.stakeholder?.filter((holder) => holder?.isShareholder === true)
  );

  let calculatedCapital = states?.noOfShares * states?.valueOfShares;

  const checkSharesDecimal = () => {
    return ownership.map((person) => isDecimal(person.shares));
  };

  const checkSharesNotZero = () => {
    return ownership.map((person) => person.shares == 0);
  };

  const onSubmit = async () => {
    const sumOfOwnership = ownership.reduce((accumulator, currentValue) => {
      const numericValue = parseInt(currentValue.ownership);
      return accumulator + numericValue;
    }, 0);
    if (sumOfOwnership !== 100) {
      setError('ownership', { type: 'focus' }, { shouldFocus: true });
      setStates({ ...states, capitalErrorMessage: 'Ownership percentage shall add up to 100%' });
    } else if (checkSharesDecimal().includes(true)) {
      setError('ownership', { type: 'focus' }, { shouldFocus: true });
      setStates({ ...states, capitalErrorMessage: 'Each company owner must hold a round number of shares.' });
    } else if (checkSharesNotZero().includes(true)) {
      setError('ownership', { type: 'focus' }, { shouldFocus: true });
      setStates({ ...states, capitalErrorMessage: 'Each company owner must have some percentage of ownership.' });
    } else {
      let payload = {
        stakeholder: companyAllInformation?.stakeholder,
        capital: {
          totalNumberOfShares: states?.noOfShares,
          currency: currency,
          valuePerShare: states?.valueOfShares
        },
        companyId: companyAllInformation?._id,
        completedStep: COMPANY_SETUP_STEP?.CAPITAL_OF_THE_COMPANY
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextStep();
        setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.SIGN);
      }
    }
  };

  const calculateShares = (id, ownershipPercentage) => {
    clearErrors('ownership');
    setOwnership(
      ownership?.map((item) => {
        if (item?._id === id) {
          return {
            ...item,
            ownership: ownershipPercentage,
            shares: (ownershipPercentage / 100) * states?.noOfShares,
            totalInvestment: (ownershipPercentage / 100) * calculatedCapital
          };
        }
        return item;
      })
    );
  };

  function updatedSharesValue() {
    setOwnership(
      ownership?.map((item) => {
        return {
          ...item,
          ownership: item?.ownership,
          shares: (item?.ownership / 100) * states?.noOfShares,
          totalInvestment: (item?.ownership / 100) * calculatedCapital
        };
      })
    );
  }
  useEffect(() => {
    updatedSharesValue();
  }, [states?.noOfShares, states?.valueOfShares]);

  useEffect(() => {
    setOwnership(companyAllInformation?.stakeholder?.filter((holder) => holder?.isShareholder === true));
  }, [companyAllInformation]);

  const backButton = () => {
    prevStep();
    setSubStep(CONSTANT_NUMBER.ONE);
  };

  function changeNameOfShares(value) {
    setStates({ ...states, noOfShares: value });
    clearErrors('ownership');
  }

  function changesValueOfShares(value) {
    setStates({ ...states, valueOfShares: value });
    clearErrors('ownership');
  }

  return (
    <div className="flex flex-col justify-between h-full main-wrapper-container">
      <MDSubtitleText title="Capital of the company" fontWeight="text-bold" />
      <div className="py-8">
        <InfoCard
          icon={Bulb}
          alt="IconMoneyAtm"
          fontSize="text-sm"
          width="32"
          height="32"
          title={
            <div className="pr-8">
              The <span className="text-bold">capital of the company</span> corresponds to the{' '}
              <span className="text-bold">funds invested by the company owners.</span> The invested funds give the right
              to the owners to <span className="text-bold">obtain shares in the company</span> (i.e., they become
              shareholders). The shares are distributed among the owners{' '}
              <span className="text-bold">in proportion to their respective investments in the company.</span>
            </div>
          }
          alignItems="items-start"
          fontWeight="text-regular"
        />
        <XSText
          className="mt-4"
          title="Specify the number of shares in your company, their individual value, and the currency used"
        />
        <form id="support-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex md:flex-row flex-col gap-2 mt-4">
            <TextInput
              defaultValue={
                companyAllInformation?.capital?.totalNumberOfShares
                  ? companyAllInformation?.capital?.totalNumberOfShares
                  : ''
              }
              name="NumberOfShares"
              placeholder="100"
              label="Number of shares"
              type="number"
              margin="mt-2"
              onChange={(event) => changeNameOfShares(event?.target.value)}
              inputRef={register({
                required: true
              })}
              error={errors?.numberOfShares ? true : false}
              helperText={errors?.numberOfShares && <ValidationMessage title="Please fill the number of shares" />}
            />
            <TextInput
              defaultValue={
                companyAllInformation?.capital?.valuePerShare ? companyAllInformation?.capital?.valuePerShare : ''
              }
              name="valueOfShares"
              placeholder="10"
              label="Value of shares"
              type="number"
              margin="mt-2"
              onChange={(event) => changesValueOfShares(event?.target.value)}
              inputRef={register({
                required: true
              })}
              error={errors?.valueOfShares ? true : false}
              helperText={errors?.valueOfShares && <ValidationMessage title="Please fill the value of shares" />}
            />
            <SelectInputAutoComplete
              containerClass="mt-2 currency-input"
              label="Currency"
              id="currency"
              disableClearable={true}
              options={currencies}
              value={currency}
              setSelectedValue={setCurrency}
            />
            <div className="flex flex-col items-start justify-center capital-calculate-container">
              <div className="px-4">
                <MSText title="Capital of the company" textColor="text-gray-450" />
                {currency && (
                  <XSText title={`${currency?.code} ${addCommas(calculatedCapital)}`} fontWeight="text-bold" />
                )}
              </div>
            </div>
          </div>
        </form>
        <XSText className="mt-8 mb-4" title="Indicate the percentage of shares held by each company owner" />
        <div className="flex flex-col gap-2">
          {ownership?.map((owners, index) => {
            return (
              <div key={index} className="w-full rounded-lg border-gray-450 bg-customBg-secondaryBg">
                <div className="flex md:flex-row flex-col justify-between px-4 py-2">
                  <div className="flex flex-row gap-4 items-center">
                    <Avatar avatarName={owners?.firstName.charAt(0) + owners?.lastName.charAt(0)} />
                    <div className="flex flex-col gap-2">
                      <XSText fontWeight="text-bold capitalize" title={owners?.firstName + ' ' + owners?.lastName} />
                      <div className="flex flex-row gap-2">
                        <MSText
                          className="card-type-text flex items-center"
                          title={index === 0 ? 'Yourself' : 'Individual'}
                        />
                        <MSText
                          className="role-owner flex items-center justify-center px-2 py-1"
                          fontWeight="text-bold"
                          title="Owner"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-center gap-10">
                    <TextInput
                      defaultValue={`${owners?.ownership}`}
                      name="ownership"
                      placeholder="10%"
                      label="Ownership %"
                      type="number"
                      textFieldContainerClass="ownership-text-input"
                      onChange={(event) => calculateShares(owners?._id, event.target.value)}
                      error={errors?.ownership ? true : false}
                    />
                    <div className="flex flex-row gap-1">
                      <div className="shares-container">
                        <MSText
                          fontWeight="text-bold"
                          title="Shares"
                          textColor={errors?.ownership ? 'text-coral-500' : 'text-gray-450'}
                        />
                        {owners?.shares && (
                          <XSText
                            title={!isNaN(owners?.shares) ? parseFloat(owners?.shares).toFixed(2) : 0}
                            fontWeight="text-bold"
                            textColor={errors?.ownership && 'text-coral-500'}
                          />
                        )}
                      </div>
                      <div className="shares-container">
                        <MSText title="Total investment" textColor="text-gray-450" />
                        {owners?.totalInvestment && currency && (
                          <XSText
                            title={`${currency?.code} ${
                              !isNaN(owners?.totalInvestment) ? parseFloat(owners?.totalInvestment).toFixed(2) : 0
                            }`}
                            fontWeight="text-bold"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {states?.capitalErrorMessage && (
          <div className="mt-4">
            <ValidationMessage title={states?.capitalErrorMessage} />
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <BackButton onClick={backButton} />
        <PrimaryButton caption="Continue" color="text-white" isBorderRequired={true} onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(Capital);
