import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function monthDateSuffix(date) {
  return format(new Date(date), 'MMMM dd yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function getTimeDate(date) {
  return new Date(date);
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function getUTCBasedDate(date, ianatz) {
  var invdate = new Date(
    date?.toLocaleString('en-US', {
      timeZone: ianatz
    })
  );

  var diff = date?.getTime() - invdate.getTime();

  return String(format(new Date(date.getTime() - diff), 'yyyy-MM-dd'));
}
