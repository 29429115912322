export const units = [
  {
    name: 'each',
    priceOrRate: 'Price'
  },
  {
    name: 'hours',
    priceOrRate: 'Rate'
  },
  {
    name: 'kg',
    priceOrRate: 'Price'
  },
  {
    name: 'km',
    priceOrRate: 'Price'
  },
  {
    name: 'liter',
    priceOrRate: 'Price'
  },
  {
    name: 'm²',
    priceOrRate: 'Rate'
  },
  {
    name: 'm³',
    priceOrRate: 'Price'
  },
  {
    name: 'meter',
    priceOrRate: 'Price'
  },
  {
    name: 'month',
    priceOrRate: 'Rate'
  },
  {
    name: 'order',
    priceOrRate: 'Price'
  },
  {
    name: 'piece',
    priceOrRate: 'Price'
  },
  {
    name: 'week',
    priceOrRate: 'Rate'
  }
];
