import React from 'react';
import BaseText from '../../ui/typography/BaseText';
import MSText from '../../ui/typography/MSText';
import CashPaymentWallet from '../../../images/icons/company/cash-payment-wallet.svg';
import CashUser from '../../../images/icons/company/cash-user.svg';
import SavingBankCash from '../../../images/icons/company/saving-bank-cash.svg';
import CurrencyCash from '../../../images/icons/company/currency-sign.svg';
import XSText from '../../ui/typography/XSText';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import { checkValueExistOrNot } from '../../../helper/helpers';

function CompanySecretary({ capitalDetails }) {
  return (
    <>
      <div className="bg-white mt-6 p-6 rounded-lg company-view-container">
        <BaseText className="mb-6" fontSize="text-xl" fontWeight="text-bold" title="Capital" />
        <div className="flex mb-4 items-start justify-between">
          <div className="flex items-start">
            <img src={CashPaymentWallet} alt="cash payment wallet" />
            <MSText className="mb-2 ml-2" textColor="text-gray-450" title="Share name" />
          </div>
          <XSText title={checkValueExistOrNot(capitalDetails?.shareName?.name)} />
        </div>
        <div className="flex mb-4 items-start justify-between">
          <div className="flex items-start">
            <img src={CashPaymentWallet} alt="cash payment wallet" />
            <MSText className="mb-2 ml-2" textColor="text-gray-450" title="Total paid-up capital" />
          </div>
          <XSText title={checkValueExistOrNot(capitalDetails?.totalPaidUpCapitals)} />
        </div>
        <div className="flex mb-4 items-start justify-between">
          <div className="flex items-start">
            <img src={CashUser} alt="cash user" />
            <MSText className="mb-2 ml-2" textColor="text-gray-450" title="Total issued shared capital" />
          </div>
          <XSText title={checkValueExistOrNot(capitalDetails?.totalIssuedSharedCapital)} />
        </div>
        <div className="flex mb-4 items-start justify-between">
          <div className="flex items-start">
            <img src={SavingBankCash} alt="saving bank cash" />
            <MSText className="mb-2 ml-2" textColor="text-gray-450" title="Total number of shares" />
          </div>
          <XSText title={checkValueExistOrNot(capitalDetails?.totalNumberOfShares)} />
        </div>
        <div className="flex mb-4 items-start justify-between">
          <div className="flex items-start">
            <img src={CurrencyCash} alt="currency cash" />
            <MSText className="mb-2 ml-2" textColor="text-gray-450" title="Currency" />
          </div>
          <XSText
            title={checkValueExistOrNot(
              capitalDetails?.currency?.name ? capitalDetails?.currency?.name : capitalDetails?.currency?.symbol
            )}
          />
        </div>
      </div>
    </>
  );
}

export default CompanySecretary;
