import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const updateCompanyDetailsRequestStart = () => ({ type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_START });
export const updateCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const updateCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS,
  companyDetails: companyDetails
});
export const updateCompanyDetails = (companyDetailsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.put(ENDPOINTS.UPDATE_COMPANY_DETAILS, companyDetailsPayload);
      dispatch(updateCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(updateCompanyDetailsRequestFail(error.response));
    }
  };
};

export const createCompanyDetailsRequestStart = () => ({ type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_START });
export const createCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const createCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_SUCCESS,
  companyDetails: companyDetails
});
export const createCompanyDetails = (companyDetailsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.post(ENDPOINTS.COMPANY_DETAILS, companyDetailsPayload);
      dispatch(createCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(createCompanyDetailsRequestFail(error.response));
    }
  };
};

export const getCompanyDetailsRequestStart = () => ({ type: actionTypes.GET_COMPANY_DETAILS_REQUEST_START });
export const getCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const getCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS,
  getCompanyDetails: companyDetails
});
export const getCompanyDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.get(ENDPOINTS.COMPANY_DETAILS);
      dispatch(getCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(getCompanyDetailsRequestFail(error.response));
    }
  };
};
