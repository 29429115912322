import React, { useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import ArrowIcon from '../../../images/icons/arrow-right-1.svg';
import MultipleManWomanIcon from '../../../images/icons/companyDetailsModal/multiple-man-woman.svg';
import SingleNeutralActionsRemovedIcon from '../../../images/icons/companyDetailsModal/single-neutral-actions-removed.svg';
import SingleNeutralIcon from '../../../images/icons/companyDetailsModal/single-neutral.svg';
export default function AboutYourBusiness({ nextStep, companyDetailsData }) {
  const handleSelection = (selectedValue) => {
    nextStep({ businessStatus: selectedValue?.title });
  };
  const businessStatusOptions = [
    { title: 'I have a company', icon: MultipleManWomanIcon, id: 'I_have_a_company' },
    { title: 'I’m a freelancer', icon: SingleNeutralIcon, id: 'Im_a_freelancer' },
    { title: 'I don’t have a business', icon: SingleNeutralActionsRemovedIcon, id: 'I_dont_have_a_business' }
  ];
  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText
        className="md:whitespace-pre-line my-6"
        fontWeight="text-bold"
        title={`Tell us about your business`}
      />
      <XSText className="mb-4" title="What is the legal status of your business?" />
      <div className="flex flex-col justify-center gap-2">
        {businessStatusOptions?.map((businessOptions) => (
          <div
            id={businessOptions?.id}
            onClick={() => handleSelection(businessOptions)}
            className={`flex justify-between hover:bg-white ${
              companyDetailsData?.businessStatus === businessOptions?.title
                ? 'bg-white border-purple-500'
                : 'bg-customBg-mainBg border-white'
            } rounded-lg border  hover:border-purple-500 px-4 py-6 cursor-pointer`}
          >
            <div className="flex items-center gap-4">
              <img src={businessOptions?.icon} alt="MultipleManWomanIcon" />
              <XSText title={businessOptions?.title} />
            </div>
            <img src={ArrowIcon} alt="ArrowIcon" />
          </div>
        ))}
      </div>
    </div>
  );
}
