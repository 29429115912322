import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/company-set-up/wrappers.scss';
import VerticalLinearStepper from './VerticalLinearStepper';

const MainWrapper = ({ children, activeStepNumber, subStep }) => {
  return (
    <div className="bg-white rounded-2xl">
      <div className="flex md:flex-row flex-col gap-2 w-full h-full">
        <VerticalLinearStepper activeStepNumber={activeStepNumber} subStep={subStep} />
        <div className="md:p-8 p-4 w-full">{children}</div>
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.any
};

export default MainWrapper;
