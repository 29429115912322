import React, { useEffect, useState } from 'react';
import TextInput from '../ui/inputs/TextInput';
import MultiTextInput from '../ui/inputs/MultiTextInput';
import DynamicModal from '../ui/modal/DynamicModal';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import { defaultValidationMessage } from '../../helper/helpers';
import { API_CODE, INVOICE_STATUS, MUI_COLORS, REGEX } from '../../constants/enum';
import MSText from '../ui/typography/MSText';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import * as importingInvoiceReducer from '../importedInvoices/reduxStore/action';
import Snackbar from '../../components/Snackbar';
import Image from '../../components/Image';
import XSText from '../ui/typography/XSText';
import SuccessIcon from '../../images/icons/success-icon.svg';
import { PATH_PAGE } from '../../routes/paths';
import { Link } from 'gatsby';
function ShareInvoiceByMail({
  title,
  type,
  bgColor,
  className,
  modalTitle,
  emailInvoiceData,
  getUserProfile,
  sendEmailInvoice,
  updateImportingInvoice,
  getImportingInvoiceDetails,
  companyDetails
}) {
  const { register, handleSubmit, errors } = useForm();
  const [sendByMail, setSendByMail] = useState(false);
  const [userProfileData, setUserProfileData] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  const sendInvoiceByMail = () => {
    setSendByMail(true);
  };
  const closeSendByMailDynamicModal = () => {
    setSendByMail(false);
  };

  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  const getProfileDetailsHandler = async () => {
    const getUserProfileDetail = await getUserProfile();
    if (getUserProfileDetail?.status === API_CODE?.STATUS_200) {
      setUserProfileData(getUserProfileDetail?.data?.data);
    }
  };

  const updateImportingInvoiceHandler = async () => {
    const payload = {
      invoiceId: id,
      validationStatus: INVOICE_STATUS.AWAITING_VALIDATION,
      status: INVOICE_STATUS.INVOICE_REVIEW,
      publicURL: `${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`
    };
    const getUpdatedInvoiceResponse = await updateImportingInvoice(payload);
    if (getUpdatedInvoiceResponse?.status === API_CODE?.STATUS_200) {
      getImportingInvoiceDetails();
      closeSendByMailDynamicModal();
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const substringToRemove = 'statrys';
  const regex = new RegExp(substringToRemove, 'g');
  const onSubmit = (data) => {
    setIsButtonLoading(true);
    const messageContent = data.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const requestPayload = {
      subject: data.subject.replace(regex, ''),
      invoice_url: `${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`,
      message: messageContent.replace(regex, ''),
      email: data.email,
      company_name: emailInvoiceData?.supplier?.companyName.replace(regex, ''),
      invoice: emailInvoiceData?.invoiceNumber.replace(regex, '')
    };
    sendInvoiceWithMail(requestPayload);
  };

  const sendInvoiceWithMail = async (data) => {
    const setEmailResponse = await sendEmailInvoice(data);
    if (setEmailResponse?.status === API_CODE?.STATUS_200) {
      updateImportingInvoiceHandler();
      setIsButtonLoading(false);
      setUserProfileData(setEmailResponse?.data?.data);
      setIsSnackBarOpen(true);
      setSuccessMessage({
        ...successMessage,
        title: setEmailResponse?.data?.message,
        content: `Email send sucessfully!`
      });
    }
  };

  useEffect(() => {
    getProfileDetailsHandler();
  }, []);
  return (
    <div>
      <PrimaryButton
        id="share-invoice-btn"
        bgColor={bgColor}
        isBorderRequired={false}
        caption={title}
        onClick={() => sendInvoiceByMail()}
        className={className}
        type={type}
      />
      <DynamicModal openDynamicModal={sendByMail} closeDynamicModal={closeSendByMailDynamicModal} minWidth="520px">
        <div>
          <MDSubtitleText title={modalTitle} fontWeight="text-bold" />
          <form id="send-invoice-form" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              defaultValue=""
              name="email"
              label="to"
              variant="filled"
              textFieldContainerClass="mt-8"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED
                }
              })}
              error={errors?.email ? true : false}
              helperText={
                errors?.email && <ValidationMessage title={defaultValidationMessage('email (Tags not allowed)')} />
              }
            />
            <TextInput
              defaultValue={`Invoice ${emailInvoiceData?.invoiceNumber} from ${emailInvoiceData?.supplier?.companyName}`}
              name="subject"
              label="Subject"
              variant="filled"
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED
                }
              })}
              error={errors?.subject ? true : false}
              helperText={
                errors?.subject && <ValidationMessage title={defaultValidationMessage('subject (Tags not allowed)')} />
              }
            />
            <MultiTextInput
              inputContainerClass="mt-4"
              defaultValue={`Hello,\nPlease validate ${
                emailInvoiceData?.invoiceNumber ? emailInvoiceData?.invoiceNumber : ''
              } from ${emailInvoiceData?.supplier?.companyName ? emailInvoiceData?.supplier?.companyName : ''} for ${
                (emailInvoiceData?.currency?.symbol
                  ? emailInvoiceData?.currency?.symbol
                  : emailInvoiceData?.currency?.name) + emailInvoiceData?.totalAmount
              }.\n\nSincerely,\n${
                userProfileData?.firstName ? `${capitalizeFirstLetter(userProfileData?.firstName)} ` : ''
              }${userProfileData?.firstName ? `${capitalizeFirstLetter(userProfileData?.lastName)} from ` : ''}${
                companyDetails?.companyName ? companyDetails?.companyName : ''
              }`}
              name="message"
              rows={5}
              label="Message for your customer"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED
                }
              })}
              error={errors?.message ? true : false}
              helperText={
                errors?.message && <ValidationMessage title={defaultValidationMessage('message (Tags not allowed')} />
              }
            />

            <MSText
              title={
                <div>
                  Your customer will receive this{' '}
                  <Link
                    className=" text-coral-500"
                    target="_blank"
                    to={`${process.env.SITE_URL}${PATH_PAGE.incomingPublicInvoice}?id=${id}`}
                  >
                    link
                  </Link>
                </div>
              }
              className="leading-6 mt-2"
              textColor="text-gray-450"
            />
            <div className="flex flex-row gap-4 mt-8">
              <PrimaryButton
                id="invoice-share-email-modal-send"
                caption="Send"
                isLoading={isButtonLoading}
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
              />
              <PrimaryButton
                id="invoice-share-email-modal-cancel"
                className="modal-cancel-button font-weight-bold border border-gray-400"
                bgColor="bg-white"
                color="text-gray-300"
                caption="Cancel"
                onClick={() => setSendByMail(false)}
              />
            </div>
          </form>
        </div>
      </DynamicModal>
      <Snackbar
        open={isSnackBarOpen}
        handleClose={closeSnackbar}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={SuccessIcon} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
              <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
            </div>
          </div>
        }
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailInvoice: (invoiceData) => dispatch(importingInvoiceReducer.requestValidationInvoices(invoiceData)),
    updateImportingInvoice: (invoiceData) => dispatch(importingInvoiceReducer.editImportingInvoice(invoiceData)),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile())
  };
};

export default connect(null, mapDispatchToProps)(ShareInvoiceByMail);

ShareInvoiceByMail.propTypes = {
  getUserProfile: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  modalTitle: PropTypes.string,
  emailInvoiceData: PropTypes.object,
  sendEmailInvoice: PropTypes.func,
  updateImportingInvoice: PropTypes.func,
  getImportingInvoiceDetails: PropTypes.func,
  companyDetails: PropTypes.object
};
