import React, { useState, useEffect } from 'react';
import InitialWrapper from '../components/companyIncorporation/wrappers/InitialWrapper';
import Start from '../components/companyIncorporation/verifyEmail/Start.js';
import Email from '../components/companyIncorporation/verifyEmail/Email.js';
import VerifyOtp from '../components/companyIncorporation/verifyEmail/VerifyOtp';
import { navigate } from 'gatsby';
import {
  COMPANY_SETUP_STEP,
  COMPANY_SETUP_SIDEBAR_STEP,
  CONSTANT_NUMBER,
  OWNER_DIRECTOR_TYPES
} from '../constants/enum';
import MainWrapper from '../components/companyIncorporation/wrappers/MainWrapper';
import Industry from '../components/companyIncorporation/companyInformation/Industry';
import BusinessDescription from '../components/companyIncorporation/companyInformation/BusinessDescription';
import CompanyRelationSelect from '../components/companyIncorporation/ownerAndDirectors/CompanyRelationSelect';
import UploadDocuments from '../components/companyIncorporation/ownerAndDirectors/UploadDocuments';
import PersonalInformationForm from '../components/companyIncorporation/ownerAndDirectors/PersonalInformationForm';
import RecidentialAddress from '../components/companyIncorporation/ownerAndDirectors/RecidentialAddress';
import OwnerAndDirectorList from '../components/companyIncorporation/ownerAndDirectors/OwnerAndDirectorList';
import BussinessDetailsAndExperience from '../components/companyIncorporation/ownerAndDirectors/BusinessDetailAndExperience';
import CompanyName from '../components/companyIncorporation/companyInformation/CompanyName';
import Capital from '../components/companyIncorporation/ownerAndDirectors/Capital';
import StepSuccessPage from '../components/companyIncorporation/companyLastStep/StepSuccessPage';
import SignConfirm from '../components/companyIncorporation/confirmAndsign/SignConfirm.js';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import * as CompanyAction from '../components/companyIncorporation/reduxStore/action';
import { connect, useSelector } from 'react-redux';

const CompanyIncorporation = ({ location, getCompanyDetails }) => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const token = typeof window !== `undefined` && sessionStorage.getItem('token');

  const [step, setStep] = useState(token ? COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION : COMPANY_SETUP_STEP?.START);

  const [subStep, setSubStep] = useState(1);
  const [activeStep, setActiveStep] = useState(COMPANY_SETUP_SIDEBAR_STEP?.COMPANY_INFORMATION);
  const [isAddingOwner, setIsAddingOwner] = useState(false);
  const [isOwnerDirectorEdit, setIsOwnerDirectorEdit] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [passportUploadResponse, setPassportUploadResponse] = useState('');
  const [stackHolderType, setStackHolderType] = useState(OWNER_DIRECTOR_TYPES?.OWNER_DIRECTOR);
  const [stackHolderPayload, setStackHolderPayload] = useState([]);
  const [currentStackHolder, setCurrentStackHolder] = useState({});

  const query = new URLSearchParams(location?.search);
  const companyId = query.get('companyId');
  const email = query.get('email');
  companyId && sessionStorage.setItem('companyId', companyId);
  email && sessionStorage.setItem('email', email);

  let nextStep = () => {
    setStep(step + 1);
  };

  let nextSubStep = () => {
    setSubStep(subStep + 1);
  };

  let prevStep = () => {
    setStep(step - 1 > 0 ? step - 1 : 1);
  };

  let prevSubStep = () => {
    setSubStep(subStep - 1 > 0 ? subStep - 1 : 1);
  };

  const chooseStackHolder = (type) => {
    setStackHolderType(type);
  };
  let selectedCase = () => {
    switch (step) {
      default:
        return <h1>coming soon...</h1>;
      case COMPANY_SETUP_STEP?.START:
        return (
          <Start
            nextStep={nextStep}
            completedStep={CONSTANT_NUMBER.ZERO}
            currentStep={CONSTANT_NUMBER.ONE}
            buttonText="Let’s start"
          />
        );
      case COMPANY_SETUP_STEP?.EMAIL:
        return <Email nextStep={nextStep} prevStep={prevStep} />;
      case COMPANY_SETUP_STEP?.VERIFY_OTP:
        return <VerifyOtp nextStep={nextStep} prevStep={prevStep} />;
      case COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION:
        return (
          <Start
            nextStep={nextStep}
            prevStep={prevStep}
            completedStep={COMPANY_SETUP_STEP?.START}
            currentStep={COMPANY_SETUP_STEP?.EMAIL}
            buttonText="Continue"
          />
        );
      case COMPANY_SETUP_STEP?.COMPANY_NAME:
        return <CompanyName nextStep={nextStep} nextSubStep={nextSubStep} prevSubStep={prevSubStep} />;
      case COMPANY_SETUP_STEP?.INDUSTRY:
        return <Industry nextStep={nextStep} nextSubStep={nextSubStep} prevStep={prevStep} prevSubStep={prevSubStep} />;
      case COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION:
        return (
          <BusinessDescription
            prevSubStep={prevSubStep}
            nextStep={nextStep}
            setActiveStep={() => setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.OWNERS_AND_DIRECTORS)}
            setSubStep={(value) => setSubStep(value)}
            prevStep={prevStep}
          />
        );
      case COMPANY_SETUP_STEP?.OWNERS_AND_DIRECTOR_SELECTOR:
        return (
          <CompanyRelationSelect
            isOwnerDirectorEdit={isOwnerDirectorEdit}
            isAddingOwner={isAddingOwner}
            setStep={(value) => setStep(value)}
            setActiveStep={(value) => setActiveStep(value)}
            nextStep={nextStep}
            prevSubStep={prevSubStep}
            prevStep={prevStep}
            stackHolderType={chooseStackHolder}
          />
        );
      case COMPANY_SETUP_STEP?.PASSPORT_UPLOAD:
        return (
          <UploadDocuments
            setPassportUploadResponse={setPassportUploadResponse}
            nextStep={nextStep}
            isAddingOwner={isAddingOwner}
            prevStep={prevStep}
            setDocumentUrl={setDocumentUrl}
            setCurrentStackHolder={setCurrentStackHolder}
            currentStackHolder={currentStackHolder}
          />
        );
      case COMPANY_SETUP_STEP?.PERSONAL_INFORMATION:
        return (
          <PersonalInformationForm
            isOwnerDirectorEdit={isOwnerDirectorEdit}
            passportUploadResponse={passportUploadResponse}
            nextStep={nextStep}
            isAddingOwner={isAddingOwner}
            stackHolderType={stackHolderType}
            pdfURL={documentUrl}
            setStep={(value) => setStep(value)}
            prevStep={prevStep}
            setCurrentStackHolder={setCurrentStackHolder}
            currentStackHolder={currentStackHolder}
            companyAllInformation={companyAllInformation}
            stackHolderPayload={stackHolderPayload}
          />
        );
      case COMPANY_SETUP_STEP?.RESIDENTIAL_ADDRESS_INFORMATION:
        return (
          <RecidentialAddress
            nextStep={nextStep}
            prevStep={prevStep}
            isOwnerDirectorEdit={isOwnerDirectorEdit}
            setCurrentStackHolder={setCurrentStackHolder}
            currentStackHolder={currentStackHolder}
          />
        );
      case COMPANY_SETUP_STEP?.BUSINESS_AND_EXPERIENCE_DETAILS:
        return (
          <BussinessDetailsAndExperience
            nextStep={nextStep}
            prevStep={prevStep}
            isOwnerDirectorEdit={isOwnerDirectorEdit}
            setCurrentStackHolder={setCurrentStackHolder}
            currentStackHolder={currentStackHolder}
            setStackHolderPayload={setStackHolderPayload}
            stackHolderPayload={stackHolderPayload}
          />
        );
      case COMPANY_SETUP_STEP?.OWNER_AND_DIRECTOR_LIST:
        return (
          <OwnerAndDirectorList
            prevStep={prevStep}
            setStep={(value) => setStep(value)}
            setSubStep={(value) => setSubStep(value)}
            setIsAddingOwner={() => setIsAddingOwner(true)}
            setIsOwnerDirectorEdit={setIsOwnerDirectorEdit}
            setCurrentStackHolder={setCurrentStackHolder}
            stackHolderPayload={stackHolderPayload}
            setStackHolderPayload={setStackHolderPayload}
            companyAllInformation={companyAllInformation}
          />
        );
      case COMPANY_SETUP_STEP?.CAPITAL_OF_THE_COMPANY:
        return (
          <Capital
            prevStep={prevStep}
            setSubStep={(value) => setSubStep(value)}
            nextStep={nextStep}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            companyAllInformation={companyAllInformation}
          />
        );

      case COMPANY_SETUP_STEP?.SIGN:
        return (
          <SignConfirm
            prevStep={prevStep}
            nextStep={nextStep}
            setActiveStep={setActiveStep}
            companyAllInformation={companyAllInformation}
          />
        );
      case COMPANY_SETUP_STEP?.STEP_COMPLETE_SUCCESS:
        return (
          <StepSuccessPage prevStep={prevStep} nextStep={nextStep} companyAllInformation={companyAllInformation} />
        );
    }
  };

  async function companyInformation() {
    const companyDetails = await getCompanyDetails(sessionStorage.getItem('companyId'));
    if (companyDetails?.data?.data?.completedStep) {
      setStep(companyDetails?.data?.data?.completedStep);
    }
  }

  const getStoredSteps = async () => {
    const response = await getCompanyDetails(sessionStorage.getItem('companyId'));
    setSubStep(
      response?.data?.data?.completedStep
        ? response?.data?.data?.completedStep
        : COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION
    );
  };

  const getApiCall = async () => {
    await getCompanyDetails(sessionStorage.getItem('companyId'));
  };

  useEffect(() => {
    if (step > COMPANY_SETUP_STEP?.VERIFY_OTP) getStoredSteps();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const continueForm = params.get('continue-form');
    continueForm && companyInformation();

    if (step > COMPANY_SETUP_STEP?.VERIFY_OTP) getApiCall();

    window.onpopstate = () => {
      prevStep();
    };
    if (step === COMPANY_SETUP_STEP?.EMAIL) {
      navigate('?=create-account');
    }
    if (step === COMPANY_SETUP_STEP?.VERIFY_OTP) {
      navigate('?=verify-email');
    }
    if (step === COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION) {
      navigate('?=company-information');
    }
    if (step === COMPANY_SETUP_STEP?.COMPANY_NAME) {
      navigate('?=company-name');
    }
    if (step === COMPANY_SETUP_STEP?.INDUSTRY) {
      navigate('?=industry');
    }
    if (step === COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION) {
      navigate('?=business-description');
      setSubStep(CONSTANT_NUMBER?.THREE);
    }
    if (step === COMPANY_SETUP_STEP?.OWNERS_AND_DIRECTOR_SELECTOR) {
      navigate('?=owner-director-selection');
    }
    if (step === COMPANY_SETUP_STEP?.PASSPORT_UPLOAD) {
      navigate('?=owner-director-upload-passport');
    }
    if (step === COMPANY_SETUP_STEP?.PERSONAL_INFORMATION) {
      navigate('?=owner-director-personal-information');
    }
    if (step === COMPANY_SETUP_STEP?.RESIDENTIAL_ADDRESS_INFORMATION) {
      navigate('?=owner-director-address-information');
    }
    if (step === COMPANY_SETUP_STEP?.BUSINESS_AND_EXPERIENCE_DETAILS) {
      navigate('?=business_experience_details');
    }
    if (step === COMPANY_SETUP_STEP?.OWNER_AND_DIRECTOR_LIST) {
      navigate('?=owner-director-list');
    }
    if (step === COMPANY_SETUP_STEP?.YOURSELF) {
      navigate('?=capital-and-share');
    }
    if (step === COMPANY_SETUP_STEP?.SIGN) {
      navigate('?=sign-confirm');
    }
    if (step === COMPANY_SETUP_STEP?.STEP_COMPLETE_SUCCESS) {
      navigate('?=company-book-a-call');
    }
  }, [step]);

  return (
    <>
      {companyAllInformation && (
        <div className="max-w-7xl layout-container  pt-4 mx-auto bg-customBg-secondaryBg">
          <PublicHeaderNavigation hideSignInButton={true} />
          <div className="bg-white box-shadow flex rounded-2xl flex-col justify-between gap-10 mt-10">
            {step <= COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION || step > COMPANY_SETUP_STEP?.SIGN ? (
              <InitialWrapper>{selectedCase()}</InitialWrapper>
            ) : (
              <MainWrapper activeStepNumber={activeStep} subStep={subStep}>
                {selectedCase()}
              </MainWrapper>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(CompanyIncorporation);
