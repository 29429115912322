import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CompanySecretaryTable from './CompanySecretaryTable';
import * as CompanyDetailsActions from '../reduxStore/action';
import PropTypes from 'prop-types';
import { API_CODE } from '../../../constants/enum';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import { useLocation } from '@reach/router';
import CompanySecretaryView from './CompanySecretaryView';
import { find, findIndex, forEach } from 'lodash';

function CompanySecretary({ companySecretaryDetails, companyId }) {
  const [secretaryDetails, setSecretaryDetails] = useState();
  const [isSecretary, setIsSecretary] = useState(false);
  const [isViewSecretary, setIsViewSecretary] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedFolder, setSelectedFolder] = useState(0);
  const [viewSelectedRow, setViewSelectedRow] = useState();
  const location = useLocation();

  const companySecretary = async () => {
    setIsSecretary(true);
    const companySecretaryResponse = await companySecretaryDetails();
    if (companySecretaryResponse?.status === API_CODE.STATUS_200) {
      findIndexById(companySecretaryResponse?.data?.data, location?.state?.id);
      setSecretaryDetails(companySecretaryResponse?.data?.data);
      setIsSecretary(false);
    }
  };

  const findIndexById = (secretaryArray, id) => {
    let foundObject = null;
    forEach(secretaryArray, (data) => {
      const companySecretaryDocuments = find(data?.companySecretaryDocuments, (doc) => doc._id == id);
      if (companySecretaryDocuments) {
        foundObject = data;
      }
    });
    if (foundObject) {
      const foundIndex = findIndex(secretaryArray, (secretary) => secretary._id == foundObject._id);
      setSelectedFolder(foundIndex);
    }
  };

  useEffect(() => {
    companySecretary();

    if (location?.state?.isCompanySecretaryView) {
      setIsViewSecretary(location?.state?.isCompanySecretaryView);
      setSelectedRow(location?.state?.id);
      setViewSelectedRow(location?.state?.companySecretaryData);
    } else {
      setIsViewSecretary(false);
    }
  }, [location]);
  return (
    <>
      {!isViewSecretary ? (
        <div className="mt-8">
          {isSecretary ? (
            <StatrysLoader />
          ) : (
            <CompanySecretaryTable isView={false} secretaryDetails={secretaryDetails} companyId={companyId} />
          )}
        </div>
      ) : (
        <div className="mt-8">
          <CompanySecretaryView
            isViewRowDetails={true}
            selectedFolder={selectedFolder}
            selectedRow={selectedRow}
            secretaryDetails={secretaryDetails}
            viewSelectedRow={viewSelectedRow}
            companyId={companyId}
          />
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    companySecretaryDetails: () => dispatch(CompanyDetailsActions.companySecretaryDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanySecretary);

CompanySecretary.propTypes = {
  companySecretaryDetails: PropTypes.func
};
