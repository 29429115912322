import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import Emitter from '../service/emitter';
import Success from '../images/icons/error-icon.svg';
import MSText from './ui/typography/MSText';
import XSText from './ui/typography/XSText';
import Image from './Image';
import { MUI_COLORS, EMITTER_CONSTANTS } from '../constants/enum';
function AlertComponenet(props) {
  const { icon, autoHideDuration } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    Emitter.on(EMITTER_CONSTANTS.INPUT_FROM_MAIN, (newValue) => setOpen(newValue));
    return () => Emitter.off(EMITTER_CONSTANTS.INPUT_FROM_MAIN);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {' '}
      <Snackbar
        open={open.snackbarState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={autoHideDuration}
        transitionDuration={{ enter: 1000, exit: 5000 }}
        TransitionProps={{ enter: true, exit: false }}
        sx={{ width: '100%' }}
        onClose={handleClose}
      >
        <Alert
          className="snackbar"
          onClose={handleClose}
          icon={icon ?? false}
          sx={{
            width: '364px',
            alignItems: 'center',
            borderRadius: '8px',
            bgcolor: MUI_COLORS.LIGHT_CREAM,
            border: '1px solid',
            borderColor: MUI_COLORS.SUNSET_ORANGE
          }}
        >
          <div className="flex">
            <div className="flex items-center w-1/5">
              <Image className="w-6 h-6" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={open.errorCode}></XSText>
              <MSText textColor="text-gray-500" title={open.errorMessage}></MSText>
            </div>
          </div>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertComponenet;

AlertComponenet.propTypes = {
  autoHideDuration: PropTypes.number
};

AlertComponenet.defaultProps = {
  autoHideDuration: 3000
};
