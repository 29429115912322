export const MARK_AS_PAID_REQUEST_START = 'MARK_AS_PAID_REQUEST_START';
export const MARK_AS_PAID_REQUEST_FAIL = 'MARK_AS_PAID_REQUEST_FAIL';
export const MARK_AS_PAID_REQUEST_SUCCESS = 'MARK_AS_PAID_REQUEST_SUCCESS';

export const UPDATE_MARK_AS_PAID_REQUEST_START = 'UPDATE_MARK_AS_PAID_REQUEST_START';
export const UPDATE_MARK_AS_PAID_REQUEST_FAIL = 'UPDATE_MARK_AS_PAID_REQUEST_FAIL';
export const UPDATE_MARK_AS_PAID_REQUEST_SUCCESS = 'UPDATE_MARK_AS_PAID_REQUEST_SUCCESS';

export const DELETE_MARK_AS_PAID_REQUEST_START = 'DELETE_MARK_AS_PAID_REQUEST_START';
export const DELETE_MARK_AS_PAID_REQUEST_FAIL = 'DELETE_MARK_AS_PAID_REQUEST_FAIL';
export const DELETE_MARK_AS_PAID_REQUEST_SUCCESS = 'DELETE_MARK_AS_PAID_REQUEST_SUCCESS';

export const SEND_INVOICE_EMAIL_REQUEST_START = 'SEND_INVOICE_EMAIL_REQUEST_START';
export const SEND_INVOICE_EMAIL_REQUEST_FAIL = 'SEND_INVOICE_EMAIL_REQUEST_FAIL';
export const SEND_INVOICE_EMAIL_REQUEST_SUCCESS = 'SEND_INVOICE_EMAIL_REQUEST_SUCCESS';

export const INVOICE_VALIDATE_REQUEST_START = 'INVOICE_VALIDATE_REQUEST_START';
export const INVOICE_VALIDATE_REQUEST_FAIL = 'INVOICE_VALIDATE_REQUEST_FAIL';
export const INVOICE_VALIDATE_REQUEST_SUCCESS = 'INVOICE_VALIDATE_REQUEST_SUCCESS';
