export const INVOICE_DATA_REQUEST_SUCCESS = 'INVOICE_DATA_REQUEST_SUCCESS';

export const ADD_BANK_DETAILS_REQUEST_START = 'ADD_BANK_DETAILS_REQUEST_START';
export const ADD_BANK_DETAILS_REQUEST_FAIL = 'ADD_BANK_DETAILS_REQUEST_FAIL';
export const ADD_BANK_DETAILS_REQUEST_SUCCESS = 'ADD_BANK_DETAILS_REQUEST_SUCCESS';

export const GET_BANK_DETAILS_LIST_REQUEST_START = 'GET_BANK_DETAILS_LIST_REQUEST_START';
export const GET_BANK_DETAILS_LIST_REQUEST_FAIL = 'GET_BANK_DETAILS_LIST_REQUEST_FAIL';
export const GET_BANK_DETAILS_LIST_REQUEST_SUCCESS = 'GET_BANK_DETAILS_LIST_REQUEST_SUCCESS';

export const DELETE_BANK_DETAILS_REQUEST_START = 'DELETE_BANK_DETAILS_REQUEST_START';
export const DELETE_BANK_DETAILS_REQUEST_FAIL = 'DELETE_BANK_DETAILS_REQUEST_FAIL';
export const DELETE_BANK_DETAILS_REQUEST_SUCCESS = 'DELETE_BANK_DETAILS_REQUEST_SUCCESS';

export const GET_BANK_DETAILS_REQUEST_START = 'GET_BANK_DETAILS_REQUEST_START';
export const GET_BANK_DETAILS_REQUEST_FAIL = 'GET_BANK_DETAILS_REQUEST_FAIL';
export const GET_BANK_DETAILS_REQUEST_SUCCESS = 'GET_BANK_DETAILS_REQUEST_SUCCESS';

export const EDIT_BANK_DETAILS_REQUEST_START = 'EDIT_BANK_DETAILS_REQUEST_START';
export const EDIT_BANK_DETAILS_REQUEST_FAIL = 'EDIT_BANK_DETAILS_REQUEST_FAIL';
export const EDIT_BANK_DETAILS_REQUEST_SUCCESS = 'EDIT_BANK_DETAILS_REQUEST_SUCCESS';

export const INVOICE_MARK_PAYMENT_REQUEST_START = 'INVOICE_MARK_PAYMENT_REQUEST_START';
export const INVOICE_MARK_PAYMENT_REQUEST_FAIL = 'INVOICE_MARK_PAYMENT_REQUEST_FAIL';
export const INVOICE_MARK_PAYMENT_REQUEST_SUCCESS = 'INVOICE_MARK_PAYMENT_REQUEST_SUCCESS';

export const INVOICE_DELETE_MARK_PAYMENT_REQUEST_START = 'INVOICE_DELETE_MARK_PAYMENT_REQUEST_START';
export const INVOICE_DELETE_MARK_PAYMENT_REQUEST_FAIL = 'INVOICE_DELETE_MARK_PAYMENT_REQUEST_FAIL';
export const INVOICE_DELETE_MARK_PAYMENT_REQUEST_SUCCESS = 'INVOICE_DELETE_MARK_PAYMENT_REQUEST_SUCCESS';

export const INVOICE_UPDATE_MARK_PAYMENT_REQUEST_START = 'INVOICE_UPDATE_MARK_PAYMENT_REQUEST_START';
export const INVOICE_UPDATE_MARK_PAYMENT_REQUEST_FAIL = 'INVOICE_UPDATE_MARK_PAYMENT_REQUEST_FAIL';
export const INVOICE_UPDATE_MARK_PAYMENT_REQUEST_SUCCESS = 'INVOICE_UPDATE_MARK_PAYMENT_REQUEST_SUCCESS';

export const INVOICE_UPDATE_STATUS_REQUEST_START = 'INVOICE_UPDATE_STATUS_REQUEST_START';
export const INVOICE_UPDATE_STATUS_REQUEST_FAIL = 'INVOICE_UPDATE_STATUS_REQUEST_FAIL';
export const INVOICE_UPDATE_STATUS_REQUEST_SUCCESS = 'INVOICE_UPDATE_STATUS_REQUEST_SUCCESS';

export const INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_START = 'INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_START';
export const INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_FAIL = 'INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_FAIL';
export const INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_SUCCESS = 'INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_SUCCESS';

export const INVOICE_PAYMENT_APPROVE_REQUEST_START = 'INVOICE_PAYMENT_APPROVE_REQUEST_START';
export const INVOICE_PAYMENT_APPROVE_REQUEST_FAIL = 'INVOICE_PAYMENT_APPROVE_REQUEST_FAIL';
export const INVOICE_PAYMENT_APPROVE_REQUEST_SUCCESS = 'INVOICE_PAYMENT_APPROVE_REQUEST_SUCCESS';
