import React, { useState } from 'react';
import TextInput from '../../ui/inputs/TextInput';
import ValidationMessage from '../../ui/ValidationMessage';
import IconInvoiceTrash from '../../../images/icons/Invoice/invoice-trash.svg';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Plus from '../../../images/icons/customSvg/plus';
import {
  defaultValidationMessage,
  itemsTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  tasksTotalPrice
} from '../../../helper/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from '../reduxStore/action';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import { useForm } from 'react-hook-form';

function InvoiceTaxes({ invoiceData, getInvoiceDetails, closeDynamicModal }) {
  const { register, errors, handleSubmit } = useForm();

  const [formValues, setFormValues] = useState(
    getInvoiceDetails.invoiceTaxes
      ? getInvoiceDetails.invoiceTaxes
      : [{ taxConcept: '', taxDescription: '', taxPercentage: '', taxPrice: '', itemTotal: '' }]
  );

  let handleChange = (index, event, name) => {
    const subtotal =
      itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
      tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
      newItemsTotalPrice(getInvoiceDetails?.items);
    const discountPrice = priceCalculatorThroughPercentage(
      subtotal,
      getInvoiceDetails?.invoiceDiscount?.discountPercentage
    );
    const totalAfterDiscount = discountPrice ? subtotal - discountPrice : subtotal;
    let newFormValues = [...formValues];
    newFormValues[index][name] = event.target.value;
    if (newFormValues[index]['taxPercentage']) {
      newFormValues[index]['taxPrice'] = String(
        priceCalculatorThroughPercentage(totalAfterDiscount, newFormValues[index]['taxPercentage'])
      );
    }
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { taxConcept: '', taxDescription: '', taxPercentage: '', taxPrice: '', itemTotal: '' }
    ]);
  };

  let removeFormFields = (index) => {
    let newFormValues = [...formValues];
    newFormValues.splice(index, 1);
    setFormValues(newFormValues);
  };

  const onSubmit = () => {
    invoiceData({ invoiceTaxes: formValues });
    closeDynamicModal();
  };
  return (
    <form>
      <MDSubtitleText fontWeight="text-bold" title={'Add taxes'} />
      {formValues?.map((element, index) => (
        <div className="flex gap-4 mt-6" key={index}>
          <TextInput
            textFieldContainerClass="w-full"
            defaultValue={getInvoiceDetails?.invoiceTaxes && getInvoiceDetails?.invoiceTaxes[index]?.taxConcept}
            name={`taxConcept${index}`}
            label="Concept"
            onChange={(event) => handleChange(index, event, 'taxConcept')}
            inputRef={register({
              required: true
            })}
            error={errors?.[`taxConcept${index}`] ? true : false}
            helperText={
              errors?.[`taxConcept${index}`] && <ValidationMessage title={defaultValidationMessage('Title')} />
            }
          />
          <TextInput
            textFieldContainerClass="md:w-1/3"
            name={`taxPercentage${index}`}
            label="Percentage"
            type="number"
            value={element?.taxPercentage || ''}
            onChange={(e) => handleChange(index, e, 'taxPercentage')}
            inputRef={register({
              required: false
            })}
          />
          <img
            onClick={() => removeFormFields(index)}
            src={IconInvoiceTrash}
            alt="IconInvoiceTrash"
            className="cursor-pointer w-4"
          />
        </div>
      ))}
      <PrimaryButton
        id="invoice-taxes-form-add-btn"
        className="invoice-button mt-6"
        bgColor="bg-coral-500"
        textColor="text-white"
        onClick={() => addFormFields()}
        caption={
          <div className="flex gap-2 text-medium items-center">
            <Plus />
            Add Item
          </div>
        }
      />
      <div className="flex gap-4 mt-8">
        <PrimaryButton
          id="invoice-taxes-form-save-btn"
          className="invoice-primary-button"
          caption="Save & Continue"
          onClick={handleSubmit(onSubmit)}
        />
        <PrimaryButton
          id="invoice-taxes-form-cancel-btn"
          className="invoice-white-primary-button border border-gray-400"
          bgColor="bg-white"
          color="text-gray-300"
          caption="Cancel"
          onClick={closeDynamicModal}
        />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTaxes);

InvoiceTaxes.propTypes = {
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  closeDynamicModal: PropTypes.func
};
