import React from 'react';
import PropTypes from 'prop-types';

function MDSubtitleText({ title, className, textColor, textAlign, fontWeight, style }) {
  return (
    <div className={`${className}`}>
      <p
        className={`md-subtitle-text ${fontWeight} text-2xl ${textColor} ${textAlign} letter-spacing-p leading-6`}
        style={style}
      >
        {title}
      </p>
    </div>
  );
}

export default MDSubtitleText;

MDSubtitleText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  style: PropTypes.any
};

MDSubtitleText.defaultProps = {
  textColor: 'text-gray-300',
  textAlign: 'text-left',
  fontWeight: 'text-light'
};
