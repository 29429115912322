import React, { useEffect, useState } from 'react';
import PublicCompanyDocument from '../components/company/common/PublicCompanyDocument';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import { useLocation } from '@reach/router';
import * as CompanyDetailsActions from '../../src/components/company/reduxStore/action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_CODE } from '../constants/enum';

function PublicCompanyDocs({ companySecretaryShare, companyMailroomShare }) {
  const [shareDocumentDetails, setShareDocumentDetails] = useState();
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const id = params.get('id');
  const mailroomDocId = params.get('mailroomDocId');
  const secretaryDocId = params.get('secretaryDocId');

  const shareCompanySecretaryDocument = async () => {
    const payload = {
      companyId: id,
      mailId: secretaryDocId
    };
    const shareCompanySecretaryResponse = await companySecretaryShare(payload);
    if (shareCompanySecretaryResponse?.status === API_CODE.STATUS_200) {
      setShareDocumentDetails(shareCompanySecretaryResponse?.data?.data[0]?.companySecretaryDocuments);
    }
  };
  const mailroomDocuments = async () => {
    const payload = {
      companyId: id,
      mailId: mailroomDocId
    };
    const mailroomDetailsResponse = await companyMailroomShare(payload);
    if (mailroomDetailsResponse?.status === API_CODE.STATUS_200) {
      setShareDocumentDetails(mailroomDetailsResponse?.data?.data[0]?.mailRoomDocuments);
    }
  };

  useEffect(() => {
    if (mailroomDocId) {
      mailroomDocuments();
    }
    if (secretaryDocId) {
      shareCompanySecretaryDocument();
    }
  }, []);
  return (
    <div className="max-w-7xl layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
      <PublicHeaderNavigation />
      {!shareDocumentDetails ? (
        <div className="flex justify-center items-center">
          <StatrysLoader />
        </div>
      ) : (
        <div className="mt-11">
          <PublicCompanyDocument shareDetails={shareDocumentDetails} />
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    companySecretaryShare: (payload) => dispatch(CompanyDetailsActions.companySecretaryShare(payload)),
    companyMailroomShare: (payload) => dispatch(CompanyDetailsActions.companyMailroomShare(payload))
  };
};

export default connect(null, mapDispatchToProps)(PublicCompanyDocs);

PublicCompanyDocs.propTypes = {
  companyMailroomShare: PropTypes.func,
  companySecretaryShare: PropTypes.func
};
