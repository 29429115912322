import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TextInput from '../ui/inputs/TextInput';
import XSText from '../ui/typography/XSText';
import { connect } from 'react-redux';
import * as paymentActions from './reduxStore/action';
import * as invoiceActions from '../invoice/reduxStore/action';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { defaultValidationMessage } from '../../helper/helpers';
import ValidationMessage from '../ui/ValidationMessage';

function InvoiceBankDetails({
  addBankDetails,
  getBankDetailsList,
  getBankDetails,
  editBankDetails,
  handleCancelbutton,
  isEdit,
  bankId,
  isTemporary,
  invoiceData,
  getInvoiceDetails
}) {
  const [selectedCountry, setSelectedCountry] = useState();
  const [bankPaymentDetails, setBankPaymentDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSavedLoading, setIsSavedLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      alias: bankPaymentDetails?.alias,
      bankCountry: bankPaymentDetails?.bankCountry,
      fullName: bankPaymentDetails?.fullName,
      bankName: bankPaymentDetails?.bankName,
      bankAddress: bankPaymentDetails?.bankAddress,
      accountNumberOrIban: bankPaymentDetails?.accountNumberOrIban,
      swiftCode: bankPaymentDetails?.swiftCode,
      bankCode: bankPaymentDetails?.bankCode,
      branchCode: bankPaymentDetails?.branchCode,
      routingCode: bankPaymentDetails?.routingCode
    }
  });

  const onSelectingCountry = (country) => {
    setSelectedCountry(country.name);
  };

  const handleSaveNewPaymentDetail = async (data) => {
    if (!isTemporary) {
      setIsSavedLoading(true);
      if (isEdit) {
        await editBankDetails({ ...data, bankCountry: selectedCountry, bankId: bankPaymentDetails?._id });
      } else {
        await addBankDetails({ ...data, bankCountry: selectedCountry });
      }
      handleCancelbutton();
      setIsSavedLoading(false);
    } else {
      setIsSavedLoading(true);
      let count = Math.floor(Math.random() * 10000);
      if (isEdit) {
        const afterEditedData = getInvoiceDetails?.paymentDetails?.bankDetails?.map((bankDetails) => {
          if (bankDetails?._id == bankId) {
            return {
              ...data,
              bankCountry: selectedCountry ? selectedCountry : bankDetails?.bankCountry,
              _id: bankDetails?._id
            };
          }
          return getInvoiceDetails?.paymentDetails?.bankDetails && bankDetails;
        });
        invoiceData({
          paymentDetails: {
            bankDetails: afterEditedData
          }
        });
      } else {
        invoiceData({
          paymentDetails: {
            bankDetails: getInvoiceDetails?.paymentDetails?.bankDetails
              ? [
                  ...getInvoiceDetails?.paymentDetails?.bankDetails,
                  { ...data, bankCountry: selectedCountry, isSelected: false, _id: count }
                ]
              : [{ ...data, bankCountry: selectedCountry, isSelected: false, _id: count }]
          }
        });
      }

      handleCancelbutton();
      setIsSavedLoading(false);
    }
  };
  useEffect(() => {
    if (!isTemporary) {
      !isEdit && bankDetailsList();
      isEdit && onEditBankDetails();
    } else {
      const editedInfo = getInvoiceDetails?.paymentDetails?.bankDetails?.find(
        (bankDetails) => bankDetails?._id == bankId
      );
      isEdit && setBankPaymentDetails(editedInfo);
      setIsLoading(false);
    }
  }, []);
  const bankDetailsList = async () => {
    const bankDetailsList = await getBankDetailsList();
    setBankPaymentDetails(bankDetailsList?.data?.data);
    setIsLoading(false);
  };
  const onEditBankDetails = async () => {
    const bankDetail = await getBankDetails(bankId);
    setBankPaymentDetails(bankDetail?.data?.data);
    setIsLoading(false);
  };
  return (
    <>
      {!isLoading ? (
        <div className="px-4 rounded-lg">
          <XSText fontSize="text-xl" textColor="text-gray-450" title="Add an alias to identify these account details" />
          <div className="mt-4">
            <form id="bank-details-form" onSubmit={handleSubmit(handleSaveNewPaymentDetail)}>
              <div className="md:gap-4 w-full">
                <TextInput
                  defaultValue={bankPaymentDetails?.alias}
                  margin="w-full"
                  name="alias"
                  label="Alias"
                  inputRef={register({
                    required: true
                  })}
                  error={errors.alias}
                  helperText={errors?.alias && <ValidationMessage title={defaultValidationMessage('alias')} />}
                />
                <XSText
                  fontSize="text-xl"
                  className="mt-4"
                  textColor="text-gray-450"
                  title="Country and bank information"
                />
                <div className="mt-4 w-full">
                  <SelectCountryAutoComplete
                    defaultValue={bankPaymentDetails?.bankCountry}
                    setSelectedValue={onSelectingCountry}
                    bgColor="bg-white"
                    className="bg-white"
                    label="Bank Country"
                    name="bankCountry"
                  />
                </div>

                <TextInput
                  defaultValue={bankPaymentDetails?.fullName}
                  margin="mt-4 w-full"
                  name="fullName"
                  label="Full Name"
                  inputRef={register({
                    required: false
                  })}
                />
                <TextInput
                  defaultValue={bankPaymentDetails?.bankName}
                  margin="mt-4 w-full"
                  name="bankName"
                  label="Bank Name (Optional)"
                  inputRef={register({
                    required: false
                  })}
                />
                <TextInput
                  defaultValue={bankPaymentDetails?.bankAddress}
                  margin="mt-4 w-full"
                  name="bankAddress"
                  label="Bank Address (Optional)"
                  inputRef={register({
                    required: false
                  })}
                />
                <XSText fontSize="text-xl" className="mt-4" textColor="text-gray-450" title="Account details" />
                <div className="flex md:flex-row flex-col md:gap-4 w-full">
                  <TextInput
                    defaultValue={bankPaymentDetails?.accountNumberOrIban}
                    margin="mt-4 w-full"
                    name="accountNumberOrIban"
                    label="Account Number or IBAN"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextInput
                    defaultValue={bankPaymentDetails?.swiftCode}
                    margin="mt-4 w-full"
                    name="swiftCode"
                    label="SWIFT/BIC"
                    inputRef={register({
                      required: false
                    })}
                  />
                </div>
                <div className="flex md:flex-row flex-col md:gap-4 w-full">
                  <TextInput
                    defaultValue={bankPaymentDetails?.bankCode}
                    margin="mt-4 w-full"
                    name="bankCode"
                    label="Bank Code (Optional)"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextInput
                    defaultValue={bankPaymentDetails?.branchCode}
                    margin="mt-4 w-full"
                    name="branchCode"
                    label="Branch Code (Optional)"
                    inputRef={register({
                      required: false
                    })}
                  />
                </div>
                <div className="mb-4 profile-cureentpass-input">
                  <TextInput
                    defaultValue={bankPaymentDetails?.routingCode}
                    margin="mt-4"
                    name="routingCode"
                    label="Routing Code (Optional)"
                    inputRef={register({
                      required: false
                    })}
                  />
                </div>
              </div>
            </form>
          </div>
          <div>
            <PrimaryButton
              id="invoice-bank-save-btn"
              className="modal-save-button mt-4 mr-4"
              caption="Save new payment details"
              fontWeight="text-bold"
              isLoading={isSavedLoading}
              onClick={handleSubmit(handleSaveNewPaymentDetail)}
              type="small"
            />
            <PrimaryButton
              id="invoice-bank-cancel-btn"
              onClick={handleCancelbutton}
              bgColor="bg-white"
              className=" modal-cancel-button mt-4"
              color="text-gray-300"
              caption="Cancel"
              fontWeight="text-bold"
              type="small"
            />
          </div>
        </div>
      ) : (
        <StatrysLoader />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBankDetailsList: () => dispatch(paymentActions.getBankDetailsList()),
    getBankDetails: (bankId) => dispatch(paymentActions.getBankDetails(bankId)),
    addBankDetails: (bankDetails) => dispatch(paymentActions.addBankDetails(bankDetails)),
    editBankDetails: (bankDetails) => dispatch(paymentActions.editBankDetails(bankDetails)),
    invoiceData: (invoiceData) => dispatch(invoiceActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBankDetails);
