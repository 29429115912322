import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import TextInput from '../../ui/inputs/TextInput';
import GreenStudyLightIcon from '../../../images/icons/bulb.svg';
import IndustriesList from '../../../data/industries-list.json';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import ValidationMessage from '../../ui/ValidationMessage';
import { Controller } from 'react-hook-form';
import CountriesList from '../../../data/countries.json';
export default function CompanyAddress({ register, errors, control, clearErrors, setValue, companyDetailsData }) {
  const setIndustryy = (value) => {
    setValue('country', value?.name);
    value && clearErrors('country');
  };
  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText
        className="md:whitespace-pre-line my-6"
        fontWeight="text-bold"
        title={`Fill in your company details`}
      />
      <XSText title="Enter the business address and country" />
      <TextInput
        name="address"
        inputRef={register({
          required: true
        })}
        defaultValue={companyDetailsData?.address}
        error={errors?.address}
        helperText={errors?.address && <ValidationMessage title="Please select a valid Address" />}
        className="mt-2 w-full"
        label="Address"
      />
      <TextInput
        name="city"
        inputRef={register({
          required: true
        })}
        defaultValue={companyDetailsData?.city}
        error={errors?.city}
        helperText={errors?.city && <ValidationMessage title="Please select a valid City" />}
        className="mt-2 w-full"
        label="City"
      />
      <div className="flex gap-4 mb-2">
        <TextInput
          name="state"
          inputRef={register({
            required: false
          })}
          defaultValue={companyDetailsData?.state}
          className="mt-2 w-full"
          label="State or province"
        />
        <TextInput
          inputRef={register({
            required: false
          })}
          defaultValue={companyDetailsData?.postalCode}
          name="postalCode"
          className="mt-2 w-full"
          label="Zip / Postal code"
        />
      </div>
      <Controller
        name="country"
        control={control}
        rules={{ required: true }}
        defaultValue={companyDetailsData?.country}
        render={({ field }) => (
          <SelectInputAutoComplete
            {...field}
            label="Country"
            name="country"
            defaultValue={companyDetailsData?.country}
            options={CountriesList}
            setSelectedValue={setIndustryy}
            error={errors?.country}
            inputRef={register({
              required: false
            })}
            helperText={errors?.country && <ValidationMessage title="Please select a valid Country" />}
          />
        )}
      />
      <div className="flex gap-4 bg-green-200 p-4 items-start rounded-lg mt-2">
        <img src={GreenStudyLightIcon} alt="GreenStudyLightIcon" />
        <XSText title="The address will be shown on the invoice along with all other legal information about your company." />
      </div>
    </div>
  );
}
