import React from 'react';
import AlertTriangle from '../../images/icons/alert-triangle.svg';
import MSText from './typography/MSText';
import PropTypes from 'prop-types';

export default function ValidationMessage({ title, classNameForText }) {
  return (
    <div className="flex gap-2 mt-2 items-center">
      <img src={AlertTriangle} alt="alert triangle" />
      <MSText textColor="text-red-alert md:whitespace-pre-line" className={classNameForText} title={title} />
    </div>
  );
}

ValidationMessage.propTypes = {
  title: PropTypes.string,
  classNameForText: PropTypes.string
};
