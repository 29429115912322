import React from 'react';
import MSText from '../ui/typography/MSText';
import XXSText from '../ui/typography/XXSText';
import { isIbanNumber } from '../../helper/helpers';
export default function BankDetails({ paymentDetails }) {
  return (
    <>
      {paymentDetails?.bankDetails?.map((bankData, index) => (
        <div
          className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}
          key={index + 1}
        >
          <div>
            <div className="flex justify-between items-center mb-3">
              <XXSText fontWeight="text-bold" title={bankData?.alias} />
            </div>
            <div className="flex flex-col">
              <div>
                {bankData?.bankCountry && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank country:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankCountry} />
                  </div>
                )}
                {bankData?.bankAddress && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank address:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankAddress} />
                  </div>
                )}
                {bankData?.fullName && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Full Name:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.fullName} />
                  </div>
                )}
                {bankData?.bankName && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Bank name:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.bankName} />
                  </div>
                )}
                {bankData?.accountNumberOrIban && (
                  <div className="flex">
                    <MSText
                      className="mb-2 bank-details-column whitespace-pre-line"
                      textColor="text-gray-700"
                      title={isIbanNumber(bankData?.accountNumberOrIban)}
                    />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.accountNumberOrIban} />
                  </div>
                )}
                {bankData?.swiftCode && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="SWIFT/BIC code:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.swiftCode} />
                  </div>
                )}
                {bankData?.routingCode && (
                  <div className="flex">
                    <MSText className="mb-2 bank-details-column" textColor="text-gray-700" title="Routing Code:" />
                    <MSText className="mb-2" textColor="text-gray-300" title={bankData?.routingCode} />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2">
                {bankData?.bankCode && (
                  <div className="flex mr-8">
                    <MSText className="bank-details-column" textColor="text-gray-700" title="Bank code:" />
                    <MSText textColor="text-gray-300" title={bankData?.bankCode} />
                  </div>
                )}
                {bankData?.branchCode && (
                  <div className="flex">
                    <MSText className="bank-details-column" textColor="text-gray-700" title="Branch code:" />
                    <MSText textColor="text-gray-300" title={bankData?.branchCode} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
