import React from 'react';
import DynamicModalScroll from '../ui/modal/DynamicModalInsideScroll';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import termAndCondition from '../../data/terms-and-condition.json';
import termAndConditionDiscription from '../../data/terms-and-condition-description.json';
import BaseText from '../ui/typography/BaseText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function TermAndConditionModal({ openModal, closeDynamicModal }) {
  return (
    <DynamicModalScroll
      openDynamicModal={openModal}
      closeDynamicModal={closeDynamicModal}
      scrollType="paper"
      minWidth="520px"
      action={
        <>
          <div className="mb-3">
            <PrimaryButton
              id="term-and-conditions-close-btn"
              className="modal-cancel-button font-weight-bold border border-gray-400"
              bgColor="bg-coral-500"
              caption="Close"
              onClick={closeDynamicModal}
            />
          </div>
        </>
      }
      title={
        <>
          <MDSubtitleText
            className="mb-4"
            title={
              <span className="flex justify-between items-center">
                <span>Terms and Conditions</span>
                <span>
                  <IconButton onClick={closeDynamicModal}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            }
            fontWeight="text-bold"
          />
        </>
      }
      children={
        <>
          <div className="w-full max-w-7xl">
            <section>
              <div
                className="p-text whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: termAndConditionDiscription.description }}
              ></div>
              {termAndCondition.map((item, idx) => (
                <div key={'_' + idx} className="mt-2" id={item.link}>
                  <div key={'_' + idx}>
                    <BaseText className="mt-4 capitalize" fontWeight="text-bold" title={`${idx + 1}. ${item.title} `} />
                  </div>
                  {item?.description !== '' && (
                    <p className="p-text text-left mt-4" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  )}
                  <div className="mt-2">
                    {item.list.map((k, idxs) => (
                      <div
                        key={'_' + idxs}
                        className={`${
                          k?.title ? 'items-start justify-start' : 'flex flex-row items-start justify-start'
                        } `}
                      >
                        {k?.title ? (
                          <>
                            <BaseText
                              className="mr-4 mt-4 capitalize"
                              fontWeight="text-bold"
                              title={`${idx + 1}.${idxs + 1}   ${k.title}`}
                            />
                            <div
                              className=" whitespace-pre-line  p-text text-left mt-4"
                              dangerouslySetInnerHTML={{ __html: k.text.trim() }}
                            ></div>
                          </>
                        ) : (
                          <>
                            {k.text ? <div className="mr-4 font-bold">{`${idx + 1}.${idxs + 1}`}</div> : null}
                            <div
                              className="p-text text-left"
                              dangerouslySetInnerHTML={{ __html: k?.text?.trim() }}
                            ></div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </>
      }
    />
  );
}
