import React, { useState } from 'react';
import TextInput from '../ui/inputs/TextInput';
import ValidationMessage from '../ui/ValidationMessage';
import IconInvoiceTrash from '../../images/icons/Invoice/invoice-trash.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import Plus from '../../images/icons/customSvg/plus';
import { invoiceInputBackgroundColor, defaultValidationMessage, multiplyNumbers } from '../../helper/helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import XXSText from '../ui/typography/XXSText';

function Invoicetasks({ invoiceData, register, errors, selectedCurrency, getInvoiceDetails, isFinalize }) {
  const [formValues, setFormValues] = useState(
    getInvoiceDetails?.invoiceTasks
      ? getInvoiceDetails?.invoiceTasks
      : [{ taskTitle: '', taskDescription: '', taskRate: '', taskHours: '', taskTotal: '' }]
  );

  let handleChange = (index, event, name) => {
    let newFormValues = [...formValues];
    newFormValues[index][name] = event.target.value;
    if (formValues[index]['taskRate'] && formValues[index]['taskHours']) {
      newFormValues[index]['taskTotal'] = String(
        multiplyNumbers([formValues[index]['taskRate'], formValues[index]['taskHours']])
      );
    } else {
      newFormValues[index]['taskTotal'] = '';
    }
    setFormValues(newFormValues);
    invoiceData({ invoiceTasks: newFormValues });
  };

  let addFormFields = () => {
    setFormValues([...formValues, { taskTitle: '', taskDescription: '', taskRate: '', taskHours: '', taskTotal: '' }]);
  };

  let removeFormFields = (index) => {
    let newFormValues = [...formValues];
    newFormValues.splice(index, 1);
    setFormValues(newFormValues);
    invoiceData({ invoiceTasks: newFormValues });
  };

  return (
    <div>
      <XXSText className="my-6" textColor="text-gray-600" title="Description of services" />
      {formValues.map((element, index) => (
        <div className="flex md:flex-row flex-col gap-4 mt-3 invoice-right-padding" key={index}>
          <TextInput
            textFieldContainerClass="invoice-items-and-task-title"
            name={`taskTitle${index}`}
            label="Item"
            variant="filled"
            defaultValue={element?.taskTitle || ''}
            onChange={(event) => handleChange(index, event, 'taskTitle')}
            backgroundColor={invoiceInputBackgroundColor(element?.taskTitle)}
            inputRef={register({
              required: isFinalize
            })}
            error={errors?.[`taskTitle${index}`] ? true : false}
            helperText={
              errors?.[`taskTitle${index}`] && <ValidationMessage title={defaultValidationMessage('Service details')} />
            }
          />

          <TextInput
            textFieldContainerClass="invoice-items-and-task-description"
            name={`taskDescription${index}`}
            label="Description"
            variant="filled"
            value={element?.taskDescription || ''}
            onChange={(e) => handleChange(index, e, 'taskDescription')}
            backgroundColor={invoiceInputBackgroundColor(element?.taskDescription)}
            inputRef={register({
              required: false
            })}
          />
          <div className="flex flex-col md:flex-row gap-4">
            <TextInput
              textFieldContainerClass="invoice-items-price-and-task-rate"
              name={`taskRate${index}`}
              label="Rate"
              variant="filled"
              type="number"
              value={element?.taskRate || ''}
              startAdornment={
                element?.taskRate
                  ? selectedCurrency?.symbol
                    ? selectedCurrency?.symbol
                    : selectedCurrency?.name
                  : null
              }
              onChange={(e) => handleChange(index, e, 'taskRate')}
              backgroundColor={invoiceInputBackgroundColor(element?.taskRate)}
              inputRef={register({
                required: isFinalize
              })}
              error={errors?.[`taskRate${index}`] ? true : false}
            />
            <TextInput
              textFieldContainerClass="invoice-items-quantity-and-task-hour"
              name={`taskHours${index}`}
              label="Hours"
              variant="filled"
              type="number"
              value={element?.taskHours || ''}
              onChange={(e) => handleChange(index, e, 'taskHours')}
              backgroundColor={invoiceInputBackgroundColor(element?.taskHours)}
              inputRef={register({
                required: isFinalize
              })}
              error={errors?.[`taskHours${index}`] ? true : false}
            />
            <div className="flex self-start w-full md:w-auto items-center">
              <TextInput
                textFieldContainerClass="invoice-items-and-task-total"
                name={`taskTotal${index}`}
                label="Total"
                variant="filled"
                value={formValues ? formValues[index].taskTotal : ''}
                startAdornment={
                  multiplyNumbers([element?.taskRate, element?.taskHours])
                    ? selectedCurrency?.symbol
                      ? selectedCurrency?.symbol
                      : selectedCurrency?.name
                    : null
                }
                backgroundColor={invoiceInputBackgroundColor(multiplyNumbers([element?.taskRate, element?.taskHours]))}
                inputRef={register({
                  required: false
                })}
              />
              <img
                onClick={() => removeFormFields(index)}
                src={IconInvoiceTrash}
                alt="IconInvoiceTrash"
                className="cursor-pointer -mr-10 pl-2 md:pl-5"
              />
            </div>
          </div>
        </div>
      ))}
      <PrimaryButton
        id="invoice-task-add-btn"
        className="invoice-button mt-4"
        bgColor="bg-coral-500"
        textColor="text-white"
        onClick={() => addFormFields()}
        caption={
          <div className="flex gap-2 text-medium items-center">
            <Plus />
            Add service
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoicetasks);

Invoicetasks.propTypes = {
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  isFinalize: PropTypes.bool,
  register: PropTypes.any,
  errors: PropTypes.any,
  selectedCurrency: PropTypes.string
};
