import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import TextInput from '../../ui/inputs/TextInput';
import {
  defaultValidationMessage,
  itemsTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  tasksTotalPrice
} from '../../../helper/helpers';
import ValidationMessage from '../../ui/ValidationMessage';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as userActions from '../reduxStore/action';
import PropTypes from 'prop-types';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import IconInvoiceTrash from '../../../images/icons/Invoice/invoice-trash.svg';

function InvoiceDiscountForm({ getInvoiceDetails, invoiceData, closeDynamicModal }) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const subtotal =
      itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
      tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
      newItemsTotalPrice(getInvoiceDetails?.items);
    const discountPrice = priceCalculatorThroughPercentage(subtotal, data?.percentage);
    invoiceData({
      invoiceDiscount: {
        discountName: data?.discountName,
        discountDescription: data?.discountDescription,
        discountPercentage: data?.percentage,
        discountPrice: String(discountPrice)
      }
    });
    closeDynamicModal();
  };
  const removeDiscount = () => {
    invoiceData({
      invoiceDiscount: {
        discountName: '',
        discountDescription: '',
        discountPercentage: '',
        discountPrice: ''
      }
    });
    closeDynamicModal();
  };
  return (
    <form id="invoice-discount-form" onSubmit={handleSubmit(onSubmit)}>
      <MDSubtitleText fontWeight="text-bold" title={'Add discount'} />
      <div className="flex gap-4 mt-6">
        <TextInput
          defaultValue={getInvoiceDetails?.invoiceDiscount?.discountName}
          textFieldContainerClass="w-full"
          name="discountName"
          label="Discount Name"
          variant="filled"
          inputRef={register({
            required: true
          })}
          error={errors.discountName ? true : false}
          helperText={errors?.discountName && <ValidationMessage title={defaultValidationMessage('Discount name')} />}
        />
        <TextInput
          defaultValue={getInvoiceDetails?.invoiceDiscount?.discountPercentage}
          textFieldContainerClass="md:w-1/3"
          name="percentage"
          type="number"
          label="Percentage"
          variant="filled"
          inputRef={register({
            required: true
          })}
          error={errors.percentage ? true : false}
          helperText={errors?.percentage && <ValidationMessage title={defaultValidationMessage('Discount name')} />}
        />
        <img
          onClick={() => removeDiscount()}
          src={IconInvoiceTrash}
          alt="IconInvoiceTrash"
          className="cursor-pointer w-4"
        />
      </div>
      <div className="flex gap-4 mt-8">
        <PrimaryButton
          id="invoice-discount-form-save-btn"
          className="invoice-primary-button"
          caption="Save & Continue"
          onClick={handleSubmit(onSubmit)}
        />
        <PrimaryButton
          id="invoice-discount-cancel-btn"
          className="invoice-white-primary-button border border-gray-400"
          bgColor="bg-white"
          color="text-gray-300"
          caption="Cancel"
          onClick={closeDynamicModal}
        />
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDiscountForm);

InvoiceDiscountForm.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  invoiceDiscount: PropTypes.func,
  selectedCurrency: PropTypes.object,
  closeDynamicModal: PropTypes.any
};
