import React, { useEffect, useState } from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import CoralEditIcon from '../../images/icons/coral-edit-icon.svg';
import MSText from '../ui/typography/MSText';
import H4HeaderText from '../ui/typography/H4HeaderText';
import XSText from '../ui/typography/XSText';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewContactModal from '../contact/NewContactModal';
import Avatar from '../ui/Avatar';
import { API_CODE, CONTACT_TABLE_TITLE, MUI_COLORS } from '../../constants/enum';
import * as contactActions from '../../components/contact/reduxStore/action';
import * as supplierActions from '../../components/supplier/reduxStore/action';
import { useLocation } from '@reach/router';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import Image from '../Image';
import Snackbar from '../Snackbar';
import Success from '../../images/icons/success-icon.svg';
function ViewContact({ viewClient, getSupplierDetails }) {
  const [contactModal, setContactModal] = useState(false);
  const [error, setError] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [contact, setContactData] = useState('');
  const [contactType, setContactType] = useState('');
  const [open, setOpen] = useState(false);
  const [editedSuccessMessage, setEditedSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (editedResponse) => {
    const editDetails = editedResponse.data;
    setOpen(true);
    setEditedSuccessMessage({
      ...editedSuccessMessage,
      title: editedResponse.message,
      content: `Invoice ${
        editDetails.companyName ? editDetails.companyName : editDetails.firstName
      } was sucessfully saved!`
    });
  };

  const closeDynamicModal = () => {
    setContactModal(false);
  };
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const paramId = params.get('id');

  const openEditContactModal = async () => {
    // invoiceData({
    //   toFirstName: contact?.firstName,
    //   toLastName: contact?.lastName,
    //   toEmail: contact?.email,
    //   toCompanyName: contact?.companyName,
    //   toRegistrationNumber: contact?.registrationNumber,
    //   toWebsite: contact?.website,
    //   toAddress: contact?.address,
    //   toTownCity: contact?.city,
    //   toState: contact?.state,
    //   toPostalCode: contact?.postalCode,
    //   toCountry: contact?.country
    // });
    setIsEdit(true);
    setContactModal(true);
  };

  const getClientById = async (id) => {
    const requestedData = {
      contactId: id
    };
    const viewClientResponse = await viewClient({ params: { ...requestedData } });
    if (viewClientResponse?.status === API_CODE.STATUS_200) {
      setContactData(viewClientResponse?.data?.data);
    } else {
      setError(true);
    }
  };

  const getSupplierById = async (id) => {
    const supplierResponse = await getSupplierDetails(id);
    if (supplierResponse?.status === API_CODE.STATUS_200) {
      setContactData(supplierResponse?.data?.data);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (location?.state?.contactType) {
      setContactType(location?.state?.contactType);
      if (location?.state?.contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
        getClientById(paramId);
      }
      if (location?.state?.contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
        getSupplierById(paramId);
      }
    }
  }, []);

  return (
    <div className="view-contact-container bg-white md:mr-6 px-6 pt-6 pb-10 mb-6">
      {!contact ? (
        <div className="flex items-center justify-center h-96">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-6">
            <div>
              {contact && (
                <Avatar
                  avatarStyling="w-20 h-20"
                  fontSize="text-3xl"
                  avatarImage={contact?.profileImage}
                  avatarName={contact?.firstName ? contact?.firstName?.charAt(0) : contact?.companyName?.charAt(0)}
                />
              )}
            </div>
            <div>
              <PrimaryButton
                id="view-contact-edit-btn"
                className="view-contact-button"
                bgColor="bg-customBg-mainBg"
                color="text-coral-500"
                isLoading={contactModal}
                onClick={() => openEditContactModal()}
                caption={
                  <div className="flex gap-1 text-bold items-center">
                    <img src={CoralEditIcon} alt="IconBaseEdit" />
                    Edit
                  </div>
                }
              />
            </div>
          </div>
          <div className="bg-customBg-mainBg batch-container rounded-lg">
            <XSText title={contact?.contactType} fontWeight="text-bold" textColor="text-gray-500" className="p-2" />
          </div>
          <div>
            <div>
              <MSText className="mb-2 mt-6" title="Company information" />
              <H4HeaderText className="mb-2" title={contact?.companyName} />
              <XSText className="mb-2" title={contact?.registrationNumber} />
              <XSText className="mb-2" title={contact?.address} />
              <XSText className="mb-2" title={contact?.city} />
              <XSText className="mb-2" title={`${contact?.state} ${contact?.postalCode}`} />
              <XSText className="mb-2" title={contact?.country} />
              <XSText title={contact?.website} />
              {(contact?.firstName || contact?.email) && <MSText className="mb-2 mt-6" title="Contact information" />}
              <XSText
                fontWeight="text-bold"
                className="mb-2 capitalize"
                title={
                  <span className="capitalize">
                    {contact?.firstName} {contact?.lastName}
                  </span>
                }
              />
              <XSText className="underline view-contact-email-text" textColor="text-coral-500" title={contact?.email} />
            </div>
            <div></div>
          </div>
        </>
      )}

      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={editedSuccessMessage.title}></XSText>
              <MSText textColor="text-gray-500" title={editedSuccessMessage.content}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      {contact && (
        <NewContactModal
          openDynamicModal={contactModal}
          closeDynamicModal={closeDynamicModal}
          getContactById={getClientById}
          getSupplierById={getSupplierById}
          contactData={contact}
          contactType={contactType}
          isEdit={isEdit}
          handleOpen={handleOpen}
        />
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    viewClient: (contactData) => dispatch(contactActions.viewContact(contactData)),
    getSupplierDetails: (supplierId) => dispatch(supplierActions.getSupplierDetails(supplierId))
  };
};

export default connect(null, mapDispatchToProps)(ViewContact);

ViewContact.propTypes = {
  viewClient: PropTypes.func,
  getSupplierDetails: PropTypes.func
};
