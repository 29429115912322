import React from "react"

const PieLineGraph = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1355_56132)">
        <path
          d="M10.8867 15.5H6.88672L7.38672 12.5H10.3867L10.8867 15.5Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.38672 15.5H12.3867"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3867 0.5H3.38672C2.28215 0.5 1.38672 1.39543 1.38672 2.5V10.5C1.38672 11.6046 2.28215 12.5 3.38672 12.5H14.3867C15.4913 12.5 16.3867 11.6046 16.3867 10.5V2.5C16.3867 1.39543 15.4913 0.5 14.3867 0.5Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.38672 7.16648L4.05339 4.49982C4.14714 4.40618 4.27422 4.35359 4.40672 4.35359C4.53922 4.35359 4.6663 4.40618 4.76005 4.49982L6.03339 5.81315C6.12714 5.90679 6.25422 5.95938 6.38672 5.95938C6.51922 5.95938 6.6463 5.90679 6.74005 5.81315L9.40672 3.14648"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.38672 9.8335H16.3867"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3867 5.8335V7.66683"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3867 5.8335V7.66683"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3867 4.3335V7.66683"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1355_56132">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.886719)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PieLineGraph
