import React, { useState } from 'react';
import BaseText from '../../ui/typography/BaseText';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import MSText from '../../ui/typography/MSText';
import { convertDateToDDMMYYYY, downloadImage, getFileExtension } from '../../../helper/helpers';
import { FILE_EXTENTION_TYPE } from '../../../constants/enum';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Iconify from '../../Iconify';

function PublicCompanyDocument({ shareDetails }) {
  const [numPages, setNumPages] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <div className="flex md:flex-row flex-col items-start justify-between w-full gap-6">
        <div className="public-company-img-container">
          <div className="w-full">
            {getFileExtension(shareDetails?.documentUrl) === FILE_EXTENTION_TYPE.PNG ||
            getFileExtension(shareDetails?.documentUrl) === FILE_EXTENTION_TYPE.JPEG ||
            getFileExtension(shareDetails?.documentUrl) === FILE_EXTENTION_TYPE.JPG ? (
              <>
                <div className="w-full box-shadow">
                  <img className="w-full rounded-2xl" src={shareDetails?.documentUrl} alt="Uploaded Image" />
                </div>
              </>
            ) : (
              <>
                <Document file={shareDetails?.documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <div className="company-pdf-container">
                    {Array.from(new Array(numPages), (el, index) => (
                      <>
                        <div className="flex justify-end relative">
                          <MSText
                            className="px-2 rounded-md bg-purple-500 line-height-22 z-10 company-page-badge"
                            textColor="text-white"
                            title={`Page ${index + 1} of ${numPages}`}
                          />
                        </div>
                        <div className="mb-6 public-company-mailroom-pdf-rendering">
                          <Page scale={1} key={`page_${index + 1}`} pageNumber={index + 1} />
                        </div>
                      </>
                    ))}
                  </div>
                </Document>
              </>
            )}
          </div>
        </div>
        <div className="public-invoice-details-container bg-white rounded-2xl px-8 py-6 whitespace-pre-line">
          <BaseText
            textColor="text-blue-300"
            fontSize="text-xl"
            fontWeight="text-bold"
            className="public-invoice-due-date-detail mt-2"
            title={shareDetails?.documentName}
          />
          <BaseText
            textColor="text-gray-700"
            fontSize="text-xs"
            className="public-invoice-due-date-detail mt-2"
            title={convertDateToDDMMYYYY(shareDetails?.uploadedDate)}
          />

          <PrimaryButton
            id="public-company-doc-download-btn"
            caption={
              <div className={`flex justify-center gap-2 items-center`}>
                <Iconify icon={'lucide:download-cloud'} />
                <span>Download</span>
              </div>
            }
            onClick={() => downloadImage(shareDetails?.documentUrl)}
            bgColor="bg-white"
            color="text-coral-500"
            type="small"
            className="incoming-payment-coral-button w-full border border-gray-400 mt-10"
          />
        </div>
      </div>
    </div>
  );
}

export default PublicCompanyDocument;
