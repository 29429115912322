import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
//
import Iconify from '../Iconify';
import { MenuItem, Divider } from '@mui/material';
import { TableMoreMenu } from '../table';
import { MUI_COLORS } from '../../constants/enum';
// ----------------------------------------------------------------------
MoreMenu.propTypes = {
  actions: PropTypes.node,
  open: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.string
};
MoreMenu.defaultProps = {
  icon: 'eva:more-vertical-fill'
};
export default function MoreMenu({ onApproveMarkAsPaid, onDeleteMarkAsPaid, isApproved }) {
  const [openMenu, setOpenMenuActions] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  return (
    <>
      <TableMoreMenu
        open={openMenu}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        icon="eva:more-horizontal-fill"
        actions={
          <div>
            <MenuItem onClick={onApproveMarkAsPaid} disabled={isApproved} sx={{ color: MUI_COLORS.GREEN }}>
              <Iconify icon={'mdi:success-circle'} />
              Approve
            </MenuItem>
            <MenuItem onClick={onDeleteMarkAsPaid} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} />
              Delete
            </MenuItem>
          </div>
        }
      />
    </>
  );
}
