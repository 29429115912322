import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack, Divider } from '@mui/material';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { MUI_COLORS, STATUS_COLORS, STRING_MAX_CHAR } from '../../constants/enum';
import MSText from '../../components/ui/typography/MSText';
import Iconify from '../../components/Iconify';
import { backgroundColor } from 'tailwindcss/defaultTheme';
import { convertDateToDDMMYYYY, stringTruncate } from '../../helper/helpers';

LatestUpdateTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleDeleteAction: PropTypes.func,
  handleDownloadAction: PropTypes.func,
  handleSelectedRow: PropTypes.func
};

export default function LatestUpdateTableRow({
  row,
  selected,
  handleDeleteAction,
  handleDownloadAction,
  selectedRow,
  handleSelectedRow,
  selectedRowData,
  isViewableTable
}) {
  const { documentName, uploadedDate, _id } = row;

  return (
    <>
      <TableRow
        selected={selected}
        hover={selectedRowData !== _id && true}
        sx={
          isViewableTable
            ? selectedRowData === _id && {
                backgroundColor: MUI_COLORS.PURPLE
              }
            : selectedRow === _id && {
                backgroundColor: MUI_COLORS.PURPLE
              }
        }
        onClick={handleSelectedRow}
      >
        <TableCell>
          <Stack>
            <Typography
              sx={
                isViewableTable
                  ? selectedRowData === _id && { color: 'white' }
                  : selectedRow === _id
                  ? { color: 'white' }
                  : {
                      color: MUI_COLORS.MID_NIGHT,
                      textDecoration: 'none',
                      boxShadow: 'none',
                      fontSize: '16px',
                      fontWeight: 400
                    }
              }
              variant="subtitle2"
              noWrap
            >
              {stringTruncate(documentName, STRING_MAX_CHAR.TWENTY_NINE)}
            </Typography>
            <MSText
              textColor={
                isViewableTable
                  ? selectedRowData === _id && 'text-white'
                  : selectedRow === _id
                  ? 'text-white'
                  : 'text-gray-450'
              }
              title={convertDateToDDMMYYYY(uploadedDate)}
            />
          </Stack>
        </TableCell>

        <TableCell align="right">
          <div className="flex justify-end">
            {(isViewableTable ? selectedRowData !== _id : selectedRow !== _id) && (
              <>
                <div className="bg-customBg-mainBg text-coral-500 rounded-lg p-2 cursor-pointer">
                  <Iconify icon={'lucide:download-cloud'} onClick={handleDownloadAction} />
                </div>
                {/* <div
                className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
                onClick={handleDeleteAction}
              >
                <Iconify icon={'ep:delete'} />
              </div> */}
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
      <Divider sx={{ color: STATUS_COLORS.GREY, width: '344px' }} />
    </>
  );
}
