import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InvoiceDetails from '../../components/invoice/InvoiceDetails';
import * as userActions from '../../components/invoice/reduxStore/action';

const CreateInvoice = ({ resetInvoiceDetails }) => {
  const [isCreateDataStored, setIsCreateDataStored] = useState(true);
  useEffect(() => {
    return () => {
      resetInvoiceDetails();
    };
  }, []);
  return (
    <Layout>
      <InvoiceDetails isEditDataStored={isCreateDataStored} showGoodAndServices={false} />
    </Layout>
  );
};

export const Head = () => <Seo title="Create Invoice" />;

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetInvoiceDetails: () => dispatch(userActions.resetInvoiceDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);

CreateInvoice.propTypes = {
  getInvoiceDetails: PropTypes.object
};
