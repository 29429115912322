import React from 'react';
import InvoiceWithTablet from '../../images/graphics/invoice-with-tablet.png';
import DynamicModal from '../ui/modal/DynamicModal';
import BaseText from '../ui/typography/BaseText';
import FileCopyright from '../../images/icons/file-copyright.svg';
import SendEmail from '../../images/icons/send-email.svg';
import AnalyticGraphBar from '../../images/icons/analytics-graph-bar.svg';
import '../../styles/pages/redirection-to-invoice.scss';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { Dialog, DialogContent } from '@mui/material';
import * as userActions from '../invoice/reduxStore/action';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_CODE } from '../../constants/enum';
import DownloadInvoiceAsPDF from '../common/DownloadInvoiceAsPDF';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';

const ctaPoints = [
  {
    text: 'Everything you need in one package',
    icon: FileCopyright
  },
  {
    text: '100% Online and hassle-free',
    icon: SendEmail
  },
  {
    text: 'Get a business account + payment card + account manager with Statrys.(subject to approval) ',
    icon: AnalyticGraphBar
  }
];
function RedirectToSignUpModal({
  openRedirectionModal,
  closeRedirectionModal,
  creteTempInvoice,
  redirectToInvoiceUserData,
  getInvoiceDetails
}) {
  const handleTempInvoice = () => {
    createTempInvoiceHandler(redirectToInvoiceUserData);
  };
  const createTempInvoiceHandler = async (redirectToInvoiceUserData) => {
    const createCompanyDetail = await creteTempInvoice(redirectToInvoiceUserData);
    if (createCompanyDetail?.status === API_CODE.STATUS_200) {
      navigate(`${PATH_PAGE.signUp}`, { state: { tempInvoiceData: createCompanyDetail?.data?.data } });
    }
  };
  return (
    <Dialog
      open={openRedirectionModal}
      onClose={() => closeRedirectionModal()}
      aria-labelledby="responsive-dialog-title"
      maxWidth="920px"
      sx={{
        '&.MuiDialog-root': {
          '& .MuiDialogContent-root': {
            paddingTop: '0px'
          }
        },
        '& .MuiDialogContent-root': {
          padding: '0px'
        }
      }}
    >
      <DialogContent>
        <div className="flex justify-between invoice-modal-container">
          <div className="invoice-modal-image-container">
            <img src={InvoiceWithTablet} alt="statrys logo with invoice" />
          </div>
          <div className="p-10 invoice-modal-content-container">
            <BaseText
              fontSize="text-2xl"
              className="mb-5"
              textColor="text-gray-500"
              title="Step up to a real invoicing solution."
            />
            <BaseText
              fontSize="text-2xl"
              textColor="text-gray-500"
              className="mb-5"
              fontWeight="text-bold"
              title="Welcome to Statrys Invoices"
            />
            {ctaPoints?.map((points, index) => {
              return (
                <div className="flex flex-row gap gap-4 items-center mb-4" key={index}>
                  <div className="rounded-lg py-1 image-layout">
                    <img src={points?.icon} alt={points?.text} />
                  </div>
                  <BaseText className="" textColor="text-gray-500" title={points?.text} />
                </div>
              );
            })}
            <div className="flex justify-between mt-12">
              <DownloadInvoiceAsPDF
                isIcon={false}
                color="text-blue-300"
                className="button-style-overide button-border"
                insideClass="text-bold"
                caption="Just download a PDF"
                getInvoiceDetails={redirectToInvoiceUserData}
              />
              <PrimaryButton
                id="redirect-to-sign-up-btn"
                className="button-style-overide"
                fontWeight="text-bold"
                caption="Yes, sign me in!"
                onClick={handleTempInvoice}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    creteTempInvoice: (invoiceData) => dispatch(userActions.invoiceGenerator(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToSignUpModal);

RedirectToSignUpModal.propTypes = {
  creteTempInvoice: PropTypes.func,
  redirectToInvoiceUserData: PropTypes.object,
  getInvoiceDetails: PropTypes.any,
  closeRedirectionModal: PropTypes.any,
  openRedirectionModal: PropTypes.bool
};
