import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { isBrowser } from '../../helper/helpers';
import { PAGE_TITLE, LOGIN_TAB } from '../../constants/enum';
import axios from 'axios';
import { ENDPOINTS } from '../../service/services';
const BASE_API_URL = process.env.BASE_API_URL;
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') //Get LocalStorage Data of User
    ? JSON.parse(window.localStorage.getItem('user'))
    : {};

const setUser = async (user, token, activeTab) => {
  typeof window !== 'undefined' && window.localStorage.setItem('user', JSON.stringify(user)); //Set LocalStorage Data of User
  user?.companyId && window.sessionStorage.setItem('companyId', user?.companyId);
  if (activeTab === LOGIN_TAB?.INVOICE) {
    navigate(PATH_PAGE.dashboard);
  } else if (user?.isIncorporation && !user?.isApproved) {
    const companyDetails = await axios.get(
      `${BASE_API_URL}${ENDPOINTS?.GET_COMPANY_SET_UP_DETAILS}?companyId=${user?.companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    if (companyDetails?.data?.data?.bookedCall) {
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
  } else if (user?.isIncorporation && user?.isApproved) {
    navigate(PATH_PAGE.companyProfile);
  } else {
    navigate(PATH_PAGE.dashboard);
  }
  isBrowser() && window.localStorage.setItem('pageName', JSON.stringify(PAGE_TITLE.DASHBOARD));
};
const navigateToLogin = () => navigate(PATH_PAGE.login);

export const handleLogin = (token, userDetails, activeTab) => {
  if (token) {
    sessionStorage.setItem('token', token);
    setUser(
      {
        id: userDetails?._id,
        isVerified: userDetails?.isVerified,
        email: userDetails?.email,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        isCompanyOwner: userDetails?.isCompanyOwner,
        isIncorporation: activeTab === LOGIN_TAB?.INVOICE ? false : userDetails?.isIncorporation,
        isApproved: userDetails?.isApproved,
        companyId: userDetails?.companyId
      },
      token,
      activeTab
    );
    return true;
  }
  return false;
};

export const isLoggedIn = () => {
  const token = sessionStorage.getItem('token');
  return token ? true : false;
};

export const logOut = () => {
  sessionStorage.clear();
  typeof window !== 'undefined' && window.localStorage.clear();
  setUser({});
  navigateToLogin();
};
