import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { COMPANY_SETUP_SIDEBAR_SUB_STEP, MUI_COLORS } from '../../../constants/enum';
import MSText from '../../ui/typography/MSText';
import LeftArrow from '../../../images/icons/arrow-left.svg';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';

const companySteps = [
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.FIRST,
    name: 'Company Name'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.SECOND,
    name: 'Industry'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.THIRD,
    name: 'Business description'
  }
];

const ownersSteps = [
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.FIRST,
    name: 'Yourself'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.SECOND,
    name: 'Others'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.THIRD,
    name: 'Capital of the company'
  }
];

export default function VerticalLinearStepper({ activeStepNumber, subStep }) {
  const [activeStep, setActiveStep] = useState(activeStepNumber ? activeStepNumber : 0);
  const steps = [
    {
      label: 'Create your account',
      description: ``
    },
    {
      label: 'Company information',
      description: (
        <div className="flex flex-col gap-2">
          {companySteps?.map((steps, index) => {
            return (
              <div className="flex flex-row" key={index}>
                {steps?.id === subStep ? <img src={LeftArrow} alt="left arrow" /> : null}{' '}
                <MSText
                  fontWeight={steps?.id === subStep && 'text-bold'}
                  className={steps?.id !== subStep ? 'ml-8' : 'ml-4'}
                  title={steps.name}
                />
              </div>
            );
          })}
        </div>
      )
    },
    {
      label: 'Owners and directors',
      description: (
        <div className="flex flex-col gap-2">
          {ownersSteps?.map((steps, index) => {
            return (
              <div className="flex flex-row" key={index}>
                {steps?.id === subStep ? <img src={LeftArrow} alt="left arrow" /> : null}{' '}
                <MSText
                  fontWeight={steps?.id === subStep && 'text-bold'}
                  className={steps?.id !== subStep ? 'ml-8' : 'ml-4'}
                  title={steps.name}
                />
              </div>
            );
          })}
        </div>
      )
    },
    {
      label: 'Confirm and sign',
      description: ``
    }
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setActiveStep(activeStepNumber);
  }, [activeStepNumber]);

  return (
    <div className="flex flex-col gap-8 p-8 border-r border-gray-400 w-72 md:block hidden">
      <LGSubtitleText className="whitespace-pre-line" textWeight="text-medium" title={`Company \nIncorporation`} />
      <Box sx={{ maxWidth: 400 }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            '& .MuiSvgIcon-root': {
              color: `${MUI_COLORS?.GREEN} !important`,
              marginTop: '3px',
              marginLeft: '12%',
              width: '26px',
              height: '26px'
            },

            '& .MuiStepConnector-line': {
              borderLeftWidth: '3px',
              minHeight: '17px',
              borderColor: MUI_COLORS?.LIGHT_GREEN
            },
            '& .MuiStepContent-root': {
              borderLeftWidth: '3px',
              borderColor: MUI_COLORS?.LIGHT_GREEN,
              marginLeft: '14px'
            },
            '& .MuiStepLabel-iconContainer': {
              height: '32px',
              background: MUI_COLORS?.LIGHT_GREEN,
              borderRadius: '50%',
              width: '32px'
            },
            '& .MuiStepConnector-root': {
              marginLeft: '14px'
            },
            '& .MuiStepLabel-labelContainer': {
              marginLeft: '7px'
            },
            '& .MuiStepLabel-label': {
              color: MUI_COLORS?.DARK_CHARCOAL,
              fontFamily: 'HKGrotesk-Regular',
              fontSize: '14px'
            },
            '& .MuiStepLabel-active': {
              fontFamily: 'HKGrotesk-Bold'
            }
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label} sx={{ cursor: 'pointer' }}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                {/* <Box sx={{ mb: 2 }}>
                  <div>
                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
