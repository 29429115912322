import * as actionTypes from './actionTypes';

let initialState = {
  uploadedFile: {},
  checkSendEmail: {},
  checkValidateSendEmail: {},
  loading: false,
  error: false,
  getCountryDetails: {}
};

const CommonReduxStore = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FILE_UPLOAD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.FILE_UPLOAD_REQUEST_SUCCESS:
      return {
        ...state,
        uploadedFile: action.uploadedFile,
        loading: false,
        error: false
      };
    case actionTypes.FILE_UPLOAD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHECK_SEND_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        checkSendEmail: action.checkSendEmail,
        loading: false,
        error: false
      };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        checkValidateSendEmail: action.checkValidateSendEmail,
        loading: false,
        error: false
      };
    case actionTypes.CHECK_SEND_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COUNTRY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COUNTRY_REQUEST_SUCCESS:
      return {
        ...state,
        getCountryDetails: action.getCountryPayload,
        loading: false,
        error: false
      };
    case actionTypes.GET_COUNTRY_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
export default CommonReduxStore;
