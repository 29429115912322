import React, { useState } from 'react';
import XSText from '../../ui/typography/XSText';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import { useSelector, connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE, COMPANY_SETUP_STEP, CONSTANT_NUMBER } from '../../../constants/enum';

const turnOverList = [
  {
    turnoverRange: '0 to USD50,000'
  },
  {
    turnoverRange: 'USD50,000 to USD100,000'
  },
  {
    turnoverRange: 'USD100,000 to USD250,000'
  },
  {
    turnoverRange: 'USD250,000 to USD500,000'
  },
  {
    turnoverRange: 'USD500,000 to USD1,000,000'
  },
  {
    turnoverRange: 'More than USD1,000,000'
  }
];
const TurnOver = ({ prevDescriptionStep, nextStep, setActiveStep, setSubStep, updateCompanyIncorporation }) => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const [selectedTurnOver, setSelectedTurnOver] = useState(
    companyAllInformation?.turnoverRange ? companyAllInformation?.turnoverRange : ''
  );

  const onSelect = (turnover) => {
    setSelectedTurnOver(turnover);
  };

  const onContinue = async () => {
    if (selectedTurnOver) {
      let payload = {
        turnoverRange: selectedTurnOver?.turnoverRange,
        companyId: companyAllInformation?._id
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextStep();
        setSubStep(CONSTANT_NUMBER.ONE);
        setActiveStep();
      }
    }
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <XSText
          className="md:whitespace-pre-line"
          title={`What turnover do you expect for the first year \nof operation of the company? `}
        />
        {turnOverList?.map((turnOver, index) => {
          return (
            <div
              key={index}
              className={`flex items-center border cursor-pointer ${
                selectedTurnOver?.turnoverRange === turnOver?.turnoverRange
                  ? 'border-purple-500 turnover-selected-box-shadow'
                  : 'border-gray-400'
              } rounded-lg turnover-value-container`}
              onClick={() => onSelect(turnOver)}
            >
              <XSText title={turnOver?.turnoverRange} className="py-6 px-6" />
            </div>
          );
        })}
      </div>
      <div className="flex flex-row justify-between ">
        <BackButton onClick={prevDescriptionStep} />
        <PrimaryButton caption="Continue" color="text-white" isBorderRequired={true} onClick={onContinue} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(TurnOver);
