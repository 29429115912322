import React from 'react';
import XSText from './typography/XSText';
import PropTypes from 'prop-types';
import ArrowDownIcon from '../../images/icons/arrow-down-menu.svg';
import ArrowDownWhiteIcon from '../../images/icons/arrow-down-white.svg';
import { STATUS_COLORS, INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
export default function InvoiceStatus({ requiredInvoiceStatus, isArrowImage }) {
  const invoiceStatusSelector = () => {
    switch (requiredInvoiceStatus) {
      case INVOICE_STATUS.INVOICE_DRAFT:
        return { bgcolor: STATUS_COLORS.GREY, color: 'text-gray-500', title: 'Draft', isDarkColor: true };
      case INVOICE_STATUS.INVOICE_PAID:
        return { bgcolor: STATUS_COLORS.GREEN, color: 'text-green-200', title: 'Paid', isDarkColor: false };
      case INVOICE_STATUS.INVOICE_UNPAID:
        return { bgcolor: STATUS_COLORS.STATRYS_YELLOW, color: 'text-gray-500', title: 'Unpaid', isDarkColor: true };
      case INVOICE_STATUS.PARTIALLY_PAID:
        return { bgcolor: MUI_COLORS.PAPAYA_WHIP, color: 'text-gray-500', title: 'Partially paid' };
      case INVOICE_STATUS.INVOICE_OVERDUE:
        return { bgcolor: STATUS_COLORS.STATRYS_RED, color: 'text-salmon-500', title: 'Overdue' };
      case INVOICE_STATUS.UPLOADED:
        return { bgcolor: STATUS_COLORS.STATRYS_YELLOW, color: 'text-gray-500', title: 'Uploaded' };
      case INVOICE_STATUS.VALID:
        return { bgcolor: STATUS_COLORS.GREEN, color: 'text-green-200', title: 'Valid' };
      case INVOICE_STATUS.INVALID:
        return { bgcolor: STATUS_COLORS.STATRYS_RED, color: 'text-salmon-500', title: 'Invalid' };
      case INVOICE_STATUS.INVOICE_OVERDUE:
        return { bgcolor: STATUS_COLORS.STATRYS_RED, color: 'text-salmon-500', title: 'Overdue', isDarkColor: false };
      case INVOICE_STATUS.INVOICE_PARTIAL_PAID:
        return {
          bgcolor: STATUS_COLORS.STATRYS_YELLOW,
          color: 'text-gray-500',
          title: 'Partial Paid',
          isDarkColor: true
        };
      case INVOICE_STATUS.INVOICE_REVIEW:
        return { bgcolor: STATUS_COLORS.STATRYS_YELLOW, color: 'text-gray-500', title: 'Review', isDarkColor: true };
      default:
        return 'invoice-status-draft';
    }
  };
  return (
    <div className="invoice-status-container flex" style={{ backgroundColor: invoiceStatusSelector()?.bgcolor }}>
      <XSText
        fontWeight="text-bold"
        lineHeight="line-height-18"
        textColor={invoiceStatusSelector()?.color}
        title={invoiceStatusSelector()?.title}
      />
      {isArrowImage && (
        <img
          src={invoiceStatusSelector()?.isDarkColor ? ArrowDownIcon : ArrowDownWhiteIcon}
          className="ml-2"
          width="8"
          height="8"
          alt="Arrow down"
        />
      )}
    </div>
  );
}

InvoiceStatus.propTypes = {
  isArrowImage: PropTypes.bool,
  requiredInvoiceStatus: PropTypes.string
};
