import React, { useState } from 'react';
import '../../../styles/get-started-banner.scss';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XSText from '../../ui/typography/XSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import InvoiceBannerImg from '../../../images/graphics/contact/contact-listing.png';
import { PATH_PAGE } from '../../../routes/paths';
import ImportedInvoiceUploadModal from '../../importedInvoices/ImportedInvoiceUploadModal';

export default function ContactListBanner() {
  const [uploadModal, setUploadModal] = useState(false);
  const closeDynamicModal = () => {
    setUploadModal(false);
  };
  return (
    <div className="send-invoice-container rounded-2xl box-shadow md:pl-8 pl-4  flex md:flex-row flex-col md:justify-between">
      <div className="py-8 md:w-4/12 w-full">
        <div>
          <LGSubtitleText
            className="whitespace-pre-line"
            textWeight="text-bold"
            textColor="text-gray-500"
            title={'Manage your\n contacts'}
          />
          <XSText
            fontWeight="text-medium"
            className="whitespace-pre-line mt-4"
            textColor="text-gray-500"
            title={
              'Keep all your contacts in one place by\n managing your suppliers and clients.\n\nEasily access contact information and\n maintain a centralized database for efficient\n communication and invoicing.'
            }
          />
        </div>
        <div className="send-invoice-button">
          <PrimaryButton
            id="contact-list-banner-create-invoice-btn"
            className="mr-2"
            isBorderRequired="true"
            color="text-white"
            fontSize="text-base"
            fontWeight="text-medium"
            bgColor="bg-green-500"
            caption="Get your invoice paid"
            urlLink={PATH_PAGE.createInvoice}
          />
          <PrimaryButton
            id="contact-list-banner-upload-invoice-btn"
            className="send-invoice-review-button mr-2 mb-2"
            fontSize="text-base"
            fontWeight="text-medium"
            bgColor="bg-white"
            caption="Pay an invoice"
            onClick={() => setUploadModal(true)}
          />
          <ImportedInvoiceUploadModal openDynamicModal={uploadModal} closeDynamicModal={closeDynamicModal} />
        </div>
      </div>

      <div className="md:mt-8 flex md:justify-end justify-center md:w-8/12 w-full">
        <img className="contact-invoice-image rounded-2xl" src={InvoiceBannerImg} alt="Invoice"></img>
      </div>
    </div>
  );
}
