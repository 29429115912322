import React from 'react';
import XSText from '../ui/typography/XSText';
import CheckedIcon from '../../images/icons/check-box-tick.svg';
import '../../styles/custom-checkbox.scss';
import PropTypes from 'prop-types';

const CustomCheckBox = ({ selected, label, onClick }) => {
  return (
    <div
      className="flex flex-row gap-1 items-center justify-between py-2 px-6 cursor-pointer rounded-lg border-gray-400 border checkbox-container"
      onClick={onClick}
    >
      <XSText title={label} fontWeight="text-medium" />
      {selected ? (
        <img src={CheckedIcon} alt="checked" />
      ) : (
        <div className="border rounded-full check-box-circle bg-white border-gray-400"></div>
      )}
    </div>
  );
};

export default CustomCheckBox;

CustomCheckBox.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
};

CustomCheckBox.defaultProps = {
  selected: false,
  label: 'Checkbox'
};
