import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const getImportingInvoiceDetailsRequestStart = () => ({
  type: actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_START
});
export const getImportingInvoiceDetailsRequestFail = (error) => ({
  type: actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_FAIL,
  error: error
});
export const getImportingInvoiceDetailsRequestSuccess = (getImportingInvoiceDetails) => ({
  type: actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_SUCCESS,
  getImportingInvoiceDetails: getImportingInvoiceDetails
});
export const getImportingInvoiceDetails = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(getImportingInvoiceDetailsRequestStart());
      let importingInvoiceDetailsResponse = await Api.get(`${ENDPOINTS.INVOICE_IN_GET_DETAILS}?invoiceId=${invoiceId}`);
      dispatch(getImportingInvoiceDetailsRequestSuccess(importingInvoiceDetailsResponse));
      return importingInvoiceDetailsResponse;
    } catch (error) {
      dispatch(getImportingInvoiceDetailsRequestFail(error.response));
    }
  };
};

export const getImportingInvoiceListRequestStart = () => ({
  type: actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_START
});
export const getImportingInvoiceListRequestFail = (error) => ({
  type: actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_FAIL,
  error: error
});
export const getImportingInvoiceListRequestSuccess = (getImportingInvoiceList) => ({
  type: actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_SUCCESS,
  getImportingInvoiceList: getImportingInvoiceList
});
export const getImportingInvoiceList = (supplierId) => {
  return async (dispatch) => {
    try {
      dispatch(getImportingInvoiceListRequestStart());
      let importingInvoiceListResponse = await Api.get(ENDPOINTS.GET_INVOICE_IN_LIST, supplierId);
      dispatch(getImportingInvoiceListRequestSuccess(importingInvoiceListResponse));
      return importingInvoiceListResponse;
    } catch (error) {
      dispatch(getImportingInvoiceListRequestFail(error.response));
    }
  };
};

export const createImportingInvoiceRequestStart = () => ({
  type: actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_START
});
export const createImportingInvoiceRequestFail = (error) => ({
  type: actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_FAIL,
  error: error
});
export const createImportingInvoiceRequestSuccess = (createResponse) => ({
  type: actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_SUCCESS,
  createResponse: createResponse
});
export const createImportingInvoice = (ImportingInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(createImportingInvoiceRequestStart());
      let createdImportingInvoiceResponse = await Api.post(ENDPOINTS.INVOICE_IN_CREATE, ImportingInvoicePayload);
      dispatch(createImportingInvoiceRequestSuccess(createdImportingInvoiceResponse));
      return createdImportingInvoiceResponse;
    } catch (error) {
      dispatch(createImportingInvoiceRequestFail(error.response));
    }
  };
};

export const editImportingInvoiceRequestStart = () => ({
  type: actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_START
});
export const editImportingInvoiceRequestFail = (error) => ({
  type: actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_FAIL,
  error: error
});
export const editImportingInvoiceRequestSuccess = (editedImportingInvoiceResponse) => ({
  type: actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_SUCCESS,
  editedImportingInvoiceData: editedImportingInvoiceResponse
});
export const editImportingInvoice = (importedInvoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(editImportingInvoiceRequestStart());
      let editedImportingInvoiceResponse = await Api.put(ENDPOINTS.INVOICE_IN_EDIT, importedInvoiceId);
      dispatch(editImportingInvoiceRequestSuccess(editedImportingInvoiceResponse));
      return editedImportingInvoiceResponse;
    } catch (error) {
      dispatch(editImportingInvoiceRequestFail(error.response));
    }
  };
};

export const deleteImportedInvoicesRequestStart = () => ({
  type: actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_START
});
export const deleteImportedInvoicesRequestFail = (error) => ({
  type: actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_FAIL,
  error: error
});
export const deleteImportedInvoicesRequestSuccess = (importedInvoicePayload) => ({
  type: actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_SUCCESS,
  importedInvoicePayload: importedInvoicePayload
});
export const deleteImportedInvoice = (importedInvoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteImportedInvoicesRequestStart());
      let deletedImportedInvoiceResponse = await Api.delete(
        `${ENDPOINTS.INVOICE_IN_DELETE}?invoiceId=${importedInvoiceId}`
      );
      dispatch(deleteImportedInvoicesRequestSuccess(deletedImportedInvoiceResponse));
      return deletedImportedInvoiceResponse;
    } catch (error) {
      dispatch(deleteImportedInvoicesRequestFail(error.response));
    }
  };
};

export const uploadedInvoicesRequestStart = () => ({
  type: actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_START
});
export const uploadedInvoicesRequestFail = (error) => ({
  type: actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_FAIL,
  error: error
});
export const uploadedInvoicesRequestSuccess = (uploadedInvoiceResponse) => ({
  type: actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_SUCCESS,
  uploadedInvoiceData: uploadedInvoiceResponse
});
export const uploadedInvoices = (importedInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(uploadedInvoicesRequestStart());
      let uploadedInvoiceResponse = await Api.post(ENDPOINTS.INVOICE_UPLOAD_PDF, importedInvoicePayload);
      dispatch(uploadedInvoicesRequestSuccess(uploadedInvoiceResponse));
      return uploadedInvoiceResponse;
    } catch (error) {
      dispatch(uploadedInvoicesRequestFail(error.response));
    }
  };
};

export const getImportingInvoicePublicRequestStart = () => ({
  type: actionTypes.GET_IMPORTING_PUBLIC_REQUEST_START
});
export const getImportingInvoicePublicRequestFail = (error) => ({
  type: actionTypes.GET_IMPORTING_PUBLIC_REQUEST_FAIL,
  error: error
});
export const getImportingInvoicePublicRequestSuccess = (getImportingInvoicePublic) => ({
  type: actionTypes.GET_IMPORTING_PUBLIC_REQUEST_SUCCESS,
  getImportingInvoicePublic: getImportingInvoicePublic
});
export const getImportingInvoicePublic = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(getImportingInvoicePublicRequestStart());
      let importingInvoicePublicResponse = await Api.get(`${ENDPOINTS.INVOICE_IN_PUBLIC}/${invoiceId}`);
      dispatch(getImportingInvoicePublicRequestSuccess(importingInvoicePublicResponse));
      return importingInvoicePublicResponse;
    } catch (error) {
      dispatch(getImportingInvoicePublicRequestFail(error.response));
    }
  };
};

export const requestValidationInvoicesRequestStart = () => ({
  type: actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_START
});
export const requestValidationInvoicesRequestFail = (error) => ({
  type: actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_FAIL,
  error: error
});
export const requestValidationInvoicesRequestSuccess = (importedInvoicePayload) => ({
  type: actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_SUCCESS,
  importedInvoicePayload: importedInvoicePayload
});
export const requestValidationInvoices = (importedInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(requestValidationInvoicesRequestStart());
      let requestValidationInvoiceResponse = await Api.post(
        ENDPOINTS.INVOICE_IN_SEND_VALIDATE_EMAIL,
        importedInvoicePayload
      );
      dispatch(requestValidationInvoicesRequestSuccess(requestValidationInvoiceResponse));
      return requestValidationInvoiceResponse;
    } catch (error) {
      dispatch(requestValidationInvoicesRequestFail(error.response));
    }
  };
};

export const getRequestValidatorsRequestStart = () => ({
  type: actionTypes.GET_VALIDATORS_REQUEST_START
});
export const getRequestValidatorsRequestFail = (error) => ({
  type: actionTypes.GET_VALIDATORS_REQUEST_FAIL,
  error: error
});
export const getRequestValidatorsRequestSuccess = (validatorsList) => ({
  type: actionTypes.GET_VALIDATORS_REQUEST_SUCCESS,
  validatorsList: validatorsList
});
export const getRequestValidators = () => {
  return async (dispatch) => {
    try {
      dispatch(getRequestValidatorsRequestStart());
      let getValidatorsListResponse = await Api.get(ENDPOINTS.INVOICE_IN_VALIDATORS_LIST);
      dispatch(getRequestValidatorsRequestSuccess(getValidatorsListResponse));
      return getValidatorsListResponse;
    } catch (error) {
      dispatch(getRequestValidatorsRequestFail(error.response));
    }
  };
};

export const getImportingDashboardDataRequestStart = () => ({
  type: actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_START
});
export const getImportingDashboardDataRequestFail = (error) => ({
  type: actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_FAIL,
  error: error
});
export const getImportingDashboardDataRequestSuccess = (getImportingDashboardData) => ({
  type: actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_SUCCESS,
  getImportingDashboardData: getImportingDashboardData
});
export const getImportingDashboardData = (supplierQueries) => {
  return async (dispatch) => {
    try {
      dispatch(getImportingDashboardDataRequestStart());
      let importingDashboardDataResponse = await Api.get(ENDPOINTS.INVOICE_IN_DASHBOARD_DATA, supplierQueries);
      dispatch(getImportingDashboardDataRequestSuccess(importingDashboardDataResponse));
      return importingDashboardDataResponse;
    } catch (error) {
      dispatch(getImportingDashboardDataRequestFail(error.response));
    }
  };
};
