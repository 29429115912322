import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import * as profileActions from '../profile/reduxStore/action';
import PropTypes from 'prop-types';
import { API_CODE, MUI_COLORS } from '../../constants/enum';
import Snackbar from '../Snackbar';
import Success from '../../images/icons/success-icon.svg';
import Image from '../Image';
import XSText from '../ui/typography/XSText';
import MSText from '../ui/typography/MSText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import InvoiceCurrenciesList from '../../data/invoice-currencies-list.json';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BaseText from '../ui/typography/BaseText';

function CompanySettings() {
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmitCompanySetting = async () => {};
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.messageTitle}></XSText>
              <MSText textColor="text-gray-500" title={message?.contentMessage}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      <div className="setting-form-container md:ml-6 bg-white px-6 pb-8">
        <MDSubtitleText className="py-6" fontWeight="text-bold" textColor="text-gray-500" title="Company Settings" />
        <BaseText
          textColor="text-gray-450"
          className="mb-2"
          fontSize="text-base"
          title="The currency used by default in our invoices"
        />
        <div className="mb-4 w-full">
          <SelectInputAutoComplete
            name="currency"
            disableClearable={true}
            inputRef={register({
              required: false
            })}
            options={InvoiceCurrenciesList}
            label="Currency"
          />
        </div>
        <BaseText textColor="text-gray-450" className="mb-2" fontSize="text-base" title="How we number our invoices" />

        <div className="mb-4 w-full">
          <SelectInputAutoComplete
            name="numbering"
            disableClearable={true}
            inputRef={register({
              required: false
            })}
            options={InvoiceCurrenciesList}
            label="Numbering"
          />
        </div>
        <BaseText
          textColor="text-gray-450"
          className="mb-2"
          fontSize="text-base"
          title="We call people who buy from us"
        />

        <div className="mb-4 w-full">
          <SelectInputAutoComplete
            name="preferredCurrency"
            disableClearable={true}
            inputRef={register({
              required: false
            })}
            options={InvoiceCurrenciesList}
            label="Clients"
          />
        </div>
        <BaseText
          textColor="text-gray-450"
          className="mb-2"
          fontSize="text-base"
          title="We call people who sell to us"
        />

        <div className="w-full">
          <SelectInputAutoComplete
            name="currency"
            disableClearable={true}
            inputRef={register({
              required: false
            })}
            options={InvoiceCurrenciesList}
            label="Vendors"
          />
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-6">
        <PrimaryButton
          id="company-settings-save-changes-btn"
          isLoading={buttonLoader}
          className="setting-section-button"
          caption="Save Changes"
          onClick={handleSubmit(onSubmitCompanySetting)}
        />
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    currencyPreference: (payload) => dispatch(profileActions.currencyPreference(payload)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(null, mapDispatchToProps)(CompanySettings);
CompanySettings.propTypes = {};
