import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const companyProfileDetailsRequestStart = () => ({ type: actionTypes.GET_COMPANY_DETAILS_REQUEST_START });
export const companyProfileDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_FAILURE,
  error: error
});
export const companyProfileDetailsRequestSuccess = (allCompanyDetails) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS,
  allCompanyDetails: allCompanyDetails
});
export const companyProfileDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(companyProfileDetailsRequestStart());
      let companyProfileDetailsResponse = await Api.get(ENDPOINTS.GET_COMPANY_REGISTRATION_DETAILS);
      dispatch(companyProfileDetailsRequestSuccess(companyProfileDetailsResponse?.data));
      return companyProfileDetailsResponse;
    } catch (error) {
      dispatch(companyProfileDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyDocumentsDetailsRequestStart = () => ({ type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_START });
export const companyDocumentsDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_FAILURE,
  error: error
});
export const companyDocumentsDetailsRequestSuccess = (companyDocumentDetails) => ({
  type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS,
  companyDocumentDetails: companyDocumentDetails
});
export const companyDocumentsDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(companyDocumentsDetailsRequestStart());
      let companyDocumentsDetailsResponse = await Api.get(ENDPOINTS.COMPANY_DOCUMENTS_DETAILS);
      dispatch(companyDocumentsDetailsRequestSuccess(companyDocumentsDetailsResponse?.data));
      return companyDocumentsDetailsResponse;
    } catch (error) {
      dispatch(companyDocumentsDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyLatestUpdatesDetailsRequestStart = () => ({
  type: actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_START
});
export const companyLatestUpdatesDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_FAILURE,
  error: error
});
export const companyLatestUpdatesDetailsRequestSuccess = (latestUpdatesDetails) => ({
  type: actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_SUCCESS,
  latestUpdatesDetails: latestUpdatesDetails
});
export const companyLatestUpdatesDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(companyLatestUpdatesDetailsRequestStart());
      let companyLatestUpdatesDetailsResponse = await Api.get(ENDPOINTS.COMPANY_LATEST_UPDATES);
      dispatch(companyLatestUpdatesDetailsRequestSuccess(companyLatestUpdatesDetailsResponse?.data));
      return companyLatestUpdatesDetailsResponse;
    } catch (error) {
      dispatch(companyLatestUpdatesDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyStackHoldersDetailsRequestStart = () => ({
  type: actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_START
});
export const companyStackHoldersDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_FAILURE,
  error: error
});
export const companyStackHoldersDetailsRequestSuccess = (stackHoldersDetails) => ({
  type: actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_SUCCESS,
  stackHoldersDetails: stackHoldersDetails
});
export const companyStackHoldersDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(companyStackHoldersDetailsRequestStart());
      let companyStackHoldersDetailsResponse = await Api.get(ENDPOINTS.COMPANY_STACKHOLDERS_DETAILS);
      dispatch(companyStackHoldersDetailsRequestSuccess(companyStackHoldersDetailsResponse?.data));
      return companyStackHoldersDetailsResponse;
    } catch (error) {
      dispatch(companyStackHoldersDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companySecretaryDetailsRequestStart = () => ({
  type: actionTypes.GET_COMPANY_SECRETARY_REQUEST_START
});
export const companySecretaryDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_SECRETARY_REQUEST_FAILURE,
  error: error
});
export const companySecretaryDetailsRequestSuccess = (secretaryDetails) => ({
  type: actionTypes.GET_COMPANY_SECRETARY_REQUEST_SUCCESS,
  secretaryDetails: secretaryDetails
});
export const companySecretaryDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(companySecretaryDetailsRequestStart());
      let companySecretaryDetailsResponse = await Api.get(ENDPOINTS.COMPANY_SECRETARY_DETAILS);
      dispatch(companySecretaryDetailsRequestSuccess(companySecretaryDetailsResponse?.data));
      return companySecretaryDetailsResponse;
    } catch (error) {
      dispatch(companySecretaryDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companySecretaryShareRequestStart = () => ({
  type: actionTypes.COMPANY_SECRETARY_REQUEST_START
});
export const companySecretaryShareRequestFail = (error) => ({
  type: actionTypes.COMPANY_SECRETARY_REQUEST_FAILURE,
  error: error
});
export const companySecretaryShareRequestSuccess = (secretaryShare) => ({
  type: actionTypes.COMPANY_SECRETARY_REQUEST_SUCCESS,
  secretaryShare: secretaryShare
});
export const companySecretaryShare = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(companySecretaryShareRequestStart());
      let companySecretaryShareResponse = await Api.post(ENDPOINTS.COMPANY_SECRETARY_SHARE, payload);
      dispatch(companySecretaryShareRequestSuccess(companySecretaryShareResponse?.data));
      return companySecretaryShareResponse;
    } catch (error) {
      dispatch(companySecretaryShareRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyMailroomShareRequestStart = () => ({
  type: actionTypes.COMPANY_MAILROOM_REQUEST_START
});
export const companyMailroomShareRequestFail = (error) => ({
  type: actionTypes.COMPANY_MAILROOM_REQUEST_FAILURE,
  error: error
});
export const companyMailroomShareRequestSuccess = (mailroomShare) => ({
  type: actionTypes.COMPANY_MAILROOM_REQUEST_SUCCESS,
  mailroomShare: mailroomShare
});
export const companyMailroomShare = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(companyMailroomShareRequestStart());
      let companyMailroomShareResponse = await Api.post(ENDPOINTS.COMPANY_MAILROOM_SHARE, payload);
      dispatch(companyMailroomShareRequestSuccess(companyMailroomShareResponse?.data));
      return companyMailroomShareResponse;
    } catch (error) {
      dispatch(companyMailroomShareRequestFail(error.response));
      return error?.response;
    }
  };
};
