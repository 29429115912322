import React, { useEffect } from 'react';
import BaseText from '../components/ui/typography/BaseText';
import MSText from '../components/ui/typography/MSText';
import IconStatrysLogo from '../images/icons/statrys-logo.svg';
import config from '../config/config.json';
import IconMailBox from '../images/icons/mailbox.svg';
import IconPhone from '../images/icons/phone.svg';
import IconWhatsApp from '../images/icons/whatsapp.svg';
import '../styles/pages/maintenance.scss';
import LGSubtitleText from '../components/ui/typography/LGSubtitleText';
import { STATUS } from '../constants/enum';
import { navigate } from 'gatsby';
import UnderMaintenanceImage from '../images/graphics/maintenance-img.png';

export default function UnderMaintenance() {
  useEffect(() => {
    if (process.env.MAINTENANCE_FLOW_ENABLED === STATUS.FALSE) {
      if (typeof window !== `undefined`) {
        navigate(`/`);
      }
    }
  }, []);
  return (
    <div className="flex flex-col md:flex-row items-center justify-center maintenance-page-container gap-0 gap-10">
      <div className="w-full h-full bg-customBg-mainBg maintenance-page-left-section flex flex-col justify-center items-center">
        <div className="md:w-8/12 w-10/12">
          <img src={IconStatrysLogo} className="md:mt-0 mt-6" alt="statrys-logo" />
          <BaseText className="mt-8" fontWeight="text-bold" title="Scheduled Maintenance" />
          <LGSubtitleText title={'We’ll be back at 1:00PM HKT'} fontWeight="text-light" className="mt-8 mb-4" />
          <BaseText
            className="md:w-10/12"
            title="We are servicing our platform to make sure our resources are as good as new. Sorry for the inconvenience."
          />
          <MSText className="mt-4 mb-2" title="For any urgent issue you can contact us:" />
          <div className="flex flex-col md:flex-row md:items-center gap-3">
            <a href={`tel:${config.phone_number}`}>
              <div className="flex gap-1 items-center">
                <img src={IconPhone} alt="Icon Phone" />
                <MSText textColor="text-coral-500" fontWeight="text-bold" title={config.phone_number} />
              </div>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={config.whatsapp_link}>
              <div className="flex gap-1 items-center">
                <img src={IconWhatsApp} alt="Icon Whatsapp" />
                <MSText textColor="text-coral-500" fontWeight="text-bold" title={config.whatsapp} />
              </div>
            </a>
            <a href={config.email_support_link}>
              <div className="flex gap-1 items-center">
                <img src={IconMailBox} alt="Icon Mailbox" />
                <MSText textColor="text-coral-500" fontWeight="text-bold" title={config.email_support} />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full">
        <img src={UnderMaintenanceImage} alt="maintenance image" className="w-full" />
      </div>
    </div>
  );
}
