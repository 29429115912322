import React, { useState } from 'react';
import H3HeaderText from '../ui/typography/H3HeaderText';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from '@reach/router';
import {
  concatenateSelectedCurrency,
  convertDateToDDMMYYYY,
  getFileExtension,
  stringTruncate
} from '../../helper/helpers';
import InvoiceStatus from '../ui/InvoiceStatus';
import ValidationStatus from '../ui/ValidationStatus';
import { FILE_EXTENTION_TYPE, INVOICE_STATUS } from '../../constants/enum';
function IncomingInvoiceDetails({ importedInvoiceData }) {
  const [pageScale, setPageScale] = useState(true);
  const [numPages, setNumPages] = useState(null);
  let pageNumber = 1;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handlePageZooming = () => {
    if (pageScale === true) {
      setPageScale(false);
    } else {
      setPageScale(true);
    }
  };
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const checkObjectValues = (obj) => {
    for (const key in obj) {
      if (obj[key] !== '') {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div className="incoming-invoice-details-container bg-white rounded-2xl px-8 pt-8">
        <>
          <div className="flex justify-between mb-6">
            <H3HeaderText title="Invoice" />
            <div className="flex self-end text-bold rounded-lg">
              <InvoiceStatus requiredInvoiceStatus={importedInvoiceData?.status && importedInvoiceData?.status} />
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-between">
            <div className="flex w-full gap-1 flex-col">
              <MSText textColor="text-gray-700" title="Issuing company" />
              <div className="flex items-center gap-4 my-4">
                <Avatar
                  avatarName={
                    importedInvoiceData?.supplier?.companyName && importedInvoiceData?.supplier?.companyName.charAt(0)
                  }
                />
                {importedInvoiceData?.supplier?.companyName && (
                  <MSText fontWeight="text-bold" title={importedInvoiceData?.supplier?.companyName} />
                )}
              </div>
              {importedInvoiceData?.supplier?.registrationNumber && (
                <MSText title={importedInvoiceData?.supplier?.registrationNumber} />
              )}
              {importedInvoiceData?.supplier?.address && <MSText title={importedInvoiceData?.supplier?.address} />}
              <MSText
                title={`${importedInvoiceData?.supplier?.city ? importedInvoiceData?.supplier?.city + ', ' : ''}${
                  importedInvoiceData?.supplier?.state ? importedInvoiceData?.supplier?.state + ', ' : ''
                }${importedInvoiceData?.supplier?.postalCode ? importedInvoiceData?.supplier?.postalCode : ''}`}
              />
              {importedInvoiceData?.supplier?.country && <MSText title={importedInvoiceData?.supplier?.country} />}
              {importedInvoiceData?.supplier?.website && <MSText title={importedInvoiceData?.supplier?.website} />}
              {importedInvoiceData?.supplier?.email && <MSText title={importedInvoiceData?.supplier?.email} />}
              {checkObjectValues(importedInvoiceData?.paymentDetails) && (
                <MSText className="mt-4" textColor="text-gray-700" title="Banking information" />
              )}
              <div className="flex gap-8 mt-2">
                <div className="flex flex-col gap-2">
                  {importedInvoiceData?.paymentDetails?.iBan && <MSText textColor="text-gray-700" title="IBAN:" />}
                  {importedInvoiceData?.paymentDetails?.bankName && <MSText textColor="text-gray-700" title="Bank:" />}
                  {importedInvoiceData?.paymentDetails?.country && (
                    <MSText textColor="text-gray-700" title="Country:" />
                  )}
                  {importedInvoiceData?.paymentDetails?.accountNumber && (
                    <MSText textColor="text-gray-700" title="Account:" />
                  )}
                  {importedInvoiceData?.paymentDetails?.swift && (
                    <MSText textColor="text-gray-700" title="SWIFT/BIC:" />
                  )}
                  {importedInvoiceData?.paymentDetails?.routingCode && (
                    <MSText textColor="text-gray-700" title="Routing Code:" />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  {importedInvoiceData?.paymentDetails?.iBan && (
                    <MSText title={importedInvoiceData?.paymentDetails?.iBan} />
                  )}
                  {importedInvoiceData?.paymentDetails?.bankName && (
                    <MSText title={importedInvoiceData?.paymentDetails?.bankName} />
                  )}
                  {importedInvoiceData?.paymentDetails?.country && (
                    <MSText title={importedInvoiceData?.paymentDetails?.country} />
                  )}
                  {importedInvoiceData?.paymentDetails?.accountNumber && (
                    <MSText title={importedInvoiceData?.paymentDetails?.accountNumber} />
                  )}
                  {importedInvoiceData?.paymentDetails?.swift && (
                    <MSText title={importedInvoiceData?.paymentDetails?.swift} />
                  )}
                  {importedInvoiceData?.paymentDetails?.routingCode && (
                    <MSText title={importedInvoiceData?.paymentDetails?.routingCode} />
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col md:mt-0 mt-6">
              <MSText textColor="text-gray-700" title="Invoice details" />
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex justify-between">
                  <MSText textColor="text-gray-700" title="Invoice number:" />
                  <MSText textAlign="text-right" title={importedInvoiceData?.invoiceNumber} />
                </div>
                <div className="flex justify-between">
                  <MSText textColor="text-gray-700" title="Created:" />
                  <MSText textAlign="text-right" title={convertDateToDDMMYYYY(importedInvoiceData?.createDate)} />
                </div>
                <div className="flex justify-between">
                  <MSText textColor="text-gray-700" title="Due date:" />
                  <MSText textAlign="text-right" title={convertDateToDDMMYYYY(importedInvoiceData?.dueDate)} />
                </div>
                <div className="flex justify-between">
                  <MSText textColor="text-gray-700" title="Taxes:" />
                  <MSText
                    textAlign="text-right"
                    title={concatenateSelectedCurrency(
                      importedInvoiceData?.currency,
                      importedInvoiceData?.taxWithPrice?.[0]?.taxPrice
                    )}
                  />
                </div>
                <div className="flex justify-between">
                  <MSText textColor="text-gray-700" title="Invoice amount:" />
                  <MSText
                    textAlign="text-right"
                    title={concatenateSelectedCurrency(importedInvoiceData?.currency, importedInvoiceData?.totalAmount)}
                  />
                </div>
                {importedInvoiceData?.validationStatus !== INVOICE_STATUS.NONE && (
                  <div className="flex justify-between items-center">
                    <MSText textColor="text-gray-700" title="Validation:" />
                    <ValidationStatus
                      requiredInvoiceStatus={
                        importedInvoiceData?.validationStatus && importedInvoiceData?.validationStatus
                      }
                    />
                  </div>
                )}
                <div className="flex justify-between items-center">
                  <MSText textColor="text-gray-700" title="Uploaded by:" />
                  <MSText
                    textAlign="text-right"
                    title={
                      <div className="flex items-center justify-end gap-2">
                        <Avatar
                          avatarStyling="w-6 h-6"
                          avatarName={importedInvoiceData?.uploadedBy && importedInvoiceData?.uploadedBy?.charAt(0)}
                          fontSize="text-xs"
                        />
                        {stringTruncate(importedInvoiceData?.uploadedBy && importedInvoiceData?.uploadedBy, 30)}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-6 uploaded-imported-invoice-preview">
            {getFileExtension(importedInvoiceData?.pdfURL) === FILE_EXTENTION_TYPE.PNG ||
            getFileExtension(importedInvoiceData?.pdfURL) === FILE_EXTENTION_TYPE.JPEG ||
            getFileExtension(importedInvoiceData?.pdfURL) === FILE_EXTENTION_TYPE.JPG ? (
              <>
                {pageScale ? (
                  <div
                    className="w-96 h-52 overflow-hidden incoming-image-background incoming-invoice-image-zoom-in"
                    onClick={handlePageZooming}
                  >
                    <img
                      className="imported-invoice-upload-container"
                      src={importedInvoiceData?.pdfURL && importedInvoiceData?.pdfURL}
                      alt="Uploaded Image"
                    />
                  </div>
                ) : (
                  <div className="incoming-invoice-image-zoom-out" onClick={handlePageZooming}>
                    <img
                      className="imported-invoice-upload-container"
                      src={importedInvoiceData?.pdfURL && importedInvoiceData?.pdfURL}
                      alt="Uploaded Image"
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {pageScale ? (
                  <>
                    <div className="w-96 h-52 overflow-hidden incoming-image-background">
                      <Document file={importedInvoiceData?.pdfURL}>
                        <div className="incoming-invoice-image-zoom-in">
                          <Page scale={0.8} pageNumber={pageNumber} onClick={handlePageZooming} />
                        </div>
                      </Document>
                    </div>
                  </>
                ) : (
                  <Document file={importedInvoiceData?.pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
                    <div className="incoming-invoice-image-zoom-out">
                      {Array.from(new Array(numPages), (el, index) => (
                        <div className="mb-6">
                          <Page
                            scale={1}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            onClick={handlePageZooming}
                          />
                        </div>
                      ))}
                    </div>
                  </Document>
                )}
              </>
            )}
            {pageScale && <div className="incoming-invoice-image-gradient"></div>}
          </div>
        </>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(null, mapDispatchToProps)(IncomingInvoiceDetails);

IncomingInvoiceDetails.propTypes = {
  importedInvoiceData: PropTypes.object
};
