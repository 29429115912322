import React from 'react';
import QRCode from 'qrcode.react';

const QRGenerator = (props) => {
  const { valueString, documentId } = props;

  return (
    <div>
      <QRCode id={documentId} value={valueString} size={128} level={'H'} includeMargin={false} onLoad={props.onLoad} />
    </div>
  );
};

export default QRGenerator;
