import React, { useEffect, useState } from 'react';
import BaseText from '../ui/typography/BaseText';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../../styles/pages/payment-review.scss';
import { API_CODE, INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
import * as dashboardActions from './reduxStore/action';
import { connect } from 'react-redux';
import SelectInput from './SelectInput';
import InvoiceCurrenciesList from '../../data/invoice-currencies-list.json';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart({ getPerformance, preferedCurrency, isPerformanceData }) {
  const [years, setYears] = React.useState('');
  const [currency, setCurrency] = React.useState();
  const [menuItems, setMenuItems] = React.useState([]);
  const [expenses, setExpenses] = React.useState([]);
  const [revenue, setRevenue] = React.useState([]);
  const [isData, setIsData] = React.useState(false);

  const getInvoicePerformance = async (year) => {
    const payload = {
      year: year
    };
    const getPerformanceResponse = await getPerformance(payload);
    if (getPerformanceResponse?.status === API_CODE.STATUS_200) {
      const expensesPerformance = getPerformanceResponse?.data?.data?.Expenses;
      const revenuePerformance = getPerformanceResponse?.data?.data?.Revenues;

      if (expensesPerformance?.length > 0 || revenuePerformance?.length > 0) {
        isPerformanceData();
        setIsData(true);
      }

      getExpensesArray(expensesPerformance);
      getRevenueArray(revenuePerformance);
    }
  };

  const getExpensesArray = (expensesPerformance) => {
    const yearlyData = Array(12).fill(0);

    expensesPerformance?.forEach((expense) => {
      if (expense?.month >= 1 && expense?.month <= 12) {
        yearlyData[expense?.month - 1] = -expense?.total;
      }
    });
    setExpenses(yearlyData);
  };

  const getRevenueArray = (revenuePerformance) => {
    const yearlyData = Array(12).fill(0);

    revenuePerformance?.forEach((revenue) => {
      if (revenue?.month >= 1 && revenue?.month <= 12) {
        yearlyData[revenue?.month - 1] = revenue?.total;
      }
    });
    setRevenue(yearlyData);
  };

  const getDropdownYears = () => {
    const d = new Date();
    const currentYear = d.getFullYear();
    let optionsYear = [];
    for (var i = currentYear; i >= 2010; i--) {
      optionsYear.push({ name: i });
    }
    setMenuItems(optionsYear);
  };

  const handleYearChange = (value) => {
    getInvoicePerformance(value);
    setYears(value);
  };

  const handleAnalyticsTabs = () => {
    navigate(PATH_PAGE.profile, {
      state: { preferences: true }
    });
  };

  useEffect(() => {
    getDropdownYears();
    const d = new Date();
    const year = d.getFullYear();
    setYears(year);
    getInvoicePerformance(year);
    setCurrency(preferedCurrency?.name ? preferedCurrency?.name : preferedCurrency?.symbol);
  }, [preferedCurrency]);
  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            return `${preferedCurrency?.symbol ? preferedCurrency?.symbol : preferedCurrency?.name}` + value;
          }
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: (tooltipItem) => {
            return `  ${tooltipItem.dataset.label}  ${tooltipItem.label}`;
          },
          labelTextColor: function (context) {
            return MUI_COLORS.MID_NIGHT;
          },
          footer: function (tooltipItem) {
            return `${preferedCurrency?.symbol ? preferedCurrency?.symbol : preferedCurrency?.name} ${
              tooltipItem[0].formattedValue
            }`;
          }
        },
        footerAlign: 'center',
        usePointStyle: true,
        backgroundColor: MUI_COLORS.WHITE,
        borderColor: MUI_COLORS.DARK_CHARCOAL,
        bodyColor: MUI_COLORS.MID_NIGHT,
        footerColor: MUI_COLORS.MID_NIGHT,
        caretPadding: 4,
        caretSize: 9,
        borderWidth: 0.2,
        padding: 12
      }
    }
  };

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const plugins = [
    {
      id: 'increase-legend-spacing',
      beforeInit(chart) {
        const originalFit = chart.legend.fit;
        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)();
          this.height += 20;
        };
      }
    }
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: revenue,
        backgroundColor: MUI_COLORS.GREEN,
        borderRadius: 40,
        maxBarThickness: 11,
        barThickness: 11
      },
      {
        label: 'Expenses',
        data: expenses,
        backgroundColor: MUI_COLORS.SUNGLOW,
        borderRadius: 40,
        maxBarThickness: 11,
        barThickness: 11
      }
    ]
  };

  return (
    <>
      {isData && (
        <div className="bg-white rounded-2xl p-6 bar-chart-main">
          <div className="mb-4 flex justify-between">
            <div className="flex items-center">
              <BaseText fontWeight="text-bold" className="mr-2" fontSize="text-lg" title="Performance" />
              <SelectInput
                selectedMenu={years}
                isDisabled={true}
                setSelectedMenu={(value) => handleYearChange(value?.name)}
                menuItems={menuItems}
              />
            </div>
            {currency && (
              <div className="cursor-pointer" onClick={() => handleAnalyticsTabs()}>
                <SelectInput
                  selectedMenu={currency}
                  isDisabled={false}
                  setSelectedMenu={(value) => handleYearChange(value?.name)}
                  menuItems={InvoiceCurrenciesList}
                />
              </div>
            )}
          </div>
          <div className="bar-chart-container">
            <Bar data={data} options={options} plugins={plugins} />
          </div>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPerformance: (payload) => dispatch(dashboardActions.getPerformance(payload))
  };
};

export default connect(null, mapDispatchToProps)(BarChart);
