import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { API_CODE, EMAIL_JS, MUI_COLORS, REGEX } from '../../../constants/enum';
import { defaultValidationMessage } from '../../../helper/helpers';
import TextInput from '../../ui/inputs/TextInput';
import MultiTextInput from '../../ui/inputs/MultiTextInput';
import PrimaryButton from '../..//ui/buttons/PrimaryButton';
import ValidationMessage from '../../ui/ValidationMessage';
import UploadDocument from '../../importedInvoices/UploadDocument';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action.js';
import * as userProfileActions from '../../../components/profile/reduxStore/action.js';
import { connect } from 'react-redux';
import { send } from '@emailjs/browser';
import { getUser } from '../../authService/auth';
import * as accountSettingActions from '../../../components/accountSetting/reduxStore/action';
import Snackbar from '../../../components/Snackbar';
import errorIcon from '../../../images/icons/error-icon.svg';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import Image from '../../../components/Image';
import StatrysLoader from '../../ui/loaders/StatrysLoader';

function Form({ cancelSupport, uploadFile, getUserProfile, getCompanyDetails }) {
  const { register, handleSubmit, errors } = useForm();
  const [acceptedFile, setAcceptedFile] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [supportFormImage, setSupportForm] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const user = getUser();

  const onSubmit = async (data) => {
    setLoading(true);
    function templateParamsForMail(data) {
      let templateParams = {
        name: userInfo?.firstName ? userInfo?.firstName : '',
        lastName: userInfo?.lastName ? userInfo?.lastName : '',
        email: data?.email,
        message: data?.message,
        upload_url: supportFormImage && supportFormImage,
        companyname: companyName && companyName
      };
      return templateParams;
    }

    await send(EMAIL_JS.INVOICING, EMAIL_JS.EMAIL_SUPPORT, templateParamsForMail(data), EMAIL_JS.PUBLIC_KEY).then(
      function (response) {
        if (response.status === API_CODE?.STATUS_200) {
          setLoading(false);
          cancelSupport(true);
        }
      },
      function (error) {
        setIsSnackBarOpen(true);
        setMessage({
          title: 'Something went wrong!',
          subtitle: error?.text,
          icon: errorIcon,
          bgColor: MUI_COLORS.LIGHT_CREAM,
          borderColor: MUI_COLORS.SUNSET_ORANGE
        });
        setLoading(false);
      }
    );
  };

  const fileSelection = async (acceptedFiles) => {
    try {
      setAcceptedFile(acceptedFiles);
      let selectedimg = acceptedFiles?.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setSelectedImage(selectedimg);
      const formData = new FormData();
      formData.append('file', selectedimg[0]);
      const uploadDocument = await uploadFile(formData);
      if (uploadDocument?.status === API_CODE.STATUS_200) {
        setSupportForm(uploadDocument?.data?.data?.file);
      } else {
        setUploadError(true);
      }
      setSaveImage(true);
    } catch (error) {
      setUploadError(true);
    }
  };
  useEffect(() => {
    apiCalls();
  }, []);

  const apiCalls = async () => {
    const getUserInfo = await getUserProfile();
    if (getUserInfo?.data?.statusCode === API_CODE?.STATUS_200) {
      setUserInfo(getUserInfo?.data?.data);
    }
    const getCompanyName = await getCompanyDetails();
    if (getCompanyName?.data?.statusCode === API_CODE?.STATUS_200) {
      setCompanyName(getCompanyName?.data?.data?.companyName);
    }
  };

  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <StatrysLoader />
        </div>
      ) : (
        <form id="support-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <TextInput
              defaultValue={user?.email}
              name="email"
              label="Email Address"
              variant="filled"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED,
                  message: 'Please fill in a valid Email Address'
                }
              })}
              error={errors?.email ? true : false}
              helperText={errors?.email && <ValidationMessage title={'Please fill in a valid Email Address'} />}
            />
            <TextInput
              name="subject"
              label="Subject"
              variant="filled"
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED
                }
              })}
              error={errors?.subject ? true : false}
              helperText={
                errors?.subject && <ValidationMessage title={defaultValidationMessage('subject (Tags not allowed)')} />
              }
            />
            <MultiTextInput
              inputContainerClass="mt-4"
              name="message"
              rows={4}
              label="Describe the problem so we can help you better"
              inputRef={register({
                required: true,
                pattern: {
                  value: REGEX.TAGS_NOT_ALLOWED
                }
              })}
              error={errors?.message ? true : false}
              helperText={
                errors?.message && <ValidationMessage title={defaultValidationMessage('message (Tags not allowed')} />
              }
            />
            <UploadDocument
              saveImage={saveImage}
              selectedImage={selectedImage}
              uploadError={uploadError}
              acceptedFile={acceptedFile?.[0]}
              fileSelection={fileSelection}
            />
          </div>
          <div className="flex flex-row gap gap-4 mt-6">
            <PrimaryButton
              id="form-email-send-btn"
              bgColor="bg-coral-500"
              caption="Send"
              fontWeight="text-bold"
              className="form-btn"
              onClick={handleSubmit(onSubmit)}
            />
            <PrimaryButton
              id="form-email-cancel-btn"
              bgColor="bg-white"
              color="text-gray-300"
              className="form-btn gray-border"
              caption="Cancel"
              fontWeight="text-bold"
              isBorderRequired={true}
              onClick={() => cancelSupport(false)}
            />
          </div>
        </form>
      )}
      <Snackbar
        open={isSnackBarOpen}
        handleClose={closeSnackbar}
        sx={{ bgcolor: message.bgColor, border: '1px solid', borderColor: message.borderColor }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={message?.icon} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.title}></XSText>
              <MSText textColor="text-gray-500" title={message?.subtitle}></MSText>
            </div>
          </div>
        }
      />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (uploadedImage) => dispatch(commonReduxActions.fileUpload(uploadedImage)),
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(null, mapDispatchToProps)(Form);
