import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_TYPE } from '../../constants/enum';
import MSText from '../ui/typography/MSText';
import '../../styles/pages/upload-image.scss';
import HardDeriveUpload from '../../images/icons/harddrive-upload.svg';
import FileIcon from '../../images/icons/file-icon-1.svg';
import { stringTruncate } from '../../helper/helpers';
import CloseIcon from '../../images/icons/close-icon.svg';

export default function ImageUpload({
  saveImage,
  fileSelection,
  uploadError,
  selectedImage,
  acceptedFile,
  handleRemoveFile,
  isEdit
}) {
  const [uploadLogo, setUploadLogo] = useState(false);
  const handleSelectingFiles = (event) => {
    if (selectedImage.length > 0) {
      event.stopPropagation();
    }
  };

  return (
    <div
      onClick={() => !saveImage && !uploadLogo && setUploadLogo(!uploadLogo)}
      className={`rounded-lg bg-customBg-mainBg ${!saveImage && !uploadLogo && 'cursor-pointer'}  ${
        uploadLogo ? ' items-start' : ' items-center'
      }`}
    >
      <Dropzone onDrop={(acceptedFiles) => fileSelection(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className="">
            <div {...getRootProps({ onClick: (event) => handleSelectingFiles(event) })}>
              <input {...getInputProps()} />
              {selectedImage ? (
                selectedImage[0].type === FILE_UPLOAD_TYPE.PNG ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.JPEG ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.URL ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.PDF ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.JPG ? (
                  <div className="flex justify-between px-4 py-4 mt-4 border rounded-lg items-center cursor-pointer">
                    <div className="flex items-center">
                      <div className="mr-4">
                        <img src={FileIcon} alt="invoice file" />
                      </div>
                      <MSText title={stringTruncate(acceptedFile?.name, 20)} />
                    </div>
                    <div>
                      {!isEdit && (
                        <img
                          className="cursor-pointer"
                          src={CloseIcon}
                          alt="close-icon"
                          onClick={() => handleRemoveFile(acceptedFile)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex px-4 py-4 mt-4 border rounded-lg items-center cursor-pointer">
                    <div className="mr-4">
                      <img src={HardDeriveUpload} alt="invoice upload" />
                    </div>
                    <div>
                      <MSText title="Proof of payment (Optional)" />
                      <MSText title="JPG, PNG, PDF up to 5MB." />
                    </div>
                  </div>
                )
              ) : (
                <div className="flex px-4 py-4 mt-4 border rounded-lg items-center cursor-pointer">
                  <div className="mr-4">
                    <img src={HardDeriveUpload} alt="invoice upload" />
                  </div>
                  <div>
                    <MSText title="Proof of payment (Optional)" />
                    <MSText title="JPG, PNG, PDF up to 5MB." />
                  </div>
                </div>
              )}
            </div>
            {uploadError ? (
              <MSText
                className="mt-2 whitespace-pre-line flex justify-center"
                textColor="upload-error"
                textAlign="text-center"
                title={'Only Docx, Doc, PDF, JPG, and\n PNG files with max size of\n 4 MB.'}
              />
            ) : null}
          </section>
        )}
      </Dropzone>
    </div>
  );
}
