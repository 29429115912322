import React, { useState } from 'react';
import CompanyProfile from '../../components/companyIncorporation/profileSettings/CompanyProfile';
import Security from '../../components/companyIncorporation/profileSettings/Security';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Layout from '../../components/layout';
import Page from '../../components/Page';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import MSText from '../../components/ui/typography/MSText';
import { PAGE_TITLE } from '../../constants/enum';

function SettingProfile() {
  const [buttonLoader, setButtonLoader] = useState(false);

  return (
    <Layout>
      <Page title="Company">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.PROFILE}
          links={[{ name: '' }]}
          action={
            <>
              <button positive form="password-form" type="submit">
                <PrimaryButton
                  isLoading={buttonLoader}
                  id="getpaid-create-invoice-btn"
                  caption={
                    <div className="flex gap-1 items-center">
                      <MSText textColor="text-white" fontWeight="text-bold" title="Save changes" />
                    </div>
                  }
                  type="small"
                />
              </button>
            </>
          }
        />

        <div className="flex">
          <CompanyProfile />
          <Security setButtonLoader={setButtonLoader} />
        </div>
      </Page>
    </Layout>
  );
}

export default SettingProfile;
