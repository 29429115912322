import React, { useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import UploadPaymentProof from '../publicInvoice/UploadPaymentProof';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_CODE, INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import * as invoiceReduxActions from '../invoice/reduxStore/action';
import * as invoicePaymentReduxActions from '../invoicePaymentDetails/reduxStore/action';
import moment from 'moment';
import { InputAdornment } from '@mui/material';
import { addCommas } from '../../helper/helpers';
const options = [
  {
    name: 'Cash'
  },
  {
    name: 'Cheque'
  },
  {
    name: 'Credit Card'
  },
  {
    name: 'Bank Transfer'
  },
  {
    name: 'PayPal'
  },
  {
    name: 'PayMe'
  }
];

function AddPaymentModal({
  openDynamicModal,
  closeDynamicModal,
  updateStatusOnPayment,
  getInvoiceDetails,
  uploadImage,
  invoiceMarkPayment,
  invoicePublicMarkPayment,
  handleInvoiceDetailsbyID,
  isPublic,
  publicInvoiceDetails,
  editInvoice
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [paymentType, setPaymentType] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(false);

  const handleCheckValidAmount = (e) => {
    if (getInvoiceDetails?.payments?.length > 0) {
      if (Number(e.target.value) <= Number(getInvoiceDetails?.pendingAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    } else {
      if (Number(e.target.value) <= Number(getInvoiceDetails?.totalAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    }
  };

  const handleToEmptyState = () => {
    closeDynamicModal();
    setAcceptedFile('');
    setSaveImage(false);
    setSelectedImage('');
    setPaymentType('');
    setIsPayment(false);
    setIsLoading(false);
  };

  const handlePaymentType = (value) => {
    setPaymentType(value);
    setIsPayment(false);
  };

  const onSubmit = (data) => {
    if (paymentType) {
      setIsLoading(true);
      if (saveImage) {
        uploadPaymentProof(data);
      } else {
        if (!isPublic) {
          invoiceMarkPaymentDetails(data);
        } else {
          invoicePublicMarkPaymentDetails(data);
        }
      }
    } else {
      setIsPayment(true);
    }
  };

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const uploadPaymentProof = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedProfileResponse = await uploadImage(formData);
    if (uploadedProfileResponse?.status === API_CODE.STATUS_200) {
      if (!isPublic) {
        invoiceMarkPaymentDetails(data, uploadedProfileResponse?.data?.data?.file);
      } else {
        invoicePublicMarkPaymentDetails(data, uploadedProfileResponse?.data?.data?.file);
      }
    }
  };

  const invoiceMarkPaymentDetails = async (data, paymentLink) => {
    const payload = {
      paidDate: data?.paidDate,
      paymentType: paymentType?.name,
      paidAmount:
        getInvoiceDetails?.payments?.length > 0 ? getInvoiceDetails?.pendingAmount : getInvoiceDetails?.totalAmount,
      paymentProof: paymentLink,
      invoiceId: getInvoiceDetails?._id
    };
    const invoiceMarkPaymentResponse = await invoiceMarkPayment(payload);
    if (invoiceMarkPaymentResponse?.status === API_CODE.STATUS_200) {
      const remaining = invoiceMarkPaymentResponse?.data?.data?.payments?.reduce((accumulator, object) => {
        return accumulator + object.paidAmount;
      }, 0);
      if (remaining) {
        editInvoiceHandler(invoiceMarkPaymentResponse?.data?.data, remaining);
      }
    }
  };

  const invoicePublicMarkPaymentDetails = async (data, paymentLink) => {
    const payload = {
      paidDate: data?.paidDate,
      paymentType: paymentType?.name,
      paidAmount:
        getInvoiceDetails?.payments?.length > 0 ? getInvoiceDetails?.pendingAmount : getInvoiceDetails?.totalAmount,
      paymentProof: paymentLink,
      invoiceId: getInvoiceDetails?._id
    };
    const invoicePublicMarkPaymentResponse = await invoicePublicMarkPayment(payload);
    if (invoicePublicMarkPaymentResponse?.status === API_CODE.STATUS_200) {
      publicInvoiceDetails(true);
      handleToEmptyState();
    }
  };

  const remaining = getInvoiceDetails?.payments?.reduce((accumulator, object) => {
    return accumulator + object.paidAmount;
  }, 0);

  const editInvoiceHandler = async (invoiceData, remainingAmount) => {
    const payload = {
      invoiceId: invoiceData?._id,
      pendingAmount: remainingAmount
        ? invoiceData?.totalAmount - remainingAmount
        : invoiceData?.totalAmount - (remaining + Number(remainingAmount))
    };
    const editInvoiceResponse = await editInvoice(payload);
    if (editInvoiceResponse?.status === API_CODE.STATUS_200) {
      if (Number(remainingAmount) === invoiceData?.totalAmount) {
        updateStatusOnPayment(INVOICE_STATUS.INVOICE_PAID);
      } else {
        updateStatusOnPayment(INVOICE_STATUS.INVOICE_PARTIAL_PAID);
      }
      handleInvoiceDetailsbyID();
    }
    handleToEmptyState();
  };

  const handleRemoveFile = (file) => {
    const remainingFiles = selectedImage.filter((item) => item.name !== file.name);
    setSelectedImage('');
    setAcceptedFile('');
    setSaveImage(false);
  };

  return (
    <div>
      <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={closeDynamicModal} minWidth="520px">
        <div className="mt-5 px-10">
          <MDSubtitleText className="flex justify-center mb-8" title={`Record payment`} fontWeight="text-bold" />
          <form id="add-invoice-payment" onSubmit={handleSubmit(onSubmit)}>
            <SelectInputAutoComplete
              name="paymentType"
              variant="filled"
              defaultValue={paymentType}
              value={paymentType}
              setSelectedValue={handlePaymentType}
              disableClearable={true}
              inputRef={register({
                required: false
              })}
              error={isPayment ? true : false}
              helperText={isPayment && <ValidationMessage title={'Please provide a payment type'} />}
              label="Payment type"
              options={options}
            />
            <TextInput
              defaultValue={moment(new Date()).format('YYYY-MM-DD')}
              name="paidDate"
              label="Date"
              type="date"
              variant="filled"
              textFieldContainerClass="mt-4"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register({
                required: true
              })}
              error={errors?.paidDate ? true : false}
              helperText={errors?.paidDate && <ValidationMessage title={'Please provide the date'} />}
            />
            <TextInput
              defaultValue={
                getInvoiceDetails?.payments?.length > 0
                  ? getInvoiceDetails?.pendingAmount
                  : addCommas(getInvoiceDetails?.totalAmount)
              }
              startAdornment={
                <InputAdornment position="start">
                  {getInvoiceDetails?.currency?.symbol
                    ? getInvoiceDetails?.currency?.symbol
                    : getInvoiceDetails?.currency?.name}
                </InputAdornment>
              }
              name="amount"
              label="Amount"
              variant="filled"
              disabled={true}
              backgroundColor={MUI_COLORS.GRAY_FOR_DISABLE_INPUTS}
              onChange={handleCheckValidAmount}
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: true
              })}
              error={errors?.amount ? true : isAmountValid ? isAmountValid : false}
              helperText={
                errors?.amount ? (
                  <ValidationMessage title={'Please provide the amount'} />
                ) : (
                  isAmountValid && (
                    <ValidationMessage title={'The amount recorded cannot be higher than the invoice amount'} />
                  )
                )
              }
            />
            <UploadPaymentProof
              isEdit={false}
              saveImage={saveImage}
              selectedImage={selectedImage}
              uploadError={uploadError}
              acceptedFile={acceptedFile?.[0]}
              fileSelection={fileSelection}
              handleRemoveFile={handleRemoveFile}
            />
            <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
              <PrimaryButton
                id="add-payment-save-btn"
                isLoading={isLoading}
                disabled={isAmountValid}
                caption="Save Payment"
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
              />
              <PrimaryButton
                id="add-payment-cancel-btn"
                className="modal-cancel-button font-weight-bold border border-gray-400"
                bgColor="bg-white"
                color="text-gray-300"
                caption="Cancel"
                onClick={handleToEmptyState}
                disabled={isAmountValid}
              />
            </div>
          </form>
        </div>
      </DynamicModal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    editInvoice: (payload) => dispatch(invoiceReduxActions.editInvoice(payload)),
    invoiceMarkPayment: (paymentData) => dispatch(invoicePaymentReduxActions.invoiceMarkPayment(paymentData)),
    invoicePublicMarkPayment: (paymentData) =>
      dispatch(invoicePaymentReduxActions.invoicePublicMarkPayment(paymentData)),
    uploadImage: (uploadedImage) => dispatch(commonReduxActions.fileUpload(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(AddPaymentModal);
AddPaymentModal.propTypes = {
  getInvoiceDetails: PropTypes.any,
  invoicePublicMarkPayment: PropTypes.func,
  uploadProfileImage: PropTypes.func,
  invoiceMarkPayment: PropTypes.func,
  editInvoice: PropTypes.func
};
