import React, { useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import MSText from '../../ui/typography/MSText';
import MessageLogo from '../../../images/icons/support-message.svg';
import Form from './Form';
import SendMessageButton from './SendMessageButton';

const HelpCenterForm = ({ cancelSupport }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toOpenForm = () => {
    setIsFormOpen(true);
  };
  return (
    <div className={`support-main-container bg-white rounded-lg`}>
      <div className="bg-coral-500 rounded-t-lg">
        <div className="flex flex-row justify-between px-8 pt-8 pb-6">
          <div className="flex flex-col gap gap-2">
            <MDSubtitleText title="Help Center" textColor="text-white" fontWeight="text-bold" />
            <MSText title="Let us know what we can help you with" textColor="text-white" fontWeight="text-medium" />
          </div>
          <img src={MessageLogo} alt="message" width="37.5" height="37.5" />
        </div>
      </div>
      <div className="py-6 px-8">
        {isFormOpen ? <Form cancelSupport={cancelSupport} /> : <SendMessageButton openForm={toOpenForm} />}
      </div>
    </div>
  );
};
export default HelpCenterForm;
