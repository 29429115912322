import React, { useState } from 'react';
import TextInput from '../ui/inputs/TextInput';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import ValidationMessage from '../ui/ValidationMessage';
import { INVOICE_TYPE, REGEX, FILE_UPLOAD_TYPE, API_CODE, REQUIRED_FILE_SIZE } from '../../constants/enum';
import { defaultValidationMessage, companyAcceptedFileFormats } from '../../helper/helpers';
import XSText from '../ui/typography/XSText';
import UploadImage from '../common/UploadImage';
import * as accountSettingActions from '../accountSetting/reduxStore/action';
import * as commonReducerActions from '../commonReduxStore/reduxStore/action';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
function InvoiceFrom({
  getInvoiceDetails,
  invoiceData,
  closeDynamicModal,
  type,
  companyDetails,
  accountSettingDetails,
  createCompanyDetails,
  updateCompanyDetails,
  uploadCompanyImage,
  handleOpen,
  isTemporary
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const emailRegex = REGEX.EMAIL_REGEX;
  const [saveImage, setSaveImage] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [acceptedFile, setAcceptedFile] = useState('');
  const [uploadImageUrl, setUploadImageUrl] = useState('');
  const [selectedImage, setSelectedImages] = useState(
    getInvoiceDetails?.invoiceImage?.preview
      ? [{ preview: getInvoiceDetails?.invoiceImage?.preview, type: FILE_UPLOAD_TYPE.URL }]
      : null
  );
  const [uploadError, setUploadError] = useState(false);

  const onSelectingCountry = (country) => {
    setSelectedCountry(country.name);
  };

  const fileSelection = (acceptedFiles) => {
    const fileSize = Math.round(acceptedFiles[0].size / REQUIRED_FILE_SIZE.ONE_MB);
    if (fileSize <= REQUIRED_FILE_SIZE.ONE_MB) {
      setAcceptedFile(acceptedFiles);
      let selectedimg = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setSelectedImages(selectedimg);
      setSaveImage(true);
      if (companyAcceptedFileFormats(acceptedFiles)) {
        invoiceData({ invoiceImage: selectedimg[0] });
        setUploadError(false);
      }
    } else {
      setSaveImage(false);
      setUploadError(true);
    }
  };
  const updateCompanyDetailsHandler = async (data, companyImageUrl) => {
    const updateCompanyDetailRequest = {
      ...data,
      logo: companyImageUrl ? companyImageUrl : getInvoiceDetails?.invoiceImage?.preview,
      country: selectedCountry ? selectedCountry : getInvoiceDetails?.fromCountry
    };
    const updatedCompanyDetail = await updateCompanyDetails(updateCompanyDetailRequest);
    if (updatedCompanyDetail?.status === API_CODE.STATUS_200) {
      accountSettingDetails();
      handleOpen(updatedCompanyDetail?.data);
    }
  };

  const createCompanyDetailsHandler = async (data, companyImageUrl) => {
    const companyDetailRequest = {
      ...data,
      logo: companyImageUrl ? companyImageUrl : getInvoiceDetails?.invoiceImage?.preview,
      country: selectedCountry ? selectedCountry : ''
    };
    const createCompanyDetail = await createCompanyDetails(companyDetailRequest);
    if (createCompanyDetail?.status === API_CODE.STATUS_200) {
      accountSettingDetails();
      handleOpen(createCompanyDetail?.data);
    }
  };

  const getUploadedContactProfileUrl = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedCompanyImage = await uploadCompanyImage(formData);
    if (uploadedCompanyImage?.status === API_CODE.STATUS_200) {
      const companyImageUrl = uploadedCompanyImage?.data?.data?.file;
      setUploadImageUrl(companyImageUrl);
      if (isTemporary) {
        companyTempProfileUploadHandler(data, companyImageUrl);
      } else {
        await createAndEditCompanyModal(data, companyImageUrl);
      }
    } else {
      setUploadError(true);
    }
  };

  const createAndEditCompanyModal = (data, companyImageUrl) => {
    if (!companyDetails?._id) {
      createCompanyDetailsHandler(data, companyImageUrl);
    } else {
      updateCompanyDetailsHandler(data, companyImageUrl);
    }
  };

  const onSubmit = (data) => {
    if (!isTemporary) {
      if (type === INVOICE_TYPE.FROM) {
        if (saveImage) {
          getUploadedContactProfileUrl(data);
        } else {
          createAndEditCompanyModal(data);
        }
      }
    } else {
      if (saveImage && uploadError) {
        getUploadedContactProfileUrl(data);
      } else {
        companyTempProfileUploadHandler(data);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedImages('');
    setSaveImage(false);
    setUploadError(false);
    setAcceptedFile('');
    closeDynamicModal();
    if (!isTemporary) {
      accountSettingDetails();
    }
  };

  const companyTempProfileUploadHandler = (data, companyImageUrl) => {
    invoiceData({
      fromFirstName: data?.name,
      fromLastName: data?.lastName,
      fromEmail: data?.email,
      fromCompanyName: data?.companyName,
      fromRegistrationNumber: data?.registrationNumber,
      fromWebsite: data?.website,
      fromAddress: data?.address,
      fromTownCity: data?.city,
      fromState: data?.state,
      fromPostalCode: data?.postalCode,
      fromCountry: selectedCountry ? selectedCountry : getInvoiceDetails?.fromCountry,
      invoiceImage: companyImageUrl
        ? { preview: companyImageUrl, type: FILE_UPLOAD_TYPE.URL }
        : { preview: getInvoiceDetails?.invoiceImage?.preview, type: FILE_UPLOAD_TYPE.URL }
    });
    handleCloseModal();
    setSaveImage(false);
  };

  const setCustomDefaultValue = (from, to) => {
    if (type === INVOICE_TYPE.FROM) {
      return from;
    }
    if (type === INVOICE_TYPE.TO) {
      return to;
    }
  };
  const watchAllFields = watch();

  return (
    <>
      <form id="invoice-form" onSubmit={handleSubmit(onSubmit)}>
        <MDSubtitleText
          className="mb-9"
          fontWeight="text-bold"
          title={setCustomDefaultValue('Add your information', 'Add new contact')}
        />
        <div className="flex gap-8">
          <div>
            {type === INVOICE_TYPE.FROM && (
              <>
                <TextInput
                  defaultValue={setCustomDefaultValue(
                    getInvoiceDetails?.fromCompanyName,
                    getInvoiceDetails?.toCompanyName
                  )}
                  margin="mb-4 w-full"
                  name="companyName"
                  label="Company Name"
                  inputRef={register({
                    required:
                      watchAllFields.firstName ||
                      setCustomDefaultValue(getInvoiceDetails?.fromFirstName, getInvoiceDetails?.toFirstName)
                        ? false
                        : true
                  })}
                  error={errors.companyName && !watchAllFields.firstName ? true : false}
                  helperText={
                    errors?.companyName &&
                    !watchAllFields.firstName && (
                      <ValidationMessage
                        title={defaultValidationMessage('Company Name or else provide a valid First name')}
                      />
                    )
                  }
                />
                <TextInput
                  defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromEmail, getInvoiceDetails?.toEmail)}
                  name="email"
                  label="Email Address"
                  variant="filled"
                  inputRef={register({
                    required: false,
                    pattern: {
                      value: emailRegex,
                      message: 'Please fill in a valid Email Address'
                    }
                  })}
                  error={errors.email ? true : false}
                  helperText={errors.email && <ValidationMessage title={defaultValidationMessage('Email address')} />}
                />
              </>
            )}
            <div className="flex md:flex-row flex-col md:gap-4 w-full">
              <TextInput
                defaultValue={setCustomDefaultValue(
                  getInvoiceDetails?.fromRegistrationNumber,
                  getInvoiceDetails?.toRegistrationNumber
                )}
                margin="mt-4 w-full"
                name="registrationNumber"
                label="Registration number"
                inputRef={register({
                  required: false
                })}
              />
              <TextInput
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromWebsite, getInvoiceDetails?.toWebsite)}
                margin="mt-4 w-full"
                name="website"
                label="Website"
                inputRef={register({
                  required: false
                })}
              />
            </div>
            <div className="flex md:flex-row flex-col md:gap-4 w-full">
              <TextInput
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromAddress, getInvoiceDetails?.toAddress)}
                margin="mt-4 w-full"
                name="address"
                label="Address"
                inputRef={register({
                  required: type === INVOICE_TYPE.TO ? true : false
                })}
                error={errors.address ? true : false}
                helperText={errors?.address && <ValidationMessage title={defaultValidationMessage('Address')} />}
              />
            </div>
            <div className="flex md:flex-row flex-col md:gap-4 w-full">
              <TextInput
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromTownCity, getInvoiceDetails?.toTownCity)}
                margin="mt-4 w-full"
                name="city"
                label="Town / City"
                inputRef={register({
                  required: type === INVOICE_TYPE.TO ? true : false
                })}
                error={errors.townCity ? true : false}
                helperText={errors?.townCity && <ValidationMessage title={defaultValidationMessage('Town / City')} />}
              />
              <TextInput
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromState, getInvoiceDetails?.toState)}
                margin="mt-4 w-full"
                name="state"
                label="State"
                inputRef={register({
                  required: false
                })}
              />
              <TextInput
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromPostalCode, getInvoiceDetails?.toPostalCode)}
                margin="mt-4 w-full"
                name="postalCode"
                label="Zip / Postal Code"
                inputRef={register({
                  required: false
                })}
              />
            </div>
            <div className="mt-4">
              <SelectCountryAutoComplete
                defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromCountry, getInvoiceDetails?.toCountry)}
                setSelectedValue={onSelectingCountry}
                label="Country"
                name="country"
                variant="filled"
              />
            </div>

            {type === INVOICE_TYPE.TO && (
              <>
                <XSText className="mt-8 mb-4" title="Contact details" />
                <div className="flex md:flex-row flex-col mb-4 gap-4 w-full">
                  <TextInput
                    defaultValue={setCustomDefaultValue(
                      getInvoiceDetails?.fromFirstName,
                      getInvoiceDetails?.toFirstName
                    )}
                    textFieldContainerClass="w-full"
                    name="firstName"
                    label="First name"
                    inputRef={register({
                      required:
                        watchAllFields.companyName ||
                        setCustomDefaultValue(getInvoiceDetails?.fromCompanyName, getInvoiceDetails?.toCompanyName)
                          ? false
                          : true
                    })}
                    error={errors.firstName && !watchAllFields.companyName ? true : false}
                    helperText={
                      errors?.firstName &&
                      !watchAllFields.companyName && (
                        <ValidationMessage
                          title={defaultValidationMessage('First name or else provide a valid Company name')}
                        />
                      )
                    }
                  />
                  <TextInput
                    defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromLastName, getInvoiceDetails?.toLastName)}
                    textFieldContainerClass="w-full"
                    name="lastName"
                    label="Last name"
                    inputRef={register({
                      required: false
                    })}
                    error={errors.lastName ? true : false}
                    helperText={errors?.lastName && <ValidationMessage title={defaultValidationMessage('Last name')} />}
                  />
                </div>
                <TextInput
                  defaultValue={setCustomDefaultValue(getInvoiceDetails?.fromEmail, getInvoiceDetails?.toEmail)}
                  name="email"
                  label="Email Address"
                  inputRef={register({
                    required: false,
                    pattern: {
                      value: emailRegex,
                      message: 'Please fill in a valid Email Address'
                    }
                  })}
                  error={errors.email ? true : false}
                  helperText={errors.email && <ValidationMessage title={defaultValidationMessage('Email address')} />}
                />
              </>
            )}
          </div>
          <div className="flex flex-col justify-between w-64">
            <UploadImage
              saveImage={saveImage}
              selectedImage={selectedImage}
              uploadError={uploadError}
              fileSelection={fileSelection}
            />
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <PrimaryButton
            id="from-and-to-save-btn"
            className="invoice-primary-button"
            caption="Save & Continue"
            onClick={handleSubmit(onSubmit)}
          />
          <PrimaryButton
            id="from-and-to-cancel-btn"
            className="invoice-white-primary-button border border-gray-400"
            bgColor="bg-white"
            color="text-gray-300"
            caption="Cancel"
            onClick={handleCloseModal}
          />
        </div>
      </form>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    updateCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.updateCompanyDetails(companyDetailsPayload)),
    createCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.createCompanyDetails(companyDetailsPayload)),
    uploadCompanyImage: (uploadedImage) => dispatch(commonReducerActions.fileUpload(uploadedImage))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFrom);

InvoiceFrom.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  closeDynamicModal: PropTypes.func,
  type: PropTypes.func,
  uploadCompanyImage: PropTypes.func,
  handleOpen: PropTypes.func,
  companyDetails: PropTypes.any,
  accountSettingDetails: PropTypes.func,
  createCompanyDetails: PropTypes.func,
  updateCompanyDetails: PropTypes.func
};
