import React, { useState } from 'react';
// import IconPencil from '../../../images/icons/company-incorporation/edit-pencil-icon.svg';
import InvoiceTrash from '../../../images/icons/Invoice/invoice-trash.svg';
import TrashIcon from '../../../images/icons/trash-icon.svg';
import '../../../styles/cards.scss';
import PropTypes from 'prop-types';
import { CONSTANT_NUMBER, OWNER_DIRECTOR_TYPES } from '../../../constants/enum';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Avatar from '../../ui/Avatar';

export default function OwnersCard({
  firstName,
  lastName,
  companyName,
  type,
  deleteCard,
  id,
  setIsOwnerDirectorEdit,
  isYourSelf
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const deleteAndClose = (id) => {
    deleteCard(id);
    handleClose();
  };

  /* will un-comment when we implement this feature  */

  // const editOwnerDirector = () => {
  //   setIsOwnerDirectorEdit(true);
  //   setStep(CONSTANT_NUMBER.EIGHT);
  //   setPayload({
  //     directors: directors,
  //     // cardCode: cardCode,
  //     cardType: cardType
  //   });
  // };

  const avatarText = firstName ? firstName?.charAt(0) + lastName?.charAt(0) : companyName?.charAt(0);
  return (
    <div className="owners-card">
      <div className="flex justify-between p-4">
        <div className="flex gap-4">
          <Avatar avatarName={avatarText} />
          <div>
            <XSText
              fontWeight="text-bold mb-2 capitalize"
              title={firstName && lastName ? firstName + ' ' + lastName : companyName}
            />
            <div className="flex gap-2">
              <MSText className="card-type-text flex items-center" title={isYourSelf} />
              {type === OWNER_DIRECTOR_TYPES.OWNER ? (
                <MSText
                  className="role-owner flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Owner"
                />
              ) : null}
              {type === OWNER_DIRECTOR_TYPES.DIRECTOR ? (
                <MSText
                  className="role-director flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Director"
                />
              ) : null}
              {type === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR ? (
                <div className="flex gap-2">
                  <MSText
                    className="role-owner flex items-center justify-center px-2 py-1"
                    fontWeight="text-bold"
                    title="Owner"
                  />
                  <MSText
                    className="role-director flex items-center justify-center px-2 py-1"
                    fontWeight="text-bold"
                    title="Director"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="cursor-pointer mr-4" onClick={() => setOpen(true)}>
            <img src={InvoiceTrash} alt="bin" className="w-4 h-4" />
          </div>
          {/* will un-comment when we implement this feature  */}

          {/* <div className="cursor-pointer" onClick={() => editOwnerDirector()}> 
            <img src={IconPencil} alt={'Pencil'} className="w-4 h-4" />
          </div> */}
        </div>
      </div>
      <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
          <DialogContent>
            <div className="flex mb-6">
              <img src={TrashIcon} alt="IconBin" className="w-12 h-12 mr-4" />
              <MDSubtitleText
                className="md:whitespace-pre-line"
                fontWeight="text-bold"
                title={`Are you sure you want to \ndelete this ${firstName}?`}
              />
            </div>

            <div className="flex flex-col md:flex-row items-center md:items-start md:gap-6 gap-2">
              <PrimaryButton
                className="capitalize w-32 md:my-4 onboarding-button"
                bgColor="bg-white"
                color="text-coral-500"
                fontSize="text-base"
                caption="Cancel"
                onClick={handleClose}
              />
              <PrimaryButton
                className="capitalize w-32 md:my-4 onboarding-button"
                fontSize="text-base"
                caption="Delete"
                onClick={() => deleteAndClose(id)}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

OwnersCard.propTypes = {
  directors: PropTypes.bool,
  setPayload: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
  type: PropTypes.string,
  deleteCard: PropTypes.func,
  cardCode: PropTypes.string,
  cardType: PropTypes.string,
  relationshipType: PropTypes.string
};
