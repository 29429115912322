import React from 'react';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XSText from '../../ui/typography/XSText';
import GetStartedSideBannerImg from '../../../images/graphics/getpaid/getpaid-listing.png';
import { PATH_PAGE } from '../../../routes/paths';

export default function ContactStartedBanner() {
  return (
    <div className="pl-8 bg-green-200 rounded-2xl whitespace-pre-line flex md:flex-row flex-col overflow-hidden h-full">
      <div className="md:w-7/12 w-full pt-8 flex md:flex-col flex-row justify-between">
        <div>
          <LGSubtitleText
            className="w-9/12"
            textWeight="text-bold"
            title={`Get started by\n creating your first\n invoice`}
          />
          <XSText
            className="mt-4 mb-8"
            title={
              'Simplify your invoicing process by creating\n and sending invoices to your clients.\n\nKeep track of your invoices and easily\n manage payments to ensure your invoices\n get paid on time.'
            }
          />
        </div>
        <PrimaryButton
          id="contact-started-banner-create-invoice-btn"
          className="mb-11"
          bgColor="bg-green-500"
          textColor="text-white"
          isBorderRequired="false"
          fontSize="text-lg"
          fontWeight="text-bold"
          caption="Create your first invoice"
          urlLink={PATH_PAGE.createInvoice}
        />
      </div>
      <div className="md:mt-6 md:w-5/12 w-full relative">
        <div className="green-background-shadow-effect md:block hidden"></div>
        <div className="image-z-index">
          <img
            className="contact-invoice-image contact-client-invoice"
            src={GetStartedSideBannerImg}
            alt="GetStartedSideBannerImg"
          />
        </div>
      </div>
    </div>
  );
}
