import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import CorporateRow from '../../../sections/@company/CorporateRow';
import ViewCorporateDetails from '../common/ViewCorporateDetails';
import { downloadImage } from '../../../helper/helpers';
import { STATUS_COLORS } from '../../../constants/enum';

function CorporateShareHoldersTable({ corporateShareHolders }) {
  const { page, rowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [corporateShareholder, setCorporateShareholder] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const handleViewShareHolder = (data) => {
    setCorporateShareholder(data);
    setOpen(true);
  };

  const isNotFound = tableData?.length === 0;

  useEffect(() => {
    if (corporateShareHolders) {
      setTableData(corporateShareHolders);
    } else {
      setTableData([]);
    }
  }, [corporateShareHolders]);

  return (
    <>
      {tableData?.length > 0 && (
        <Card
          sx={{
            '&.MuiPaper-root': {
              position: 'unset',
              boxShadow: 'none',
              border: `1px solid ${STATUS_COLORS.GREY}`
            }
          }}
        >
          <div className="m-6">
            <BaseText
              title="Corporate shareholders(s)"
              fontSize="text-lg"
              textColor="text-blue-100"
              fontWeight="text-bold"
            />
          </div>
          <Divider />
          <Scrollbar>
            <TableContainer sx={{ position: 'relative' }}>
              <Table>
                <TableBody>
                  {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <>
                      <CorporateRow
                        row={row}
                        handleViewAction={() => handleViewShareHolder(row)}
                        handleDownloadAction={() => downloadImage(row?.certificateDocument?.documentUrl)}
                      />
                    </>
                  ))}

                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                  <TableNoData isNotFound={isNotFound} title="There are no corporate shareholder(s) at the moment" />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      )}
      <ViewCorporateDetails open={open} shareholderData={corporateShareholder} closeModal={closeModal} />
    </>
  );
}

export default CorporateShareHoldersTable;

CorporateShareHoldersTable.propTypes = {};
