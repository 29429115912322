import React from 'react';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';

function InfoCard({ className, icon, alt, title, description, alignItems, fontWeight, height, width, fontSize }) {
  return (
    <div className={`${className} bg-green-200 rounded-lg p-4 flex ${alignItems}`}>
      <img src={icon} alt={alt} loading="lazy" width={width} height={height} className="w-8 h-8 " />
      <div className="ml-4">
        <BaseText fontSize={fontSize} className="whitespace-pre-line" title={title} fontWeight={fontWeight} />
        {description ? (
          <BaseText className="mt-2 whitespace-pre-line" title={description} fontWeight="text-regular" />
        ) : null}
      </div>
    </div>
  );
}

export default InfoCard;

InfoCard.defaultProps = {
  alignItems: 'items-start',
  fontWeight: 'text-bold'
};

InfoCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  alignItems: PropTypes.string,
  fontWeight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string
};
