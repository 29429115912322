import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RegistrationSuccessPage from '../components/companyIncorporation/companyLastStep/RegistrationSuccessPage';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import * as CompanyAction from '../components/companyIncorporation/reduxStore/action';

function CompanyIncorporationSuccess({ getCompanyDetails }) {
  async function companyInformation() {
    await getCompanyDetails(sessionStorage.getItem('companyId'));
  }

  useEffect(() => {
    companyInformation();
  }, []);
  return (
    <div className="max-w-7xl layout-container  pt-4 mx-auto bg-customBg-secondaryBg">
      <PublicHeaderNavigation hideSignInButton={true} />
      <div className="mt-10 bg-white rounded-lg initial-wrapper-container">
        <RegistrationSuccessPage />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(CompanyIncorporationSuccess);
