import * as actionTypes from './actionTypes';

let initialState = {
  signupDetails: null,
  companySignupDetails: null,
  loading: false,
  error: false
};

const SignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        signupDetails: action.signupPayload,
        loading: false,
        error: false
      };
    case actionTypes.SIGNUP_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.COMPANY_SIGNUP_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.COMPANY_SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        companySignupDetails: action.companySignupPayload,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_SIGNUP_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default SignupReducer;
