import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SelectInputAutoComplete from '../../components/ui/inputs/selectInputAutoComplete';
import { API_CODE } from '../../constants/enum';
import * as commonAction from '../commonReduxStore/reduxStore/action';
import PropTypes from 'prop-types';

function SelectCountryAutoComplete({ getCountry, label, name, defaultValue, setSelectedValue, variant, disabled }) {
  const [getCountryList, setCountryList] = useState([]);
  const [error, setError] = useState(false);
  const getCountryDetails = async () => {
    const getCountryResponse = await getCountry();
    if (getCountryResponse?.status === API_CODE.STATUS_200) {
      setCountryList(getCountryResponse?.data);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    getCountryDetails();
  }, []);

  return (
    <div>
      <SelectInputAutoComplete
        name={name}
        variant={variant}
        defaultValue={defaultValue}
        setSelectedValue={setSelectedValue}
        disableClearable={true}
        label={label}
        options={getCountryList}
        disabled={disabled}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCountry: (countryData) => dispatch(commonAction.getCountry(countryData))
  };
};

export default connect(null, mapDispatchToProps)(SelectCountryAutoComplete);

SelectCountryAutoComplete.propTypes = {
  getCountry: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  setSelectedValue: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool
};
