/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderNavigation from './Navigation/HeaderNavigation';
import '../styles/layout.scss';
import FooterLogo from '../images/icons/footer-statrys-logo.svg';
import StatrysSupport from './common/statrysSupport/StatrysSupport';
import NavigationBar from './sideBar/NavigationBar';
import { getUser } from './authService/auth';
import Banner from '../components/common/Banner';
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isIncorporation = getUser()?.isIncorporation;

  return (
    <>
      <Banner />

      {isIncorporation ? (
        <div>
          <NavigationBar children={children} />
        </div>
      ) : (
        <div>
          <div className="max-w-7xl layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
            <HeaderNavigation />
            <main id="main-container" className="py-14">
              {children}
            </main>
            <footer className="pb-5">
              <div className="flex justify-center">
                <img src={FooterLogo} alt={FooterLogo} />
              </div>
              <StatrysSupport />
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
