import React from 'react';
import PropTypes from 'prop-types';

function XSText({ title, className, textColor, textAlign, fontWeight, lineHeight }) {
  return (
    <div className={`${className}`}>
      <p className={`${fontWeight} text-base ${textColor} ${textAlign} letter-spacing-p ${lineHeight}`}>{title}</p>
    </div>
  );
}

export default XSText;

XSText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string
};

XSText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-regular',
  lineHeight: 'leading-4'
};
