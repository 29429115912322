import React, { useEffect, useState } from 'react';
import MailRoomDetails from '../mailRoom/MailRoomDetails';
import CompanySecretaryTable from './CompanySecretaryTable';

function CompanySecretaryView({
  selectedRow,
  selectedFolder,
  secretaryDetails,
  viewSelectedRow,
  isViewDetails,
  companyId
}) {
  const [selectedViewRow, setSelectedViewRow] = useState();
  const [viewSelectedRowData, setViewSelectedRowData] = useState();
  const [selectedViewFolder, setSelectedViewFolder] = useState();
  const [isViewRowDetails, setIsViewRowDetails] = useState(false);

  const handleViewTableRow = (id, index, rowData) => {
    setSelectedViewRow(id);
    setSelectedViewFolder(index);
    setViewSelectedRowData(rowData);
  };

  useEffect(() => {
    setSelectedViewRow(selectedRow);
    setViewSelectedRowData(viewSelectedRow);
    setSelectedViewFolder(selectedFolder);
    setIsViewRowDetails(isViewDetails);
  }, [selectedRow, viewSelectedRow, selectedFolder, isViewRowDetails]);

  return (
    <div className="flex">
      <div className="company-view-container mr-4">
        <CompanySecretaryTable
          isView={true}
          handeSelectedRow={selectedViewRow}
          selectedFolderIndex={selectedViewFolder}
          secretaryDetails={secretaryDetails}
          handleViewTableRow={handleViewTableRow}
          isViewRowDetails={isViewRowDetails}
          companyId={companyId}
        />
      </div>
      <MailRoomDetails
        viewdata={viewSelectedRowData}
        companyId={companyId}
        documentURLQuery={`secretaryDocId=${selectedViewRow}`}
      />
    </div>
  );
}

export default CompanySecretaryView;
