import * as actionTypes from './actionTypes';

let initialState = {
  confirmEmailDetails: {},
  resendCodeDetails: {},
  loading: false,
  error: false
};

const ConfirmEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONFIRM_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CONFIRM_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        confirmEmailDetails: action.confirmEmailPayload,
        loading: false,
        error: false
      };
    case actionTypes.CONFIRM_EMAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.RESEND_CODE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.RESEND_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        resendCodeDetails: action.resendCodeData,
        loading: false,
        error: false
      };
    case actionTypes.RESEND_CODE_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
export default ConfirmEmailReducer;
