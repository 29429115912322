import React from 'react';

const Plus = ({ fill }) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.995 3.44999L15.05 5.50499C15.6245 6.05231 15.648 6.96123 15.1025 7.53749L8.35248 14.2875C8.10801 14.5299 7.78758 14.6809 7.44498 14.715L4.31748 15H4.24998C4.05063 15.0011 3.85902 14.9229 3.71748 14.7825C3.55926 14.6249 3.47953 14.4049 3.49998 14.1825L3.82248 11.055C3.85661 10.7124 4.00756 10.392 4.24998 10.1475L11 3.39749C11.5819 2.9059 12.4398 2.92848 12.995 3.44999ZM10.49 5.99999L12.5 8.00999L14 6.54749L11.9525 4.49999L10.49 5.99999Z"
        fill={fill}
      />
    </svg>
  );
};
export default Plus;

Plus.defaultProps = {
  fill: 'white'
};
