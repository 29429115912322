import * as React from "react"
import FormGroup from "@mui/material/FormGroup"
import Checkbox from "@mui/material/Checkbox"
import PropTypes from "prop-types"
import { MUI_COLORS } from "../../constants/enum"

export default function CustomisedCheckbox({ isChecked, handleChange }) {
  return (
    <FormGroup>
      <Checkbox
        checked={isChecked}
        onChange={handleChange}
        sx={{
          color: MUI_COLORS.CORAL,
          "&.Mui-checked": {
            color: MUI_COLORS.CORAL,
          },
        }}
      />
    </FormGroup>
  )
}

CustomisedCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
}
