import React from "react"

const Logout = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1355_57078)">
        <path
          d="M13.5 12.5V13.5C13.5 13.7652 13.3947 14.0196 13.2071 14.2071C13.0196 14.3947 12.7652 14.5 12.5 14.5H9.5"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 2.5H12.5C12.7652 2.5 13.0196 2.60536 13.2071 2.79289C13.3947 2.98043 13.5 3.23479 13.5 3.5V4.5"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.92667 15.4937L0.926693 14.667C0.807167 14.6493 0.698093 14.5889 0.619657 14.4969C0.541219 14.405 0.498723 14.2878 0.500029 14.167V2.4803C0.501945 2.36769 0.541829 2.25901 0.613217 2.17189C0.684607 2.08477 0.78332 2.02431 0.89336 2.0003L6.89333 0.5203C6.9662 0.504392 7.04167 0.50498 7.11427 0.522018C7.18687 0.539056 7.25473 0.572115 7.31293 0.618773C7.37107 0.665432 7.41807 0.724505 7.45047 0.791672C7.4828 0.858845 7.49973 0.932406 7.5 1.00697V15.007C7.5 15.0785 7.4844 15.1492 7.4544 15.2141C7.42433 15.2791 7.38047 15.3367 7.32593 15.3829C7.2714 15.4293 7.20747 15.4631 7.13853 15.4823C7.06953 15.5013 6.99727 15.5053 6.92667 15.4937Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 6.5L15.5 8.5H10"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 10.5L15.5 8.5"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.91669 8.22949C4.77861 8.22949 4.66669 8.11756 4.66669 7.97949C4.66669 7.84143 4.77861 7.72949 4.91669 7.72949"
          stroke="#E15741"
        />
        <path
          d="M4.91669 8.22949C5.05476 8.22949 5.16669 8.11756 5.16669 7.97949C5.16669 7.84143 5.05476 7.72949 4.91669 7.72949"
          stroke="#E15741"
        />
      </g>
      <defs>
        <clipPath id="clip0_1355_57078">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default Logout
