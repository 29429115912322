import React, { useEffect, useState } from 'react';
import ViewInvoice from '../components/common/PublicViewInvoice';
import PublicInvoiceDetails from '../components/publicInvoice/PublicInvoiceDetails';
import '../styles/pages/public-invoice.scss';
import '../styles/layout.scss';
import * as invoiceActions from '../components/invoice/reduxStore/action';
import { connect } from 'react-redux';
import { API_CODE, INVOICE_STATUS } from '../constants/enum';
import { useLocation } from '@reach/router';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import * as publicInvoiceActions from '../components/publicInvoice/reduxStore/action';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import Seo from '../components/seo';
import SeoData from '../data/Seo-data.json';
import DraftPublicInvoice from '../components/publicInvoice/DraftPublicInvoice';

function PublicInvoice({ getPublicInvoiceDetails, sendEmail }) {
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    publicInvoiceDetails();
  }, []);
  const publicInvoiceDetails = async (onMarkPay) => {
    const query = new URLSearchParams(location?.search);
    const invoiceId = query.get('id');
    const getPublicInvoiceDetailsResponse = await getPublicInvoiceDetails(invoiceId);

    if (getPublicInvoiceDetailsResponse?.status === API_CODE.STATUS_200) {
      setInvoiceDetails(getPublicInvoiceDetailsResponse?.data?.data);
      setIsLoading(false);
      if (!onMarkPay && getPublicInvoiceDetailsResponse?.data?.data?.invoiceNumber) {
        publicInvoiceEmailHandler(getPublicInvoiceDetailsResponse?.data?.data);
      }
    }
  };

  const publicInvoiceEmailHandler = async (invoiceData) => {
    const requestPayload = {
      invoiceNumber: invoiceData?.invoiceNumber,
      companyName: invoiceData?.contact?.companyName,
      publicURL: `${process.env.SITE_URL}/public?id=${invoiceData?._id}`,
      toEmail: invoiceData?.companyDetails?.email,
      invoiceId: invoiceData?._id
    };
    await sendEmail(requestPayload);
  };
  return (
    <>
      {isLoading ? (
        <div className="w-full mt-64">
          <div className="flex justify-center items-center h-screen">
            <StatrysLoader />
          </div>
        </div>
      ) : (
        <div className="max-w-7xl layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
          <PublicHeaderNavigation />
          {invoiceDetails?.status === INVOICE_STATUS.INVOICE_DRAFT ? (
            <DraftPublicInvoice />
          ) : (
            <>
              {invoiceDetails && (
                <>
                  <div className="flex py-14 lg:flex-row flex-col gap-10">
                    <ViewInvoice isInternalUserView={false} getInvoiceDetails={invoiceDetails} />
                    <PublicInvoiceDetails
                      getInvoiceDetails={invoiceDetails}
                      publicInvoiceDetails={(onMarkPay) => publicInvoiceDetails(onMarkPay)}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className="text-gray-450 text-sm">
            <p className="text-bold"> DISCLAIMER </p>
            This invoice contain information provided solely by the sender and may include logos, payment details, or
            other proprietary data. Recipients are advised to exercise caution and verify any information contained
            herein before taking any action based on it. Statrys Limited expressly denies any and all liability arising
            out of or in connection with the content of this email and the attached invoice. The sender bears full
            responsibility for the content and any consequences arising from its use or dissemination.
          </div>
        </div>
      )}
    </>
  );
}
export const Head = () => (
  <Seo title={SeoData?.public?.title} description={SeoData?.public?.description} image={SeoData?.public?.image} />
);
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicInvoiceDetails: (invoiceId) => dispatch(invoiceActions.getPublicInvoiceDetails(invoiceId)),
    sendEmail: (invoiceData) => dispatch(publicInvoiceActions.sendEmail(invoiceData))
  };
};

export default connect(null, mapDispatchToProps)(PublicInvoice);
