import React, { useEffect, useState } from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XXSText from '../../ui/typography/XXSText';
import NormalStepper from '../Steppers/NormalStepper';
import BulbIcon from '../../../images/icons/bulb.svg';
import MSText from '../../ui/typography/MSText';
import EmployeeWithLaptop from '../../../images/graphics/company-incorporation/employee-with-laptop.png';
import { EXTERNAL_LINKS, MUI_COLORS, REGISTERED_COUNTRY } from '../../../constants/enum';
import { PopupButton, useCalendlyEventListener } from 'react-calendly';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../../routes/paths';
import { connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';

const StepSuccessPage = ({ completedStep, currentStep, companyAllInformation, updateCompanyIncorporation }) => {
  const verifyEmailSteps = [
    {
      number: 1,
      name: 'Account created',
      isCompleted: true
    },
    {
      number: 2,
      name: 'Fill in your company information',
      isCompleted: true
    },
    {
      number: 3,
      name: 'Add Owners and directors',
      isCompleted: true
    },
    {
      number: 4,
      name: 'Confirm and sign',
      isCompleted: true
    }
  ];

  const [root, setRoot] = useState('');
  useEffect(() => {
    const rootElement = document.getElementById('main-container');
    setRoot(rootElement);
  }, []);

  const onBookedCall = async () => {
    let payload = {
      companyId: companyAllInformation?._id,
      bookedCall: true
    };
    const response = await updateCompanyIncorporation(payload);
    if (response?.status === API_CODE?.STATUS_200) {
      navigate(PATH_PAGE.companyIncorporationSuccess);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: (e) => onBookedCall()
  });
  return (
    <>
      <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full">
        <div className="md:w-1/2 w-full">
          <LGSubtitleText title="Great! Last step" textWeight="text-medium" />
          <XXSText title="Your information has been processed successfully." className="mt-4" />
          <XXSText
            title={
              <div>
                <span className="text-bold">Book a call with us </span>to verify your identity and confirm some
                information about your future company before we can submit the application.
              </div>
            }
            className="mt-6"
          />
          <NormalStepper completedStep={completedStep} currentStep={currentStep} verifyEmailSteps={verifyEmailSteps} />
        </div>

        <div className="md:w-1/2 w-full flex justify-center">
          <img src={EmployeeWithLaptop} alt="let's start company" width="368" className="object-contain" />
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-2">
        {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG ? (
          <PopupButton
            url={EXTERNAL_LINKS.HONG_KONG_CALENDAR}
            rootElement={root}
            text="Book a call"
            textColor={MUI_COLORS.WHITE}
            color={MUI_COLORS.GREEN}
            className="calendly-btn"
          />
        ) : (
          <PopupButton
            url={EXTERNAL_LINKS.SINGAPORE_CALENDAR}
            rootElement={root}
            text="Book a call"
            textColor={MUI_COLORS.WHITE}
            color={MUI_COLORS.GREEN}
            className="calendly-btn"
          />
        )}
        <div className="bg-yellow-300 p-4 flex items-center info-guid-note-container rounded-lg">
          <img src={BulbIcon} alt="check icon" className="h-6 mr-4" />
          <MSText title="Please have your passport on hand during the call." />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(StepSuccessPage);
