import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import MSText from '../typography/MSText';
import InputAdornment from '@mui/material/InputAdornment';
import Plus from '../../../images/icons/customSvg/plus';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Avatar from '../Avatar';
import { MUI_COLORS } from '../../../constants/enum';

const filter = createFilterOptions();
export default function SelectInputAutoComplete({
  label,
  value,
  defaultValue,
  options,
  setSelectedValue,
  multiple,
  disabled,
  inputRef,
  error,
  helperText,
  addNewOption,
  onClose
}) {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <React.Fragment>
      <Autocomplete
        multiple={multiple}
        onClose={onClose}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              addNewOption(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            addNewOption(newValue.inputValue);
          } else {
            setSelectedValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              companyName: `Add "${params.inputValue}"`
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.companyName;
        }}
        renderOption={(props, option) => {
          return (
            <div className="flex gap-4 items-center" {...props}>
              <div className="ml-2 mr-4">
                {!option?.inputValue && (
                  <Avatar avatarImage={option?.profileImage} avatarName={option?.companyName?.charAt(0)} />
                )}
              </div>
              <div>
                <MSText title={<div className="text-bold py-3">{option?.companyName}</div>} />
              </div>
            </div>
          );
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ width: '100%' }}
        freeSolo
        renderInput={(params) => (
          <TextField
            value={value}
            {...params}
            label={!value && label}
            sx={
              value && {
                '& .MuiInputBase-input': {
                  marginTop: '5px',
                  marginBottom: '5px'
                },
                '& .MuiInputAdornment-outlined': {
                  marginTop: '0px'
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: '8px 6px 7px 5px'
                }
              }
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: inputValue && value && (
                <>
                  <InputAdornment position="start">
                    <div className="flex gap-4 items-center">
                      <div className="ml-2 mr-4">
                        <Avatar
                          avatarImage={value?.profileImage}
                          avatarName={
                            inputValue?.split(', ')[1] ? inputValue?.split(', ')[1]?.charAt(0) : inputValue?.charAt(0)
                          }
                        />
                      </div>
                    </div>
                  </InputAdornment>
                </>
              )
            }}
            inputRef={inputRef}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </React.Fragment>
  );
}

SelectInputAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedValue: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  hideBorder: PropTypes.bool,
  onClose: PropTypes.bool,
  disableClearable: PropTypes.bool
};

SelectInputAutoComplete.defaultProps = {
  multiple: false,
  disabled: false,
  hideBorder: false,
  disableClearable: false
};
