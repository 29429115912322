import React from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TextInput from '../ui/inputs/TextInput';
import XSText from '../ui/typography/XSText';
import StudyLightIcon from '../../images/icons/green-study-light1.svg';
import { Switch } from '@mui/material';
import MSText from '../ui/typography/MSText';
import { defaultValidationMessage } from '../../helper/helpers';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as userActions from '../invoice/reduxStore/action';
import PropTypes from 'prop-types';
import { EXTERNAL_PAYMENT_LINKS } from '../../constants/enum';

function PaypalDetails({ invoiceData, getInvoiceDetails, handleSelectPaymentDetails, setStep }) {
  const { register, handleSubmit, errors } = useForm();
  const [isQrCodeRequired, setIsQrCodeRequired] = React.useState(
    getInvoiceDetails?.paymentDetails?.paypalDetails?.showQrCode
      ? getInvoiceDetails?.paymentDetails?.paypalDetails?.showQrCode
      : true
  );
  const onSubmitPaypalDetails = (data) => {
    invoiceData({
      paymentDetails: {
        ...getInvoiceDetails.paymentDetails,
        paypalDetails: {
          ...getInvoiceDetails?.paymentDetails?.paypalDetails,
          payPalLink: data.paypalLink,
          showQrCode: isQrCodeRequired
        }
      }
    });
    handleSelectPaymentDetails();
  };
  const handleChange = (event) => {
    setIsQrCodeRequired(event.target.checked);
  };

  return (
    <div>
      <form id="paypal-details-form" onSubmit={handleSubmit(onSubmitPaypalDetails)}>
        <TextInput
          defaultValue={
            getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink &&
            getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink
          }
          margin="w-full"
          name="paypalLink"
          label="Enter your PayPal.me username"
          startAdornment={EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}
          inputRef={register({
            required: true
          })}
          error={errors.paypalLink ? true : false}
          helperText={errors.paypalLink && <ValidationMessage title={defaultValidationMessage('PayPal link')} />}
        />
        <div className="flex mt-2 py-4 px-4 rounded-lg border border-solid border-gray-200 justify-between items-center">
          <MSText title="Show QR code on the invoice" />
          <Switch checked={isQrCodeRequired} onChange={handleChange} />
        </div>
        <div>
          <XSText
            className="mt-4 mb-6"
            title={
              <span className="flex bg-green-200 p-6 rounded-lg">
                <span className="mr-2">
                  <img src={StudyLightIcon} alt="StudyLight icon" />
                </span>
                <span>
                  <div>How to get your PayPal link?</div>
                  <div>
                    Go to{' '}
                    <a href="https://PayPal.me/" target="_blank">
                      PayPal.me
                    </a>{' '}
                    and click on create your PayPal.Me link.
                  </div>
                </span>
              </span>
            }
          />
        </div>
        <PrimaryButton
          id="paypal-details-add-to-invoice"
          onClick={handleSubmit(onSubmitPaypalDetails)}
          className="modal-save-button mt-4 mr-4"
          caption="Add to invoice"
          fontWeight="text-bold"
          type="small"
        />
        <PrimaryButton
          id="paypal-details-cancel-btn"
          onClick={handleSelectPaymentDetails}
          bgColor="bg-white"
          className="modal-cancel-button mt-4"
          color="text-gray-300"
          caption="Cancel"
          fontWeight="text-bold"
          type="small"
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalDetails);

PaypalDetails.propTypes = {
  invoiceData: PropTypes.func,
  handleSelectPaymentDetails: PropTypes.func
};
