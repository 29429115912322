import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import IconPartyConfetti from '../../../images/icons/party-confetti.svg';

export default function WelcomeToStatrys() {
  return (
    <div div className="modal-step-container">
      <MDSubtitleText fontWeight="text-bold" title="Welcome to Statrys Invoicing!" />
      <XSText
        className="mb-8 mt-4"
        title="Let’s begin setting up your company so you can start creating and sending invoices in no time!"
      />
      <div>
        <div className="flex gap-4 items-center py-4">
          <div className="w-8 h-8 bg-green-200 text-green-500 text-lg rounded-full flex justify-center items-center">
            1
          </div>
          <XSText title="Complete your company information" />
        </div>
        <div class="border-t border-gray-400"></div>
        <div className="flex gap-4 items-center py-4">
          <div className="w-8 h-8 bg-green-200 text-green-500 text-lg rounded-full flex justify-center items-center">
            2
          </div>
          <XSText title="Add your logo" />
        </div>
        <div class="border-t border-gray-400"></div>
        <div className="flex gap-4 items-center py-4">
          <div className="w-8 h-8 bg-green-200 text-green-500 text-lg rounded-full flex justify-center items-center">
            <img src={IconPartyConfetti} alt="IconPartyConfetti" />
          </div>
          <XSText title="Start creating invoices!" />
        </div>
      </div>
    </div>
  );
}
