import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ArrowDown from '../../../images/icons/arrow-down-menu.svg';
import XMSText from './XMSText';
import { IMPORTING_INVOICE, PATH_PAGE } from '../../../routes/paths';
import { getURLFirstPathName } from '../../../helper/helpers';

function MenuText({ title, urlLink, withDropDown, linkClassName, imgClassName, isNew, otherClass, location }) {
  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (urlFirstPathName === linkFirstPath) {
      return true;
    } else if (
      (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
      urlLink === PATH_PAGE.pay
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`${handleActiveTab(urlLink) ? 'active' : null}`}>
      {urlLink ? (
        <Link
          to={urlLink}
          className={`menu-text  ${handleActiveTab(urlLink) ? 'active' : null} ${
            handleActiveTab(urlLink) ? 'active text-blue-300' : null
          } ${linkClassName} ${otherClass}`}
        >
          {title}

          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
          {isNew && (
            <div className="bg-coral-500 rounded-lg ml-2">
              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
            </div>
          )}
        </Link>
      ) : (
        <Link
          to={urlLink}
          className={`menu-text ${handleActiveTab(urlLink) ? 'active' : null} ${linkClassName} ${otherClass}`}
        >
          {title}

          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
          {isNew && (
            <div className="bg-coral-500 rounded-lg ml-2">
              <XMSText title="New" textColor="text-white" className="px-2 py-1" />
            </div>
          )}
        </Link>
      )}
    </div>
  );
}

export default MenuText;

MenuText.propTypes = {
  title: PropTypes.string,
  urlLink: PropTypes.string,
  withDropDown: PropTypes.bool,
  imgClassName: PropTypes.bool,
  linkClassName: PropTypes.bool,
  isNew: PropTypes.bool,
  otherClass: PropTypes.string
};

MenuText.defaultProps = {
  withDropDown: false
};
