import React, { useState } from 'react';
import { COMPANY_SETUP_STEP, CONSTANT_NUMBER } from '../../../constants/enum';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import OwnersCard from '../common/OwnersDirectorsCard';
import { connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';

function OwnerAndDirectorList({
  prevStep,
  setStep,
  setIsAddingOwner,
  setSubStep,
  setIsOwnerDirectorEdit,
  stackHolderPayload,
  updateCompanyIncorporation,
  companyAllInformation,
  setCurrentStackHolder,
  setStackHolderPayload
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreOwnerClicked, setNoMoreOwnerClicked] = useState(false);

  const handleOwnerBackButton = () => {
    prevStep();
  };

  const addOwnerHandler = () => {
    setCurrentStackHolder({});
    setIsOwnerDirectorEdit(false);
    setIsAddingOwner();
    setSubStep(CONSTANT_NUMBER.TWO);
    setStep(CONSTANT_NUMBER.EIGHT);
  };

  const handleNoMoreDirector = async () => {
    for (let i = 0; i < stackHolderPayload.length; i++) {
      stackHolderPayload[i].ownership = '';
      stackHolderPayload[i].shares = 0;
      stackHolderPayload[i].totalInvestment = 0;
    }
    let finalPayload = {
      stakeholder: stackHolderPayload,
      companyId: companyAllInformation?._id,
      completedStep: COMPANY_SETUP_STEP.OWNER_AND_DIRECTOR_LIST
    };
    const response = await updateCompanyIncorporation(finalPayload);
    if (response?.status === API_CODE?.STATUS_200) {
      setStep(CONSTANT_NUMBER.FOURTEEN);
      setSubStep(CONSTANT_NUMBER.THREE);
    }
  };

  const handleNoMoreOwner = async () => {
    setNoMoreOwnerClicked(true);
  };

  const deleteOwner = (id) => {
    const deleteStackHoler = stackHolderPayload.filter((holder) => holder?.id !== id);
    setStackHolderPayload([...deleteStackHoler]);
  };

  return (
    <div className="flex flex-col w-full h-full justify-between">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center document-list-loader">
            <StatrysLoader />
          </div>
        ) : (
          <>
            <div className="md:w-4/6 w-full md:py-8 py-4">
              {stackHolderPayload &&
                stackHolderPayload?.map((ownerDirectorData, index) => {
                  return (
                    <div key={ownerDirectorData.code} className="mb-2">
                      <OwnersCard
                        firstName={ownerDirectorData.firstName}
                        lastName={ownerDirectorData.lastName}
                        type={ownerDirectorData?.stackHolderType}
                        id={ownerDirectorData?.id}
                        deleteCard={(value) => deleteOwner(value)}
                        setIsOwnerDirectorEdit={setIsOwnerDirectorEdit}
                        setStep={setStep}
                        isYourSelf={index === 0 ? 'Yourself' : 'Individual'}
                      />
                    </div>
                  );
                })}
              {noMoreOwnerClicked ? (
                <PrimaryButton
                  caption="Add director"
                  bgColor="bg-white"
                  color="text-coral-500"
                  className="mr-2 owner-and-directorList"
                  onClick={addOwnerHandler}
                />
              ) : (
                <PrimaryButton
                  caption="Add owner"
                  bgColor="bg-white"
                  color="text-coral-500"
                  className="mr-2 owner-and-directorList"
                  onClick={addOwnerHandler}
                />
              )}
              {noMoreOwnerClicked ? (
                <PrimaryButton
                  caption="No more directors"
                  className="owner-and-directorList md:mt-0 mt-2"
                  color="text-white"
                  onClick={handleNoMoreDirector}
                />
              ) : (
                <PrimaryButton
                  caption="No more owners"
                  className="owner-and-directorList md:mt-0 mt-2"
                  color="text-white"
                  onClick={handleNoMoreOwner}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex justify-between">
        <BackButton onClick={handleOwnerBackButton} />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(OwnerAndDirectorList);
