import React, { useEffect, useState } from 'react';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import '../../styles/headerNavigation.scss';
import { Link } from 'gatsby';
import MSText from '../ui/typography/MSText';
import OutlineDownArrow from '../../images/icons/outlineDownArrow.svg';
import { getSvgIcon } from '../../helper/svgHelper';
import { API_CODE, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { slide as Menu } from 'react-burger-menu';
import BaseText from '../../components/ui/typography/BaseText';
import NavigationDropDown from './NavigationDropDown';
import XSText from '../../components/ui/typography/XSText';
import { logOut } from '../authService/auth';
import { INVOICE_DASHBOARD, INVOICE_LIST, PATH_PAGE } from '../../routes/paths';
import Avatar from '../ui/Avatar';
import { getUser } from '../../components/authService/auth';
import { connect } from 'react-redux';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import MenuText from '../../components/ui/typography/MenuText';
import { stringTruncate } from '../../helper/helpers';
import { useLocation } from '@reach/router';

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.profile
  },
  {
    pageName: 'Account Settings',
    icon: getSvgIcon(SVG_TYPES.SETTING),
    to: PATH_PAGE.accountSettings
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut
  }
];

function HeaderNavigation({ getProfile, getUserProfile, getCompanyInfo, getCompanyDetails }) {
  const [profileData, setProfileData] = useState({});
  const [companyFormdata, setCompanyFormdata] = useState();
  const user = getUser();
  const location = useLocation();

  const navigation = [
    {
      id: 1,
      pageName: 'Dashboard',
      to: INVOICE_DASHBOARD
    },
    {
      id: 2,
      pageName: 'Get paid',
      to: INVOICE_LIST
    },
    {
      id: 3,
      pageName: 'Pay',
      to: PATH_PAGE.pay
    },
    {
      id: 4,
      pageName: 'Contacts',
      to: PATH_PAGE.contact
    }
  ];

  useEffect(() => {
    getUserProfileInfo();
    getCompanyDetailsHandler();
  }, []);

  const getCompanyDetailsHandler = async () => {
    await getCompanyDetails();
  };

  const getUserProfileInfo = async () => {
    await getUserProfile();
  };

  useEffect(() => {
    setProfileData(getProfile);
    setCompanyFormdata(getCompanyInfo);
  }, [getProfile, getCompanyInfo]);

  const mobileViewMenuOptions = navigation.concat(profileOptions);
  const handleDisplayEmail = () => {
    if (user?.email) {
      return stringTruncate(user?.email, STRING_MAX_CHAR.TWENTY_EIGHT);
    } else {
      return stringTruncate(profileData?.email, STRING_MAX_CHAR.TWENTY_EIGHT);
    }
  };
  const firstName = profileData?.firstName ? profileData?.firstName : handleDisplayEmail();
  const lastName = profileData?.firstName ? profileData?.lastName : '';
  const fullName = firstName + ' ' + lastName;

  return (
    <div>
      <div className="handburger-menu">
        <Menu>
          <div className="sticky top-0 bg-white h-16">
            <div className="flex flex-row justify-between ml-2 items-center pt-4 ">
              <Link className="flex hover:no-underline " to={PATH_PAGE.dashboard}>
                <img src={StatrysLogo} alt="Statrys Logo" />
              </Link>
            </div>
          </div>
          {mobileViewMenuOptions?.map((menuItem, index) => {
            return (
              <div className="mt-6 expand-section ml-2" key={index}>
                <div>
                  <Link className="hover:no-underline flex flex-row cursor-pointer mobile-menu-items" to={menuItem.to}>
                    <div className="py-2 pl-2">{menuItem?.icon}</div>
                    <BaseText
                      title={menuItem?.pageName}
                      fontWeight="text-semibold"
                      textColor="text-gray-700"
                      className="px-2 py-2"
                    />
                  </Link>
                </div>
              </div>
            );
          })}
        </Menu>
      </div>
      <div className="md:static flex flex-row justify-between header-navigation items-center mb-4">
        <div className="flex items-center">
          <div className="md:ml-0 ml-14">
            <Link className="flex hover:no-underline" to={PATH_PAGE.dashboard}>
              <img src={StatrysLogo} alt="Statrys Logo" />
            </Link>
          </div>
          <div className="flex">
            <div className="menu-text-container align-center flex gap pt-1 ml-16">
              {navigation?.map((menuItem, index) => {
                return (
                  <MenuText
                    location={location}
                    title={menuItem?.pageName}
                    withDropDown={true}
                    linkClassName="menu-text-link"
                    urlLink={menuItem?.to}
                    imgClassName="hidden"
                    otherClass="text-lg hover:text-blue-300 text-gray-450"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center item-menu-dropdown dropdown">
          <div className="flex flex-row gap-2 items-center cursor-pointer">
            <div className="rounded-full">
              <Avatar
                avatarImage={companyFormdata?.logo}
                avatarName={
                  companyFormdata?.companyName ? companyFormdata?.companyName?.charAt(0) : user?.email?.charAt(0)
                }
              />
            </div>
            <div>
              {companyFormdata?.companyName && (
                <MSText
                  title={stringTruncate(companyFormdata?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                  fontWeight="text-bold"
                />
              )}
              <MSText title={handleDisplayEmail()} className="menu-item-text" textColor="text-gray-450" />
            </div>
            <div>
              <img src={OutlineDownArrow} alt={OutlineDownArrow} />
            </div>
          </div>
          <NavigationDropDown
            isNavigation={false}
            menuItems={profileOptions}
            needSeparator={true}
            separtorPositions={[0, 2]}
          >
            <div className="mt-4 px-4">
              <XSText
                title={
                  profileData?.lastName && profileData?.firstName
                    ? stringTruncate(fullName, STRING_MAX_CHAR.TWENTY_NINE)
                    : ''
                }
                fontWeight="text-bold"
                lineHeight="leading-6"
              />
              <MSText
                title={handleDisplayEmail()}
                textColor="text-gray-450"
                className="dropDown-email-text whitespace-nowrap overflow-hidden"
              />
            </div>
          </NavigationDropDown>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data,
    getCompanyInfo: state?.accountSettings?.getCompanyDetails?.data?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavigation);
