import * as actionTypes from './actionTypes';

let initialState = {
  loading: false,
  error: false,

  companyProfileDetails: {},
  companyDocuments: {},
  companyStackHolders: {},
  companyLatestUpdate: {},
  companySecretary: {},
  companySecretaryShare:{},
  companyMailroomShare:{}
};

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        companyProfileDetails: action.allCompanyDetails,
        loading: false,
        error: false
      };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        companyDocuments: action.companyDocumentDetails,
        loading: false,
        error: false
      };
    case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        companyLatestUpdate: action.latestUpdatesDetails,
        loading: false,
        error: false
      };
    case actionTypes.GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_SUCCESS:
      return {
        ...state,
        companyStackHolders: action.stackHoldersDetails,
        loading: false,
        error: false
      };
    case actionTypes.GET_COMPANY_STACK_HOLDERS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_COMPANY_SECRETARY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_SECRETARY_REQUEST_SUCCESS:
      return {
        ...state,
        companySecretary: action.secretaryDetails,
        loading: false,
        error: false
      };
    case actionTypes.GET_COMPANY_SECRETARY_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.COMPANY_MAILROOM_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.COMPANY_MAILROOM_REQUEST_SUCCESS:
      return {
        ...state,
        companyMailroomShare: action.mailroomShare,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_MAILROOM_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.COMPANY_SECRETARY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.COMPANY_SECRETARY_REQUEST_SUCCESS:
      return {
        ...state,
        companySecretaryShare: action.secretaryShare,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_SECRETARY_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default CompanyReducer;
