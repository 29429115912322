import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { convertDateToDDMMYYYY } from '../../helper/helpers';
import { STATUS_COLORS } from '../../constants/enum';

LatestUpdateTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onApproveRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handleToShowLatestData: PropTypes.func
};

export default function LatestUpdateTableRow({ row, selected, handleToShowLatestData }) {
  const theme = useTheme();

  const { documentName, Identity, uploadedDate } = row;

  return (
    <TableRow
      hover
      selected={selected}
      onClick={handleToShowLatestData}
      sx={{ borderBottom: `1px solid ${STATUS_COLORS.GREY}`, '&:last-child td, &:last-child th': { borderBottom: 0 } }}
    >
      <TableCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '16px'
        }}
        className="cursor-pointer"
      >
        {documentName}
      </TableCell>

      <TableCell className="cursor-pointer" align="right">
        {Identity?.map((status) => (
          <span className="ml-2">
            <CompanyStatus requiredCompanyStatus={status} />
          </span>
        ))}
      </TableCell>

      <TableCell className="cursor-pointer" align="right" sx={{ fontSize: '15px' }}>
        {convertDateToDDMMYYYY(uploadedDate)}
      </TableCell>
    </TableRow>
  );
}
