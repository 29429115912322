export const UPDATE_COMPANY_DETAILS_REQUEST_START = 'UPDATE_COMPANY_DETAILS_REQUEST_START';
export const UPDATE_COMPANY_DETAILS_REQUEST_FAIL = 'UPDATE_COMPANY_DETAILS_REQUEST_FAIL';
export const UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS = 'UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS';

export const CREATE_COMPANY_DETAILS_REQUEST_START = 'CREATE_COMPANY_DETAILS_REQUEST_START';
export const CREATE_COMPANY_DETAILS_REQUEST_FAIL = 'CREATE_COMPANY_DETAILS_REQUEST_FAIL';
export const CREATE_COMPANY_DETAILS_REQUEST_SUCCESS = 'CREATE_COMPANY_DETAILS_REQUEST_SUCCESS';

export const GET_COMPANY_DETAILS_REQUEST_START = 'GET_COMPANY_DETAILS_REQUEST_START';
export const GET_COMPANY_DETAILS_REQUEST_FAIL = 'GET_COMPANY_DETAILS_REQUEST_FAIL';
export const GET_COMPANY_DETAILS_REQUEST_SUCCESS = 'GET_COMPANY_DETAILS_REQUEST_SUCCESS';
