import React from "react"

export default function CopyClipboard() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#E15741" />
      <path
        d="M27.75 31.5H18.75C18.4848 31.5 18.2304 31.3946 18.0429 31.2071C17.8554 31.0196 17.75 30.7652 17.75 30.5V19.75"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.2498 28.106C30.2572 28.4233 30.1386 28.7305 29.9199 28.9605C29.7013 29.1906 29.4004 29.3246 29.0832 29.3333H20.9165C20.5993 29.3246 20.2984 29.1906 20.0798 28.9605C19.8611 28.7305 19.7425 28.4233 19.7498 28.106V17.7273C19.7425 17.4101 19.8611 17.1028 20.0798 16.8728C20.2984 16.6428 20.5993 16.5087 20.9165 16.5H26.8498C27.0046 16.5008 27.1576 16.533 27.2994 16.5948C27.4413 16.6566 27.5692 16.7466 27.6752 16.8593L29.9078 19.2087C30.1285 19.4432 30.2509 19.7533 30.2498 20.0753V28.106Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
