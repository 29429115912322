import React from "react"

const MultiplleUser = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1355_56175)">
        <path
          d="M3.88672 5.5C4.71515 5.5 5.38672 4.82843 5.38672 4C5.38672 3.17157 4.71515 2.5 3.88672 2.5C3.05829 2.5 2.38672 3.17157 2.38672 4C2.38672 4.82843 3.05829 5.5 3.88672 5.5Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.88672 6.5C3.22368 6.5 2.58779 6.76339 2.11895 7.23223C1.65011 7.70107 1.38672 8.33696 1.38672 9V10.5H2.38672L2.88672 14.5H4.88672"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8867 5.5C14.7151 5.5 15.3867 4.82843 15.3867 4C15.3867 3.17157 14.7151 2.5 13.8867 2.5C13.0583 2.5 12.3867 3.17157 12.3867 4C12.3867 4.82843 13.0583 5.5 13.8867 5.5Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8867 6.5C14.5498 6.5 15.1856 6.76339 15.6545 7.23223C16.1233 7.70107 16.3867 8.33696 16.3867 9V10.5H15.3867L14.8867 14.5H12.8867"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.88672 4.5C9.99129 4.5 10.8867 3.60457 10.8867 2.5C10.8867 1.39543 9.99129 0.5 8.88672 0.5C7.78215 0.5 6.88672 1.39543 6.88672 2.5C6.88672 3.60457 7.78215 4.5 8.88672 4.5Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3867 9C12.3867 8.07174 12.018 7.1815 11.3616 6.52513C10.7052 5.86875 9.81498 5.5 8.88672 5.5C7.95846 5.5 7.06822 5.86875 6.41184 6.52513C5.75547 7.1815 5.38672 8.07174 5.38672 9V10.5H6.88672L7.38672 15.5H10.3867L10.8867 10.5H12.3867V9Z"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1355_56175">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.886719)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default MultiplleUser
