/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import InvoiceImage from '../static/invoice.png';

function Seo({ description, title, children, image, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;
  const defaultUrl = url || site.siteMetadata.siteUrl;
  const defaultImage = image || site.siteMetadata.image || InvoiceImage;

  return (
    <>
      <title>{defaultTitle ? `${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" content={defaultTitle} />
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="artical" />
      <meta property="og:image" itemprop="image" content={defaultImage} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={defaultTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </>
  );
}

export default Seo;
