import React, { useState } from 'react';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import VerifyOtpInput from '../otp/verifyOtpInput';
import Countdown, { zeroPad } from 'react-countdown';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import CompanyOtpInput from '../otp/CompanyOtpInput';
function VerifyEmailForm({ email, fromForgotPassword, emailForConfirmation, fromResendCode }) {
  const [resendLink, setResendLink] = useState(false);
  const [timeUp, setTimeUp] = useState(false);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeUp(true);
      setResendLink(true);
      return <div></div>;
    } else {
      return (
        <span className={`${timeUp ? 'hidden' : 'block'} text-gray-400 ml-1 -mt-1`}>
          ({zeroPad(minutes)}:{zeroPad(seconds)})
        </span>
      );
    }
  };
  const reSendOtp = () => {
    navigate(PATH_PAGE?.confirmEmailAccount, {
      state: {
        email: email,
        path: 'resend-code'
      }
    });
  };

  return (
    <div className="flex items-center company-signup-layout">
      <div className="bg-white rounded-md company-verify-email-container p-8 flex flex-col justify-between">
        <MDSubtitleText
          className="md:whitespace-pre-line"
          fontWeight="text-bold"
          title={`Verify your e-mail account`}
        />
        <div>
          <XSText
            title={
              <div>
                A 6-digit code has been sent to{' '}
                <span className="text-bold">{emailForConfirmation ? emailForConfirmation : email}</span>
              </div>
            }
            className="mt-4"
          />
          <XSText title="Enter the code to verify your e-mail." className="mt-4" />
          <div className="mt-6">
            <CompanyOtpInput
              fromForgotPassword={fromForgotPassword}
              fromResendCode={fromResendCode}
              emailForConfirmation={emailForConfirmation}
            />
          </div>
        </div>

        <div className="mt-6 flex flex-col">
          <XSText title="Did not receive the code?" />
          <div className="flex mt-1">
            <div onClick={() => (resendLink ? reSendOtp() : null)}>
              <XSText
                className={resendLink ? 'cursor-pointer' : null}
                textColor={resendLink ? 'text-coral-500' : 'text-gray-400'}
                title="Resend the code"
              />
            </div>
            {<Countdown date={Date.now() + 60000 * 3} renderer={renderer} />}
            <XSText className="mx-1" title="or" />
            <Link to="/contact">
              <XSText textColor="text-coral-500" title="Contact us" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state?.signupReducer?.companySignupDetails?.data?.email
  };
};

export default connect(mapStateToProps, null)(VerifyEmailForm);
VerifyEmailForm.propTypes = {
  email: PropTypes.string,
  fromForgotPassword: PropTypes.bool,
  emailForConfirmation: PropTypes.string,
  fromResendCode: PropTypes.bool
};

VerifyEmailForm.defaultProps = {
  fromForgotPassword: false
};
