import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Profile from '../../components/company/Profile';
import PropTypes from 'prop-types';
import * as CompanyDetailsActions from '../../components/company/reduxStore/action';
import { API_CODE, COMPANY_STATUS, PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import StatrysLoader from '../../components/ui/loaders/StatrysLoader';

function CompanyProfile({ companyStackHoldersDetails, companyProfileDetails, companyLatestUpdatesDetails }) {
  const [stackHolderDetails, setStackHolderDetails] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [latestUpdatesDetails, setLatestUpdatesDetails] = useState([]);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isStackHolderLoading, setIsStackHolderLoading] = useState(false);
  const [isLatestUpdatesLoading, setIsLatestUpdatesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState();

  const companyRegistartion = async () => {
    setIsProfileLoading(true);
    setIsLoading(true);
    const companyProfileResponse = await companyProfileDetails();
    if (companyProfileResponse?.status === API_CODE.STATUS_200) {
      setProfileDetails(companyProfileResponse?.data?.data);
      setCompanyId(companyProfileResponse?.data?.data?._id);
      setIsProfileLoading(false);
      setIsLoading(false);
    }
  };

  const companyLatestUpdates = async () => {
    setIsLatestUpdatesLoading(true);
    const companyLatestUpdatesResponse = await companyLatestUpdatesDetails();
    if (companyLatestUpdatesResponse?.status === API_CODE.STATUS_200) {
      setLatestUpdatesDetails(companyLatestUpdatesResponse?.data?.data[0]?.documents);
      setIsLatestUpdatesLoading(false);
    }
  };

  const companyStackHolder = async () => {
    setIsStackHolderLoading(true);
    const companyStackHolderResponse = await companyStackHoldersDetails();
    if (companyStackHolderResponse?.status === API_CODE.STATUS_200) {
      setStackHolderDetails(companyStackHolderResponse?.data?.data[0]?.stakeholder);
      setIsStackHolderLoading(false);
    }
  };

  const handleToShowLatestData = (rowData) => {
    if (rowData?.Identity[0] === COMPANY_STATUS.COMPANY_SECRETARY) {
      navigate(PATH_PAGE.companyDocuments, {
        state: {
          isCompanySecretaryView: true,
          id: rowData?._id,
          companySecretaryData: rowData
        }
      });
    }
    if (rowData?.Identity[0] === COMPANY_STATUS.MAIL) {
      navigate(PATH_PAGE.companyMailRoom, {
        state: {
          isView: true,
          id: rowData?._id,
          mailRoomData: rowData
        }
      });
    }
  };

  useEffect(() => {
    companyStackHolder();
    companyRegistartion();
    companyLatestUpdates();
  }, []);
  return (
    <Layout>
      <Page title="Company">
        <HeaderBreadcrumbs
          sx={{ height: '56px', display: 'flex', alignItems: 'center', marginBottom: '32px' }}
          heading={PAGE_TITLE.COMPANY_PROFILE}
          links={[{ name: '' }]}
        />

        {isLoading ? (
          <div className="mt-10">
            <StatrysLoader />
          </div>
        ) : (
          <div className="w-full">
            <Profile
              stackHolderDetails={stackHolderDetails}
              profileDetails={profileDetails}
              latestUpdatesDetails={latestUpdatesDetails}
              isLoading={isProfileLoading}
              isLatestUpdatesLoading={isLatestUpdatesLoading}
              isStackHolderLoading={isStackHolderLoading}
              handleToShowLatestData={(value) => handleToShowLatestData(value)}
            />
          </div>
        )}
      </Page>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    companyStackHoldersDetails: () => dispatch(CompanyDetailsActions.companyStackHoldersDetails()),
    companyProfileDetails: () => dispatch(CompanyDetailsActions.companyProfileDetails()),
    companyLatestUpdatesDetails: () => dispatch(CompanyDetailsActions.companyLatestUpdatesDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanyProfile);

CompanyProfile.propTypes = {
  companyStackHoldersDetails: PropTypes.func,
  companyProfileDetails: PropTypes.func,
  companyLatestUpdatesDetails: PropTypes.func
};
