import React, { useEffect } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { DialogActions, DialogContentText } from '@mui/material';

function CustomizedDialogs({
  children,
  openDynamicModal,
  closeDynamicModal,
  maxWidth,
  minWidth,
  scrollType,
  title,
  action
}) {
  const [open, setOpen] = React.useState(openDynamicModal);

  const handleClose = () => {
    setOpen(false);
    closeDynamicModal();
  };
  useEffect(() => {
    setOpen(openDynamicModal);
  }, [openDynamicModal]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      scroll={scrollType}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={minWidth}
      maxWidth={maxWidth}
    >
      <MuiDialogTitle id="scroll-dialog-title">{title}</MuiDialogTitle>
      <MuiDialogContent dividers={scrollType === 'paper'}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {children}
        </DialogContentText>
      </MuiDialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>{action}</DialogActions>
    </MuiDialog>
  );
}

export default CustomizedDialogs;

CustomizedDialogs.propTypes = {
  children: PropTypes.any,
  openDynamicModal: PropTypes.func,
  closeDynamicModal: PropTypes.func,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  scrollType: PropTypes.string,
  title: PropTypes.object,
  action: PropTypes.object
};

CustomizedDialogs.defaultProps = {
  scrollType: 'body'
};
