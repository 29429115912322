import React, { useEffect, useState } from 'react';
import {
  Card,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider
} from '@mui/material';
import useTable, { getComparator } from '../../../hooks/useTable';
import PropTypes from 'prop-types';
import BaseText from '../../ui/typography/BaseText';
import { STATUS_COLORS } from '../../../constants/enum';
import Iconify from '../../Iconify';
import CompanySecretaryRow from '../../../sections/@company/CompanySecretaryRow';
import FolderIcon from '../../../images/icons/company/folder-icon.svg';
import OpenFolderIcon from '../../../images/icons/company/open-folder-icon.svg';
import MSText from '../../ui/typography/MSText';
import CompanySecretaryView from './CompanySecretaryView';
import { ExpandMore } from '@mui/icons-material';
import EmptyContent from '../../EmptyContent';
import ShareDocumentByLink from '../common/ShareDocumentByLink';

const TABLE_HEAD = [
  { id: 'title', label: 'Folder name', align: 'left' },
  { id: 'date', label: 'Last modified', align: 'right' },
  { id: '', label: '', width: '190px' }
];

function CompanySecretaryTable({
  isView,
  handeSelectedRow,
  selectedFolderIndex,
  secretaryDetails,
  handleViewTableRow,
  isViewRowDetails,
  companyId
}) {
  const { page, order, orderBy, setPage, rowsPerPage } = useTable({
    defaultOrderBy: 'folderName'
  });
  const [viewSelectedRow, setViewSelectedRow] = useState();
  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedFolder, setSelectedFolder] = useState();
  const [isExpand, setIsExpand] = useState(true);
  const [isSorting, setIsSorting] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    if (isExpand) {
      setExpanded(true);
      setIsExpand(false);
    } else {
      setExpanded(newExpanded ? panel : false);
      setIsExpand(false);
    }
  };

  const handleIconChange = () => {
    if (isSorting) {
      setIsSorting(false);
    } else {
      setIsSorting(true);
    }
  };

  const handleShareModal = (event, id) => {
    event.stopPropagation();
    setId(id);
    setOpen(true);
  };
  const handleViewDetails = (id, index, rowData) => {
    setSelectedRow(id);
    setSelectedFolder(index);
    setViewSelectedRow(rowData);
    setIsViewDetails(true);
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName
  });

  useEffect(() => {
    if (secretaryDetails) {
      setTableData(secretaryDetails);
    } else {
      setTableData([]);
    }
  }, [secretaryDetails]);

  useEffect(() => {
    setIsViewDetails(isViewRowDetails);

    if (handeSelectedRow) {
      setExpanded(selectedFolderIndex);
      setIsExpand(false);
      setSelectedRow(handeSelectedRow);
    }
  }, [handeSelectedRow, selectedFolderIndex]);

  return (
    <>
      {!isViewDetails ? (
        <Card
          sx={{
            '&.MuiPaper-root': {
              position: 'unset',
              boxShadow: 'none',
              border: !isView && `1px solid ${STATUS_COLORS.GREY}`
            }
          }}
        >
          <div className="flex justify-between m-6">
            <BaseText title="Company secretary" fontSize="text-lg" textColor="text-blue-100" fontWeight="text-bold" />
            {!isView && (
              <div>
                <TextField
                  fullWidth
                  value={filterName}
                  onChange={(event) => handleFilterName(event.target.value)}
                  sx={{
                    '& .MuiInputBase-input': {
                      marginTop: '0px',
                      padding: '8px'
                    },
                    '& .MuiInputAdornment-outlined': {
                      marginTop: '0px'
                    },
                    '& .MuiInputAdornment-outlined': {
                      marginTop: '0px'
                    }
                  }}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon={'eva:search-fill'}
                          sx={{ color: 'text.disabled', width: 20, height: 20, marginTop: '-1px' }}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            )}
          </div>
          {isView && <Divider />}

          {!isView && (
            <div className="flex rounded-lg mx-2 p-4 bg-gray-150 justify-between">
              <div className="flex" onClick={handleIconChange}>
                <MSText title="Folder name" className="mr-2" />
                {/* {isSorting ? <Iconify icon={'mdi:arrow-up'} /> : <Iconify icon={'mdi:arrow-down'} />} */}
              </div>
              <div className="flex align-heading-text">
                <MSText className="mr-2" title="Last modified" />
              </div>
            </div>
          )}
          {dataFiltered?.length === 0 ? (
            <EmptyContent title="No data found" description="Try refining your search" />
          ) : (
            <>
              {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <>
                  <Accordion
                    disableGutters={true}
                    square={true}
                    elevation={0}
                    expanded={!isView ? (isExpand ? expanded : expanded === index) : expanded === index}
                    onChange={handleChange(index)}
                    sx={{
                      '&.MuiAccordion-root': {
                        boxShadow: 'none',
                        borderRadius: '0px',
                        border: `none`
                      },
                      '&:not(:last-child)': {
                        borderBottom: 0
                      },
                      '&:before': {
                        display: 'none'
                      }
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      sx={{
                        '&.MuiButtonBase-root': {
                          minHeight: '56px',
                          paddingLeft: '24px',
                          paddingRight: '24px'
                        }
                      }}
                      id="panel1bh-header"
                      expandIcon={<ExpandMore />}
                    >
                      <div className="flex items-center">
                        <img
                          className="mr-5"
                          src={(isExpand ? expanded : expanded === index) ? OpenFolderIcon : FolderIcon}
                          alt="folder icon"
                        />
                        <MSText fontWeight="text-bold" title={row?.name} />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        '&.MuiAccordionDetails-root': {
                          padding: '0px'
                        }
                      }}
                    >
                      <div>
                        {row?.companySecretaryDocuments
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((file) => (
                            <>
                              <CompanySecretaryRow
                                isView={isView}
                                handleShareAction={(e) => handleShareModal(e, file?._id)}
                                handleViewAction={() => handleViewDetails(file?._id, index, file)}
                                handleViewTableRow={() => handleViewTableRow(file?._id, index, file)}
                                selectedRow={selectedRow}
                                row={file}
                              />
                            </>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              ))}
            </>
          )}
          <ShareDocumentByLink
            openModal={open}
            closeDynamicModal={() => setOpen(false)}
            id={companyId}
            documentURLQuery={`secretaryDocId=${id}`}
          />
        </Card>
      ) : (
        <CompanySecretaryView
          selectedFolder={selectedFolder}
          selectedRow={selectedRow}
          secretaryDetails={secretaryDetails}
          viewSelectedRow={viewSelectedRow}
          isViewRowDetails={isViewDetails}
          companyId={companyId}
        />
      )}
    </>
  );
}

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    const foundFiles = [];
    tableData?.forEach((folder) => {
      const companySecretaryDocuments = folder?.companySecretaryDocuments?.filter((file) => {
        if (file?.documentName?.toLowerCase()?.includes(filterName.toLowerCase())) {
          return file;
        }
      });
      if (companySecretaryDocuments?.length) {
        return foundFiles?.push({ ...folder, companySecretaryDocuments });
      }
    });
    tableData = foundFiles;
  }

  return tableData;
}

export default CompanySecretaryTable;

CompanySecretaryTable.propTypes = {};
