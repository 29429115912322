import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Profile from '../../components/company/Profile';
import PropTypes from 'prop-types';
import * as CompanyDetailsActions from '../../components/company/reduxStore/action';
import { API_CODE, COMPANY_STATUS, PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Organization from '../../components/company/organization/Organization';
import StatrysLoader from '../../components/ui/loaders/StatrysLoader';

function CompanyOrganization({ companyStackHoldersDetails, companyProfileDetails }) {
  const [stackHolderDetails, setStackHolderDetails] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isStackHolderLoading, setIsStackHolderLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const companyRegistartion = async () => {
    setIsProfileLoading(true);
    setIsLoading(true);
    const companyProfileResponse = await companyProfileDetails();
    if (companyProfileResponse?.status === API_CODE.STATUS_200) {
      setProfileDetails(companyProfileResponse?.data?.data);
      setIsProfileLoading(false);
      setIsLoading(false);
    }
  };

  const companyStackHolder = async () => {
    setIsStackHolderLoading(true);
    const companyStackHolderResponse = await companyStackHoldersDetails();
    if (companyStackHolderResponse?.status === API_CODE.STATUS_200) {
      setStackHolderDetails(companyStackHolderResponse?.data?.data[0]?.stakeholder);
      setIsStackHolderLoading(false);
    }
  };

  useEffect(() => {
    companyStackHolder();
    companyRegistartion();
  }, []);
  return (
    <Layout>
      <Page title="Company">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.COMPANY_ORGANIZATION}
          links={[{ name: '' }]}
          sx={{ height: '56px', display: 'flex', alignItems: 'center', marginBottom: '32px' }}
        />
        {isLoading ? (
          <div className="mt-10">
            <StatrysLoader />
          </div>
        ) : (
          <div className="w-full">
            <Organization
              profileDetails={profileDetails}
              stackHolderDetails={stackHolderDetails}
              isStackHolderLoading={isStackHolderLoading}
              isLoading={isProfileLoading}
            />
          </div>
        )}
      </Page>
    </Layout>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    companyStackHoldersDetails: () => dispatch(CompanyDetailsActions.companyStackHoldersDetails()),
    companyProfileDetails: () => dispatch(CompanyDetailsActions.companyProfileDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanyOrganization);

CompanyOrganization.propTypes = {
  companyStackHoldersDetails: PropTypes.func,
  companyProfileDetails: PropTypes.func
};
