import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as paymentActions from '../invoicePaymentDetails/reduxStore/action';
import * as invoiceActions from './reduxStore/action';
import MSText from '../ui/typography/MSText';
import XMSText from '../ui/typography/XMSText';
import XXSText from '../ui/typography/XXSText';
import QRcode from 'react-qr-code';
import Plus from '../../images/icons/customSvg/plus';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import InvoicePayment from './InvoicePayment';
import GreenCircleIdeaIcon from '../../images/icons/green-circle-idea-icon.svg';
import BankDetails from '../common/BankDetails';
import { EXTERNAL_PAYMENT_LINKS } from '../../constants/enum';

function InvoicePaymentDetails({
  getBankDetailsList,
  getInvoiceDetails,
  invoicePaymentDetails,
  invoiceData,
  isTemporary
}) {
  const [bankPaymentDetails, setBankPaymentDetails] = useState();
  const [openPaymentDetailModal, setOpenPaymentDetailModal] = useState(false);
  useEffect(() => {
    bankDetailsList();
  }, [getInvoiceDetails?.paymentDetails, invoicePaymentDetails?.editBankDetailsResponse]);

  const bankDetailsList = async () => {
    if (!isTemporary) {
      const bankDetailsList = await getBankDetailsList();
      const bankDetailsListWithSelection = bankDetailsList?.data?.data?.map((obj) => ({
        ...obj,
        isSelected: getInvoiceDetails?.paymentDetails?.bankDetails?.find((bankDetail) => bankDetail?._id === obj?._id)
          ? true
          : false
      }));
      setBankPaymentDetails(bankDetailsListWithSelection);
    } else {
      setBankPaymentDetails(getInvoiceDetails?.paymentDetails?.bankDetails);
    }
  };
  const paymentDetailsCaption = () => {
    if (
      getInvoiceDetails?.paymentDetails?.invoicePaypalDetails ||
      getInvoiceDetails?.paymentDetails?.invoiceHsbcDetails ||
      getInvoiceDetails?.paymentDetails?.bankDetails?.length
    ) {
      return 'Edit';
    } else return 'Add Payment details';
  };
  return (
    <>
      <InvoicePayment
        isTemporary={isTemporary}
        openDynamicModal={openPaymentDetailModal}
        closeDynamicModal={() => setOpenPaymentDetailModal(false)}
      />

      <div className="my-4 flex justify-between items-center">
        <XXSText textColor="text-gray-600" title="Payment details" />
        <PrimaryButton
          id="invoice-payment-details-add-btn"
          onClick={() => setOpenPaymentDetailModal(true)}
          caption={
            <div className="flex gap-2 items-center">
              <div className="w-6">
                <Plus />
              </div>
              <MSText textColor="text-white" fontWeight="text-bold" title={paymentDetailsCaption()} />
            </div>
          }
          fontWeight="text-bold"
          type="small"
        />
      </div>
      <BankDetails
        paymentDetails={{
          bankDetails: bankPaymentDetails?.filter((bankData) => bankData?.isSelected === true)
        }}
      />
      {getInvoiceDetails?.paymentDetails?.paypalDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              {getInvoiceDetails?.paymentDetails?.paypalDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink}`}
                  />
                </div>
              )}

              <div>
                <MSText className="mb-2 bank-details-column" title="Pay with PayPal" />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={` ${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${getInvoiceDetails?.paymentDetails?.paypalDetails?.payPalLink}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.hsbcDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              {getInvoiceDetails?.paymentDetails?.hsbcDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
                  />
                </div>
              )}

              <div>
                <MSText className="mb-2 bank-details-column" title="Pay with hsbc" />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.customPaymentDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              <div>
                <MSText
                  className="mb-2 bank-details-column"
                  title={getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLabel}
                />
                <MSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  title={getInvoiceDetails?.paymentDetails?.customPaymentDetails?.customLink}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {getInvoiceDetails?.paymentDetails?.customTextDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              <div>
                <MSText title={getInvoiceDetails?.paymentDetails?.customTextDetails?.customText} />
              </div>
            </div>
          </div>
        </div>
      )}
      {paymentDetailsCaption() !== 'Edit' && (
        <div>
          <XMSText
            className="mt-4 mb-6"
            title={
              <span className="flex bg-customBg-mainBg px-4 py-2 rounded-lg">
                <span className="mr-2">
                  <img src={GreenCircleIdeaIcon} alt="Green Circle" />
                </span>
                <span>Add your account details or payment links and get your invoice paid faster.</span>
              </span>
            }
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData,
    invoicePaymentDetails: state?.invoicePaymentDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBankDetailsList: () => dispatch(paymentActions.getBankDetailsList()),
    invoiceData: (invoiceData) => dispatch(invoiceActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentDetails);

InvoicePaymentDetails.propTypes = {};
