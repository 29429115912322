import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const getSupplierDetailsRequestStart = () => ({
  type: actionTypes.GET_SUPPLIER_DETAILS_REQUEST_START
});
export const getSupplierDetailsRequestFail = (error) => ({
  type: actionTypes.GET_SUPPLIER_DETAILS_REQUEST_FAIL,
  error: error
});
export const getSupplierDetailsRequestSuccess = (getSupplierDetails) => ({
  type: actionTypes.GET_SUPPLIER_DETAILS_REQUEST_SUCCESS,
  getSupplierDetails: getSupplierDetails
});
export const getSupplierDetails = (supplierId) => {
  return async (dispatch) => {
    try {
      dispatch(getSupplierDetailsRequestStart());
      let supplierDetailsResponse = await Api.get(`${ENDPOINTS.GET_SUPPLIER_BY_ID}?supplierId=${supplierId}`);
      dispatch(getSupplierDetailsRequestSuccess(supplierDetailsResponse));
      return supplierDetailsResponse;
    } catch (error) {
      dispatch(getSupplierDetailsRequestFail(error.response));
    }
  };
};

export const getSupplierListRequestStart = () => ({
  type: actionTypes.GET_SUPPLIER_LIST_REQUEST_START
});
export const getSupplierListRequestFail = (error) => ({
  type: actionTypes.GET_SUPPLIER_LIST_REQUEST_FAIL,
  error: error
});
export const getSupplierListRequestSuccess = (getSupplierList) => ({
  type: actionTypes.GET_SUPPLIER_LIST_REQUEST_SUCCESS,
  getSupplierList: getSupplierList
});
export const getSupplierList = () => {
  return async (dispatch) => {
    try {
      dispatch(getSupplierListRequestStart());
      let supplierListResponse = await Api.get(ENDPOINTS.GET_SUPPLIER_LIST);
      dispatch(getSupplierListRequestSuccess(supplierListResponse));
      return supplierListResponse;
    } catch (error) {
      dispatch(getSupplierListRequestFail(error.response));
    }
  };
};

export const createSupplierRequestStart = () => ({
  type: actionTypes.CREATE_SUPPLIER_REQUEST_START
});
export const createSupplierRequestFail = (error) => ({
  type: actionTypes.CREATE_SUPPLIER_REQUEST_FAIL,
  error: error
});
export const createSupplierRequestSuccess = (supplierPayload) => ({
  type: actionTypes.CREATE_SUPPLIER_REQUEST_SUCCESS,
  supplierPayload: supplierPayload
});
export const createSupplier = (supplierPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createSupplierRequestStart());
      let createdSupplierResponse = await Api.post(ENDPOINTS.CREATE_SUPPLIER, supplierPayload);
      dispatch(createSupplierRequestSuccess(createdSupplierResponse));
      return createdSupplierResponse;
    } catch (error) {
      dispatch(createSupplierRequestFail(error.response));
    }
  };
};

export const editSupplierRequestStart = () => ({
  type: actionTypes.EDIT_SUPPLIER_REQUEST_START
});
export const editSupplierRequestFail = (error) => ({
  type: actionTypes.EDIT_SUPPLIER_REQUEST_FAIL,
  error: error
});
export const editSupplierRequestSuccess = (editedSupplierResponse) => ({
  type: actionTypes.EDIT_SUPPLIER_REQUEST_SUCCESS,
  editedSupplierData: editedSupplierResponse
});
export const editSupplier = (importedInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(editSupplierRequestStart());
      let editedSupplierResponse = await Api.put(ENDPOINTS.UPDATE_SUPPLIER, importedInvoicePayload);
      dispatch(editSupplierRequestSuccess(editedSupplierResponse));
      return editedSupplierResponse;
    } catch (error) {
      dispatch(editSupplierRequestFail(error.response));
    }
  };
};

export const deleteSupplierRequestStart = () => ({
  type: actionTypes.DELETE_SUPPLIER_REQUEST_START
});
export const deleteSupplierRequestFail = (error) => ({
  type: actionTypes.DELETE_SUPPLIER_REQUEST_FAIL,
  error: error
});
export const deleteSupplierRequestSuccess = (supplierPayload) => ({
  type: actionTypes.DELETE_SUPPLIER_REQUEST_SUCCESS,
  supplierPayload: supplierPayload
});
export const deleteSupplier = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteSupplierRequestStart());
      let deletedSupplierResponse = await Api.delete(`${ENDPOINTS.DELETE_SUPPLIER}?supplierId=${id}`);
      dispatch(deleteSupplierRequestSuccess(deletedSupplierResponse));
      return deletedSupplierResponse;
    } catch (error) {
      dispatch(deleteSupplierRequestFail(error.response));
    }
  };
};
