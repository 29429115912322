import React from 'react';
import Layout from '../../components/layout';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Seo from '../../components/seo';
import { PATH_PAGE } from '../../routes/paths';
import CheckMark from '../../images/icons/customSvg/CheckMark';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import MSText from '../../components/ui/typography/MSText';
import EditImportedInvoiceForm from '../../components/importedInvoices/EditImportedInvoiceForm';
import { useSelector } from 'react-redux';

export default function EditImportingInvoice() {
  const isLoading = useSelector((state) => state.importingInvoiceReducer?.loading);

  return (
    <Layout>
      <Page title="Edit Importing Invoice">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.EDIT_IMPORTING_INVOICE}
          links={[{ name: PAGE_TITLE.GET_PAID, href: PATH_PAGE.pay }, { name: PAGE_TITLE.EDIT_IMPORTING_INVOICE }]}
          action={
            <div className="flex flex-row gap-2">
              <button positive form="edit-imported-invoice-form" type="submit">
                <PrimaryButton
                  id="edit-invoice-save-btn"
                  isLoading={isLoading}
                  caption={
                    <div className="flex gap-2 items-center">
                      <div className="w-6">
                        <CheckMark />
                      </div>
                      <MSText textColor="text-white" fontWeight="text-bold" title="Save" />
                    </div>
                  }
                  fontWeight="text-bold"
                  type="small"
                />
              </button>
            </div>
          }
        />
        <EditImportedInvoiceForm />
      </Page>
    </Layout>
  );
}

export const Head = () => (
  <Seo title="View Your Invoice" description="Create Your invoice by Register on our website" />
);
