import React from 'react';
import BaseText from '../ui/typography/BaseText';
import '../../styles/pages/dashboard.scss';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import Bullet from '../ui/Bullet';
import CloseIcon from '../../images/icons/gray-close-icon.svg';
import ArrowIcon from '../../images/icons/dashboard/arrow-left-side.svg';

const InvoiceHighlightsModal = ({
  openModal,
  closeModal,
  mainImage,
  contentdata,
  handleNextButton,
  handleBackButton,
  handleCloseButton,
  isBack,
  isNext
}) => {
  const handleCloseModal = () => {
    closeModal();
  };
  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="responsive-dialog-title"
        scroll="body"
        sx={{
          '&.MuiDialog-root': {
            '& .MuiDialogContent-root': {
              paddingTop: '0px'
            }
          },
          '& .MuiDialogContent-root': {
            padding: '0px',
            height: 'auto'
          }
        }}
      >
        <DialogContent>
          <div className="invoice-highlights-container">
            <div className="relative">
              <img src={mainImage} className="invoice-highlights-image-width" alt="invoice highlights image" />
              <div aria-label="close" onClick={handleCloseModal} className="absolute top-6 right-6">
                <img src={CloseIcon} alt="close icon" />
              </div>
            </div>

            <div className="px-8 pt-6 pb-8">
              <BaseText
                fontSize="text-xl"
                className="mb-4"
                textColor="text-gray-500"
                fontWeight="text-bold"
                title={contentdata?.heading}
              />
              <BaseText
                fontSize="text-base"
                textColor="text-gray-500"
                className="mb-2"
                title={contentdata?.subHeading}
              />
              {contentdata?.highlightedPoints.map((point) => (
                <div className="flex">
                  <Bullet />
                  <BaseText fontSize="text-base" textColor="text-gray-500" className="mb-2 mr-2" title={point?.text} />
                </div>
              ))}
              <div className={`${!isBack && isNext ? 'justify-end' : 'justify-between'} flex  mt-10`}>
                {isBack && (
                  <PrimaryButton
                    id="invoice-highlight-back-btn"
                    className="invoice-highlights-back-button"
                    fontWeight="text-bold"
                    bgColor="bg-white"
                    color="text-gray-500"
                    caption={
                      <div className="flex gap-1 text-medium items-center">
                        <img src={ArrowIcon} alt="back button icon" />
                        Back
                      </div>
                    }
                    onClick={handleBackButton}
                  />
                )}
                {isNext ? (
                  <PrimaryButton
                    id="invoice-highlight-next-btn"
                    className="invoice-highlights-button"
                    fontWeight="text-bold"
                    caption="Next"
                    onClick={handleNextButton}
                  />
                ) : (
                  <PrimaryButton
                    id="invoice-highlight-cancel-btn"
                    className="invoice-highlights-button"
                    fontWeight="text-bold"
                    caption="Close"
                    onClick={handleCloseButton}
                  />
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvoiceHighlightsModal;

InvoiceHighlightsModal.propTypes = {
  closeRedirectionModal: PropTypes.any,
  openRedirectionModal: PropTypes.bool,
  mainImage: PropTypes.string,
  contentdata: PropTypes.object
};
