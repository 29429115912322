import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import TextInput from '../../ui/inputs/TextInput';
import GreenStudyLightIcon from '../../../images/icons/bulb.svg';
import ValidationMessage from '../../ui/ValidationMessage';
export default function CompanyName({ register, errors, companyDetailsData }) {
  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText
        className="md:whitespace-pre-line my-6"
        fontWeight="text-bold"
        title={`Fill in your company details`}
      />
      <XSText title="Enter your legal business name and registration number" />
      <TextInput
        className="mt-2 w-full"
        name="companyName"
        inputRef={register({
          required: true
        })}
        defaultValue={companyDetailsData?.companyName}
        error={errors?.companyName}
        helperText={errors?.companyName && <ValidationMessage title="Please select a valid Business name" />}
        label="Business name"
      />
      <TextInput
        className="mt-2 w-full"
        name="registrationNumber"
        inputRef={register({
          required: false
        })}
        defaultValue={companyDetailsData?.registrationNumber}
        label="Registration number (Optional)"
      />
      <div className="flex gap-4 bg-green-200 p-4 items-start rounded-lg mt-2">
        <img src={GreenStudyLightIcon} alt="GreenStudyLightIcon" />
        <XSText title="The company name should be the legal business name. It will be used in your invoices and in all communications about Statrys Invoicing Software." />
      </div>
    </div>
  );
}
