import React, { useState } from 'react';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import DynamicModal from '../../ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Iconify from '../../Iconify';
import { checkValueExistOrNot, downloadImage, makeFirstLetterCapitalize } from '../../../helper/helpers';
import EmptyStageImg from '../../../images/icons/company/empty-stage-gray-cloud.svg';

function ViewDetailsModal({ open, closeModal, shareholderData }) {
  return (
    <div>
      <DynamicModal openDynamicModal={open} closeDynamicModal={closeModal} maxWidth="920px">
        <H4HeaderText title="View corporate shareholder" />
        <div className="flex mt-6">
          <div>
            <img
              src={
                shareholderData?.certificateDocument?.documentUrl
                  ? shareholderData?.certificateDocument?.documentUrl
                  : EmptyStageImg
              }
              alt="passport image"
              className="h-60 object-contain"
              width="364"
              height="240"
            />
            {shareholderData?.certificateDocument?.documentUrl && (
              <div className="flex justify-end mt-2">
                <PrimaryButton
                  id="view-corporate-pdf-download"
                  caption={
                    <div className={`flex justify-center gap-2 items-center`}>
                      <Iconify icon={'lucide:download-cloud'} />
                      <span>Download</span>
                    </div>
                  }
                  onClick={() => downloadImage(shareholderData?.certificateDocument?.documentUrl)}
                  type="small"
                  bgColor="bg-white"
                  className="company-white-button mb-2"
                />
              </div>
            )}
          </div>

          <div className="ml-8 w-64">
            <MSText textColor="text-gray-450 mb-2" title="Company name" />
            <XSText
              className="mb-4"
              title={checkValueExistOrNot(makeFirstLetterCapitalize(shareholderData?.companyName))}
            />
            <MSText textColor="text-gray-450 mb-2" title="Company chinese name" />
            <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.companyChineseName)} />
            <MSText textColor="text-gray-450 mb-2" title="Percentage of shares" />
            <XSText className="mb-4" title={`${checkValueExistOrNot(shareholderData?.percentageOfShares)}%`} />
            <MSText textColor="text-gray-450 mb-2" title="Country of registration" />
            <XSText className="mb-4 " title={checkValueExistOrNot(shareholderData?.countryOfRegistration?.name)} />
            <MSText textColor="text-gray-450 mb-2" title="Country of BR certificate number" />
            <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.brCertificateNumber)} />
          </div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default ViewDetailsModal;
