import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MSText from '../ui/typography/MSText';
import { connect } from 'react-redux';
import { INVOICE_STATUS } from '../../constants/enum';
import { useLocation } from '@reach/router';
import InvoiceStatusSelectAlertModal from './InvoiceStatusSelectAlertModal';

function NavigationDropDown({ menuItems, handleInvoiceDetailsbyID, getInvoiceDetails }) {
  const [onHover, setOnHover] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [alertMessageText, setAlertMessageText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  const handleToUpdateStatus = (menuItem) => {
    setSelectedMenu(menuItem);
    if (
      getInvoiceDetails?.invoiceStatus === INVOICE_STATUS.INVOICE_DRAFT &&
      menuItem?.name === INVOICE_STATUS.INVOICE_PAID
    ) {
      setAlertTitle('Are you sure you want to finalise this invoice and mark it as paid?');
      setAlertMessageText('You will need to enter payment details to complete this action.');
      setPaymentDetails(true);
      setIsOpen(true);
    } else if (
      getInvoiceDetails?.invoiceStatus === INVOICE_STATUS.INVOICE_PAID &&
      menuItem?.name === INVOICE_STATUS.INVOICE_UNPAID
    ) {
      setAlertTitle('Are you sure you want to mark this invoice as unpaid?');
      setAlertMessageText(
        'This action will remove any associated payment information. If you proceed, you will need to manually enter payment details again in the future.'
      );
      setIsOpen(true);
    } else if (
      (getInvoiceDetails?.invoiceStatus === INVOICE_STATUS.INVOICE_OVERDUE ||
        getInvoiceDetails?.invoiceStatus === INVOICE_STATUS.INVOICE_UNPAID ||
        getInvoiceDetails?.invoiceStatus === INVOICE_STATUS.INVOICE_PARTIAL_PAID) &&
      menuItem?.name === INVOICE_STATUS.INVOICE_PAID
    ) {
      setAlertTitle('Are you sure you want to finalise this invoice and mark it as paid?');
      setAlertMessageText('You will need to enter payment details to complete this action.');
      setPaymentDetails(true);
      setIsOpen(true);
    } else if (
      getInvoiceDetails?.invoiceStatus !== INVOICE_STATUS.INVOICE_DRAFT &&
      menuItem?.name === INVOICE_STATUS.INVOICE_DRAFT
    ) {
      setAlertTitle('Are you sure you want to mark this invoice as Draft?');
      setAlertMessageText('');
      setIsOpen(true);
    } else if (
      getInvoiceDetails?.invoiceStatus !== INVOICE_STATUS.INVOICE_UNPAID &&
      menuItem?.name === INVOICE_STATUS.INVOICE_UNPAID
    ) {
      setAlertTitle('Are you sure you want to mark this invoice as unpaid?');
      setAlertMessageText('');
      setIsOpen(true);
    }
  };

  const handleCloseStatusModal = () => {
    setPaymentDetails(false);
    setIsOpen(false);
  };

  return (
    <>
      <div className="status-selector-dropdown-container">
        <div className="status-selector-dropdown-content">
          <div className="status-selector-tooltip-container ">
            <div className="tooltip-icon"></div>
          </div>
          <div
            className="status-selector-dropdown-content-container rounded-xl flex flex-col p-2"
            onMouseLeave={() => setOnHover()}
          >
            {menuItems?.map((menu, index) => {
              return (
                <>
                  <div
                    onMouseEnter={() => setOnHover(index)}
                    onClick={() => handleToUpdateStatus(menu)}
                    className={`flex flex-row items-center rounded-lg p-3 ${index === onHover ? 'bg-gray-100 ' : ''}`}
                    key={index}
                  >
                    <div>{menu?.icon}</div>
                    <MSText title={menu?.name} textColor="text-gray-300" className="dropDown-email-text ml-2" />
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <InvoiceStatusSelectAlertModal
          paymentDetails={paymentDetails}
          getInvoiceDetails={getInvoiceDetails}
          openDynamicModal={isOpen}
          closeDynamicModal={() => handleCloseStatusModal()}
          title={alertTitle}
          invoiceId={id}
          selectedMenu={selectedMenu}
          handleInvoiceDetailsbyID={handleInvoiceDetailsbyID}
          message={alertMessageText}
        />
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(null, mapDispatchToProps)(NavigationDropDown);

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  editInvoice: PropTypes.func,
  invoiceDetailsByID: PropTypes.object,
  handleInvoiceDetailsbyID: PropTypes.func
};
