import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
import CloseIcon from '@mui/icons-material/Close';
export default function SelectInputAutoComplete({
  label,
  value,
  defaultValue,
  options,
  setSelectedValue,
  multiple,
  disabled,
  inputRef,
  error,
  helperText,
  onClose,
  disableClearable,
  id,
  backgroundColor,
  containerClass
}) {
  const [inputValue, setInputValue] = React.useState('');
  return (
    <div className={containerClass}>
      <Autocomplete
        multiple={multiple}
        disabled={disabled}
        value={value}
        getOptionLabel={(option) => (option.name ? option.name : option)}
        defaultValue={defaultValue}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id={id}
        options={options}
        style={{ width: '100%' }}
        onClose={onClose}
        disableClearable={disableClearable}
        ChipProps={{ deleteIcon: <CloseIcon /> }}
        renderInput={(params) => (
          <TextField
            value={value}
            {...params}
            label={label}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            inputRef={inputRef}
            error={error}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: backgroundColor
              },
              '& .MuiOutlinedInput-root': multiple && {
                padding: '26px 6px 7px 10px'
              },
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': !multiple
                ? {
                    padding: '18px 6px 7px 5px'
                  }
                : {
                    padding: '4px 6px 7px 5px'
                  },
              '& .MuiInputBase-input': {
                marginTop: '0px'
              },
              '& .MuiAutocomplete-tag': {
                marginTop: '8px',
                borderRadius: '8px',
                backgroundColor: MUI_COLORS?.PURPLE,
                color: MUI_COLORS?.WHITE,
                fontFamily: 'HKGrotesk-Bold'
              },
              '& .MuiChip-deleteIcon': {
                color: `${MUI_COLORS?.WHITE} !important`,
                width: '16px'
              },
              '& .MuiChip-deleteIcon:hover': {
                color: MUI_COLORS?.WHITE
              }
            }}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
}

SelectInputAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedValue: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onClose: PropTypes.bool,
  disableClearable: PropTypes.bool,
  backgroundColor: PropTypes.string,
  containerClass: PropTypes.string
};

SelectInputAutoComplete.defaultProps = {
  multiple: false,
  disabled: false,
  disableClearable: false,
  id: 'controllable-states-demo'
};
