import * as React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Checkbox, TableRow, TableCell, Typography, Stack, Link, MenuItem, Divider } from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { TableMoreMenu } from '../../../components/table';
import { fDate } from '../../../utils/formatTime';
import { CONTACT_TABLE_TITLE, INVOICE_STATUS } from '../../../constants/enum';
import { concatenateSelectedCurrency } from '../../../helper/helpers';
import { useLocation } from '@reach/router';
// ----------------------------------------------------------------------

ViewContactTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};

export default function ViewContactTableRow({ row, selected, onDuplicateRow, onViewRow, onDeleteRow, onEditRow }) {
  const theme = useTheme();
  const location = useLocation();
  const { status, invoiceNumber, createdAt, totalAmount, currency } = row;

  const [openMenu, setOpenMenuActions] = useState(null);
  const [contactType, setContactType] = useState('');

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  React.useEffect(() => {
    setContactType(location?.state?.contactType);
  }, []);

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack>
          <Typography variant="subtitle2" noWrap>
            {invoiceNumber}
          </Typography>
          <Link noWrap variant="body2" onClick={onViewRow} sx={{ color: 'text.disabled', cursor: 'pointer' }}>
            {createdAt && fDate(createdAt)}
          </Link>
        </Stack>
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'transparent' : ''}
          color={
            (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
            (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
            (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
            (status === INVOICE_STATUS.PARTIALLY_PAID && 'warning') ||
            'noBackground'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {totalAmount ? concatenateSelectedCurrency(currency, totalAmount) : '-'}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
          color={
            (status === INVOICE_STATUS.INVOICE_PAID && 'success') ||
            (status === INVOICE_STATUS.INVOICE_UNPAID && 'warning') ||
            (status === INVOICE_STATUS.INVOICE_OVERDUE && 'error') ||
            (status === INVOICE_STATUS.PARTIALLY_PAID && 'warning') ||
            'default'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <div>
                <MenuItem
                  className="pl-6"
                  onClick={() => {
                    onViewRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:eye-fill'} />
                  View
                </MenuItem>
                {contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS ? (
                  <>
                    <MenuItem
                      className="pl-6"
                      onClick={() => {
                        onDuplicateRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'heroicons-solid:document-duplicate'} />
                      Duplicate
                    </MenuItem>
                    <MenuItem
                      className="pl-6"
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>{' '}
                  </>
                ) : (
                  <MenuItem
                    className="pl-6"
                    onClick={() => {
                      onDeleteRow();
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>
                )}
              </div>
              {/* <Divider className="my-2" /> */}
              {/* <MenuItem
                className="pl-6"
                onClick={() => {
                  onArchiveRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Archive
              </MenuItem> */}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}

ViewContactTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onArchiveRow: PropTypes.func,
  onEditRow: PropTypes.func
};
