import * as actionTypes from './actionTypes';

let initialState = {
  addBankDetailsResponse: {},
  bankDetailsList: {},
  deletedBank: {},
  individualBankDetail: {},
  editBankDetailsResponse: {},
  markPaymentResponse: {},
  deleteMarkPaymentResponse: {},
  editInvoiceStatusResponse: {},
  publicInvoiceMarkPaymentResponse: {},
  invoicePaymentApprovalResponse: {},
  updateMarkPaymentResponse: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BANK_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.ADD_BANK_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.ADD_BANK_DETAILS_REQUEST_SUCCESS:
      return { ...state, addBankDetailsResponse: { ...action.addBankDetailsResponse }, loading: false, error: false };

    case actionTypes.GET_BANK_DETAILS_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_BANK_DETAILS_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_BANK_DETAILS_LIST_REQUEST_SUCCESS:
      return { ...state, bankDetailsList: { ...action.bankDetailsList }, loading: false, error: false };

    case actionTypes.DELETE_BANK_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_BANK_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.DELETE_BANK_DETAILS_REQUEST_SUCCESS:
      return { ...state, deletedBank: { ...action.deletedBank }, loading: false, error: false };

    case actionTypes.GET_BANK_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_BANK_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_BANK_DETAILS_REQUEST_SUCCESS:
      return { ...state, individualBankDetail: { ...action.individualBankDetail }, loading: false, error: false };

    case actionTypes.EDIT_BANK_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_BANK_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.EDIT_BANK_DETAILS_REQUEST_SUCCESS:
      return { ...state, editBankDetailsResponse: { ...action.editBankDetailsResponse }, loading: false, error: false };

    case actionTypes.INVOICE_MARK_PAYMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_MARK_PAYMENT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_MARK_PAYMENT_REQUEST_SUCCESS:
      return { ...state, markPaymentResponse: { ...action.invoiceMarkPaymentResponse }, loading: false, error: false };

    case actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteMarkPaymentResponse: { ...action.invoiceDeleteMarkPaymentResponse },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_UPDATE_STATUS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_UPDATE_STATUS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_UPDATE_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        editInvoiceStatusResponse: { ...action.invoiceUpdateStatusResponse },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        publicInvoiceMarkPaymentResponse: { ...action.invoicePublicMarkPaymentResponse },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        invoicePaymentApprovalResponse: { ...action.invoicePaymentApproveResponse },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        updateMarkPaymentResponse: { ...action.invoiceUpdateMarkPaymentResponse },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
