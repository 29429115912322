import * as actionTypes from './actionTypes';

let initialState = {
  importedInvoiceData: {},
  importedInvoiceList: {},
  createdImportedInvoiceData: {},
  importedInvoiceDataDetails: {},
  editedImportedInvoiceData: {},
  deletedImportedInvoiceData: {},
  requestValidationInvoiceData: {},
  validatorInvoiceData: {},
  importedDashboardData: {},
  loading: false,
  error: false
};

const importedInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_IMPORTING_INVOICE_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        importedInvoiceDataDetails: { ...action.getImportingInvoiceDetails },
        loading: false,
        error: false
      };

    case actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_IMPORTING_INVOICE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        importedInvoiceList: { ...action.getImportingInvoiceList },
        loading: false,
        error: false
      };

    case actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CREATE_IMPORTING_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        createdImportedInvoiceData: { ...action.createResponse },
        loading: false,
        error: false
      };

    case actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.EDIT_IMPORTING_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        editedImportedInvoiceData: { ...action.editedImportingInvoiceData },
        loading: false,
        error: false
      };

    case actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.DELETE_IMPORTED_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        deletedImportedInvoiceData: { ...action.importedInvoicePayload },
        loading: false,
        error: false
      };

    case actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPLOAD_IMPORTED_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        importedInvoiceData: { ...action.uploadedInvoiceData },
        loading: false,
        error: false
      };

    case actionTypes.GET_IMPORTING_PUBLIC_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_IMPORTING_PUBLIC_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_IMPORTING_PUBLIC_REQUEST_SUCCESS:
      return {
        ...state,
        importedPublicInvoice: { ...action.getImportingInvoicePublic },
        loading: false,
        error: false
      };

    case actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.REQUEST_VALIDATION_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        requestValidationInvoiceData: { ...action.importedInvoicePayload },
        loading: false,
        error: false
      };

    case actionTypes.GET_VALIDATORS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_VALIDATORS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_VALIDATORS_REQUEST_SUCCESS:
      return {
        ...state,
        validatorInvoiceData: { ...action.validatorsList },
        loading: false,
        error: false
      };

    case actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_IMPORTING_ANALYTICS_REQUEST_SUCCESS:
      return {
        ...state,
        importedDashboardData: { ...action.getImportingDashboardData },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};

export default importedInvoiceReducer;
