import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const invoiceData = (invoiceData) => ({
  type: actionTypes.INVOICE_DATA_REQUEST_SUCCESS,
  invoiceData: invoiceData
});

export const getInvoiceDetailsRequestStart = () => ({ type: actionTypes.GET_INVOICE_BY_ID_REQUEST_START });
export const getInvoiceDetailsRequestFail = (error) => ({
  type: actionTypes.GET_INVOICE_BY_ID_REQUEST_FAIL,
  error: error
});
export const getInvoiceDetailsRequestSuccess = (invoiceDetails) => ({
  type: actionTypes.GET_INVOICE_BY_ID_REQUEST_SUCCESS,
  invoiceDetails: invoiceDetails
});
export const getInvoiceDetails = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceDetailsRequestStart());
      let invoiceDetailsResponse = await Api.get(`${ENDPOINTS.GET_INVOICE_DETAILS}?invoiceId=${invoiceId}`);
      dispatch(getInvoiceDetailsRequestSuccess(invoiceDetailsResponse));
      return invoiceDetailsResponse;
    } catch (error) {
      dispatch(getInvoiceDetailsRequestFail(error.response));
    }
  };
};
export const getInvoiceListRequestStart = () => ({ type: actionTypes.GET_INVOICE_LIST_REQUEST_START });
export const getInvoiceListRequestFail = (error) => ({
  type: actionTypes.GET_INVOICE_LIST_REQUEST_FAIL,
  error: error
});
export const getInvoiceListRequestSuccess = (invoiceDetails) => ({
  type: actionTypes.GET_INVOICE_LIST_REQUEST_SUCCESS,
  invoiceDetails: invoiceDetails
});
export const getInvoiceList = () => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceListRequestStart());
      let invoiceListResponse = await Api.get(`${ENDPOINTS.GET_INVOICE_LIST}`);
      dispatch(getInvoiceListRequestSuccess(invoiceListResponse));
      return invoiceListResponse;
    } catch (error) {
      dispatch(getInvoiceListRequestFail(error.response));
    }
  };
};

export const createInvoiceRequestStart = () => ({ type: actionTypes.CREATE_INVOICE_REQUEST_START });
export const createInvoiceRequestFail = (error) => ({
  type: actionTypes.CREATE_INVOICE_REQUEST_FAIL,
  error: error
});
export const createInvoiceRequestSuccess = (createInvoicePayload) => ({
  type: actionTypes.CREATE_INVOICE_REQUEST_SUCCESS,
  createInvoicePayload: createInvoicePayload
});
export const createInvoice = (createInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(createInvoiceRequestStart());
      let createInvoiceResponse = await Api.post(ENDPOINTS.CREATE_INVOICE, createInvoicePayload);
      dispatch(createInvoiceRequestSuccess(createInvoiceResponse));
      return createInvoiceResponse;
    } catch (error) {
      dispatch(createInvoiceRequestFail(error.response));
    }
  };
};

export const deleteInvoiceRequestStart = () => ({ type: actionTypes.DELETE_INVOICE_REQUEST_START });
export const deleteInvoiceRequestFail = (error) => ({
  type: actionTypes.DELETE_INVOICE_REQUEST_FAIL,
  error: error
});
export const deleteInvoiceRequestSuccess = (deleteInvoice) => ({
  type: actionTypes.DELETE_INVOICE_REQUEST_SUCCESS,
  deleteInvoice: deleteInvoice
});
export const deleteInvoice = (deleteInvoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteInvoiceRequestStart());
      let deleteInvoiceResponse = await Api.delete(`${ENDPOINTS.DELETE_INVOICE}?invoiceId=${deleteInvoiceId}`);
      dispatch(deleteInvoiceRequestSuccess(deleteInvoiceResponse));
      return deleteInvoiceResponse;
    } catch (error) {
      dispatch(deleteInvoiceRequestFail(error.response));
    }
  };
};
export const editInvoiceRequestStart = () => ({ type: actionTypes.EDIT_INVOICE_REQUEST_START });
export const editInvoiceRequestFail = (error) => ({
  type: actionTypes.EDIT_INVOICE_REQUEST_FAIL,
  error: error
});
export const editInvoiceRequestSuccess = (editInvoicePayload) => ({
  type: actionTypes.EDIT_INVOICE_REQUEST_SUCCESS,
  editInvoicePayload: editInvoicePayload
});
export const editInvoice = (editInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(editInvoiceRequestStart());
      let editInvoiceResponse = await Api.put(ENDPOINTS.EDIT_INVOICE, editInvoicePayload);
      dispatch(editInvoiceRequestSuccess(editInvoiceResponse));
      return editInvoiceResponse;
    } catch (error) {
      dispatch(editInvoiceRequestFail(error.response));
    }
  };
};

export const sendEmailInvoiceRequestStart = () => ({ type: actionTypes.SEND_EMAIL_INVOICE_REQUEST_START });
export const sendEmailInvoiceRequestFail = (error) => ({
  type: actionTypes.SEND_EMAIL_INVOICE_REQUEST_FAIL,
  error: error
});
export const sendEmailInvoiceRequestSuccess = (sendEmailInvoicePayload) => ({
  type: actionTypes.SEND_EMAIL_INVOICE_REQUEST_SUCCESS,
  sendEmailInvoicePayload: sendEmailInvoicePayload
});
export const sendEmailInvoice = (sendEmailInvoicePayload) => {
  return async (dispatch) => {
    try {
      dispatch(sendEmailInvoiceRequestStart());
      let sendEmailInvoiceResponse = await Api.post(ENDPOINTS.SEND_EMAIL_INVOICE, sendEmailInvoicePayload);
      dispatch(sendEmailInvoiceRequestSuccess(sendEmailInvoiceResponse));
      return sendEmailInvoiceResponse;
    } catch (error) {
      dispatch(sendEmailInvoiceRequestFail(error.response));
    }
  };
};

export const resetInvoiceDetails = (error) => ({
  type: actionTypes.RESET_INVOICE_DETAILS_SUCCESS,
  error: error
});

export const getPublicInvoiceDetailsRequestStart = () => ({ type: actionTypes.GET_PUBLIC_INVOICE_REQUEST_START });
export const getPublicInvoiceDetailsRequestFail = (error) => ({
  type: actionTypes.GET_PUBLIC_INVOICE_REQUEST_FAIL,
  error: error
});
export const getPublicInvoiceDetailsRequestSuccess = (getPublicInvoice) => ({
  type: actionTypes.GET_PUBLIC_INVOICE_REQUEST_SUCCESS,
  getPublicInvoice: getPublicInvoice
});
export const getPublicInvoiceDetails = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(getPublicInvoiceDetailsRequestStart());
      let invoiceDetailsResponse = await Api.get(`${ENDPOINTS.GET_PUBLIC_INVOICE}/${invoiceId}`);
      dispatch(getPublicInvoiceDetailsRequestSuccess(invoiceDetailsResponse));
      return invoiceDetailsResponse;
    } catch (error) {
      dispatch(getPublicInvoiceDetailsRequestFail(error.response));
    }
  };
};

export const invoiceCountRequestStart = () => ({ type: actionTypes.INVOICE_COUNT_REQUEST_START });
export const invoiceCountRequestFail = (error) => ({
  type: actionTypes.INVOICE_COUNT_REQUEST_FAIL,
  error: error
});
export const invoiceCountRequestSuccess = (invoiceCountPayload) => ({
  type: actionTypes.INVOICE_COUNT_REQUEST_SUCCESS,
  invoiceCountPayload: invoiceCountPayload
});
export const invoiceCount = (invoiceCountPayload) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceCountRequestStart());
      let invoiceCountResponse = await Api.post(ENDPOINTS.INVOICE_COUNT, invoiceCountPayload);
      dispatch(invoiceCountRequestSuccess(invoiceCountResponse));
      return invoiceCountResponse;
    } catch (error) {
      dispatch(invoiceCountRequestFail(error.response));
    }
  };
};

export const invoiceCurrencyRequestStart = () => ({ type: actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_START });
export const invoiceCurrencyRequestFail = (error) => ({
  type: actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_FAIL,
  error: error
});
export const invoiceCurrencyRequestSuccess = (invoiceCurrencyPayload) => ({
  type: actionTypes.TOTAL_INVOICE_CURRENCY_REQUEST_SUCCESS,
  invoiceCurrencyPayload: invoiceCurrencyPayload
});
export const invoiceCurrency = (invoiceCurrencyPayload) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceCurrencyRequestStart());
      let invoiceCurrencyResponse = await Api.get(ENDPOINTS.DASHBOARD_DATA, invoiceCurrencyPayload);
      dispatch(invoiceCurrencyRequestSuccess(invoiceCurrencyResponse));
      return invoiceCurrencyResponse;
    } catch (error) {
      dispatch(invoiceCurrencyRequestFail(error.response));
    }
  };
};

export const invoiceGeneratorRequestStart = () => ({ type: actionTypes.INVOICE_GENERATOR_REQUEST_START });
export const invoiceGeneratorRequestFail = (error) => ({
  type: actionTypes.INVOICE_GENERATOR_REQUEST_FAIL,
  error: error
});
export const invoiceGeneratorRequestSuccess = (invoiceGeneratorPayload) => ({
  type: actionTypes.INVOICE_GENERATOR_REQUEST_SUCCESS,
  invoiceGeneratorPayload: invoiceGeneratorPayload
});
export const invoiceGenerator = (invoiceGeneratorPayload) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceGeneratorRequestStart());
      let invoiceGeneratorResponse = await Api.post(ENDPOINTS.TEMP_INVOICE_CREATE, invoiceGeneratorPayload);
      dispatch(invoiceGeneratorRequestSuccess(invoiceGeneratorResponse));
      return invoiceGeneratorResponse;
    } catch (error) {
      dispatch(invoiceGeneratorRequestFail(error.response));
    }
  };
};

export const getInvoiceGeneratorRequestStart = () => ({ type: actionTypes.GET_INVOICE_GENERATOR_REQUEST_START });
export const getInvoiceGeneratorRequestFail = (error) => ({
  type: actionTypes.GET_INVOICE_GENERATOR_REQUEST_FAIL,
  error: error
});
export const getInvoiceGeneratorRequestSuccess = (getInvoiceGeneratorPayload) => ({
  type: actionTypes.GET_INVOICE_GENERATOR_REQUEST_SUCCESS,
  getInvoiceGeneratorPayload: getInvoiceGeneratorPayload
});
export const getInvoiceGenerator = (invoiceId) => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceGeneratorRequestStart());
      let getInvoiceGeneratorResponse = await Api.get(`${ENDPOINTS.TEMP_GET_INVOICE_DATA}/${invoiceId}`);
      dispatch(getInvoiceGeneratorRequestSuccess(getInvoiceGeneratorResponse));
      return getInvoiceGeneratorResponse;
    } catch (error) {
      dispatch(getInvoiceGeneratorRequestFail(error.response));
    }
  };
};

export const updateUserInvoiceGeneratorRequestStart = () => ({
  type: actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_START
});
export const updateUserInvoiceGeneratorRequestFail = (error) => ({
  type: actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_FAIL,
  error: error
});
export const updateUserInvoiceGeneratorRequestSuccess = (updateUserInvoiceGeneratorPayload) => ({
  type: actionTypes.UPDATE_USER_INVOICE_GENERATOR_REQUEST_SUCCESS,
  updateUserInvoiceGeneratorPayload: updateUserInvoiceGeneratorPayload
});
export const updateUserInvoiceGenerator = (updateUserInvoiceGeneratorPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateUserInvoiceGeneratorRequestStart());
      let updateUserInvoiceGeneratorResponse = await Api.put(
        ENDPOINTS.TEMP_INVOICE_UPDATE_USER,
        updateUserInvoiceGeneratorPayload
      );
      dispatch(updateUserInvoiceGeneratorRequestSuccess(updateUserInvoiceGeneratorResponse));
      return updateUserInvoiceGeneratorResponse;
    } catch (error) {
      dispatch(updateUserInvoiceGeneratorRequestFail(error.response));
    }
  };
};
