import React, { useState } from 'react';
import DynamicModal from '../../ui/modal/DynamicModal';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from '../reduxStore/action';
import Plus from '../../../images/icons/customSvg/plus';
import XSText from '../../ui/typography/XSText';
import InvoiceTaxesForm from './invoiceTaxesForm';
import InvoiceEditPen from '../../../images/icons/Invoice/invoice-edit-pen.svg';
import {
  itemsTotalPrice,
  tasksTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  concatenateSelectedCurrency
} from '../../../helper/helpers';

function InvoiceTaxes({ getInvoiceDetails, selectedCurrency }) {
  const [reviewErrorModal, setReviewErrorModal] = useState(false);

  const closeDynamicModal = () => {
    setReviewErrorModal(false);
  };
  const subtotal =
    itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
    tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
    newItemsTotalPrice(getInvoiceDetails?.items);
  const discountPrice = priceCalculatorThroughPercentage(
    subtotal,
    getInvoiceDetails?.invoiceDiscount?.discountPercentage
  );
  const totalAfterDiscount = discountPrice ? subtotal - discountPrice : subtotal;

  const taxWithPrice = getInvoiceDetails?.invoiceTaxes?.map((taxDetails) => ({
    ...taxDetails,
    taxPrice: priceCalculatorThroughPercentage(totalAfterDiscount, taxDetails?.taxPercentage)
  }));
  return (
    <div className="flex flex-col gap-4">
      {taxWithPrice?.length > 0 ? (
        taxWithPrice?.map((taxDetails, index) => {
          return (
            <div key={index} className="cursor-pointer" onClick={() => setReviewErrorModal(true)}>
              <div
                className={`flex ${
                  getInvoiceDetails?.invoiceTaxes[0]?.taxPercentage
                    ? 'justify-start'
                    : 'justify-between invoice-right-padding'
                } items-center h-8 `}
              >
                <XSText className="w-20 capitalize" title={taxDetails?.taxConcept} />
                {getInvoiceDetails?.invoiceTaxes ? (
                  <div key={index} className="flex w-full justify-between items-center ml-10">
                    <XSText title={`${taxDetails?.taxPercentage}%`} />
                    <div className="flex gap-5 items-center">
                      <XSText
                        title={
                          Number(taxDetails?.taxPrice) < 0
                            ? `-${concatenateSelectedCurrency(selectedCurrency, Math.abs(taxDetails?.taxPrice))}`
                            : concatenateSelectedCurrency(selectedCurrency, taxDetails?.taxPrice)
                        }
                      />
                      <img src={InvoiceEditPen} alt="InvoiceEditPen" className="mb-1" />
                    </div>
                  </div>
                ) : (
                  <PrimaryButton
                    id="invoice-taxes-add-btn"
                    className="invoice-button"
                    bgColor="bg-coral-500"
                    textColor="text-white"
                    caption={
                      <div className="flex gap-2 text-medium items-center">
                        <Plus />
                        Add
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="cursor-pointer" onClick={() => setReviewErrorModal(true)}>
          <div className={`flex justify-between invoice-right-padding items-center h-8 `}>
            <XSText className="w-20" title="Taxes" />
            <PrimaryButton
              id="invoice-taxes-edit-btn"
              className="invoice-button"
              bgColor="bg-coral-500"
              textColor="text-white"
              caption={
                <div className="flex gap-2 text-medium items-center">
                  <Plus />
                  Add
                </div>
              }
            />
          </div>
        </div>
      )}

      <DynamicModal openDynamicModal={reviewErrorModal} closeDynamicModal={closeDynamicModal} minWidth="576px">
        <InvoiceTaxesForm closeDynamicModal={closeDynamicModal} />
      </DynamicModal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTaxes);

InvoiceTaxes.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  invoiceDiscount: PropTypes.func,
  selectedCurrency: PropTypes.object
};
