import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const addBankDetailsRequestStart = () => ({ type: actionTypes.ADD_BANK_DETAILS_REQUEST_START });
export const addBankDetailsRequestFail = (error) => ({
  type: actionTypes.ADD_BANK_DETAILS_REQUEST_FAIL,
  error: error
});
export const addBankDetailsRequestSuccess = (bankDetails) => ({
  type: actionTypes.ADD_BANK_DETAILS_REQUEST_SUCCESS,
  bankDetails: bankDetails
});
export const addBankDetails = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(addBankDetailsRequestStart());
      let bankDetailsResponse = await Api.post(ENDPOINTS.ADD_BANK_DETAILS, bankDetails);
      dispatch(addBankDetailsRequestSuccess(bankDetailsResponse));
      return bankDetailsResponse;
    } catch (error) {
      dispatch(addBankDetailsRequestFail(error.response));
    }
  };
};

export const getBankDetailsListRequestStart = () => ({ type: actionTypes.GET_BANK_DETAILS_LIST_REQUEST_START });
export const getBankDetailsListRequestFail = (error) => ({
  type: actionTypes.GET_BANK_DETAILS_LIST_REQUEST_FAIL,
  error: error
});
export const getBankDetailsListRequestSuccess = (bankDetailsList) => ({
  type: actionTypes.GET_BANK_DETAILS_LIST_REQUEST_SUCCESS,
  bankDetailsList: bankDetailsList
});
export const getBankDetailsList = () => {
  return async (dispatch) => {
    try {
      dispatch(getBankDetailsListRequestStart());
      let bankDetailsResponse = await Api.get(ENDPOINTS.GET_BANK_DETAILS_LIST);
      dispatch(getBankDetailsListRequestSuccess(bankDetailsResponse));
      return bankDetailsResponse;
    } catch (error) {
      dispatch(getBankDetailsListRequestFail(error.response));
    }
  };
};

export const deleteBankDetailsRequestStart = () => ({ type: actionTypes.DELETE_BANK_DETAILS_REQUEST_START });
export const deleteBankDetailsRequestFail = (error) => ({
  type: actionTypes.DELETE_BANK_DETAILS_REQUEST_FAIL,
  error: error
});
export const deleteBankDetailsRequestSuccess = (bankDetails) => ({
  type: actionTypes.DELETE_BANK_DETAILS_REQUEST_SUCCESS,
  bankDetails: bankDetails
});
export const deleteBankDetails = (bankId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteBankDetailsRequestStart());
      let bankDetailsResponse = await Api.delete(`${ENDPOINTS.DELETE_BANK_DETAILS}?bankId=${bankId}`);
      dispatch(deleteBankDetailsRequestSuccess(bankDetailsResponse));
      return bankDetailsResponse;
    } catch (error) {
      dispatch(deleteBankDetailsRequestFail(error.response));
    }
  };
};

export const getBankDetailsRequestStart = () => ({ type: actionTypes.GET_BANK_DETAILS_REQUEST_START });
export const getBankDetailsRequestFail = (error) => ({
  type: actionTypes.GET_BANK_DETAILS_REQUEST_FAIL,
  error: error
});
export const getBankDetailsRequestSuccess = (individualBankDetail) => ({
  type: actionTypes.GET_BANK_DETAILS_REQUEST_SUCCESS,
  individualBankDetail: individualBankDetail
});
export const getBankDetails = (bankId) => {
  return async (dispatch) => {
    try {
      dispatch(getBankDetailsRequestStart());
      let individualBankDetail = await Api.get(`${ENDPOINTS.GET_BANK_DETAILS}?bankId=${bankId}`);
      dispatch(getBankDetailsRequestSuccess(individualBankDetail));
      return individualBankDetail;
    } catch (error) {
      dispatch(getBankDetailsRequestFail(error.response));
    }
  };
};

export const editBankDetailsRequestStart = () => ({ type: actionTypes.EDIT_BANK_DETAILS_REQUEST_START });
export const editBankDetailsRequestFail = (error) => ({
  type: actionTypes.EDIT_BANK_DETAILS_REQUEST_FAIL,
  error: error
});
export const editBankDetailsRequestSuccess = (editBankDetailsResponse) => ({
  type: actionTypes.EDIT_BANK_DETAILS_REQUEST_SUCCESS,
  editBankDetailsResponse: editBankDetailsResponse
});
export const editBankDetails = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(editBankDetailsRequestStart());
      let editBankDetailsResponse = await Api.put(ENDPOINTS.EDIT_BANK_DETAILS, bankDetails);
      dispatch(editBankDetailsRequestSuccess(editBankDetailsResponse));
      return editBankDetailsResponse;
    } catch (error) {
      dispatch(editBankDetailsRequestFail(error.response));
    }
  };
};

export const invoiceMarkPaymentRequestStart = () => ({ type: actionTypes.INVOICE_MARK_PAYMENT_REQUEST_START });
export const invoiceMarkPaymentRequestFail = (error) => ({
  type: actionTypes.INVOICE_MARK_PAYMENT_REQUEST_FAIL,
  error: error
});
export const invoiceMarkPaymentRequestSuccess = (invoiceMarkPaymentResponse) => ({
  type: actionTypes.INVOICE_MARK_PAYMENT_REQUEST_SUCCESS,
  invoiceMarkPaymentResponse: invoiceMarkPaymentResponse
});
export const invoiceMarkPayment = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceMarkPaymentRequestStart());
      let invoiceMarkPaymentResponse = await Api.post(ENDPOINTS.INVOICE_MARK_PAYMENT, bankDetails);
      dispatch(invoiceMarkPaymentRequestSuccess(invoiceMarkPaymentResponse));
      return invoiceMarkPaymentResponse;
    } catch (error) {
      dispatch(invoiceMarkPaymentRequestFail(error.response));
    }
  };
};

export const invoiceDeleteMarkPaymentRequestStart = () => ({
  type: actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_START
});
export const invoiceDeleteMarkPaymentRequestFail = (error) => ({
  type: actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_FAIL,
  error: error
});
export const invoiceDeleteMarkPaymentRequestSuccess = (invoiceDeleteMarkPaymentResponse) => ({
  type: actionTypes.INVOICE_DELETE_MARK_PAYMENT_REQUEST_SUCCESS,
  invoiceDeleteMarkPaymentResponse: invoiceDeleteMarkPaymentResponse
});
export const invoiceDeleteMarkPayment = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceDeleteMarkPaymentRequestStart());
      let invoiceDeleteMarkPaymentResponse = await Api.delete(ENDPOINTS.INVOICE_DELETE_MARK_PAYMENT, bankDetails);
      dispatch(invoiceDeleteMarkPaymentRequestSuccess(invoiceDeleteMarkPaymentResponse));
      return invoiceDeleteMarkPaymentResponse;
    } catch (error) {
      dispatch(invoiceDeleteMarkPaymentRequestFail(error.response));
    }
  };
};

export const invoiceUpdateMarkPaymentRequestStart = () => ({
  type: actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_START
});
export const invoiceUpdateMarkPaymentRequestFail = (error) => ({
  type: actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_FAIL,
  error: error
});
export const invoiceUpdateMarkPaymentRequestSuccess = (invoiceUpdateMarkPaymentResponse) => ({
  type: actionTypes.INVOICE_UPDATE_MARK_PAYMENT_REQUEST_SUCCESS,
  invoiceUpdateMarkPaymentResponse: invoiceUpdateMarkPaymentResponse
});
export const invoiceUpdateMarkPayment = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceUpdateMarkPaymentRequestStart());
      let invoiceUpdateMarkPaymentResponse = await Api.put(ENDPOINTS.INVOICE_UPDATE_MARK_PAYMENT, bankDetails);
      dispatch(invoiceUpdateMarkPaymentRequestSuccess(invoiceUpdateMarkPaymentResponse));
      return invoiceUpdateMarkPaymentResponse;
    } catch (error) {
      dispatch(invoiceUpdateMarkPaymentRequestFail(error.response));
    }
  };
};

export const invoiceUpdateStatusRequestStart = () => ({
  type: actionTypes.INVOICE_UPDATE_STATUS_REQUEST_START
});
export const invoiceUpdateStatusRequestFail = (error) => ({
  type: actionTypes.INVOICE_UPDATE_STATUS_REQUEST_FAIL,
  error: error
});
export const invoiceUpdateStatusRequestSuccess = (invoiceUpdateStatusResponse) => ({
  type: actionTypes.INVOICE_UPDATE_STATUS_REQUEST_SUCCESS,
  invoiceUpdateStatusResponse: invoiceUpdateStatusResponse
});
export const invoiceUpdateStatus = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoiceUpdateStatusRequestStart());
      let invoiceUpdateStatusResponse = await Api.put(ENDPOINTS.INVOICE_UPDATE_STATUS, bankDetails);
      dispatch(invoiceUpdateStatusRequestSuccess(invoiceUpdateStatusResponse));
      return invoiceUpdateStatusResponse;
    } catch (error) {
      dispatch(invoiceUpdateStatusRequestFail(error.response));
    }
  };
};

export const invoicePublicMarkPaymentRequestStart = () => ({
  type: actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_START
});
export const invoicePublicMarkPaymentRequestFail = (error) => ({
  type: actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_FAIL,
  error: error
});
export const invoicePublicMarkPaymentRequestSuccess = (invoicePublicMarkPaymentResponse) => ({
  type: actionTypes.INVOICE_PUBLIC_MARK_PAYMENT_REQUEST_SUCCESS,
  invoicePublicMarkPaymentResponse: invoicePublicMarkPaymentResponse
});
export const invoicePublicMarkPayment = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoicePublicMarkPaymentRequestStart());
      let invoicePublicMarkPaymentResponse = await Api.post(ENDPOINTS.INVOICE_PUBLIC_MARK_PAYMENT, bankDetails);
      dispatch(invoicePublicMarkPaymentRequestSuccess(invoicePublicMarkPaymentResponse));
      return invoicePublicMarkPaymentResponse;
    } catch (error) {
      dispatch(invoicePublicMarkPaymentRequestFail(error.response));
    }
  };
};

export const invoicePaymentApproveRequestStart = () => ({
  type: actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_START
});
export const invoicePaymentApproveRequestFail = (error) => ({
  type: actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_FAIL,
  error: error
});
export const invoicePaymentApproveRequestSuccess = (invoicePaymentApproveResponse) => ({
  type: actionTypes.INVOICE_PAYMENT_APPROVE_REQUEST_SUCCESS,
  invoicePaymentApproveResponse: invoicePaymentApproveResponse
});
export const invoicePaymentApprove = (bankDetails) => {
  return async (dispatch) => {
    try {
      dispatch(invoicePaymentApproveRequestStart());
      let invoicePaymentApproveResponse = await Api.post(ENDPOINTS.INVOICE_APPROVE_PAYMENT, bankDetails);
      dispatch(invoicePaymentApproveRequestSuccess(invoicePaymentApproveResponse));
      return invoicePaymentApproveResponse;
    } catch (error) {
      dispatch(invoicePaymentApproveRequestFail(error.response));
    }
  };
};
