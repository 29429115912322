import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const signUpRequestStart = () => ({ type: actionTypes.SIGNUP_REQUEST_START });
export const signUpRequestFail = (error) => ({ type: actionTypes.SIGNUP_REQUEST_FAILURE, error: error });
export const signUpRequestSuccess = (signUpPayload) => ({
  type: actionTypes.SIGNUP_REQUEST_SUCCESS,
  signUpPayload: signUpPayload
});

export const signUp = (signUpPayload) => {
  return async (dispatch) => {
    try {
      dispatch(signUpRequestStart());
      let signUpResponse = await Api.post(ENDPOINTS.BACK_OFFICE_SIGN_UP, signUpPayload);
      dispatch(signUpRequestSuccess(signUpResponse?.data));
      return signUpResponse;
    } catch (error) {
      dispatch(signUpRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyRegistrationDataRequestStart = () => ({
  type: actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_START
});
export const companyRegistrationDataRequestFail = (error) => ({
  type: actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_FAILURE,
  error: error
});
export const companyRegistrationDataRequestSuccess = (companyRegistrationDataPayload) => ({
  type: actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_SUCCESS,
  companyRegistrationDataPayload: companyRegistrationDataPayload
});

export const companyRegistrationData = (companyRegistrationDataPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyRegistrationDataRequestStart());
      let companyRegistrationDataResponse = await Api.post(
        ENDPOINTS.VALIDATE_COMPANY_USER,
        companyRegistrationDataPayload
      );
      dispatch(companyRegistrationDataRequestSuccess(companyRegistrationDataResponse?.data));
      return companyRegistrationDataResponse;
    } catch (error) {
      dispatch(companyRegistrationDataRequestFail(error.response));
      return error?.response;
    }
  };
};
