import * as actionTypes from './actionTypes';

let initialState = {
  createContactDetails: {},
  loading: false,
  error: false,

  contactListDetails: {},
  editContactDetails: {},
  deleteContactDetails: {},
  uploadedContactImageURL: {},
  viewContactDetails: {},
  invoiceContactListDetails: {},
  allContacts: {}
};

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        createContactDetails: action.createContactPayload,
        loading: false,
        error: false
      };
    case actionTypes.CREATE_CONTACT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.CONTACT_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CONTACT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        contactListDetails: action.contactListPayload,
        loading: false,
        error: false
      };
    case actionTypes.CONTACT_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.EDIT_CONTACT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteContactDetails: action.deleteContactPayload,
        loading: false,
        error: false
      };
    case actionTypes.EDIT_CONTACT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.DELETE_CONTACT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteContactDetails: action.deleteContactPayload,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_CONTACT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.VIEW_CONTACT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.VIEW_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        viewContactDetails: action.viewContactPayload,
        loading: false,
        error: false
      };
    case actionTypes.VIEW_CONTACT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        invoiceContactListDetails: action.getInvoiceContact,
        loading: false,
        error: false
      };
    case actionTypes.INVOICE_CONTACT_LIST_BY_ID_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.GET_ALL_CONTACT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_ALL_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        allContacts: action.allContactListPayload,
        loading: false,
        error: false
      };
    case actionTypes.GET_ALL_CONTACT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.RESET_CONTACT_MODAL_FORM:
      return {
        ...state,
        viewContactDetails: {}
      };
    default:
      return state;
  }
};
export default ContactReducer;
