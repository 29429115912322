import React, { useState, useEffect } from 'react';
import InvoiceIcon from '../../images/graphics/invoice.png';
import { useLocation } from '@reach/router';
import { FILE_EXTENTION_TYPE } from '../../constants/enum';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function ImportedInvoiceFileUpload({ pdfURL }) {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handlePageShifting(index) {
    setPageNumber(index + 1);
  }

  const getFileExtension = (filename) => {
    const extension = filename?.split('.').pop();
    return extension;
  };

  useEffect(() => {
    if (location?.state?.urlOfImage) {
      setImageUrl(location?.state?.urlOfImage);
    } else {
      setImageUrl(pdfURL);
    }
  }, []);

  return (
    <>
      {getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.PNG ||
      getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.JPEG ||
      getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.JPG ? (
        <img className="imported-invoice-upload-container" src={imageUrl ? imageUrl : pdfURL} alt="Uploaded Image" />
      ) : (
        <div>
          <Document file={imageUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="importing-invoice-pdf-preview">
              <Page pageNumber={pageNumber} />
            </div>
            {pageNumber > 0 && (
              <div className="importing-invoice-multi-pages flex mt-6 ">
                {Array.from(new Array(numPages), (el, index) => (
                  <>
                    {numPages > 1 && (
                      <div className={`${index + 1 === pageNumber && 'active-pdf-tab'} mr-4`}>
                        <Page
                          className={`other-pdf-container cursor-pointer`}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          width={70}
                          height={100}
                          onClick={() => handlePageShifting(index)}
                        />
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </Document>
        </div>
      )}
    </>
  );
}
