import React from 'react';
import { OWNER_DIRECTOR_TYPES } from '../../../constants/enum';
import Avatar from '../../ui/Avatar';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';

function EditOwnerDetails({ firstName, lastName, companyName, type }) {
  const avatarText = firstName ? firstName?.charAt(0) + lastName?.charAt(0) : companyName?.charAt(0);
  return (
    <div className="flex justify-between py-4">
      <div className="flex gap-4">
        <Avatar avatarName={avatarText} />
        <div>
          <XSText
            fontWeight="text-bold mb-2 capitalize"
            title={firstName && lastName ? firstName + ' ' + lastName : companyName}
          />
          <div className="flex gap-2">
            <MSText className="card-type-text flex items-center" title="Yourself" />
            {type === OWNER_DIRECTOR_TYPES.OWNER ? (
              <MSText
                className="role-owner flex items-center justify-center px-2 py-1"
                fontWeight="text-bold"
                title="Owner"
              />
            ) : null}
            {type === OWNER_DIRECTOR_TYPES.DIRECTOR ? (
              <MSText
                className="role-director flex items-center justify-center px-2 py-1"
                fontWeight="text-bold"
                title="Director"
              />
            ) : null}
            {type === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR ? (
              <div className="flex gap-2">
                <MSText
                  className="role-owner flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Owner"
                />
                <MSText
                  className="role-director flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Director"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOwnerDetails;
