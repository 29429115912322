import React from 'react';
const checkMark = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.86009 17.9999C9.58292 17.999 9.31856 17.8832 9.13009 17.6799L4.27009 12.5099C3.89177 12.1068 3.91192 11.4733 4.31509 11.0949C4.71826 10.7166 5.35177 10.7368 5.73009 11.1399L9.85009 15.5299L18.2601 6.32994C18.4926 6.04035 18.8665 5.90351 19.2311 5.97455C19.5956 6.04559 19.8908 6.31281 19.9976 6.66853C20.1044 7.02424 20.0052 7.40985 19.7401 7.66994L10.6001 17.6699C10.4134 17.8769 10.1488 17.9965 9.87009 17.9999H9.86009Z"
        fill="white"
      />
    </svg>
  );
};
export default checkMark;
