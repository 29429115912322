import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { FILE_UPLOAD_TYPE } from '../../constants/enum';
import MSText from '../ui/typography/MSText';
import CameraIcon from '../../images/icons/account-camera-icon.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import XMSText from '../ui/typography/XMSText';
import IconBaseEdit from '../../images/icons/Invoice/base-edit.svg';
import '../../styles/pages/upload-image.scss';

export default function ImageUpload({ saveImage, fileSelection, uploadError, selectedImage }) {
  const [uploadLogo, setUploadLogo] = useState(false);

  return (
    <div
      onClick={() => !saveImage && !uploadLogo && setUploadLogo(!uploadLogo)}
      className={`upload-dropzone-container bg-white ${!saveImage && !uploadLogo && 'cursor-pointer'}  ${
        uploadLogo ? ' items-start' : ' items-center'
      }`}
    >
      <Dropzone onDrop={(acceptedFiles) => fileSelection(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className="">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {selectedImage ? (
                selectedImage[0].type === FILE_UPLOAD_TYPE.PNG ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.JPEG ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.URL ||
                selectedImage[0].type === FILE_UPLOAD_TYPE.JPG ? (
                  <div className="upload-image-dropzone">
                    <div className="flex flex-col justify-center items-center">
                      <img src={selectedImage[0].preview} className="preview-uploaded-image" />
                      <div className="items-center mt-10">
                        <PrimaryButton
                          id="upload-change-btn"
                          className="invoice-button"
                          bgColor="bg-coral-500"
                          textColor="text-white"
                          caption={
                            <div className="flex gap-1 text-medium items-center">
                              <img src={IconBaseEdit} alt="IconBaseEdit" />
                              Change
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="upload-image-dropzone">
                    <div className="flex flex-col justify-center items-center">
                      <img src={CameraIcon} className="image-uploaded" />
                      <div className="items-center mt-6">
                        <XMSText
                          className="whitespace-pre-line"
                          textAlign="text-center"
                          textColor="text-gray-450"
                          title={
                            <span>
                              {`Drop files here or`}
                              {<span className="text-coral-500">'browse'</span>}{' '}
                              {` to upload. \n Allowed *.jpeg, *.jpg, *.png, *.gif \n Max size of 1 MB.`}
                            </span>
                          }
                        />
                        <MSText
                          className="whitespace-pre-line"
                          textAlign="text-center"
                          textColor="text-coral-500"
                          title={'Only JPG and PNG files \n with max size of\n 1 MB.'}
                        />
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="upload-image-dropzone">
                  <div className=" flex flex-col justify-center items-center">
                    <img src={CameraIcon} className="image-upload" />
                    <div className="items-center mt-6">
                      <XMSText
                        className="whitespace-pre-line"
                        textAlign="text-center"
                        textColor="text-gray-450"
                        title={
                          <span>
                            {`Drop files here or`}
                            {<span className="text-coral-500">'browse'</span>}{' '}
                            {` to upload. \n Allowed *.jpeg, *.jpg, *.png, *.gif \n Max size of 1 MB.`}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {uploadError ? (
              <MSText
                className="mt-2 whitespace-pre-line flex justify-center"
                textColor="text-coral-500"
                textAlign="text-center"
                title={'Only Docx, Doc, PDF, JPG, and\n PNG files with max size of\n 1 MB.'}
              />
            ) : null}
          </section>
        )}
      </Dropzone>
    </div>
  );
}
