import React, { useState } from 'react';
import DynamicModal from '../../ui/modal/DynamicModal';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from '../reduxStore/action';
import Plus from '../../../images/icons/customSvg/plus';
import XSText from '../../ui/typography/XSText';
import InvoiceDiscountForm from './InvoiceDiscountForm';
import InvoiceEditPen from '../../../images/icons/Invoice/invoice-edit-pen.svg';
import {
  itemsTotalPrice,
  tasksTotalPrice,
  newItemsTotalPrice,
  priceCalculatorThroughPercentage,
  concatenateSelectedCurrency
} from '../../../helper/helpers';

function InvoiceDiscount({ getInvoiceDetails, selectedCurrency }) {
  const [reviewErrorModal, setReviewErrorModal] = useState(false);

  const closeDynamicModal = () => {
    setReviewErrorModal(false);
  };
  const subtotal =
    itemsTotalPrice(getInvoiceDetails?.invoiceItems) +
    tasksTotalPrice(getInvoiceDetails?.invoiceTasks) +
    newItemsTotalPrice(getInvoiceDetails?.items);
  const discountPrice = priceCalculatorThroughPercentage(
    subtotal,
    getInvoiceDetails?.invoiceDiscount?.discountPercentage
  );
  return (
    <div>
      <div className="cursor-pointer" onClick={() => setReviewErrorModal(true)}>
        <div
          className={`flex ${
            getInvoiceDetails?.invoiceDiscount?.discountPercentage &&
            getInvoiceDetails?.invoiceDiscount?.discountPercentage !== '0'
              ? 'justify-start'
              : 'justify-between invoice-right-padding'
          } items-center h-8 `}
        >
          <XSText
            className="w-20 capitalize"
            title={
              getInvoiceDetails?.invoiceDiscount?.discountPercentage &&
              getInvoiceDetails?.invoiceDiscount?.discountPercentage !== '0'
                ? getInvoiceDetails?.invoiceDiscount?.discountName
                : 'Discount'
            }
          />
          {getInvoiceDetails?.invoiceDiscount?.discountPercentage &&
          getInvoiceDetails?.invoiceDiscount?.discountPercentage !== '0' ? (
            <div className="flex w-full justify-between items-center ml-10">
              <XSText title={`${getInvoiceDetails?.invoiceDiscount?.discountPercentage}%`} />
              <div className="flex gap-5 items-center">
                <XSText title={`-${concatenateSelectedCurrency(selectedCurrency, discountPrice)}`} />
                <img src={InvoiceEditPen} alt="InvoiceEditPen" className="mb-1" />
              </div>
            </div>
          ) : (
            <PrimaryButton
              id="invoice-discount-add-btn"
              className="invoice-button"
              bgColor="bg-coral-500"
              textColor="text-white"
              caption={
                <div className="flex gap-2 text-medium items-center">
                  <Plus />
                  Add
                </div>
              }
            />
          )}
        </div>
      </div>
      <DynamicModal openDynamicModal={reviewErrorModal} closeDynamicModal={closeDynamicModal} minWidth="576px">
        <InvoiceDiscountForm closeDynamicModal={closeDynamicModal} />
      </DynamicModal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDiscount);

InvoiceDiscount.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  invoiceDiscount: PropTypes.func,
  selectedCurrency: PropTypes.object
};
