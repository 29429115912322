import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
//
import Image from './Image';
import Img from '../images/icons/no-data-found-icon.svg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center'
  // padding: theme.spacing(8, 2) // Commented for future use
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  description: PropTypes.string
};
EmptyContent.defaultProps = {
  height: 240,
  title: 'No data'
};

export default function EmptyContent({ title, description, img, height, ...other }) {
  return (
    <RootStyle {...other}>
      <Image disabledEffect visibleByDefault alt="empty content" src={Img} sx={{ height: height, mb: 1 }} />

      <Typography variant="h5" className="text-gray-450" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </RootStyle>
  );
}
