import React from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TextInput from '../ui/inputs/TextInput';
import XSText from '../ui/typography/XSText';
import StudyLightIcon from '../../images/icons/green-study-light1.svg';
import { Switch } from '@mui/material';
import MSText from '../ui/typography/MSText';
import { defaultValidationMessage } from '../../helper/helpers';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as userActions from '../invoice/reduxStore/action';
import PropTypes from 'prop-types';
import { EXTERNAL_PAYMENT_LINKS } from '../../constants/enum';
function HSBCPayMe({ invoiceData, getInvoiceDetails, handleSelectPaymentDetails }) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [isQrCodeRequired, setIsQrCodeRequired] = React.useState(
    getInvoiceDetails?.paymentDetails?.hsbcDetails?.showQrCode
      ? getInvoiceDetails?.paymentDetails?.hsbcDetails?.showQrCode
      : true
  );
  const onSubmitHsbcDetails = (data) => {
    invoiceData({
      paymentDetails: {
        ...getInvoiceDetails.paymentDetails,
        hsbcDetails: {
          ...getInvoiceDetails?.paymentDetails?.hsbcDetails,
          hsbcLink: data.hsbcPayMeLink,
          showQrCode: isQrCodeRequired
        }
      }
    });
    handleSelectPaymentDetails();
  };
  const handleChange = (event) => {
    setIsQrCodeRequired(event.target.checked);
  };
  return (
    <div>
      <form id="paypal-details-form" onSubmit={handleSubmit(onSubmitHsbcDetails)}>
        <TextInput
          defaultValue={
            getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink &&
            getInvoiceDetails?.paymentDetails?.hsbcDetails?.hsbcLink
          }
          margin="w-full"
          name="hsbcPayMeLink"
          label="Enter your HSBC PayMe username"
          inputRef={register({
            required: true
          })}
          startAdornment={EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}
          error={errors.hsbcLink ? true : false}
          helperText={errors.hsbcLink && <ValidationMessage title={defaultValidationMessage('HSBC link')} />}
        />
        <div className="flex mt-2 py-4 px-4 rounded-lg border border-solid border-gray-200 justify-between items-center">
          <MSText title="Show QR code on the invoice" />
          <Switch checked={isQrCodeRequired} onChange={handleChange} />
        </div>
        <div>
          <XSText
            className="mt-4 mb-6"
            title={
              <span className="flex bg-green-200 p-6 rounded-lg">
                <span className="mr-2">
                  <img src={StudyLightIcon} alt="StudyLight icon" />
                </span>
                <span>
                  <div>How to get your HSBC PayMe link?</div>
                  <div>
                    Go to{' '}
                    <a href="https://payme.hsbc.com.hk/" target="_blank">
                      payme.hsbc.com.hk
                    </a>{' '}
                    and follow the instructions.
                  </div>
                </span>
              </span>
            }
          />
        </div>
        <PrimaryButton
          id="hsbc-pay-me-add-btn"
          onClick={handleSubmit(onSubmitHsbcDetails)}
          className="modal-save-button mt-4 mr-4"
          caption="Add to invoice"
          fontWeight="text-bold"
          type="small"
        />
        <PrimaryButton
          id="hsbc-pay-me-cancel-btn"
          onClick={handleSelectPaymentDetails}
          bgColor="bg-white"
          className="modal-cancel-button mt-4"
          color="text-gray-300"
          caption="Cancel"
          fontWeight="text-bold"
          type="small"
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HSBCPayMe);

HSBCPayMe.propTypes = {
  invoiceData: PropTypes.func,
  handleSelectPaymentDetails: PropTypes.func
};
