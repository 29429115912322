import React, { useState } from 'react';
import IncomingInvoiceDetails from '../components/IncomingInvoices/IncomingInvoiceDetails';
import '../styles/pages/incoming-invoices.scss';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import { API_CODE, INVOICE_STATUS } from '../constants/enum';
import PropTypes from 'prop-types';
import * as importingInvoiceReducer from '../components/importedInvoices/reduxStore/action';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import PublicImportingInvoice from '../components/IncomingInvoices/PublicImportingInvoice';
import MessageComponent from '../components/IncomingInvoices/MessageComponent';
import Verified from '../images/graphics/verified.png';
import NotVerified from '../images/graphics/notVerified.png';
function ViewIncomingInvoicesPublic({ getPublicInvoice }) {
  const [importedPublicInvoice, setImportedPublicInvoice] = useState('');
  const [isValidationCorrect, setValidationCorrect] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  const getImportingInvoiceDetails = async (importedInvoiceId) => {
    const getImportedInvoicesResponse = await getPublicInvoice(importedInvoiceId);
    if (getImportedInvoicesResponse?.status === API_CODE.STATUS_200) {
      setImportedPublicInvoice(getImportedInvoicesResponse?.data?.data);
    }
  };
  useState(() => {
    getImportingInvoiceDetails(id);
  }, []);

  const validationStatusChanged = (isValid) => {
    setValidationCorrect(isValid);
    getImportingInvoiceDetails(id);
  };

  return (
    <div className="max-w-7xl layout-container pt-4 mx-auto bg-customBg-secondaryBg pb-8">
      <PublicHeaderNavigation />
      {!importedPublicInvoice ? (
        <div className="flex justify-center items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start gap-8 md:mt-15 mt-10">
            {importedPublicInvoice?.validationStatus === INVOICE_STATUS.VALID &&
              isValidationCorrect === INVOICE_STATUS.VALID && (
                <MessageComponent
                  image={Verified}
                  title="Invoice approved"
                  headingColor="text-green-500"
                  bgColor="bg-green-200"
                />
              )}
            {importedPublicInvoice?.validationStatus === INVOICE_STATUS.INVALID &&
              isValidationCorrect === INVOICE_STATUS.INVALID && (
                <MessageComponent
                  image={NotVerified}
                  title="Invoice successfully rejected"
                  bgColor="bg-yellow-300"
                  headingColor="text-red-alert"
                />
              )}
            <div className="flex md:flex-row flex-col items-start justify-between w-full gap-6">
              <IncomingInvoiceDetails importedInvoiceData={importedPublicInvoice} />
              <PublicImportingInvoice
                importedInvoiceData={importedPublicInvoice}
                validationButtonClicked={validationStatusChanged}
              />
            </div>
          </div>
          <div className="text-gray-450 text-sm mt-8">
            <p className="text-bold"> DISCLAIMER </p>
            This invoice contain information provided solely by the sender and may include logos, payment details, or
            other proprietary data. Recipients are advised to exercise caution and verify any information contained
            herein before taking any action based on it. Statrys Limited expressly denies any and all liability arising
            out of or in connection with the content of this email and the attached invoice. The sender bears full
            responsibility for the content and any consequences arising from its use or dissemination.
          </div>
        </>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicInvoice: (invoiceId) => dispatch(importingInvoiceReducer.getImportingInvoicePublic(invoiceId))
  };
};

export default connect(null, mapDispatchToProps)(ViewIncomingInvoicesPublic);

ViewIncomingInvoicesPublic.propTypes = {
  getPublicInvoice: PropTypes.func
};
