import React, { useState, useEffect } from 'react';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import ValidationMessage from '../ui/ValidationMessage';
import { REGEX, MUI_COLORS, API_CODE, FILE_UPLOAD_TYPE } from '../../constants/enum';
import { defaultValidationMessage } from '../../helper/helpers';
import { connect } from 'react-redux';
import * as accountSettingActions from '../accountSetting/reduxStore/action';
import PropTypes from 'prop-types';
import XSText from '../ui/typography/XSText';
import Snackbar from '../Snackbar';
import Image from '../Image';
import Success from '../../images/icons/success-icon.svg';
import MSText from '../ui/typography/MSText';
import UploadImage from '../common/UploadImage';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';

function CompanyDetails({ updateCompanyDetails, createCompanyDetails, getCompanyDetails, uploadContactImage }) {
  const [open, setOpen] = useState(false);
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [companyFormData, SetCompanyFormdata] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [companyDetailsMessage, setCompanyDetailsMessage] = useState({
    title: '',
    content: ''
  });
  const { register, handleSubmit, errors } = useForm();

  const emailRegex = REGEX.EMAIL_REGEX;

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const getProfileUrl = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedProfileResponse = await uploadContactImage(formData);
    if (uploadedProfileResponse?.status === API_CODE.STATUS_200) {
      createAndEditCompanyModal(data, uploadedProfileResponse?.data?.data?.file);
    } else {
      setUploadError(true);
      setButtonLoader(false);
    }
  };

  const onSelectingCountry = (country) => {
    setSelectedCountry(country?.name);
  };

  const onSubmitCompanyDetails = async (data) => {
    setButtonLoader(true);
    if (saveImage) {
      getProfileUrl(data);
    } else {
      createAndEditCompanyModal(data);
    }
  };

  const createNewCompanyDetails = async (data, imageUrl) => {
    const companyDetailRequest = {
      ...data,
      country: selectedCountry ? selectedCountry : companyFormData?.country,
      logo: imageUrl ? imageUrl : companyFormData?.logo
    };
    const createCompanyDetail = await createCompanyDetails(companyDetailRequest);
    if (createCompanyDetail?.status === API_CODE.STATUS_200) {
      setCompanyDetailsMessage({
        ...companyDetailsMessage,
        title: createCompanyDetail?.data?.message,
        content: `Your information was saved sucessfully`
      });
      getCompanyDetailsHandler();
      setOpen(true);
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
    }
  };

  const updateCompanyHandler = async (data, imageUrl) => {
    const updateCompanyDetailRequest = {
      ...data,
      country: selectedCountry ? selectedCountry : companyFormData?.country,
      logo: imageUrl ? imageUrl : companyFormData?.logo
    };
    const updatedCompanyDetails = await updateCompanyDetails(updateCompanyDetailRequest);
    if (updatedCompanyDetails?.status === API_CODE.STATUS_200) {
      const updateCompanyData = updatedCompanyDetails?.data?.data;
      setCompanyDetailsMessage({
        ...companyDetailsMessage,
        title: updatedCompanyDetails?.data?.message,
        content: `${
          updateCompanyData?.companyName ? updateCompanyData?.companyName : updateCompanyData?.email
        } was Successfully Updated`
      });
      getCompanyDetailsHandler();
      setOpen(true);
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
    }
  };

  const createAndEditCompanyModal = async (data, imageUrl) => {
    if (!companyFormData?._id) {
      createNewCompanyDetails(data, imageUrl);
    } else {
      updateCompanyHandler(data, imageUrl);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCompanyDetailsHandler = async () => {
    const companyDetailsResponse = await getCompanyDetails();
    if (companyDetailsResponse?.status === API_CODE.STATUS_200) {
      SetCompanyFormdata(companyDetailsResponse?.data?.data);
      setSelectedImage(
        companyDetailsResponse?.data?.data?.logo
          ? [{ preview: companyDetailsResponse?.data?.data?.logo, type: FILE_UPLOAD_TYPE.URL }]
          : ''
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDetailsHandler();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="setting-form-container bg-white md:ml-6 pb-8">
            <div className="md:ml-6 md:mx-0 mx-6">
              <MDSubtitleText
                className="py-6"
                fontWeight="text-bold"
                textColor="text-gray-500"
                title="Company details"
              />
              <div className="flex md:flex-row flex-col">
                <form id="account-setting-form" className="md:w-2/3" onSubmit={handleSubmit(onSubmitCompanyDetails)}>
                  <TextInput
                    defaultValue={companyFormData?.companyName}
                    margin="w-full"
                    name="companyName"
                    label="Company Name"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <TextInput
                    margin="mt-4"
                    defaultValue={companyFormData?.email}
                    name="email"
                    label="Email Address"
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: emailRegex,
                        message: 'Please fill in a valid Email Address'
                      }
                    })}
                    error={errors.email ? true : false}
                    helperText={errors.email && <ValidationMessage title={defaultValidationMessage('Email address')} />}
                  />
                  <div className="flex md:flex-row flex-col md:gap-4 w-full">
                    <TextInput
                      defaultValue={companyFormData?.registrationNumber}
                      margin="mt-4 w-full"
                      name="registrationNumber"
                      label="Registration number"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <TextInput
                      defaultValue={companyFormData?.website}
                      margin="mt-4 w-full"
                      name="website"
                      label="Website"
                      inputRef={register({
                        required: false
                      })}
                    />
                  </div>
                  <TextInput
                    defaultValue={companyFormData?.address}
                    margin="mt-4 w-full"
                    name="address"
                    label="Address"
                    inputRef={register({
                      required: false
                    })}
                  />
                  <div className="flex md:flex-row flex-col md:gap-4 w-full">
                    <TextInput
                      defaultValue={companyFormData?.city}
                      margin="mt-4 w-full"
                      name="city"
                      label="City"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <TextInput
                      defaultValue={companyFormData?.state}
                      margin="mt-4 w-full"
                      name="state"
                      label="State"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <TextInput
                      defaultValue={companyFormData?.postalCode}
                      margin="mt-4 w-full"
                      name="postalCode"
                      label="Zip / Postal Code"
                      inputRef={register({
                        required: false
                      })}
                    />
                  </div>
                  <div className="mt-4">
                    <SelectCountryAutoComplete
                      defaultValue={companyFormData?.country}
                      setSelectedValue={onSelectingCountry}
                      label="Country"
                      name="country"
                    />
                  </div>
                </form>
                <div className="md:w-1/3 md:order-none order-first">
                  <UploadImage
                    saveImage={saveImage}
                    selectedImage={selectedImage}
                    uploadError={uploadError}
                    fileSelection={fileSelection}
                  />
                </div>
              </div>
            </div>
            <Snackbar
              open={open}
              handleClose={handleClose}
              sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
              title={
                <div className="flex">
                  <div className="flex items-center">
                    <Image className="w-5 h-5" src={Success} />
                  </div>
                  <div className="ml-4">
                    <XSText
                      fontWeight="text-bold"
                      textColor="text-gray-500"
                      title={companyDetailsMessage?.title}
                    ></XSText>
                    <MSText textColor="text-gray-500" title={companyDetailsMessage?.content}></MSText>
                  </div>
                </div>
              }
            />
          </div>
          <div className="flex justify-end gap-4 mt-8">
            <PrimaryButton
              id="company-details-save-btn"
              isLoading={buttonLoader}
              className="setting-section-button"
              caption="Save Changes"
              onClick={handleSubmit(onSubmitCompanyDetails)}
            />
          </div>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.updateCompanyDetails(companyDetailsPayload)),
    createCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.createCompanyDetails(companyDetailsPayload)),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails()),
    uploadContactImage: (payload) => dispatch(commonReduxActions.fileUpload(payload))
  };
};

export default connect(null, mapDispatchToProps)(CompanyDetails);

CompanyDetails.propTypes = {
  updateCompanyDetails: PropTypes.func,
  createCompanyDetails: PropTypes.func,
  companyDetails: PropTypes.any,
  getCompanyDetails: PropTypes.func
};
