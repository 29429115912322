import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isNotFound: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string
};

TableNoData.defaultProps = {
  title: 'No Data'
};

export default function TableNoData({ isNotFound, title, description }) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12} className="h-52">
          <EmptyContent
            title={title}
            description={description}
            sx={{
              '& span.MuiBox-root': { height: 160 }
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
