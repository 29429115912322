export const GET_COMPANY_DETAILS_REQUEST_START = 'GET_COMPANY_DETAILS_REQUEST_START';
export const GET_COMPANY_DETAILS_REQUEST_SUCCESS = 'GET_COMPANY_DETAILS_REQUEST_SUCCESS';
export const GET_COMPANY_DETAILS_REQUEST_FAILURE = 'GET_COMPANY_DETAILS_REQUEST_FAILURE';

export const GET_COMPANY_DOCUMENTS_REQUEST_START = 'GET_COMPANY_DOCUMENTS_REQUEST_START';
export const GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS = 'GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS';
export const GET_COMPANY_DOCUMENTS_REQUEST_FAILURE = 'GET_COMPANY_DOCUMENTS_REQUEST_FAILURE';

export const GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_START = 'GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_START';
export const GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_SUCCESS = 'GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_SUCCESS';
export const GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_FAILURE = 'GET_COMPANY_LATEST_UPDATE_DETAILS_REQUEST_FAILURE';

export const GET_COMPANY_STACK_HOLDERS_REQUEST_START = 'GET_COMPANY_STACK_HOLDERS_REQUEST_START';
export const GET_COMPANY_STACK_HOLDERS_REQUEST_SUCCESS = 'GET_COMPANY_STACK_HOLDERS_REQUEST_SUCCESS';
export const GET_COMPANY_STACK_HOLDERS_REQUEST_FAILURE = 'GET_COMPANY_STACK_HOLDERS_REQUEST_FAILURE';

export const GET_COMPANY_SECRETARY_REQUEST_START = 'GET_COMPANY_SECRETARY_REQUEST_START';
export const GET_COMPANY_SECRETARY_REQUEST_SUCCESS = 'GET_COMPANY_SECRETARY_REQUEST_SUCCESS';
export const GET_COMPANY_SECRETARY_REQUEST_FAILURE = 'GET_COMPANY_SECRETARY_REQUEST_FAILURE';

export const COMPANY_MAILROOM_REQUEST_START = 'COMPANY_MAILROOM_REQUEST_START';
export const COMPANY_MAILROOM_REQUEST_SUCCESS = 'COMPANY_MAILROOM_REQUEST_SUCCESS';
export const COMPANY_MAILROOM_REQUEST_FAILURE = 'COMPANY_MAILROOM_REQUEST_FAILURE';

export const COMPANY_SECRETARY_REQUEST_START = 'COMPANY_SECRETARY_REQUEST_START';
export const COMPANY_SECRETARY_REQUEST_SUCCESS = 'COMPANY_SECRETARY_REQUEST_SUCCESS';
export const COMPANY_SECRETARY_REQUEST_FAILURE = 'COMPANY_SECRETARY_REQUEST_FAILURE';
