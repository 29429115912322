import * as actionTypes from './actionTypes';

let initialState = {
  updatedMarkAsPaidData: {},
  markAsPaidData: {},
  deleteMarkAsPaidData: {},
  sendInvoiceEmailData: {},
  validateInvoiceData: {},
  loading: false,
  error: false
};

const incomingInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MARK_AS_PAID_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.MARK_AS_PAID_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.MARK_AS_PAID_REQUEST_SUCCESS:
      return {
        ...state,
        markAsPaidData: { ...action.markAsPaidData },
        loading: false,
        error: false
      };

    case actionTypes.UPDATE_MARK_AS_PAID_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_MARK_AS_PAID_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPDATE_MARK_AS_PAID_REQUEST_SUCCESS:
      return {
        ...state,
        updatedMarkAsPaidData: { ...action.markAsPaidData },
        loading: false,
        error: false
      };

    case actionTypes.DELETE_MARK_AS_PAID_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_MARK_AS_PAID_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.DELETE_MARK_AS_PAID_REQUEST_SUCCESS:
      return {
        ...state,
        deleteMarkAsPaidData: { ...action.markAsPaidData },
        loading: false,
        error: false
      };

    case actionTypes.SEND_INVOICE_EMAIL_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SEND_INVOICE_EMAIL_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.SEND_INVOICE_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        sendInvoiceEmailData: { ...action.emailResponse },
        loading: false,
        error: false
      };

    case actionTypes.INVOICE_VALIDATE_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_VALIDATE_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_VALIDATE_REQUEST_SUCCESS:
      return {
        ...state,
        validateInvoiceData: { ...action.validationResponse },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};

export default incomingInvoiceReducer;
