import React from 'react';
import ModalThankyouImg from '../../../images/graphics/modal-thankyou.jpg';
import XXSText from '../../ui/typography/XXSText';
export default function Thankyou() {
  return (
    <div className="flex flex-col justify-center modal-step-container">
      <img src={ModalThankyouImg} alt="ModalThankyouImg" className="w-1/2 mx-auto" />
      <XXSText
        className="mb-12 mt-2 md:whitespace-pre-line mx-auto"
        title={`Thank you, you are now ready to\n start creating your first invoice!`}
      />
    </div>
  );
}
