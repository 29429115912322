import * as React from "react"
// import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
import CheckboxBorderIcon from '../../images/icons/customSvg/CheckboxBorderIcon';
import CheckboxChecked from "../../images/icons/customSvg/CheckboxChecked";
import CheckboxIndeterminateIcon from "../../images/icons/customSvg/CheckboxIndeterminateIcon";
// ----------------------------------------------------------------------

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxBorderIcon />,
        checkedIcon: <CheckboxChecked />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          '&.MuiButtonBase-root':{
            padding:"0px",
          },
          padding: theme.spacing(1),
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 24,
            height: 24,
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 20,
            height: 20,
          },
          svg: {
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20,
            },
          },
        },
      },
    },
  };
}
