import { combineReducers } from 'redux';
import ConfirmEmailReducer from '../../components/confirmEmailAccount/reduxStore/reducer';
import InvoiceDetails from '../../components/invoice/reduxStore/reducer';
import LoginReducer from '../../components/login/reduxStore/reducer';
import SignupReducer from '../../components/signup/reduxStore/reducer';
import OtpReducer from '../../components/otp/reduxStore/reducer';
import UserProfileReducer from '../../components/profile/reduxStore/reducer';
import ContactReducer from '../../components/contact/reduxStore/reducer';
import AccountSettings from '../../components/accountSetting/reduxStore/reducer';
import CommonReduxStore from '../../components/commonReduxStore/reduxStore/reducer';
import ImportingInvoiceReducer from '../../components/importedInvoices/reduxStore/reducer';
import SupplierReducer from '../../components/supplier/reduxStore/reducer';
import IncomingInvoiceReducer from '../../components/IncomingInvoices/reduxStore/reducer';
import InvoicePaymentDetails from '../../components/invoicePaymentDetails/reduxStore/reducer';
import DashboardInvoiceReducer from '../../components/dashboard/reduxStore/reducer';
import CompanyReducer from '../../components/company/reduxStore/reducer';
import AdminBackOfficeReducer from '../../components/boInvoice/reduxStore/reducer';
import CompanyIncorporationReducer from '../../components/companyIncorporation/reduxStore/reducer';
const appReducer = combineReducers({
  invoiceDetails: InvoiceDetails,
  signupReducer: SignupReducer,
  loginReucer: LoginReducer,
  confirmEmailReducer: ConfirmEmailReducer,
  otpReducer: OtpReducer,
  userProfieReducer: UserProfileReducer,
  contactReducer: ContactReducer,
  accountSettings: AccountSettings,
  commonReduxStore: CommonReduxStore,
  importingInvoiceReducer: ImportingInvoiceReducer,
  supplierReducer: SupplierReducer,
  incomingInvoiceReducer: IncomingInvoiceReducer,
  invoicePaymentDetails: InvoicePaymentDetails,
  dashboardInvoiceReducer: DashboardInvoiceReducer,
  companyReducer: CompanyReducer,
  adminBackOfficeReducer: AdminBackOfficeReducer,
  companyIncorporationReducer: CompanyIncorporationReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT' || action.type === 'RESET_REDUX_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
