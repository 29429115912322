import {
  FILE_UPLOAD_TYPE,
  API_ERROR_CODES,
  ERROR_STATUS_CODE,
  FILE_SIZE,
  REQUIRED_LENGTH,
  MUI_COLORS,
  AVATAR_COlORS,
  REGEX,
  API_CODE
} from '../constants/enum';
import { DateFormats } from '../constants/enum';
import moment from 'moment';

export const handleEnter = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
  if (
    event.target.value.length === 0 &&
    event.key.toLowerCase() === 'backspace' &&
    (event.target.name === 'month' || event.target.name === 'year')
  ) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index - 1].focus();
    event.preventDefault();
  }
};

export const isBrowser = () => typeof window !== 'undefined';

export const companyAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.MSWORD ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.OFFICE_DOCUMENT) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};
export const stakeHolderAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};

export const MatchingModalApiErrorStatus = (getApiError) => {
  if (
    (getApiError?.status === API_ERROR_CODES.STATUS_422 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1038 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1008) ||
    getApiError?.status === API_ERROR_CODES.STATUS_412 ||
    getApiError?.status === API_ERROR_CODES.STATUS_403 ||
    getApiError?.status === API_ERROR_CODES?.STATUS_402
  ) {
    return true;
  } else {
    return false;
  }
};
export const MatchingOopsApiErrorStatus = (error) => {
  if (
    error?.response?.status !== API_ERROR_CODES.STATUS_422 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_412 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_401 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_402 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_403 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_400
  ) {
    return true;
  } else {
    return false;
  }
};

export const Matching401ApiErrorStatus = (error) => {
  if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
    return true;
  } else {
    return false;
  }
};

export const convertDateToDDMMYYYY = (date) => {
  return moment(date).format(DateFormats.DDMMMYYY);
};

export const getChoppedDocumentName = (selectedDocs) => {
  let choppedDocumentname = selectedDocs[0].name
    .split('.')[0]
    .substring(0, REQUIRED_LENGTH.ALLOWED_DOCUMENT_LENGTH + 1);
  return choppedDocumentname;
};

export const stringTruncate = (text, numberOfString) => {
  let finalString;
  if (text?.length > numberOfString) {
    finalString = text?.substring(0, numberOfString) + '...';
  } else {
    finalString = text;
  }
  return finalString;
};

export const makeFirstLetterCapitalize = (value) => {
  const capitalizedString = value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : '';
  return capitalizedString;
};

export const invoiceInputBackgroundColor = (inputValue) => {
  return inputValue ? MUI_COLORS.WHITE : MUI_COLORS.PAPAYA_WHIP;
};

export const defaultValidationMessage = (fieldName) => {
  return `Please provide a valid ${fieldName}`;
};

export const itemsTotalPrice = (itemDetails) => {
  let totalItemsAmount = 0;
  itemDetails?.forEach((itemDetails) => {
    totalItemsAmount = totalItemsAmount + itemDetails?.itemPrice * itemDetails?.itemQuantity;
  });
  return totalItemsAmount;
};

export const tasksTotalPrice = (taskDetails) => {
  let totalTasksAmount = 0;
  taskDetails?.forEach((taskDetails) => {
    totalTasksAmount = totalTasksAmount + taskDetails?.taskHours * taskDetails?.taskRate;
  });
  return totalTasksAmount;
};

export const newItemsTotalPrice = (itemDetails) => {
  let totalNewItemsAmount = 0;
  itemDetails?.forEach((itemDetails) => {
    totalNewItemsAmount = totalNewItemsAmount + itemDetails?.itemPriceRate * itemDetails?.itemQuantity;
  });
  return totalNewItemsAmount;
};

export const priceCalculatorThroughPercentage = (total, percentage) => {
  return (total * percentage) / 100;
};
export const isEven = (num) => {
  return num % 2 === 0;
};

export const concatenateSelectedCurrency = (currency, amount, nofractionDigits) => {
  return `${currency?.symbol ? currency?.symbol : currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};

export const concatenateCurrencyAmountWithoutSpace = (currency, amount, nofractionDigits) => {
  return `${currency?.symbol ? currency?.symbol : currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};

export const multiplyNumbers = (Numbersarray) => {
  const sum = Numbersarray.reduce((prevValue, curValue) => {
    return prevValue * curValue;
  }, 1);
  return sum;
};
export const concatenateSelectedCurrencyForPDF = (currency, amount, nofractionDigits) => {
  return `${currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};
const colors = AVATAR_COlORS;
export function getAvatarBackgroundColor(text) {
  const charCodes = text
    ?.split('')
    .map((char) => char.charCodeAt(0))
    .join('');
  return colors[parseInt(charCodes, 10) % colors.length];
}

export const remainingAmount = (currency, totalAmount, paidAmount) => {
  const remaining = totalAmount - paidAmount;
  return concatenateSelectedCurrency(currency, remaining);
};
export function isIbanNumber(number) {
  if (
    number.charAt(0).match(REGEX.ONLY_STRING_REGEX) &&
    number.charAt(1).match(REGEX.ONLY_STRING_REGEX) &&
    number.charAt(2).match(REGEX.NUMBER_CHECKER_REGEX) &&
    number.charAt(3).match(REGEX.NUMBER_CHECKER_REGEX)
  ) {
    return 'IBAN:';
  } else return 'Account Number:';
}
export const addCommas = (num) => {
  const addedValue = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return addedValue?.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
};

export const getURLFirstPathName = (urlPathName) => {
  const firstUrlPath = urlPathName?.replace(/^\/([^\/]*).*$/, '$1');
  return firstUrlPath;
};

export const getSecondLastPathName = (pathname) => {
  const secondUrlPath = pathname?.substr(pathname?.lastIndexOf('/') + 1);
  return secondUrlPath;
};

export function takeOutImageName(url) {
  const parts = url?.split('/');
  return parts?.[parts?.length - 1];
}

export function getFileExtension(url) {
  const extension = url?.split('.').pop();
  return extension;
}
export const sendHubspotRequest = (final_data, hubspot_integration) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', hubspot_integration);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      return true;
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      alert(xhr.responseText);
    }
  };
};

export const getFileName = (fileUrl) => {
  var url = fileUrl;
  var fileName = url?.substring(url?.lastIndexOf('/') + 1);
  const fileNameFromUrl = {
    name: decodeURI(fileName)
  };
  return fileNameFromUrl;
};

export const findTotalCharacter = (currency, amount) => {
  if (currency && amount) {
    const countOfChar = `${currency?.symbol ? currency?.symbol : currency?.name} ${addCommas(amount)}`;
    if (countOfChar.length > 12) {
      return true;
    } else {
      return false;
    }
  }
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function checkValueExistOrNot(value) {
  return value ? value : '-';
}

export const addYearToConvertDate = (date) => {
  return moment(date).add(1, 'year').format(DateFormats.DDMMMYYY);
};

export const downloadImage = (imageUrl) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);

      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const isDecimal = (num) => {
  if (num % 1 !== 0) return true;
};

export const getLocalStorageValue = (key) => {
  const value = isBrowser && localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const storeDataInLocalStorage = (key, data) => {
  try {
    const dataString = JSON.stringify(data);
    localStorage.setItem(key, dataString);
    return true;
  } catch (error) {
    return false;
  }
};
