import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import {
  API_CODE,
  ENVIRONMENT_VARIABLES,
  MUI_COLORS,
  REQUIRED_LENGTH,
  SENDGRID_DETAILS,
  TYPE_OF_OTP
} from '../../constants/enum';
import '../../styles/verifyOtpInput.scss';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as OtpActions from './reduxStore/action';
import * as userActions from '../invoice/reduxStore/action';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import { navigate } from 'gatsby';
import { handleLogin } from '../authService/auth';
import { useLocation } from '@reach/router';
import { sendHubspotRequest } from '../../helper/helpers';
import { ENDPOINTS, SUCCESSFULL_SIGNUP } from '../../service/services';
import axios from 'axios';
function VerifyOtpInput({
  email,
  fromForgotPassword,
  emailForConfirmation,
  fromResendCode,
  getInvoiceGenerator,
  updateUserInvoiceGenerator,
  sendToSendGridContacts
}) {
  const [otp, setOtp] = useState('');
  const [error, setOtpError] = useState('');
  const [isTemporary, setIsTemporary] = useState(false);
  const [invoiceGeneratorData, setInvoiceGeneratorData] = useState('');
  const location = useLocation();

  const handleChange = async (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === REQUIRED_LENGTH.OTP_LENGTH) {
      let payload = {
        email: fromForgotPassword || fromResendCode ? emailForConfirmation : email,
        otp: otpValue,
        type: fromForgotPassword
          ? TYPE_OF_OTP?.FORGOT_PASSWORD
          : fromResendCode
          ? TYPE_OF_OTP?.RESEND_CODE
          : TYPE_OF_OTP?.SIGN_UP
      };
      try {
        const otpResponse = await axios.put(`${process.env.BASE_API_URL}${ENDPOINTS?.VERIFY_OTP}`, payload);
        if (otpResponse?.status === API_CODE?.STATUS_200) {
          fromForgotPassword
            ? navigate(PATH_PAGE?.resetPassword, {
                state: { emailForResetPassword: emailForConfirmation, otpForResetPassword: otpValue }
              })
            : isTemporary
            ? invoiceGeneratorData && handleUpdateUserTempInvoice(invoiceGeneratorData, otpResponse?.data?.data)
            : handleLogin(otpResponse?.data?.data?.token, otpResponse?.data?.data);
          if (process.env.ENVIRONMENT === ENVIRONMENT_VARIABLES.PRODUCTION) {
            !fromForgotPassword && (await sendEmailToSendGridContacts(email));
          }
          !fromForgotPassword && (await sendHubspot(email));
        } else {
          setOtpError(otpResponse?.data?.message);
        }
      } catch (error) {
        setOtpError(error?.response?.data?.message);
      }
    }
  };

  async function sendEmailToSendGridContacts(email) {
    let sendGridPayload = {
      sendGrid: {
        list_ids: [SENDGRID_DETAILS.LIST_IDS],
        contacts: [{ email: email }]
      }
    };
    await sendToSendGridContacts(sendGridPayload);
  }

  async function sendHubspot(email) {
    let hubspotPayload = {
      fields: [
        {
          name: 'email',
          value: email
        }
      ],
      context: {
        pageUri: `${process.env.SITE_URL}/verify-email`,
        pageName: 'Invoice signup verify OTP'
      }
    };
    var finalData = JSON.stringify(hubspotPayload);
    sendHubspotRequest(finalData, SUCCESSFULL_SIGNUP?.HUBSPOT_URL, true);
  }

  const handleUpdateUserTempInvoice = async (invoiceGeneratorData, otpResponse) => {
    const payload = {
      invoiceId: invoiceGeneratorData?._id,
      userId: otpResponse?._id
    };
    const updatedUserTempInvoice = await updateUserInvoiceGenerator(payload);
    if (updatedUserTempInvoice?.status === API_CODE.STATUS_200) {
      handleLogin(otpResponse?.token, otpResponse);
    }
  };

  const getTempInvoiceData = async (invoiceId) => {
    const getInvoiceGeneratorResponse = await getInvoiceGenerator(invoiceId);
    if (getInvoiceGeneratorResponse?.status === API_CODE.STATUS_200) {
      setInvoiceGeneratorData(getInvoiceGeneratorResponse?.data?.data);
    }
  };

  useEffect(() => {
    if (location?.state?.isTemporary && location?.state?.invoiceId) {
      setIsTemporary(true);
      getTempInvoiceData(location?.state?.invoiceId);
    }
  }, []);

  const handleClickCapture = () => {
    if (error) {
      setOtp('');
      setOtpError('');
    }
  };

  return (
    <div onClickCapture={handleClickCapture}>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        shouldAutoFocus={true}
        separator={<span className="ml-2"></span>}
        inputStyle={error ? 'otp-input-error' : 'otp-input'}
        focusStyle={{
          border: error ? '1px solid #FF4F42' : '1px solid #2D0D45',
          background: error ? MUI_COLORS.LIGHT_CREAM : MUI_COLORS.WHITE,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          outline: 'none'
        }}
      />
      {error && <ValidationMessage title={error} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state?.signupReducer?.signupDetails?.data?.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceGenerator: (invoiceData) => dispatch(userActions.getInvoiceGenerator(invoiceData)),
    updateUserInvoiceGenerator: (invoiceData) => dispatch(userActions.updateUserInvoiceGenerator(invoiceData)),
    sendToSendGridContacts: (contacts) => dispatch(OtpActions.sendContacts(contacts))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpInput);

VerifyOtpInput.propTypes = {
  email: PropTypes.string,
  fromForgotPassword: PropTypes.bool,
  emailForConfirmation: PropTypes.string,
  fromResendCode: PropTypes.bool,
  getInvoiceGenerator: PropTypes.func,
  updateUserInvoiceGenerator: PropTypes.func,
  sendToSendGridContacts: PropTypes.func
};
