import React, { useState, useRef, useEffect } from 'react';
import StatrysSupportLogo from '../../../images/graphics/statrys-support.png';
import '../../../styles/support.scss';
import HelpCenterForm from './HelpCenterForm';
import Snackbar from '../../../components/Snackbar';
import SuccessIcon from '../../../images/icons/success-icon.svg';
import { MUI_COLORS } from '../../../constants/enum';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import Image from '../../../components/Image';

export default function StatrysSupport() {
  const [showSupportForm, setShowSupportForm] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const supportRef = useRef(null);
  const needSupport = () => {
    setShowSupportForm(!showSupportForm);
  };

  const closeForm = (value) => {
    setShowSupportForm(false);
    if (value) {
      setIsSnackBarOpen(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (supportRef.current && !supportRef.current.contains(event.target)) {
        setShowSupportForm(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  return (
    <>
      <div className="flex justify-end fixed support-icon-container float-right" ref={supportRef}>
        <div
          className="bg-coral-500 flex justify-center support-icon items-center w-16 h-16 rounded-full cursor-pointer"
          onClick={needSupport}
        >
          <img src={StatrysSupportLogo} alt="support logo" className="support-logo" />
        </div>
        {showSupportForm && <HelpCenterForm cancelSupport={closeForm} />}
      </div>
      <Snackbar
        open={isSnackBarOpen}
        handleClose={closeSnackbar}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5 object-contain" src={SuccessIcon} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title="Email sent!"></XSText>
              <MSText textColor="text-gray-500" title="Our customer support team will reach out to you soon."></MSText>
            </div>
          </div>
        }
      />
    </>
  );
}
