import React from 'react';
import '../../../styles/circular-spinner.scss';
import PropTypes from 'prop-types';
export default function CircularLoader() {
  return (
    <div className="circular-loader mx-auto">
      <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle className="circular-load three" cx="60" cy="60" r="40" strokeLinecap="round" />
        <circle className="circular-load two" cx="60" cy="60" r="40" strokeLinecap="round" />
        <circle className="circular-load one" cx="60" cy="60" r="40" strokeLinecap="round" />
        <g></g>
      </svg>
    </div>
  );
}
