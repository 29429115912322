import * as actionTypes from './actionTypes';

let initialState = {
  signUpDetails: {},
  companyRegistrationDetails: {},
  loading: false,
  error: false
};

const AdminBackOfficeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        signUpDetails: action.signUpPayload,
        loading: false,
        error: false
      };
    case actionTypes.SIGNUP_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        companyRegistrationDetails: action.companyRegistrationDataPayload,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_REGISTRATION_DATA_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default AdminBackOfficeReducer;
