import React from 'react';
import '../../styles/pages/login.scss';
import PropTypes from 'prop-types';
import { LOGIN_TAB } from '../../constants/enum';
function LoginImgBanner({ children, activeLoginForm }) {
  return (
    <div
      className={`w-full ${
        activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'company-image-container' : 'img-container'
      } flex flex-col items-center justify-center`}
    >
      <div className="flex flex-col lg:flex-row lg:gap-24 gap-12 md:px-12 xl:px-36 items-center justify-between my-8">
        {children}
        <div className="px-4">
          <div className="login-holiday-name"></div>
        </div>
      </div>
    </div>
  );
}
export default LoginImgBanner;

LoginImgBanner.propTypes = {
  children: PropTypes.any
};
