export const GET_COMPANY_INCORPORATION_REQUEST_START = 'GET_COMPANY_INCORPORATION_REQUEST_START';
export const GET_COMPANY_INCORPORATION_REQUEST_FAIL = 'GET_COMPANY_INCORPORATION_REQUEST_FAIL';
export const GET_COMPANY_INCORPORATION_REQUEST_SUCCESS = 'GET_COMPANY_INCORPORATION_REQUEST_SUCCESS';

export const COMPANY_INCORPORATION_REQUEST_START = 'COMPANY_INCORPORATION_REQUEST_START';
export const COMPANY_INCORPORATION_REQUEST_FAIL = 'COMPANY_INCORPORATION_REQUEST_FAIL';
export const COMPANY_INCORPORATION_REQUEST_SUCCESS = 'COMPANY_INCORPORATION_REQUEST_SUCCESS';

export const UPLOAD_PASSPORT_REQUEST_START = 'UPLOAD_PASSPORT_REQUEST_START';
export const UPLOAD_PASSPORT_REQUEST_FAIL = 'UPLOAD_PASSPORT_REQUEST_FAIL';
export const UPLOAD_PASSPORT_REQUEST_SUCCESS = 'UPLOAD_PASSPORT_REQUEST_SUCCESS';
