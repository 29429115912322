import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import XSText from '../typography/XSText';
import { MUI_COLORS } from '../../../constants/enum';
export default function TextInput({
  inputContainerClass,
  name,
  label,
  placeholder,
  inputRef,
  error,
  showValidation,
  helperText,
  onChange,
  value,
  type,
  onKeyDown,
  max,
  defaultValue,
  backgroundColor,
  rows
}) {
  return (
    <div className={inputContainerClass}>
      <TextField
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: max }}
        name={name}
        label={label}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: backgroundColor,
            borderRadius: '8px'
          },
          '& .MuiInputBase-input': {
            padding: '1.5px 14px 13.5px 0px'
          }
        }}
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        error={error}
        helperText={helperText}
        type={type}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        rows={rows}
        multiline
      />
      {showValidation && (
        <div className="flex justify-between mt-2">
          <XSText
            textColor={error ? 'error-text-description' : 'text-light-gray'}
            title="Minimum 50 characters"
            className="ml-1"
          />
          <XSText
            textColor={error ? 'error-text-description' : 'text-light-gray'}
            title={`${value ? value?.length : 0}/50`}
          />
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  inputContainerClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  onKeyDown: PropTypes.any,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  defaultValue: PropTypes.any,
  height: PropTypes.any,
  showValidation: PropTypes.any,
  backgroundColor: PropTypes.string
};

TextInput.defaultProps = {
  className: 'w-full',
  type: 'string',
  height: '136',
  rows: '4',
  backgroundColor: MUI_COLORS.WHITE
};
