import React from 'react';
import PropTypes from 'prop-types';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';

export default function MessageComponent({ image, title, description, bgColor, headingColor }) {
  return (
    <div
      className={`w-full ${bgColor} rounded-2xl`}
      style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}
    >
      <div className="flex md:flex-row flex-col items-center justify-between px-8">
        <div className="flex flex-col gap gap-2">
          <MDSubtitleText title={title} fontWeight="text-bold" textColor={headingColor} />
          <XSText title={description} className="md:whitespace-pre-line" />
        </div>
        <img src={image} alt="validation" width="260" height="198" className="md:mr-20 mr-10" />
      </div>
    </div>
  );
}
MessageComponent.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.object,
  bgColor: PropTypes.string,
  headingColor: PropTypes.string
};
