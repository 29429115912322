import React, { useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import UploadPaymentProof from '../publicInvoice/UploadPaymentProof';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_CODE, FILE_EXTENTION_TYPE, FILE_UPLOAD_TYPE, INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
import moment from 'moment';
import { InputAdornment } from '@mui/material';
import BaseText from '../ui/typography/BaseText';
import * as paymentActionRedux from '../invoicePaymentDetails/reduxStore/action';
import { getUTCBasedDate } from '../../utils/formatTime';
import * as invoiceReduxActions from '../invoice/reduxStore/action';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import { getFileExtension, getFileName } from '../../helper/helpers';
import FileIcon from '../../images/icons/file-icon.svg';

const options = [
  {
    name: 'Cash'
  },
  {
    name: 'Cheque'
  },
  {
    name: 'Credit Card'
  },
  {
    name: 'Bank Transfer'
  },
  {
    name: 'PayPal'
  },
  {
    name: 'PayMe'
  }
];

function ApprovalConfirmationModal({
  getInvoiceDetails,
  getPaymentDetails,
  openDynamicModal,
  closeDynamicModal,
  invoicePaymentApprove,
  handleInvoiceDetailsbyID,
  invoiceDeleteMarkPayment,
  invoiceUpdateMarkPayment,
  editInvoice,
  uploadImage
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [paymentType, setPaymentType] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAmountValid, setIsAmountValid] = useState(false);

  const handleResetModal = () => {
    setAcceptedFile('');
    setSaveImage(false);
    setSelectedImage('');
    setPaymentType('');
    setIsLoading(false);
    closeDynamicModal();
  };

  const handleCheckValidAmount = (e) => {
    if (getInvoiceDetails?.payments?.length > 0) {
      if (Number(e.target.value) <= Number(getInvoiceDetails?.pendingAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    } else {
      if (Number(e.target.value) <= Number(getInvoiceDetails?.totalAmount)) {
        setIsAmountValid(false);
      } else {
        setIsAmountValid(true);
      }
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    if (!saveImage) {
      const payload = {
        paidDate: data.paidDate,
        paymentType: paymentType?.name ? paymentType?.name : getPaymentDetails?.paymentType,
        paidAmount: data?.amount ? data?.amount : getPaymentDetails?.paidAmount,
        paymentProof: getPaymentDetails?.paymentProof,
        invoiceId: getInvoiceDetails?._id,
        paymentId: getPaymentDetails?._id
      };
      updateMarkPaymentHandler(payload);
    } else {
      uploadPaymentProof(data);
    }
  };

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const handleDeletePayment = () => {
    setIsLoading(true);
    const queryParams = {
      params: { invoiceId: getInvoiceDetails?._id, paymentId: getPaymentDetails?._id }
    };
    deleteMarkPaymentHandler(queryParams);
  };

  const onApprovalOfPayment = async (payload) => {
    const invoicePaymentApproveResponse = await invoicePaymentApprove(payload);
    if (invoicePaymentApproveResponse?.status === API_CODE.STATUS_200) {
      handleResetModal();
      handleInvoiceDetailsbyID();
    }
  };

  const deleteMarkPaymentHandler = async (queryParams) => {
    const invoiceDeletePaymentResponse = await invoiceDeleteMarkPayment(queryParams);
    if (invoiceDeletePaymentResponse?.status === API_CODE.STATUS_200) {
      handleResetModal();
      handleInvoiceDetailsbyID();
    }
  };

  const uploadPaymentProof = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedProfileResponse = await uploadImage(formData);
    if (uploadedProfileResponse?.status === API_CODE.STATUS_200) {
      const payload = {
        paidDate: data.paidDate,
        paymentType: paymentType ? paymentType : getPaymentDetails?.paymentType,
        paidAmount: data?.amount ? data?.amount : getPaymentDetails?.paidAmount,
        paymentProof: uploadedProfileResponse?.data?.data?.file,
        invoiceId: getInvoiceDetails?._id,
        paymentId: getPaymentDetails?._id
      };
      updateMarkPaymentHandler(payload);
    }
  };

  const updateMarkPaymentHandler = async (payload) => {
    const invoiceUpdateMarkPaymentResponse = await invoiceUpdateMarkPayment(payload);
    if (invoiceUpdateMarkPaymentResponse?.status === API_CODE.STATUS_200) {
      const remaining = invoiceUpdateMarkPaymentResponse?.data?.data?.payments?.reduce((accumulator, object) => {
        return accumulator + object.paidAmount;
      }, 0);
      if (remaining) {
        editInvoiceHandler(remaining);
      }
    }
  };

  const editInvoiceHandler = async (remainingAmount) => {
    const payload = {
      invoiceId: getInvoiceDetails?._id,
      status:
        remainingAmount === getInvoiceDetails?.totalAmount
          ? INVOICE_STATUS.INVOICE_PAID
          : INVOICE_STATUS.INVOICE_PARTIAL_PAID,
      pendingAmount: remainingAmount
        ? getInvoiceDetails?.totalAmount - remainingAmount
        : getInvoiceDetails?.pendingAmount
    };
    const editInvoiceResponse = await editInvoice(payload);
    if (editInvoiceResponse?.status === API_CODE.STATUS_200) {
      const payload = {
        invoiceId: getInvoiceDetails?._id,
        paymentId: getPaymentDetails?._id,
        isApproved: true
      };
      onApprovalOfPayment(payload);
    }
  };
  return (
    <div>
      <DynamicModal openDynamicModal={openDynamicModal} closeDynamicModal={closeDynamicModal} minWidth="520px">
        <div className="mt-5 px-10">
          <MDSubtitleText className="flex justify-center mb-4" title="Verify invoice payment" fontWeight="text-bold" />
          <BaseText
            fontSize="text-xl"
            textAlign="text-center"
            className="mb-4 flex justify-center"
            textColor="text-gray-500"
            title="Review the payment details for the invoice marked as paid by the client. You can upload a proof of payment for reference."
          />
          <form id="edit-invoice-payment" onSubmit={handleSubmit(onSubmit)}>
            <SelectInputAutoComplete
              name="paymentType"
              variant="filled"
              defaultValue={paymentType ? paymentType : getPaymentDetails?.paymentType}
              value={paymentType ? paymentType : getPaymentDetails?.paymentType}
              setSelectedValue={setPaymentType}
              disableClearable={true}
              inputRef={register({
                required: false
              })}
              label="Payment type"
              options={options}
            />
            <TextInput
              defaultValue={
                getPaymentDetails?.paidDate
                  ? getUTCBasedDate(new Date(getPaymentDetails?.paidDate), 'UTC')
                  : moment(new Date()).format('YYYY-MM-DD')
              }
              name="paidDate"
              label="Date"
              type="date"
              variant="filled"
              textFieldContainerClass="mt-4"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register({
                required: true
              })}
              error={errors?.paidDate ? true : false}
              helperText={errors?.paidDate && <ValidationMessage title={'Please provide the date'} />}
            />
            <TextInput
              defaultValue={getPaymentDetails?.paidAmount}
              startAdornment={
                <InputAdornment position="start">
                  {getInvoiceDetails?.currency?.symbol
                    ? getInvoiceDetails?.currency?.symbol
                    : getInvoiceDetails?.currency?.name}
                </InputAdornment>
              }
              name="amount"
              disabled={true}
              label="Amount"
              variant="filled"
              textFieldContainerClass="mt-4"
              backgroundColor={MUI_COLORS.GRAY_FOR_DISABLE_INPUTS}
              inputRef={register({
                required: true
              })}
              onChange={handleCheckValidAmount}
              error={errors?.amount ? true : isAmountValid ? isAmountValid : false}
              helperText={
                errors?.amount ? (
                  <ValidationMessage title={'Please provide the amount'} />
                ) : (
                  isAmountValid && (
                    <ValidationMessage title={'The amount recorded cannot be higher than the invoice amount'} />
                  )
                )
              }
            />
            {getPaymentDetails?.paymentProof ? (
              <div className="mt-4 flex items-center border p-2 h-20 rounded-lg">
                {getFileExtension(getPaymentDetails?.paymentProof) === FILE_EXTENTION_TYPE.PDF ? (
                  <img src={FileIcon} alt="file icon" width="40" height="70" />
                ) : (
                  <img src={getPaymentDetails?.paymentProof} width="50" height="80" alt="Payment proof" />
                )}
                <BaseText
                  fontSize="text-base"
                  className="ml-4"
                  textColor="text-gray-450"
                  title={
                    <a href={getPaymentDetails?.paymentProof} target="_blank">
                      {getFileName(getPaymentDetails?.paymentProof)?.name}
                    </a>
                  }
                />
              </div>
            ) : (
              <UploadPaymentProof
                saveImage={saveImage}
                selectedImage={selectedImage}
                uploadError={uploadError}
                acceptedFile={acceptedFile?.[0]}
                fileSelection={fileSelection}
              />
            )}
            <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
              <PrimaryButton
                id="approval-modal-record-payment-btn"
                isLoading={isLoading}
                caption="Record payment"
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
                disabled={isAmountValid}
              />
              <PrimaryButton
                id="approval-modal-not-correct-btn"
                className="modal-cancel-button font-weight-bold border border-gray-400"
                bgColor="bg-white"
                color="text-gray-300"
                caption="Not correct"
                onClick={handleDeletePayment}
              />
            </div>
          </form>
        </div>
      </DynamicModal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    invoicePaymentApprove: (invoicedata) => dispatch(paymentActionRedux.invoicePaymentApprove(invoicedata)),
    invoiceDeleteMarkPayment: (invoicedata) => dispatch(paymentActionRedux.invoiceDeleteMarkPayment(invoicedata)),
    invoiceUpdateMarkPayment: (invoicedata) => dispatch(paymentActionRedux.invoiceUpdateMarkPayment(invoicedata)),
    editInvoice: (payload) => dispatch(invoiceReduxActions.editInvoice(payload)),
    uploadImage: (uploadedImage) => dispatch(commonReduxActions.fileUpload(uploadedImage))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalConfirmationModal);

ApprovalConfirmationModal.propTypes = {
  getInvoiceDetails: PropTypes.any,
  getPaymentDetails: PropTypes.any,
  openDynamicModal: PropTypes?.bool,
  invoicePaymentApprove: PropTypes.func,
  invoiceDeleteMarkPayment: PropTypes.func,
  closeDynamicModal: PropTypes.func,
  invoiceUpdateMarkPayment: PropTypes.func,
  editInvoice: PropTypes.func,
  uploadImage: PropTypes.func
};
