import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from './reduxStore/action';
import MSText from '../ui/typography/MSText';
import BaseText from '../ui/typography/BaseText';
import ValidationMessage from '../ui/ValidationMessage';
import SelectInputAutoComplete from '../ui/inputs/customSelectAutoComplete';
import NewContactModal from '../contact/NewContactModal';
import IconBaseEdit from '../../images/icons/Invoice/base-edit.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import * as contactActions from '../contact/reduxStore/action';
import { API_CODE, CONTACT_TABLE_TITLE, MUI_COLORS } from '../../constants/enum';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import Image from '../Image';
import Snackbar from '../Snackbar';
import Success from '../../images/icons/success-icon.svg';
import XSText from '../ui/typography/XSText';

function InvoiceTo({
  getInvoiceDetails,
  invoiceData,
  viewContact,
  contactList,
  resetContactForm,
  getContactId,
  isEditValue,
  isTemporary
}) {
  const [reviewErrorModal, setReviewErrorModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(
    Object.keys(getInvoiceDetails)?.length > 0 ? getInvoiceDetails : null
  );
  const [isEdit, setIsEdit] = useState();
  const [allContactList, setAllContactList] = useState([]);
  const [open, setOpen] = useState(false);
  const [contactId, setContactId] = useState('');
  const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);
  const [editedSuccessMessage, setEditedSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [prefillCompanyName, setPrefillCompanyName] = useState('');
  const handleClose = () => {
    setOpen(false);
  };

  const closeDynamicModal = () => {
    setReviewErrorModal(false);
    if (isTemporary) {
      setSelectedContact(
        Object.keys(getInvoiceDetails)?.length > 0 && getInvoiceDetails?.toCompanyName !== '' ? getInvoiceDetails : null
      );
    }
  };

  const handleOpen = (editedResponse) => {
    const editDetails = editedResponse.data;
    setOpen(true);
    setEditedSuccessMessage({
      ...editedSuccessMessage,
      title: editedResponse.message,
      content: `Invoice ${
        editDetails.companyName ? editDetails.companyName : editDetails.firstName
      } wassucessfully saved!`
    });
  };

  const openDynamicModal = async (companyName) => {
    await resetContactForm();
    await invoiceData({
      toFirstName: '',
      toLastName: '',
      toEmail: '',
      toCompanyName: '',
      toRegistrationNumber: '',
      toWebsite: '',
      toAddress: '',
      toTownCity: '',
      toState: '',
      toPostalCode: '',
      toCountry: '',
      toProfile: ''
    });
    setPrefillCompanyName(companyName);
    setIsEdit(false);
    setReviewErrorModal(true);
  };
  const onSelectingContact = async (values) => {
    {
      if (values) {
        setSelectedContact(values);
        getContactById(values?._id);
        getContactId(values?._id);
      } else {
        setSelectedContact('');
        await invoiceData({
          toFirstName: '',
          toLastName: '',
          toEmail: '',
          toCompanyName: '',
          toRegistrationNumber: '',
          toWebsite: '',
          toAddress: '',
          toTownCity: '',
          toState: '',
          toPostalCode: '',
          toCountry: '',
          toProfile: ''
        });
      }
    }
  };

  const getContactById = async (id) => {
    setIsLoading(true);
    setContactId(id);
    const requestedData = {
      contactId: id
    };
    const viewContactResponse = await viewContact({ params: { ...requestedData } });
    if (viewContactResponse?.status === API_CODE.STATUS_200) {
      const contactDataToInvoice = viewContactResponse?.data?.data;
      invoiceData({
        toFirstName: contactDataToInvoice?.firstName,
        toLastName: contactDataToInvoice?.lastName,
        toEmail: contactDataToInvoice?.email,
        toCompanyName: contactDataToInvoice?.companyName,
        toRegistrationNumber: contactDataToInvoice?.registrationNumber,
        toWebsite: contactDataToInvoice?.website,
        toAddress: contactDataToInvoice?.address,
        toTownCity: contactDataToInvoice?.city,
        toState: contactDataToInvoice?.state,
        toPostalCode: contactDataToInvoice?.postalCode,
        toCountry: contactDataToInvoice?.country,
        toProfile: contactDataToInvoice?.profileImage,
        toId: contactDataToInvoice?._id
      });
      setIsLoading(false);
      setSelectedContact(contactDataToInvoice);
      contactAllList();
      setIsEditDataLoaded(true);
    } else {
      setIsLoading(false);
    }
  };

  const contactAllList = async () => {
    const response = await contactList();
    if (response?.status === API_CODE.STATUS_200) {
      setAllContactList(response?.data?.data);
    }
  };

  useEffect(() => {
    if (isTemporary) {
      setSelectedContact(
        Object.keys(getInvoiceDetails)?.length > 0 && getInvoiceDetails?.companyName ? getInvoiceDetails : null
      );
    }
  }, [getInvoiceDetails]);

  useEffect(() => {
    if (!isTemporary) {
      if (getInvoiceDetails.toId) {
        handleContactList();
      } else {
        contactAllList();
        setIsEditDataLoaded(true);
      }
    } else {
      setIsEditDataLoaded(true);
    }
  }, []);
  const handleContactList = async () => {
    await contactList();
    await onSelectingContact({ _id: getInvoiceDetails.toId });
  };

  const openEditContactModal = () => {
    setIsEdit(true);
    setReviewErrorModal(true);
  };
  const invoiceListWithFullName = allContactList?.map((invoiceDetails) => ({
    ...invoiceDetails,
    name: invoiceDetails?.firstName + ' ' + invoiceDetails?.lastName
  }));
  return (
    <div className="w-full">
      <BaseText title="Billed to" fontWeight="text-bold" textColor="text-gray-600" className="mb-3 mt-4" />

      {isEditDataLoaded ? (
        <SelectInputAutoComplete
          label="Client"
          name="Client"
          options={invoiceListWithFullName}
          disableClearable={true}
          value={selectedContact}
          setSelectedValue={onSelectingContact}
          defaultValue={selectedContact}
          addNewOption={openDynamicModal}
          helperText={
            getInvoiceDetails?.toError &&
            !getInvoiceDetails?.toCompanyName && (
              <ValidationMessage title="Please provide valid details of your Client" />
            )
          }
          error={getInvoiceDetails?.toError && !getInvoiceDetails?.toCompanyName ? true : false}
        />
      ) : (
        <div className="flex items-center justify-center w-full align-center">
          <StatrysLoader />
        </div>
      )}
      <div
        className={`w-full mt-2 invoice-from-filled-container bg-white flex md:flex-row flex-col rounded-2xl cursor-pointer justify-between`}
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-full align-center">
            <StatrysLoader />
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-start w-10/12">
              {(getInvoiceDetails?.toFirstName || getInvoiceDetails?.toCompanyName) && (
                <MSText
                  className="capitalize mt-2"
                  title={
                    <div className="flex flex-col gap-1">
                      {getInvoiceDetails?.toCompanyName && <div>{getInvoiceDetails?.toCompanyName}</div>}
                      {getInvoiceDetails?.toRegistrationNumber && (
                        <div className="normal-case">{getInvoiceDetails?.toRegistrationNumber}</div>
                      )}
                      {(getInvoiceDetails?.toAddress ||
                        getInvoiceDetails?.toTownCity ||
                        getInvoiceDetails?.toState ||
                        getInvoiceDetails?.toPostalCode) && (
                        <div>
                          {getInvoiceDetails?.toAddress} {getInvoiceDetails?.toTownCity} {getInvoiceDetails?.toState}{' '}
                          {getInvoiceDetails?.toPostalCode}
                        </div>
                      )}
                      {getInvoiceDetails?.toWebsite && (
                        <div className="normal-case">{getInvoiceDetails?.toWebsite}</div>
                      )}
                      {getInvoiceDetails?.toCountry && <div className="uppercase">{getInvoiceDetails?.toCountry}</div>}
                      <div className="flex gap-1">
                        <div>
                          {getInvoiceDetails?.toFirstName} {getInvoiceDetails?.toLastName}
                        </div>
                      </div>
                      {getInvoiceDetails?.toEmail && <div className="normal-case">{getInvoiceDetails?.toEmail}</div>}
                    </div>
                  }
                />
              )}
            </div>
            <div className="md:w-3/12 flex justify-end">
              {(getInvoiceDetails?.toCompanyName || getInvoiceDetails?.toFirstName) && (
                <PrimaryButton
                  id="invoice-to-edit-button"
                  className="invoice-button mt-1"
                  bgColor="bg-coral-500"
                  textColor="text-white"
                  onClick={() => openEditContactModal()}
                  caption={
                    <div className="flex gap-1 text-medium items-center">
                      <img src={IconBaseEdit} alt="IconBaseEdit" />
                      Edit
                    </div>
                  }
                />
              )}
            </div>{' '}
          </>
        )}
      </div>

      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={editedSuccessMessage?.title}></XSText>
              <MSText textColor="text-gray-500" title={editedSuccessMessage?.content}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      <NewContactModal
        contactType={CONTACT_TABLE_TITLE.ONLY_CLIENTS}
        openDynamicModal={reviewErrorModal}
        closeDynamicModal={closeDynamicModal}
        isEdit={isEdit}
        getContactById={getContactById}
        contactData={selectedContact}
        handleOpen={handleOpen}
        contactId={contactId}
        contactAllList={contactAllList}
        prefillCompanyName={prefillCompanyName}
        onSelectingContact={onSelectingContact}
        isTemporary={isTemporary}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    viewContact: (contactData) => dispatch(contactActions.viewContact(contactData)),
    contactList: (allContactList) => dispatch(contactActions.contactList(allContactList)),
    resetContactForm: () => dispatch(contactActions.resetContactForm())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTo);

InvoiceTo.propTypes = {
  prevStep: PropTypes.func,
  getInvoiceDetails: PropTypes.object,
  invoiceData: PropTypes.func,
  viewContact: PropTypes.func,
  contactList: PropTypes.func,
  viewContact: PropTypes.func,
  contactList: PropTypes.any,
  resetContactForm: PropTypes.func,
  isTemporary: PropTypes.bool
};
