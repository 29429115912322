import React, { useState } from 'react';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import DynamicModal from '../../ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Iconify from '../../Iconify';
import {
  checkValueExistOrNot,
  convertDateToDDMMYYYY,
  downloadImage,
  makeFirstLetterCapitalize,
  stringTruncate
} from '../../../helper/helpers';
import { STRING_MAX_CHAR } from '../../../constants/enum';
import EmptyStageImg from '../../../images/icons/company/empty-stage-gray-cloud.svg';

function ViewDetailsModal({ open, closeModal, shareholderData, title }) {
  return (
    <div>
      <DynamicModal openDynamicModal={open} closeDynamicModal={closeModal} maxWidth="920px">
        <H4HeaderText title={title} />
        <div className="flex mt-6">
          <div>
            <img
              src={
                shareholderData?.passportDocument?.documentUrl
                  ? shareholderData?.passportDocument?.documentUrl
                  : EmptyStageImg
              }
              alt="passport image"
              className="h-60 object-contain"
              width="364"
              height="240"
            />
            {shareholderData?.passportDocument?.documentUrl && (
              <div className="flex justify-end mt-2">
                <PrimaryButton
                  id="view-details-pdf-download"
                  caption={
                    <div className={`flex justify-center gap-2 items-center`}>
                      <Iconify icon={'lucide:download-cloud'} />
                      <span>Download</span>
                    </div>
                  }
                  onClick={() => downloadImage(shareholderData?.passportDocument?.documentUrl)}
                  type="small"
                  bgColor="bg-white"
                  className="company-white-button mb-2"
                />
              </div>
            )}
          </div>

          <div className=" ml-8">
            <div className="flex">
              <div className="w-52">
                <MSText textColor="text-gray-450 mb-2" title="Lastname" />
                <XSText
                  className="mb-4"
                  title={checkValueExistOrNot(makeFirstLetterCapitalize(shareholderData?.lastName))}
                />
                <MSText textColor="text-gray-450 mb-2" title="Passport" />
                <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.passportId)} />
                <MSText textColor="text-gray-450 mb-2" title="Nationality" />
                <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.nationality?.name)} />
              </div>
              <div className="ml-4 w-52">
                <MSText textColor="text-gray-450 mb-2" title="Name" />
                <XSText
                  className="mb-4"
                  title={
                    shareholderData?.firstName
                      ? makeFirstLetterCapitalize(shareholderData?.firstName) +
                        ' ' +
                        makeFirstLetterCapitalize(shareholderData?.lastName)
                      : '-'
                  }
                />
                <MSText textColor="text-gray-450 mb-2" title="Expiry" />
                <XSText className="mb-4" title={checkValueExistOrNot(convertDateToDDMMYYYY(shareholderData?.expiry))} />
              </div>
            </div>
            <MSText textColor="text-gray-450 mb-2" title="Residential address" />
            <XSText
              className="mb-4 whitespace-pre-line"
              title={checkValueExistOrNot(shareholderData?.correspondenceAddress)}
            />
            <div className="flex">
              <div className="w-52">
                <MSText textColor="text-gray-450 mb-2" title="Email" />
                <XSText
                  title={checkValueExistOrNot(stringTruncate(shareholderData?.email, STRING_MAX_CHAR.TWENTY_FIVE))}
                />
              </div>
              <div className="ml-4 w-52">
                <MSText textColor="text-gray-450 mb-2" title="Phone number" />
                <XSText title={checkValueExistOrNot(shareholderData?.phoneNumber)} />
              </div>
            </div>
          </div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default ViewDetailsModal;
