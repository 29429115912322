import React from 'react';

const Plus = ({ fill }) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.75 5.25H7.25V0.75C7.25 0.335786 6.91421 0 6.5 0C6.08579 0 5.75 0.335786 5.75 0.75V5.25H1.25C0.835786 5.25 0.5 5.58579 0.5 6C0.5 6.41421 0.835786 6.75 1.25 6.75H5.75V11.25C5.75 11.6642 6.08579 12 6.5 12C6.91421 12 7.25 11.6642 7.25 11.25V6.75H11.75C12.1642 6.75 12.5 6.41421 12.5 6C12.5 5.58579 12.1642 5.25 11.75 5.25Z"
        fill={fill}
      />
    </svg>
  );
};
export default Plus;

Plus.defaultProps = {
  fill: 'white'
};
