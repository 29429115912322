export const USER_PROFILE_REQUEST_START = 'USER_PROFILE_REQUEST_START';
export const USER_PROFILE_REQUEST_SUCCESS = 'USER_PROFILE_REQUEST_SUCCESS';
export const USER_PROFILE_REQUEST_FAILURE = 'USER_PROFILE_REQUEST_FAILURE';

export const GET_USER_PROFILE_REQUEST_START = 'GET_USER_PROFILE_REQUEST_START';
export const GET_USER_PROFILE_REQUEST_SUCCESS = 'GET_USER_PROFILE_REQUEST_SUCCESS';
export const GET_USER_PROFILE_REQUEST_FAILURE = 'GET_USER_PROFILE_REQUEST_FAILURE';

export const CHANGE_PASSWORD_REQUEST_START = 'CHANGE_PASSWORD_REQUEST_START';
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'CHANGE_PASSWORD_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_REQUEST_FAILURE = 'CHANGE_PASSWORD_REQUEST_FAILURE';

export const CURRENCY_PREFERENCE_REQUEST_START = 'CURRENCY_PREFERENCE_REQUEST_START';
export const CURRENCY_PREFERENCE_REQUEST_SUCCESS = 'CURRENCY_PREFERENCE_REQUEST_SUCCESS';
export const CURRENCY_PREFERENCE_REQUEST_FAILURE = 'CURRENCY_PREFERENCE_REQUEST_FAILURE';

export const GET_CURRENCY_PREFERENCE_REQUEST_START = 'GET_CURRENCY_PREFERENCE_REQUEST_START';
export const GET_CURRENCY_PREFERENCE_REQUEST_SUCCESS = 'GET_CURRENCY_PREFERENCE_REQUEST_SUCCESS';
export const GET_CURRENCY_PREFERENCE_REQUEST_FAILURE = 'GET_CURRENCY_PREFERENCE_REQUEST_FAILURE';
