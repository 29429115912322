import React from 'react';
import BaseText from '../ui/typography/BaseText';
import { isIbanNumber } from '../../helper/helpers';
import XMSText from '../ui/typography/XMSText';
export default function BankDetails({ paymentDetails }) {
  return (
    <>
      {paymentDetails?.bankDetails?.map((bankData, index) => (
        <div
          className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}
          key={index + 1}
        >
          <div>
            <div className="flex justify-between items-center mb-3">
              <XMSText fontWeight="text-bold" title={bankData?.alias} />
            </div>
            <div className="flex flex-col">
              <div>
                {bankData?.bankCountry && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="Bank country:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.bankCountry}
                    />
                  </div>
                )}
                {bankData?.bankAddress && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="Bank address:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.bankAddress}
                    />
                  </div>
                )}
                {bankData?.fullName && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="Full Name:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.fullName}
                    />
                  </div>
                )}
                {bankData?.bankName && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="Bank name:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.bankName}
                    />
                  </div>
                )}
                {bankData?.accountNumberOrIban && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column whitespace-pre-line"
                      textColor="text-gray-700"
                      title={isIbanNumber(bankData?.accountNumberOrIban)}
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.accountNumberOrIban}
                    />
                  </div>
                )}
                {bankData?.swiftCode && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="SWIFT/BIC:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.swiftCode}
                    />
                  </div>
                )}
                {bankData?.routingCode && (
                  <div className="flex">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2 public-bank-details-column"
                      textColor="text-gray-700"
                      title="Routing code:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="mb-2"
                      textColor="text-gray-300"
                      title={bankData?.routingCode}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                {bankData?.bankCode && (
                  <div className="flex mr-8">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="public-bank-details-column"
                      textColor="text-gray-700"
                      title="Bank code:"
                    />
                    <BaseText fontSize="public-invoice-text-md" textColor="text-gray-300" title={bankData?.bankCode} />
                  </div>
                )}
                {bankData?.branchCode && (
                  <div className="flex mr-8 mt-2">
                    <BaseText
                      fontSize="public-invoice-text-md"
                      className="public-bank-details-column"
                      textColor="text-gray-700"
                      title="Branch code:"
                    />
                    <BaseText
                      fontSize="public-invoice-text-md"
                      textColor="text-gray-300"
                      title={bankData?.branchCode}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
