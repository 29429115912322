import React from "react"

const Profile = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7.5C9.933 7.5 11.5 5.933 11.5 4C11.5 2.067 9.933 0.5 8 0.5C6.067 0.5 4.5 2.067 4.5 4C4.5 5.933 6.067 7.5 8 7.5Z"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 15.5C1.5 13.7761 2.18482 12.1228 3.40381 10.9038C4.62279 9.68482 6.27609 9 8 9C9.72391 9 11.3772 9.68482 12.5962 10.9038C13.8152 12.1228 14.5 13.7761 14.5 15.5"
        stroke="#E15741"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default Profile
