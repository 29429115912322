import React from 'react';
import XMSText from '../ui/typography/XMSText';
import QRcode from 'react-qr-code';
import PublicViewBankDetails from './PublicViewBankDetails';
import { EXTERNAL_PAYMENT_LINKS } from '../../constants/enum';
function ViewInvoicePaymentDetails({ getInvoiceDetails, paymentDetails }) {
  return (
    <>
      <PublicViewBankDetails paymentDetails={paymentDetails} />
      {paymentDetails?.paypalDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              {paymentDetails?.paypalDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500 public-invoice-view-qr">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${paymentDetails?.paypalDetails?.payPalLink}${
                      getInvoiceDetails?.totalAmount
                        ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                        : ''
                    }`}
                  />
                </div>
              )}
              <div>
                <XMSText title="Pay with PayPal" />
                <XMSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  className="break-all"
                  title={
                    <a
                      href={`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${paymentDetails?.paypalDetails?.payPalLink}${
                        getInvoiceDetails?.totalAmount
                          ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                          : ''
                      }`}
                      target="_blank"
                    >
                      {`${EXTERNAL_PAYMENT_LINKS.PAYPAL_LINK}${paymentDetails?.paypalDetails?.payPalLink}${
                        getInvoiceDetails?.totalAmount
                          ? `/${getInvoiceDetails?.totalAmount}${getInvoiceDetails?.currency?.name}`
                          : ''
                      }`}
                    </a>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {paymentDetails?.hsbcDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              {paymentDetails?.hsbcDetails?.showQrCode && (
                <div className="w-12 h-12 bg-coral-500 public-invoice-view-qr">
                  <QRcode
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${paymentDetails?.hsbcDetails?.hsbcLink}`}
                  />
                </div>
              )}
              <div>
                <XMSText title="Pay with PayMe" />
                <XMSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  className="break-all"
                  title={
                    <a
                      href={`${EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}${paymentDetails?.hsbcDetails?.hsbcLink}`}
                      target="_blank"
                    >
                      {EXTERNAL_PAYMENT_LINKS.PAYME_HSBC_LINK}
                      {paymentDetails?.hsbcDetails?.hsbcLink}
                    </a>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {paymentDetails?.customPaymentDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              <div>
                <XMSText title={paymentDetails?.customPaymentDetails?.customLabel} />
                <XMSText
                  textColor="text-coral-500"
                  fontWeight="text-bold"
                  className="break-all"
                  title={
                    <a href={paymentDetails?.customPaymentDetails?.customLink} target="_blank">
                      {paymentDetails?.customPaymentDetails?.customLink}
                    </a>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {paymentDetails?.customTextDetails?.isSelected && (
        <div className={`p-4 bg-customBg-mainBg rounded-2xl mt-2 border border-transparent flex items-start gap-6`}>
          <div className="w-full">
            <div className="flex gap-3 items-center">
              <div>
                <XMSText className="break-all" title={paymentDetails?.customTextDetails?.customText} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ViewInvoicePaymentDetails;
