import * as actionTypes from './actionTypes';
import axios from 'axios';
import { ENDPOINTS } from '../../../service/services';

export const sendContactsRequestStart = () => ({ type: actionTypes.SEND_CONTACTS_REQUEST_START });
export const sendContactsRequestFail = (error) => ({ type: actionTypes.SEND_CONTACTS_REQUEST_FAILURE, error: error });
export const sendContactsRequestSuccess = (contactsPayload) => ({
  type: actionTypes.SEND_CONTACTS_REQUEST_SUCCESS,
  contacts: contactsPayload
});
export const sendContacts = (contactsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(sendContactsRequestStart());
      let sendContactsResponse = await axios.put(
        `${process.env.BASE_API_URL}${ENDPOINTS?.PUBLIC_SENDGRID_SENDEMAIL}`,
        contactsPayload
      );
      sendContactsRequestSuccess(sendContactsResponse);
      return sendContactsResponse;
    } catch (error) {
      dispatch(sendContactsRequestFail(error.response));
      return error?.response;
    }
  };
};
