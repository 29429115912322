import React, { useState } from 'react';
import TextInput from '../../ui/inputs/TextInput';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import MultiTextInput from '../../ui/inputs/MultiTextInput';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import { BUSINESS_EXPERIENCE, EXPERIENCE_VALUE } from '../../../constants/enum';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import BackButton from '../../ui/buttons/BackButton';
import AlertTriangle from '../../../images/icons/alert-triangle.svg';
import EditOwnerDirectorDetails from './EditOwnerDetails';
import { useForm } from 'react-hook-form';

const ownerDirectorData = {
  firstName: 'Sunil',
  lastName: 'Prajapati',
  type: 'OWNER_DIRECTOR',
  code: '---'
};

const experienceButtonArray = [
  {
    value: EXPERIENCE_VALUE.ZERO
  },
  {
    value: EXPERIENCE_VALUE.ONE
  },
  {
    value: EXPERIENCE_VALUE.TWO_TO_FIVE
  },
  {
    value: EXPERIENCE_VALUE.FIVE_TO_TEN
  },
  {
    value: EXPERIENCE_VALUE.TEN_PLUS
  }
];

const businessExperienceArray = [
  {
    value: BUSINESS_EXPERIENCE?.BUSINESS_OWNER
  },
  {
    value: BUSINESS_EXPERIENCE.EMPLOYEE
  },
  {
    value: BUSINESS_EXPERIENCE.CONSULTANT
  },
  {
    value: BUSINESS_EXPERIENCE.FREELANCER
  }
];
function BusinessDetailAndExperience({
  prevStep,
  nextStep,
  isOwnerDirectorEdit,
  setCurrentStackHolder,
  currentStackHolder,
  stackHolderPayload,
  setStackHolderPayload
}) {
  const [experience, setExperience] = useState(currentStackHolder?.experience);
  const [businessExperience, setBusinessExperience] = useState(currentStackHolder?.businessExperience);
  const [isExperience, setIsExperience] = useState(false);
  const [isBusinessExperience, setIsBusinessExperience] = useState(false);
  const { register, handleSubmit } = useForm();

  const back = () => {
    prevStep();
  };

  const handleExperience = (value) => {
    setExperience(value);
    setIsExperience(false);
  };

  const handleBusinessExperience = (value) => {
    setBusinessExperience(value);
    setIsBusinessExperience(false);
  };

  const onSubmit = (data) => {
    if (experience && businessExperience) {
      setCurrentStackHolder({
        ...currentStackHolder,
        experience: experience,
        businessExperience: businessExperience,
        linkedProfileUrl: data?.linkedinLink,
        description: data?.description
      });
      let newCurrentPayload = {
        ...currentStackHolder,
        experience: experience,
        businessExperience: businessExperience,
        linkedProfileUrl: data?.linkedinLink,
        description: data?.description
      };
      const isAlreadyExisted = stackHolderPayload.filter((holder) => holder?.id !== currentStackHolder?.id);
      setStackHolderPayload([...isAlreadyExisted, newCurrentPayload]);
      nextStep();
    }
    if (!experience) {
      setIsExperience(true);
    }
    if (!businessExperience) {
      setIsBusinessExperience(true);
    }
  };

  return (
    <div className="flex flex-col gap-8 w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      {isOwnerDirectorEdit && (
        <EditOwnerDirectorDetails
          firstName={ownerDirectorData.firstName}
          lastName={ownerDirectorData.lastName}
          type={ownerDirectorData.type}
          cardCode={ownerDirectorData.code}
        />
      )}
      <div className="md:w-4/6 w-full mr-14 mt-6">
        <XSText title="How many years of experience do you have in the company business?" />
        <div className="flex md:flex-row flex-wrap mt-2 gap-2">
          {experienceButtonArray?.map((experienceButton, index) => {
            return (
              <PrimaryButton
                key={index}
                className={
                  experience != experienceButton?.value ? 'bussinece-experince-selector' : 'active-selected-experience'
                }
                bgColor="bg-white"
                color="text-gray-300"
                caption={experienceButton?.value}
                onClick={() => handleExperience(experienceButton?.value)}
              />
            );
          })}
        </div>
        {isExperience ? (
          <div className="ml-5 mt-2 flex">
            <img src={AlertTriangle} alt="alert triangle" />
            <MSText className="ml-2" textColor="select-input-error" title="Please select a valid experience" />
          </div>
        ) : null}
        <XSText className="mt-6" title="How did you acquire experience in the business?" />
        <div className="flex flex-wrap mt-2 gap-2">
          {businessExperienceArray?.map((experience, index) => {
            return (
              <PrimaryButton
                key={index}
                className={`${businessExperience === experience?.value && 'active-selcted'} bussinece-experince`}
                bgColor="bg-white"
                caption={experience?.value}
                color="text-gray-300"
                onClick={() => handleBusinessExperience(experience?.value)}
              />
            );
          })}
        </div>

        {isBusinessExperience ? (
          <div className="ml-5 mt-2 flex">
            <img src={AlertTriangle} alt="alert triangle" />
            <MSText
              className="ml-2"
              textColor="select-input-error"
              title={'Please select a valid business experience'}
            />
          </div>
        ) : null}
        <XSText className="mt-6 mb-2" title="Linkedin profile link (if available)" />
        <form id="experience-form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="linkedinLink"
            label="Linkedin profile (Optional)"
            textFieldContainerClass="w-full"
            defaultValue={currentStackHolder?.linkedProfileUrl}
            inputRef={register({
              required: {
                value: false
              }
            })}
          />

          <XSText className="mt-6" title="Specify your current occupation" />
          <MultiTextInput
            inputContainerClass="mt-4"
            inputRef={register({
              required: {
                value: false
              }
            })}
            name="description"
            defaultValue={currentStackHolder?.description}
            rows={4}
            label="Description"
          />
        </form>
      </div>
      <div className="flex justify-between">
        <BackButton onClick={back} />
        <PrimaryButton caption="Continue" color="text-white" isBorderRequired={true} onClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
}

export default BusinessDetailAndExperience;
