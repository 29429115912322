import React, { useState } from 'react';

import PropTypes from 'prop-types';
// @mui
import { IconButton, Tab } from '@mui/material';
//
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';
import { TableRow, TableCell, Typography, Stack, Link, MenuItem, Divider } from '@mui/material';
import { TableMoreMenu } from '../table';
// ----------------------------------------------------------------------

MoreMenu.propTypes = {
  actions: PropTypes.node,
  open: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.string
};
MoreMenu.defaultProps = {
  icon: 'eva:more-vertical-fill'
};
export default function MoreMenu({ onEditBankDetails, onDeleteBankDetails, onSelectingBankDetails }) {
  const [openMenu, setOpenMenuActions] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  return (
    <>
      <TableMoreMenu
        open={openMenu}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        icon="eva:more-horizontal-fill"
        actions={
          <div>
            <MenuItem onClick={onEditBankDetails}>
              <Iconify icon={'eva:edit-fill'} />
              Edit
            </MenuItem>
            <Divider className="my-2" />
            <MenuItem onClick={onDeleteBankDetails} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} />
              Delete
            </MenuItem>
          </div>
        }
      />
    </>
  );
}
