import React, { useState } from 'react';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XSText from '../../ui/typography/XSText';
import GetStartedSideBannerImg from '../../../images/graphics/pay/pay-listing-img.png';
import ImportedInvoiceUploadModal from '../../importedInvoices/ImportedInvoiceUploadModal';

export default function ContactSupplierBanner() {
  const [uploadModal, setUploadModal] = useState(false);
  const closeDynamicModal = () => {
    setUploadModal(false);
  };
  return (
    <div className="pl-8 bg-green-200 rounded-2xl whitespace-pre-line flex md:flex-row flex-col overflow-hidden h-full">
      <div className="md:w-7/12 w-full pt-8 flex md:flex-col flex-row justify-between">
        <div>
          <LGSubtitleText
            className="w-9/12"
            textWeight="text-bold"
            title={`Manage and pay\n your suppliers\n invoices`}
          />
          <XSText
            className="mt-4 mb-8"
            title={
              'Simplify your invoice management process by\n uploading and paying invoices in one place.\n\nStay organized and keep track of your\n financial transactions effortlessly.'
            }
          />
        </div>
        <PrimaryButton
          id="contact-supplier-upload-invoice-btn"
          className="mb-11"
          bgColor="bg-green-500"
          textColor="text-white"
          isBorderRequired="false"
          fontSize="text-lg"
          fontWeight="text-bold"
          caption="Upload your first invoice"
          onClick={() => setUploadModal(true)}
        />
        <ImportedInvoiceUploadModal openDynamicModal={uploadModal} closeDynamicModal={closeDynamicModal} />
      </div>
      <div className="md:mt-6 md:w-5/12 w-full relative">
        <div className="green-background-shadow-effect md:block hidden"></div>
        <div className="image-z-index">
          <img
            className="contact-invoice-image contact-client-invoice"
            src={GetStartedSideBannerImg}
            alt="GetStartedSideBannerImg"
          />
        </div>
      </div>
    </div>
  );
}
