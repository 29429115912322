import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/steps-indicator.scss';
import XSText from './typography/XSText';

export default function StepsIndicator({ numberOfSteps, currentStepNumber }) {
  return (
    <div className="flex flex-wrap justify-between items-between gap-4">
      <div className="flex flex-row gap gap-2">
        {Array.from(Array(numberOfSteps), (e, index) => {
          return (
            <div
              className={`${currentStepNumber === index + 1 ? 'active-bar bg-coral-500' : 'inActiveBar bg-pink-100'} ${
                index < currentStepNumber && 'bg-coral-500'
              }`}
              key={index}
            ></div>
          );
        })}
      </div>
      <XSText className="md:-mt-1" title={`Step ${currentStepNumber} of ${numberOfSteps}`} />
    </div>
  );
}

StepsIndicator.propTypes = {
  numberOfSteps: PropTypes.number,
  currentStepNumber: PropTypes.number
};
