import React from 'react';
import Layout from '../components/layout';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { PAGE_TITLE } from '../constants/enum';
import Page from '../components/Page';
import SettingTabs from '../components/setting/SettingTabs';

export default function profile() {
  return (
    <Layout>
      <Page title="Invoice: settings">
        <HeaderBreadcrumbs heading={PAGE_TITLE.SETTINGS} links={[{ name: '' }]} />
        <SettingTabs />
      </Page>
    </Layout>
  );
}
