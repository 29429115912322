import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import XSText from '../../ui/typography/XSText';
import PropTypes from 'prop-types';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import CommonCompanyRow from '../../../sections/@company/CommonCompanyRow';
import ViewDetailsModal from '../common/ViewDetailsModal';
import { downloadImage } from '../../../helper/helpers';
import { STATUS_COLORS } from '../../../constants/enum';

function ShareHoldersAndDirectorsTable({ stackHolderDetails, isStackHolderLoading }) {
  const { page, rowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [open, setOpen] = useState(false);
  const [shareholderData, setShareholderData] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const handleViewShareHolder = (data) => {
    setShareholderData(data);
    setOpen(true);
  };

  const [tableData, setTableData] = useState([]);

  const stackHolderAndDirector = (stackHolderDetails) => {
    stackHolderDetails?.map((stackHolder) => {
      if (!stackHolder?.isDisabled) {
        setTableData((prevState) => [...prevState, stackHolder]);
      }
    });
  };

  const isNotFound = tableData?.length === 0;

  useEffect(() => {
    if (stackHolderDetails?.length > 0) {
      stackHolderAndDirector(stackHolderDetails);
    } else {
      setTableData([]);
    }
  }, [stackHolderDetails]);

  return (
    <>
      <Card
        sx={{
          '&.MuiPaper-root': {
            position: 'unset',
            boxShadow: 'none',
            border: `1px solid ${STATUS_COLORS.GREY}`
          }
        }}
      >
        <div className="m-6">
          <BaseText
            title="Shareholders and directors"
            fontSize="text-lg"
            textColor="text-blue-100"
            fontWeight="text-bold"
          />
        </div>
        <Divider />
        <Scrollbar>
          <TableContainer sx={{ position: 'relative' }}>
            <Table>
              <TableBody>
                {isStackHolderLoading ? (
                  <StatrysLoader />
                ) : (
                  <>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <>
                        <CommonCompanyRow
                          handleViewAction={() => handleViewShareHolder(row)}
                          row={row}
                          handleDownloadAction={() => downloadImage(row?.passportDocument?.documentUrl)}
                        />
                      </>
                    ))}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <ViewDetailsModal
        open={open}
        shareholderData={shareholderData}
        closeModal={closeModal}
        title="View shareholders and directors"
      />
    </>
  );
}

export default ShareHoldersAndDirectorsTable;

ShareHoldersAndDirectorsTable.propTypes = {
  stackHolderDetails: PropTypes.array,
  isStackHolderLoading: PropTypes.bool
};
