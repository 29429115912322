import React from 'react';
import BaseText from '../ui/typography/BaseText';
import Tick from '../../images/icons/customSvg/Tick';

const NumberCircle = ({ number, bgColor, textColor, isStepCompleted }) => {
  return (
    <div className={`${bgColor} ${!isStepCompleted && 'p-4'} rounded-full flex items-center justify-center w-8 h-8`}>
      {isStepCompleted ? <Tick /> : <BaseText textColor={textColor} title={number} fontWeight="text-bold" />}
    </div>
  );
};

export default NumberCircle;
