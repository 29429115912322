import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import StatrysLoader from '../ui/loaders/StatrysLoader';

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isLoading: PropTypes.bool
};

export default function TableNoData() {
  return (
    <TableRow>
      <TableCell colSpan={12}>
        <div className="flex items-center justify-center h-80">
          <StatrysLoader />
        </div>
      </TableCell>
    </TableRow>
  );
}
