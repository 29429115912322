import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import MSText from '../typography/MSText';
const filter = createFilterOptions();

const UnitSelectAutoComplete = ({
  setCustomOption,
  label,
  value,
  defaultValue,
  options,
  setSelectedValue,
  multiple,
  disabled,
  inputRef,
  error,
  helperText,
  onClose
}) => {
  const [inputValue, setInputValue] = useState('');
  return (
    <>
      <Autocomplete
        multiple={multiple}
        defaultValue={defaultValue}
        disabled={disabled}
        onClose={onClose}
        disableClearable={true}
        value={value}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setCustomOption(newValue);
          } else if (newValue && newValue.inputValue) {
            setCustomOption(newValue.inputValue);
          } else {
            newValue && setSelectedValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`
            });
          }
          return filtered;
        }}
        onInputChange={(event, newInputedValue) => {
          setInputValue(newInputedValue);
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ width: '104px' }}
        freeSolo
        renderOption={(props, option) => {
          return (
            <div className="flex gap-4 items-center" {...props}>
              <div>
                <MSText
                  title={
                    <div className="py-3">
                      <span>{option?.name}</span>
                    </div>
                  }
                />
              </div>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            value={value}
            {...params}
            label={label}
            sx={
              value && {
                '& .MuiInputBase-input': {
                  marginTop: '5px',
                  marginBottom: '0px'
                },
                '& .MuiInputAdornment-outlined': {
                  marginTop: '0px'
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: '16px 6px 7px 5px'
                }
              }
            }
            inputRef={inputRef}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </>
  );
};

export default UnitSelectAutoComplete;

UnitSelectAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedValue: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onClose: PropTypes.bool
};

UnitSelectAutoComplete.defaultProps = {
  multiple: false,
  disabled: false
};
