import React, { useState } from 'react';
import '../../styles/get-started-banner.scss';
import LGSubtitleText from '../ui/typography/LGSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import InvoiceBannerImg from '../../images/graphics/bar-chart.png';
import { PATH_PAGE } from '../../routes/paths';
import ImportedInvoiceUploadModal from '../importedInvoices/ImportedInvoiceUploadModal';
export default function DashboardBanner() {
  const [uploadModal, setUploadModal] = useState(false);

  const closeDynamicModal = () => {
    setUploadModal(false);
  };
  return (
    <div className="bg-green-200 box-shadow rounded-2xl flex md:flex-row flex-col md:justify-between">
      <div className="py-6 md:pl-6 pl-4 w-full">
        <div>
          <LGSubtitleText
            className="whitespace-pre-line"
            textWeight="text-bold"
            textColor="text-gray-500"
            title={'Welcome to the Statrys\n invoice management!'}
          />
          <XSText
            fontWeight="text-normal"
            className="whitespace-pre-line mt-4"
            textColor="text-gray-500"
            title={
              'Explore your financial performance and accounts\n receivable on this dashboard.\n You have no data to display yet.\n \nGet started by creating invoices or\n uploading invoices from your suppliers.'
            }
          />
        </div>
        <div className="send-invoice-button">
          <PrimaryButton
            id="dashboard-banner-create-invoice-btn"
            className="mr-2 getstarted-invoice-button"
            isBorderRequired={true}
            color="text-white"
            fontSize="text-base"
            fontWeight="text-medium"
            bgColor="bg-green-500"
            caption="Get your invoice paid"
            urlLink={PATH_PAGE.createInvoice}
          />
          <PrimaryButton
            id="dashboard-banner-upload-invoice-btn"
            className="send-invoice-review-button mr-2 mb-2"
            isBorderRequired={true}
            fontSize="text-base"
            fontWeight="text-medium"
            color="text-gray-800"
            bgColor="bg-white"
            caption="Pay an invoice"
            onClick={() => setUploadModal(true)}
          />
        </div>
        <ImportedInvoiceUploadModal openDynamicModal={uploadModal} closeDynamicModal={closeDynamicModal} />
      </div>

      <div className="md:mt-6 flex md:justify-end justify-center">
        <img className="bar-chart-image" src={InvoiceBannerImg} alt="Invoice"></img>
      </div>
    </div>
  );
}
