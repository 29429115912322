import { MUI_COLORS } from '../../constants/enum';

export default function Input(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          '& label.Mui-focused': {
            color: MUI_COLORS.GRAY
          },
          '& .MuiOutlinedInput-root': {
            border: 'solid 1px #D6D6D6',
            borderRadius: '8px',

            '& fieldset': {
              borderColor: 'transparent',
              overflow: 'hidden',
              height: '68px'
            },
            'input:-internal-autofill-selected': {
              height: '0px',
              padding: '30px 14px 25px'
            },

            '&.Mui-focused': {
              backgroundColor: 'white',
              border: 'solid 1px #2D0D45',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)'
            },
            '& .label': {
              color: MUI_COLORS.GRAY
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'transparent'
            }
          },
          '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(12px, 10px) scale(1)'
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            backgroundColor: '#F9DBD1',
            border: '1px solid #FF4F42'
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 5px) scale(1)',
            paddingTop: '12px'
          },
          '& .PrivateNotchedOutline-legendLabelled-7 > span': {
            display: 'none'
          },
          '& .MuiInputBase-input': {
            letterSpacing: '-0.5px',
            fontFamily: 'HKGrotesk-Medium',
            color: MUI_COLORS.DARK_CHARCOAL,
            marginTop: '11px',
            background: 'content-box',
            marginLeft: '-0.8px',
            padding: '18.5px 14px 13.5px 14px'
          },
          '& .MuiFormLabel-root': {
            fontFamily: 'HKGrotesk-medium',
            fontSize: '14px',
            paddingTop: '10px',
            lineHeight: '16px',
            color: '#7A7A7A',
            letterSpacing: '-0.5px'
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: '#FF4F42'
          },
          '& .MuiFormHelperText-root': {
            minWidth: '176px',
            fontFamily: 'HKGrotesk-Regular',
            letterSpacing: '-0.5px',
            fontSize: '14px',
            lineHeight: '16px',
            marginTop: '8px'
          },
          '& .MuiInputAdornment-root': {
            marginRight: '0px'
          },
          '& .MuiInputAdornment-outlined': {
            marginTop: '16px'
          },
          '& .MuiInputAdornment-positionEnd': {
            marginTop: '0px'
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '12px 6px 2px 5px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          outerWidth: '100%',

          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled }
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        outerWidth: '100%',
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16]
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          outerWidth: '100%',

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[500_32]
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        }
      }
    }
  };
}
