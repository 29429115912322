import React, { useEffect, useState } from 'react';
import { Divider, Tab, Tabs } from '@mui/material';
import BaseText from '../ui/typography/BaseText';
import { SETTING_TABS_LABEL } from '../../constants/enum';
import ProfileIcon from '../../images/icons/profile.svg';
import SecurityIcon from '../../images/icons/security-icon.svg';
import WrenchIcon from '../../images/icons/wrench-icon.svg';
import WrenchIconGrey from '../../images/icons/wrench-icon-grey.svg';
import InformationCircleIcon from '../../images/icons/information-circle.svg';
import ProfileSetting from './ProfileSetting';
import CurrencyPreference from './CurrencyPreference';
import CompanyDetails from './CompanyDetails';
import Security from './Security';
import '../../styles/pages/setting-tabs.scss';
import CompanySettings from './CompanySettings';
import LegalScaleIcon from '../../images/icons/legal-scale.svg';
import LightFlashOn from '../../images/icons/light-flash-on.svg';
import '../../styles/pages/profile.scss';
import { useLocation } from '@reach/router';

const TABS = [
  {
    label: SETTING_TABS_LABEL.PROFILE,
    icon: ProfileIcon
  },
  {
    label: SETTING_TABS_LABEL.SECURITY,
    icon: SecurityIcon
  },
  {
    label: SETTING_TABS_LABEL.PREFERENCES,
    icon: WrenchIcon
  }
];

const OrganizationTabs = [
  {
    label: SETTING_TABS_LABEL.COMPANY_DETAILS,
    icon: InformationCircleIcon
  },
  {
    disable: true,
    label: SETTING_TABS_LABEL.COMPANY_SETTINGS,
    icon: WrenchIconGrey
  },
  {
    disable: true,
    label: SETTING_TABS_LABEL.PLANS_LIMITS,
    icon: LightFlashOn
  },
  {
    disable: true,
    label: SETTING_TABS_LABEL.LEGAL_DOCUMENTS,
    icon: LegalScaleIcon
  }
];

export default function SettingTabs() {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState();
  const [activeIndexOrg, setActiveIndexOrg] = useState('');

  const path = location?.pathname;

  const handleChange = (_, activeIndex) => {
    setActiveIndex(activeIndex);
    setActiveIndexOrg('');
  };
  const handleChangeOrg = (_, activeIndexOrg) => {
    setActiveIndexOrg(activeIndexOrg);
    setActiveIndex('');
  };
  useEffect(() => {
    if (location.state?.preferences) {
      setActiveIndex(2);
    } else {
      if (path === '/profile') {
        setActiveIndex(0);
      } else {
        setActiveIndexOrg(0);
      }
    }
  }, []);
  return (
    <>
      <div className="flex md:flex-row flex-col mt-10 mb-4 ">
        <div className="setting-form-container bg-white setting-tabs-container mb-4">
          <div className="pt-8 px-6">
            <BaseText
              className="mb-2"
              fontSize="text-base"
              textColor="text-gray-450"
              lineHeight="leading-6"
              title="Personal"
            ></BaseText>
          </div>
          <Tabs
            TabIndicatorProps={{
              sx: {
                top: 0,
                left: 0
              }
            }}
            orientation="vertical"
            variant="scrollable"
            value={activeIndex}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderLeft: 1, borderColor: 'white' }}
          >
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                className="ml-6 justify-start rounded-lg w-full"
                value={index}
                icon={<img src={tab?.icon} alt={tab?.value} />}
                label={tab?.label}
              />
            ))}
          </Tabs>
          <Divider className="mt-4" />
          <div className="pt-8 px-6">
            <BaseText
              className="mb-2"
              fontSize="text-base"
              textColor="text-gray-450"
              lineHeight="leading-6"
              title="Organization"
            ></BaseText>
          </div>
          <Tabs
            TabIndicatorProps={{
              sx: {
                top: 0,
                left: 0
              }
            }}
            orientation="vertical"
            variant="scrollable"
            value={activeIndexOrg}
            onChange={handleChangeOrg}
            aria-label="Vertical tabs example"
            sx={{ borderLeft: 1, borderColor: 'white' }}
            className="mb-6"
          >
            {OrganizationTabs?.map((tab, index) => (
              <Tab
                disabled={tab?.disable}
                key={index}
                className="ml-6 justify-start rounded-lg	w-full"
                value={index}
                icon={<img src={tab?.icon} alt={tab?.value} />}
                label={tab?.label}
              />
            ))}
          </Tabs>
        </div>
        {activeIndex === 0 && (
          <div className="setting-content-container">
            <ProfileSetting />
          </div>
        )}
        {activeIndex === 1 && (
          <div className="setting-content-container">
            <Security />
          </div>
        )}
        {activeIndex === 2 && (
          <div className="setting-content-container">
            <CurrencyPreference />
          </div>
        )}
        {activeIndexOrg === 0 && (
          <div className="setting-content-container">
            <CompanyDetails />
          </div>
        )}
        {/* {activeIndexOrg === 1 && (
          <div className="setting-content-container">
            <CurrencyPreference />
          </div>
        )}
        {activeIndexOrg === 2 && (
          <div className="profile-form-container md:ml-6 bg-white setting-content-container"></div>
        )} */}
      </div>
    </>
  );
}
