import * as React from 'react';
import { navigate } from 'gatsby';
import { getUser, isLoggedIn } from '../authService/auth';
import { INVOICE_DASHBOARD, COMPANY, PATH_PAGE } from '../../routes/paths';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== PATH_PAGE.login) {
    navigate(PATH_PAGE.login);
    return null;
  } else if ((location.pathname === COMPANY || location.pathname === `${COMPANY}/`) && !getUser()?.isCompanyOwner) {
    navigate(INVOICE_DASHBOARD);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
