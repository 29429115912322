import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack } from '@mui/material';
import Avatar from '../../components/ui/Avatar';
import { MUI_COLORS } from '../../constants/enum';
import Iconify from '../../components/Iconify';
import MSText from '../../components/ui/typography/MSText';
import CompanyStatus from '../../components/ui/CompanyStatus';
import { makeFirstLetterCapitalize } from '../../helper/helpers';
import XMSText from '../../components/ui/typography/XMSText';

CorporateRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  handleViewAction: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handleDownloadAction: PropTypes.func
};

export default function CorporateRow({ row, selected, handleViewAction, handleDownloadAction }) {
  const theme = useTheme();

  const { companyName, Identity, companyChineseName, isDisabled } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }} className="cursor-pointer" onClick={handleViewAction}>
        <div className="mr-2">
          <Avatar avatarImage="" avatarName={companyName?.charAt(0)} />
        </div>
        <Stack>
          <Typography
            sx={{ color: MUI_COLORS.MID_NIGHT, textDecoration: 'none', boxShadow: 'none' }}
            variant="subtitle2"
            noWrap
          >
            {companyChineseName && companyChineseName} {makeFirstLetterCapitalize(companyName)}
          </Typography>
        </Stack>
      </TableCell>

      {!isDisabled ? (
        <TableCell className="cursor-pointer" align="right" onClick={handleViewAction}>
          {Identity?.map((status) => (
            <span className="ml-2">
              <CompanyStatus requiredCompanyStatus={status} />
            </span>
          ))}
        </TableCell>
      ) : (
        <TableCell className="cursor-pointer" align="right" onClick={handleViewAction}>
          {Identity?.map((status) => (
            <div className="px-2 rounded-md inline-block bg-gray-400 ml-2">
              <XMSText
                fontWeight="text-bold"
                className="company-status-text"
                textColor="text-gray-450"
                title={status}
              />
            </div>
          ))}
        </TableCell>
      )}

      <TableCell align="right">
        <div className="flex justify-end">
          <div className="bg-customBg-mainBg text-coral-500 rounded-lg p-2 cursor-pointer" onClick={handleViewAction}>
            <Iconify icon={'ph:eye'} />
          </div>
          <div
            className="bg-customBg-mainBg text-coral-500 rounded-lg ml-5 p-2 cursor-pointer"
            onClick={handleDownloadAction}
          >
            <Iconify icon={'lucide:download-cloud'} />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
