import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_COLORS, COMPANY_STATUS, MUI_COLORS } from '../../constants/enum';
import XMSText from './typography/XMSText';
export default function CompanyStatus({ requiredCompanyStatus }) {
  const invoiceCompanyStatus = () => {
    switch (requiredCompanyStatus) {
      case COMPANY_STATUS.COMPANY_SECRETARY:
        return { bgcolor: MUI_COLORS.LIGHT_GREEN, color: 'text-green-600', title: COMPANY_STATUS.COMPANY_SECRETARY };
      case COMPANY_STATUS.CORPORATE_SHAREHOLDER:
        return { bgcolor: STATUS_COLORS.MALVA, color: 'text-purple-500', title: COMPANY_STATUS.CORPORATE_SHAREHOLDER };
      case COMPANY_STATUS.DIRECTOR:
        return { bgcolor: STATUS_COLORS.GREEN, color: 'text-white', title: COMPANY_STATUS.DIRECTOR };
      case COMPANY_STATUS.MAIL:
        return { bgcolor: MUI_COLORS.SUNGLOW, color: 'text-black', title: COMPANY_STATUS.MAIL };
      case COMPANY_STATUS.SHAREHOLDER:
        return { bgcolor: MUI_COLORS.PURPLE, color: 'text-white', title: COMPANY_STATUS.SHAREHOLDER };
      default:
        return 'invoice-status-draft';
    }
  };
  return (
    <div className="px-2 rounded-md inline-block" style={{ backgroundColor: invoiceCompanyStatus()?.bgcolor }}>
      <XMSText
        fontWeight="text-bold"
        className="company-status-text"
        textColor={invoiceCompanyStatus()?.color}
        title={invoiceCompanyStatus()?.title}
      />
    </div>
  );
}

CompanyStatus.propTypes = {
  requiredCompanyStatus: PropTypes.string
};
