import React from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import Image from '../Image';
import AddPaymentImg from '../../images/graphics/add-payment.png';
import XSText from '../ui/typography/XSText';

export default function PaidPaymentModal({ openPaidDynamicModal, closePaidPaymentModal }) {
  const { register, handleSubmit, errors, watch } = useForm();

  const onSubmit = () => {
    closePaidPaymentModal();
  };

  return (
    <div>
      <DynamicModal openDynamicModal={openPaidDynamicModal} closeDynamicModal={closePaidPaymentModal} minWidth="520px">
        <div className="mt-5 px-10">
          <MDSubtitleText className="flex justify-center mb-10" title={`Invoice was paid`} fontWeight="text-bold" />
          <div className="flex justify-center mb-8">
            <Image className="w-64" src={AddPaymentImg} alt="addPayment" />
          </div>
          <XSText
            className="flex justify-center whitespace-pre-line"
            textAlign="text-center"
            title={'Enter email to get access to all your \n securely stored documents'}
          />
          <form id="paid-invoice" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              defaultValue=""
              name="email"
              label="Email address"
              variant="filled"
              textFieldContainerClass="mt-4"
              inputRef={register({
                required: true
              })}
              error={errors?.email ? true : false}
              helperText={errors?.email && <ValidationMessage title={'Please provide the email'} />}
            />
            <div className="flex justify-center flex-row gap-4 mt-8 mb-4">
              <PrimaryButton
                id="paid-payment-open-document-btn"
                caption="Open my documents"
                type="small"
                className="modal-save-button"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </DynamicModal>
    </div>
  );
}
