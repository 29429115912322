export const CREATE_CONTACT_REQUEST_START = 'CREATE_CONTACT_REQUEST_START';
export const CREATE_CONTACT_REQUEST_SUCCESS = 'CREATE_CONTACT_REQUEST_SUCCESS';
export const CREATE_CONTACT_REQUEST_FAILURE = 'CREATE_CONTACT_REQUEST_FAILURE';

export const CONTACT_LIST_REQUEST_START = 'CONTACT_LIST_REQUEST_START';
export const CONTACT_LIST_REQUEST_SUCCESS = 'CONTACT_LIST_REQUEST_SUCCESS';
export const CONTACT_LIST_REQUEST_FAILURE = 'CONTACT_LIST_REQUEST_FAILURE';

export const EDIT_CONTACT_REQUEST_START = 'EDIT_CONTACT_REQUEST_START';
export const EDIT_CONTACT_REQUEST_SUCCESS = 'EDIT_CONTACT_REQUEST_SUCCESS';
export const EDIT_CONTACT_REQUEST_FAILURE = 'EDIT_CONTACT_REQUEST_FAILURE';

export const DELETE_CONTACT_REQUEST_START = 'DELETE_CONTACT_REQUEST_START';
export const DELETE_CONTACT_REQUEST_SUCCESS = 'DELETE_CONTACT_REQUEST_SUCCESS';
export const DELETE_CONTACT_REQUEST_FAILURE = 'DELETE_CONTACT_REQUEST_FAILURE';

export const VIEW_CONTACT_REQUEST_START = 'VIEW_CONTACT_REQUEST_START';
export const VIEW_CONTACT_REQUEST_SUCCESS = 'VIEW_CONTACT_REQUEST_SUCCESS';
export const VIEW_CONTACT_REQUEST_FAILURE = 'VIEW_CONTACT_REQUEST_FAILURE';

export const GET_ALL_CONTACT_REQUEST_START = 'GET_ALL_CONTACT_REQUEST_START';
export const GET_ALL_CONTACT_REQUEST_SUCCESS = 'GET_ALL_CONTACT_REQUEST_SUCCESS';
export const GET_ALL_CONTACT_REQUEST_FAILURE = 'GET_ALL_CONTACT_REQUEST_FAILURE';

export const INVOICE_CONTACT_LIST_BY_ID_REQUEST_START = 'INVOICE_CONTACT_LIST_BY_ID_REQUEST_START';
export const INVOICE_CONTACT_LIST_BY_ID_REQUEST_SUCCESS = 'INVOICE_CONTACT_LIST_BY_ID_REQUEST_SUCCESS';
export const INVOICE_CONTACT_LIST_BY_ID_REQUEST_FAILURE = 'INVOICE_CONTACT_LIST_BY_ID_REQUEST_FAILURE';

export const RESET_CONTACT_MODAL_FORM = 'RESET_CONTACT_MODAL_FORM';
