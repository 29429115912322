import * as actionTypes from './actionTypes';

let initialState = {
  createdSupplierData: {},
  supplierDetails: {},
  editedSupplierData: {},
  deletedSupplierData: {},
  supplierList: {},
  loading: false,
  error: false
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPLIER_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_SUPPLIER_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_SUPPLIER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        supplierDetails: { ...action.getSupplierDetails },
        loading: false,
        error: false
      };

    case actionTypes.CREATE_SUPPLIER_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_SUPPLIER_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CREATE_SUPPLIER_REQUEST_SUCCESS:
      return {
        ...state,
        createdSupplierData: { ...action.supplierPayload },
        loading: false,
        error: false
      };

    case actionTypes.EDIT_SUPPLIER_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_SUPPLIER_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.EDIT_SUPPLIER_REQUEST_SUCCESS:
      return {
        ...state,
        editedSupplierData: { ...action.editedSupplierData },
        loading: false,
        error: false
      };

    case actionTypes.DELETE_SUPPLIER_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_SUPPLIER_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.DELETE_SUPPLIER_REQUEST_SUCCESS:
      return {
        ...state,
        deletedSupplierData: { ...action.supplierPayload },
        loading: false,
        error: false
      };

    case actionTypes.GET_SUPPLIER_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_SUPPLIER_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_SUPPLIER_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        supplierList: { ...action.getSupplierList },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};

export default supplierReducer;
