import React from 'react';
import MailRoomTable from './MailRoomTable';
import StatrysLoader from '../../ui/loaders/StatrysLoader';

function MailRoom({ documentsDetails, isDocumentsLoading, companyId }) {
  return (
    <div className="mt-8">
      {isDocumentsLoading ? (
        <StatrysLoader />
      ) : (
        <MailRoomTable
          documentsDetails={documentsDetails}
          isDocumentsLoading={isDocumentsLoading}
          companyId={companyId}
        />
      )}
    </div>
  );
}

export default MailRoom;
