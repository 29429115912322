import React from 'react';
import { checkValueExistOrNot, convertDateToDDMMYYYY } from '../../../helper/helpers';
import Avatar from '../../ui/Avatar';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import MSText from '../../ui/typography/MSText';
import XMSText from '../../ui/typography/XMSText';
import XSText from '../../ui/typography/XSText';
import '../../../styles/pages/company.scss';

function CompanyInformation({ profileDetails, isLoading }) {
  return (
    <div className="bg-white p-6 rounded-lg company-view-container">
      {!isLoading ? (
        <>
          <div className="flex justify-between mb-4">
            <Avatar
              avatarStyling="w-20 h-20"
              fontSize="text-3xl"
              avatarImage=""
              avatarName={profileDetails?.registeredCompanyName?.charAt(0)}
            />
          </div>

          <H4HeaderText title={profileDetails?.registeredCompanyName} />
          <div className="mb-4 mt-6">
            <MSText className="mb-2" textColor="text-gray-450" title="Unique Business Identifier" />
            <XSText title={checkValueExistOrNot(profileDetails?.brCertificateNumber)} />
          </div>
          <div className="mb-4">
            <MSText className="mb-2" textColor="text-gray-450" title="Registered address" />
            <XSText className="whitespace-pre-line" title={checkValueExistOrNot(profileDetails?.registeredAddress)} />
          </div>
          <div className="mb-4">
            <MSText className="mb-2" textColor="text-gray-450" title="Incorporation date" />
            <XSText title={checkValueExistOrNot(convertDateToDDMMYYYY(profileDetails?.registrationDate))} />
          </div>
          <div className="mb-4">
            <MSText className="mb-2" textColor="text-gray-450" title="Financial year" />
            <XSText title={checkValueExistOrNot(convertDateToDDMMYYYY(profileDetails?.brExpiryDate))} />
          </div>
          <div>
            <MSText className="mb-2" textColor="text-gray-450" title="Status" />
            <XMSText
              className="px-2 bg-green-500 rounded-md width-fit-content"
              textColor="text-white"
              fontWeight="text-bold"
              title={profileDetails?.companyStatus?.name}
            />
          </div>
        </>
      ) : (
        <StatrysLoader />
      )}
    </div>
  );
}

export default CompanyInformation;
