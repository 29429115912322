import React from 'react';
import DynamicModalScroll from '../ui/modal/DynamicModalInsideScroll';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import PrivacyAndPolicy from '../../data/privacy-policy.json';
import PrivacyAndPolicyIntro from '../../data/privacy-policy-intro.json';
import BaseText from '../ui/typography/BaseText';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function PrivacyAndPolicyModal({ openPrivacyModal, closeDynamicModal }) {
  return (
    <div>
      <DynamicModalScroll
        openDynamicModal={openPrivacyModal}
        closeDynamicModal={closeDynamicModal}
        scrollType="paper"
        minWidth="520px"
        title={
          <>
            <MDSubtitleText
              className="mb-4"
              title={
                <span className="flex justify-between items-center">
                  <span>Privacy and Policy</span>
                  <span>
                    <IconButton onClick={closeDynamicModal}>
                      <CloseIcon />
                    </IconButton>
                  </span>
                </span>
              }
              fontWeight="text-bold"
            />
          </>
        }
        action={
          <div className="flex justify-center flex-row gap-4 mb-4">
            <PrimaryButton
              id="privacy-and-policy-close-btn"
              className="modal-cancel-button font-weight-bold border border-gray-400"
              bgColor="bg-coral-500"
              caption="Close"
              onClick={closeDynamicModal}
            />
          </div>
        }
        children={
          <>
            <div>
              <div className="w-full max-w-7xl">
                <section>
                  <div
                    className="p-text whitespace-pre-line"
                    dangerouslySetInnerHTML={{ __html: PrivacyAndPolicyIntro?.description }}
                  ></div>
                  {PrivacyAndPolicy?.map((item, idx) => (
                    <div key={'_' + idx} className="mt-4" id={item.link}>
                      <div key={'_' + idx}>
                        <BaseText
                          className="mt-4 capitalize"
                          fontWeight="text-bold"
                          title={`${idx + 1}. ${item.title} `}
                        />
                      </div>
                      {item?.description !== '' && (
                        <p
                          className="p-text text-left mt-4"
                          dangerouslySetInnerHTML={{ __html: item?.description }}
                        ></p>
                      )}
                      <div className="mt-2">
                        {item.list.map((k, idxs) => (
                          <div
                            key={'_' + idxs}
                            className={`${
                              k?.title ? 'items-start justify-start' : 'flex flex-row items-start justify-start'
                            } `}
                          >
                            {k?.title ? (
                              <>
                                <BaseText
                                  className="mr-4 mt-4 capitalize"
                                  fontWeight="text-bold"
                                  title={`${idx + 1}.${idxs + 1}   ${k.title}`}
                                />
                                <div
                                  className=" whitespace-pre-line  p-text text-left mt-4"
                                  dangerouslySetInnerHTML={{ __html: k.text.trim() }}
                                ></div>
                              </>
                            ) : (
                              <>
                                {k.text ? <div className="mr-4 font-bold">{`${idx + 1}.${idxs + 1}`}</div> : null}
                                <div
                                  className="p-text text-left"
                                  dangerouslySetInnerHTML={{ __html: k?.text?.trim() }}
                                ></div>
                                <div>{''}</div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </section>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}
