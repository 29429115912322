import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import IconArrowLeft from '../../../images/icons/arrow-left.svg';

function BackButton({ urlLink, className, color, bgColor, onClick, fontSize }) {
  return (
    <Link to={urlLink} className="hover:no-underline cursor-pointer">
      <div
        onClick={() => onClick && onClick()}
        className={`back-button flex items-center justify-center gap-2 ${className} ${color} ${bgColor} ${fontSize}`}
      >
        <img src={IconArrowLeft} alt="arrow right" className="w-4 h-4 transform rotate-180" />
        <div className="back-button-text">Back</div>
      </div>
    </Link>
  );
}

export default BackButton;

BackButton.propTypes = {
  caption: PropTypes.string,
  urlLink: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fontSize: PropTypes.string
};
