import ArrowIconWhite from '../../../images/icons/arrow-icon-btn.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CircularLoading from '../loaders/CircularLoader';
function PrimaryButton({
  caption,
  urlLink,
  className,
  color,
  bgColor,
  onClick,
  type,
  fontSize,
  showArrowIcon,
  customerInfoLoading,
  accountSetupInfoLoading,
  disabled,
  isBorderRequired,
  style,
  fontWeight,
  form,
  buttonType,
  companyRegistrationStep,
  isLoading,
  target,
  id
}) {
  return (
    <Link
      to={urlLink}
      state={{ companyRegistrationStep: companyRegistrationStep && companyRegistrationStep }}
      target={target}
    >
      <button
        style={style}
        disabled={disabled}
        form={form}
        type={buttonType}
        id={id}
        onClick={() => !customerInfoLoading && !accountSetupInfoLoading && !isLoading && onClick && onClick()}
        className={`${
          type
            ? isBorderRequired
              ? 'sign-up-button-without-border'
              : 'sign-up-button'
            : isBorderRequired
            ? 'buttonWithoutBorder'
            : 'button'
        } ${
          isLoading && 'flex items-center justify-center'
        } button-primary ${className} ${color} ${bgColor} ${fontSize} ${fontWeight}`}
      >
        {isLoading ? (
          <CircularLoading />
        ) : (
          <>
            {showArrowIcon ? (
              <span>
                <img src={ArrowIconWhite} className="mr-3" />
              </span>
            ) : null}
            {caption}
          </>
        )}
      </button>
    </Link>
  );
}
const mapStateToProps = (state) => {
  return {
    customerInfoLoading: state?.customerInfo?.loading,
    accountSetupInfoLoading: state?.AccountSetupInfo?.loading
  };
};
export default connect(mapStateToProps, null)(PrimaryButton);

PrimaryButton.propTypes = {
  showArrowIcon: PropTypes.bool,
  caption: PropTypes.string,
  urlLink: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fontSize: PropTypes.string,
  buttonType: PropTypes.string,
  form: PropTypes.string,
  customerInfoLoading: PropTypes.bool,
  accountSetupInfoLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isBorderRequired: PropTypes.bool,
  style: PropTypes.any,
  fontWeight: PropTypes.string,
  companyRegistrationStep: PropTypes.number,
  id: PropTypes.func
};

PrimaryButton.defaultProps = {
  showArrowIcon: false,
  color: 'text-white',
  bgColor: 'bg-coral-500',
  fontSize: 'text-sm md:text-lg text-xl',
  disabled: false,
  isBorderRequired: false,
  companyRegistrationStep: 1,
  buttonType: 'button'
};
