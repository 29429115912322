import React, { useEffect, useState } from 'react';
import { Card, Table, Stack, Tooltip, TableBody, IconButton, TableContainer } from '@mui/material';
import useTable, { emptyRows } from '../../hooks/useTable';
// _mock_
import { API_CODE, MUI_COLORS } from '../../constants/enum';
// components
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { TableNoData, TableEmptyRows, TableHeadCustom, TableSelectedActions } from '../table';
import XSText from '../ui/typography/XSText';
import Snackbar from '../Snackbar';
import Image from '../Image';
import Success from '../../images/icons/success-delete-icon.svg';
import PropTypes from 'prop-types';
import * as invoiceActions from '../invoice/reduxStore/action';
import { connect } from 'react-redux';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import PaymentReviewRow from '../../sections/@paymentReview/PaymentReviewRow';
import BaseText from '../ui/typography/BaseText';
import * as paymentActionRedux from '../invoicePaymentDetails/reduxStore/action';
import MSText from '../ui/typography/MSText';
import ApprovalConfirmationModal from '../invoiceCommon/ApprovalConfirmationModal';
import { PATH_PAGE } from '../../routes/paths';
import { navigate } from 'gatsby';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'All',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development'
];

const TABLE_HEAD = [
  { id: '', label: 'Client', align: 'left' },
  { id: '', label: 'Overdue', align: 'left' },
  { id: '', label: 'Payment date', align: 'left' },
  { id: '', label: 'Type', align: 'left' },
  { id: '', label: 'Amount', align: 'right' },
  { id: '', width: '114px' }
];

// ----------------------------------------------------------------------

function PaymentReviewList({ getInvoiceList, invoiceList, invoiceDeleteMarkPayment, isInvoiceDelete }) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({ defaultOrderBy: 'dateCreated' });
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [approvePayment, setApprovePayment] = useState(false);
  const [paymentDataDetails, setPaymentDataDetails] = useState('');
  const [selectedInvoiceData, setSelectedInvoiceData] = useState('');
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleInvoiceList();
  }, [isInvoiceDelete]);

  const handleInvoiceList = async () => {
    setIsLoading(true);
    const getInvoiceListResponse = await getInvoiceList();
    setTableData(getInvoiceListResponse?.data?.data?.invoiceData);
    invoiceList();
    setIsLoading(false);
  };
  const closeAddPaymentModal = () => {
    setApprovePayment(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = async (id, paymentId, invoiceNumber) => {
    const queryParams = {
      params: { invoiceId: id, paymentId: paymentId }
    };
    const deleteRows = tableData.filter((row) => row?._id !== id);
    setTableData(deleteRows);
    deleteMarkPaymentHandler(queryParams, invoiceNumber);
  };
  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row?._id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleApproveRow = (invoiceId, paymentData, invoiceAllData) => {
    setPaymentDataDetails(paymentData);
    setSelectedInvoiceData(invoiceAllData);
    setApprovePayment(true);
  };

  const deleteMarkPaymentHandler = async (payload, invoiceNumber) => {
    const invoiceDeletePaymentResponse = await invoiceDeleteMarkPayment(payload);
    if (invoiceDeletePaymentResponse?.status === API_CODE.STATUS_200) {
      handleInvoiceList();
      setSuccessMessage({
        ...successMessage,
        title: 'Success',
        content: `Invoice ${invoiceNumber} payment was successfully deleted!`
      });
      setOpen(true);
    }
  };

  const onClickSelectedRowHandler = (id) => {
    navigate(PATH_PAGE.viewInvoice + `?id=${id}`);
  };

  const isPaymnetsArrayPresent = tableData?.filter(
    (row) => row?.hasOwnProperty('payments') && Object.keys(row?.['payments']).length > 0
  );

  const getTotalPaymentInvoiceData = isPaymnetsArrayPresent?.flat()?.map((row) => {
    return row?.payments?.filter((payment) => payment?.hasOwnProperty('isApproved') && payment?.isApproved === false);
  });

  const isNotFound = getTotalPaymentInvoiceData?.flat()?.length === 0 ? true : false;

  return (
    <>
      {!isNotFound && (
        <Card
          className="my-10"
          sx={{
            '&.MuiPaper-root': {
              position: 'unset'
            }
          }}
        >
          <div className="m-6">
            <BaseText
              title="Payments pending review"
              fontSize="text-lg"
              textColor="text-blue-100"
              fontWeight="text-bold"
            />
          </div>
          {isLoading ? (
            <StatrysLoader />
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected?.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected?.length}
                    rowCount={tableData?.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?._id)
                      )
                    }
                    actions={
                      <Stack spacing={1} direction="row">
                        <Tooltip title="Sent">
                          <IconButton color="primary">
                            <Iconify icon={'ic:round-send'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                          <IconButton color="primary">
                            <Iconify icon={'eva:download-outline'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Print">
                          <IconButton color="primary">
                            <Iconify icon={'eva:printer-fill'} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                  />
                )}

                <Table>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?._id)
                      )
                    }
                  />
                  <TableBody>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) =>
                      row?.payments?.map((payment) => {
                        if (!payment?.isApproved) {
                          return (
                            <>
                              <PaymentReviewRow
                                key={row?._id}
                                row={row}
                                paymentsData={payment}
                                selected={selected.includes(row?._id)}
                                onSelectRow={() => onSelectRow(row?._id)}
                                onApproveRow={(invoiceId, paymentData) => handleApproveRow(invoiceId, paymentData, row)}
                                onClickSelectedRow={(invoiceId) => onClickSelectedRowHandler(invoiceId)}
                                onDeleteRow={(invoiceId, paymentId) =>
                                  handleDeleteRow(invoiceId, paymentId, row?.invoiceNumber)
                                }
                              />
                            </>
                          );
                        }
                      })
                    )}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}
          <Snackbar
            open={open}
            handleClose={handleClose}
            sx={{
              bgcolor: MUI_COLORS.LIGHT_GREEN,
              border: '1px solid',
              borderColor: MUI_COLORS.GREEN
            }}
            title={
              <div className="flex">
                <div className="flex items-center">
                  <Image className="w-5 h-5" src={Success} />
                </div>
                <div className="ml-4">
                  <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
                  <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
                </div>
              </div>
            }
          ></Snackbar>
          <ApprovalConfirmationModal
            getInvoiceDetails={selectedInvoiceData}
            getPaymentDetails={paymentDataDetails}
            handleInvoiceDetailsbyID={handleInvoiceList}
            openDynamicModal={approvePayment}
            closeDynamicModal={() => closeAddPaymentModal()}
          />
        </Card>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isInvoiceDelete: state?.invoiceDetails?.deleteInvoiceDetails?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceList: () => dispatch(invoiceActions.getInvoiceList()),
    invoiceDeleteMarkPayment: (invoicedata) => dispatch(paymentActionRedux.invoiceDeleteMarkPayment(invoicedata))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReviewList);

PaymentReviewList.propTypes = {
  getInvoiceList: PropTypes.func,
  editInvoice: PropTypes.func,
  invoiceDeleteMarkPayment: PropTypes?.func
};
