/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import './src/styles/global.css';
import './src/styles/variable.scss';
import './src/styles/global.scss';
import './src/styles/typography.scss';
import './src/styles/buttons.scss';
import 'simplebar/src/simplebar.css';
import ThemeProvider from './src/theme';

import ViewInvoice from './src/pages/getpaid/view';
import EditInvoice from './src/pages/getpaid/edit';
import CreateInvoice from './src/pages/getpaid/create';
import UserContact from './src/pages/contact/list';
import ViewContact from './src/pages/contact/view';
import ViewIncomingInvoices from './src/pages/view-incoming-invoices';
import PrivateRoute from './src/components/privateRoutes/privateRoute';
import LoginAndSignUpRoute from './src/components/privateRoutes/LoginAndSignUpRoute';
import LogIn from './src/pages/login';
import PublicInvoice from './src/pages/public';
import IncomingPublicInvoice from './src/pages/view-incoming-invoices-public';
import SignUp from './src/pages/signup';
import ConfirmEmailAccount from './src/pages/confirm-email-account';
import TooManyAttempts from './src/pages/too-many-attempts';
import VerifyEmail from './src/pages/verify-email';
import ResetPassword from './src/pages/reset-password';
import { Router } from '@reach/router';
import InvoiceList from './src/pages/getpaid';
import { PATH_PAGE } from './src/routes/paths';
import Profile from './src/pages/profile';
import AccountSettings from './src/pages/account-settings';
import Pay from './src/pages/pay';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import CreateImportingInvoice from './src/pages/importing-invoice/create';
import EditImportingInvoice from './src/pages/importing-invoice/edit';
import InvoiceGenerator from './src/pages/invoice-generator';
import Dashboard from './src/pages/dashboard';
import BoInvoiceSignUp from './src/pages/boInvoice/signUp';
import PublicCompanyDocs from './src/pages/public-company-docs';
import CompanyIncorporation from './src/pages/company-set-up';
import CompanyIncorporationSuccess from './src/pages/company-incorporation-success';
import CompanySignUp from './src/pages/company-signup';
import CompanyEmailVerify from './src/pages/company-verify-email';
import CompanyProfile from './src/pages/company/profile';
import CompanyOrganization from './src/pages/company/organization';
import CompanyMailRoom from './src/pages/company/mailroom';
import CompanyDocuments from './src/pages/company/documents';
import UnderMaintenance from './src/pages/under-maintenance';
import CompanyProfileSetting from './src/pages/company/setting-profile';
import { STATUS } from './src/constants/enum';

export { default as wrapRootElement } from './src/state/reduxWrapper';
export const onPreRouteUpdate = ({ location }) => {
  const params = new URLSearchParams(location?.search);
  const token = params.get('token');
  token && sessionStorage.setItem('token', token);
};
export const onInitialClientRender = ({ location }) => {
  document.getElementById('___gatsby').style.display = 'block';
  setTimeout(function () {
    document.getElementById('___loader').style.display = 'none';
  }, 200);
};
export const wrapPageElement = () => {
  return (
    <ThemeProvider>
      {process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE ? (
        <Router>
          <UnderMaintenance default />
        </Router>
      ) : (
        <Router>
          <PrivateRoute path={PATH_PAGE.dashboard} component={Dashboard} />
          <PrivateRoute path={PATH_PAGE.invoiceList} component={InvoiceList} />
          <PrivateRoute path={PATH_PAGE.createInvoice} component={CreateInvoice} />
          <PrivateRoute path={PATH_PAGE.createImportingInvoice} component={CreateImportingInvoice} />
          <PrivateRoute path={PATH_PAGE.editImportingInvoice} component={EditImportingInvoice} />
          <PrivateRoute path={PATH_PAGE.companyProfile} component={CompanyProfile} />
          <PrivateRoute path={PATH_PAGE.companyProfileSetting} component={CompanyProfileSetting} />
          <PrivateRoute path={PATH_PAGE.companyOrganization} component={CompanyOrganization} />
          <PrivateRoute path={PATH_PAGE.companyMailRoom} component={CompanyMailRoom} />
          <PrivateRoute path={PATH_PAGE.companyDocuments} component={CompanyDocuments} />
          <PrivateRoute path={PATH_PAGE.viewInvoice} component={ViewInvoice} />
          <PrivateRoute path={PATH_PAGE.editInvoice} component={EditInvoice} />
          <PrivateRoute path={PATH_PAGE.profile} component={Profile} />
          <PrivateRoute path={PATH_PAGE.accountSettings} component={AccountSettings} />
          <PrivateRoute path={PATH_PAGE.pay} component={Pay} />
          <PrivateRoute path={PATH_PAGE.contact} component={UserContact} />
          <PrivateRoute path={PATH_PAGE.viewContact} component={ViewContact} />
          <PrivateRoute path={PATH_PAGE.viewIncomingInvoices} component={ViewIncomingInvoices} />
          <PrivateRoute path={PATH_PAGE.companyIncorporationSuccess} component={CompanyIncorporationSuccess} />
          <LoginAndSignUpRoute path={PATH_PAGE.login} component={LogIn} />
          <LoginAndSignUpRoute path={PATH_PAGE.signUp} component={SignUp} />
          <LoginAndSignUpRoute path={PATH_PAGE.confirmEmailAccount} component={ConfirmEmailAccount} />
          <LoginAndSignUpRoute path={PATH_PAGE.verifyEmail} component={VerifyEmail} />
          <LoginAndSignUpRoute path={PATH_PAGE.tooManyAttempts} component={TooManyAttempts} />
          <LoginAndSignUpRoute path={PATH_PAGE.resetPassword} component={ResetPassword} />
          <CompanySignUp path={PATH_PAGE.companySignUp} />
          <CompanyEmailVerify path={PATH_PAGE.companyEmailVerify} />
          <PublicInvoice path={PATH_PAGE.publicInvoice} />
          <IncomingPublicInvoice path={PATH_PAGE.incomingPublicInvoice} />
          <InvoiceGenerator path={PATH_PAGE.invoiceGenerator} />
          <BoInvoiceSignUp path={PATH_PAGE.boSignUp} />
          <PrivateRoute default component={InvoiceList} />
          <PublicCompanyDocs path={PATH_PAGE.publicCompanyDocs} />
          <CompanyIncorporation path={PATH_PAGE.companyIncorporation} />
        </Router>
      )}
    </ThemeProvider>
  );
};
