import React from "react"

const CashPayment = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1355_56162)">
        <path
          d="M3.43339 10.7467L4.98672 10.0801C5.14169 9.97329 5.27231 9.83492 5.36997 9.67406C5.46764 9.5132 5.53015 9.33348 5.55339 9.14673L5.80005 7.68006L7.90005 6.18006C7.99547 6.12202 8.07478 6.04093 8.13069 5.94425C8.18661 5.84756 8.21733 5.73838 8.22005 5.62673V5.24006C8.2282 5.12727 8.20751 5.01426 8.15995 4.91166C8.11239 4.80906 8.03951 4.72025 7.94816 4.65358C7.85682 4.58691 7.75002 4.54457 7.63781 4.53054C7.5256 4.51651 7.41166 4.53126 7.30672 4.5734L4.49339 5.60006C3.79055 5.84857 3.138 6.22114 2.56672 6.70006L1.38672 7.68006"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.38672 14.5002L2.92005 13.3802C3.24711 13.1349 3.64456 13.0016 4.05339 13.0002H9.18672C9.58996 13.0008 9.98399 12.8796 10.3171 12.6524C10.6502 12.4251 10.9069 12.1025 11.0534 11.7268L11.9867 9.3335"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1067 6.39323C10.2507 6.58383 10.4387 6.73674 10.6546 6.83891C10.8705 6.94107 11.108 6.98944 11.3467 6.9799C12.1067 6.9799 12.72 6.5199 12.72 5.94657C12.72 5.37323 12.1067 4.9199 11.3467 4.9199C10.5867 4.9199 9.96667 4.45323 9.96667 3.88657C9.96667 3.3199 10.5867 2.85323 11.3467 2.85323C11.5854 2.84369 11.8228 2.89206 12.0387 2.99423C12.2547 3.09639 12.4427 3.2493 12.5867 3.4399"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3467 6.97998V7.66665"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3467 2.1665V2.85317"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7201 1.5H15.3867C15.6519 1.5 15.9063 1.60536 16.0938 1.79289C16.2814 1.98043 16.3867 2.23478 16.3867 2.5V8.33333C16.3867 8.59855 16.2814 8.8529 16.0938 9.04044C15.9063 9.22798 15.6519 9.33333 15.3867 9.33333H7.88672"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.38672 3.5V2.5C4.38672 2.23478 4.49208 1.98043 4.67961 1.79289C4.86715 1.60536 5.1215 1.5 5.38672 1.5H9.22005"
          stroke="#E15741"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1355_56162">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.886719)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CashPayment
