import React from 'react';
const checkByStreamLineHq = ({ fill }) => {
  return (
    <div className="w-4">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.4333 3.6L13.5667 1.73333C13.5044 1.67226 13.4206 1.63805 13.3333 1.63805C13.2461 1.63805 13.1623 1.67226 13.1 1.73333L5.23333 9.6C5.17103 9.66107 5.08725 9.69528 5 9.69528C4.91275 9.69528 4.82898 9.66107 4.76667 9.6L2.9 7.73333C2.83769 7.67226 2.75392 7.63805 2.66667 7.63805C2.57942 7.63805 2.49564 7.67226 2.43333 7.73333L0.566668 9.6C0.505592 9.66231 0.471382 9.74608 0.471382 9.83333C0.471382 9.92058 0.505592 10.0044 0.566668 10.0667L4.76667 14.2667C4.82898 14.3277 4.91275 14.362 5 14.362C5.08725 14.362 5.17103 14.3277 5.23333 14.2667L15.4333 4.06667C15.4944 4.00436 15.5286 3.92058 15.5286 3.83333C15.5286 3.74608 15.4944 3.66231 15.4333 3.6Z"
          fill={fill ? fill : `#D9D9D9`}
        />
      </svg>
    </div>
  );
};
export default checkByStreamLineHq;