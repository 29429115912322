import React from 'react';

export default function Tick() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.48535 14.8154C5.88184 14.8154 6.19629 14.6411 6.42871 14.2925L13.9038 2.46973C13.9927 2.33301 14.0542 2.20996 14.0884 2.10059C14.1226 1.99121 14.1396 1.88184 14.1396 1.77246C14.1396 1.51953 14.061 1.31787 13.9038 1.16748C13.7534 1.01025 13.5518 0.931641 13.2988 0.931641C13.1143 0.931641 12.9639 0.969238 12.8477 1.04443C12.7314 1.11279 12.6152 1.23584 12.499 1.41357L5.44434 12.7339L1.73242 7.72998C1.51367 7.4292 1.24707 7.27881 0.932617 7.27881C0.672852 7.27881 0.460938 7.36084 0.296875 7.5249C0.132812 7.68213 0.0507812 7.89062 0.0507812 8.15039C0.0507812 8.25977 0.0712891 8.37256 0.112305 8.48877C0.160156 8.59814 0.228516 8.71094 0.317383 8.82715L4.51123 14.272C4.7915 14.6343 5.11621 14.8154 5.48535 14.8154Z"
        fill="white"
      />
    </svg>
  );
}
