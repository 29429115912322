import * as React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../authService/auth';
import { PATH_PAGE } from '../../routes/paths';
const PrivateRoute = ({ component: Component, ...rest }) => {
  if (isLoggedIn()) {
    navigate(PATH_PAGE.invoiceList);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
