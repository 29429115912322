import React from 'react';
import XSText from './typography/XSText';
import { STATUS_COLORS, INVOICE_STATUS } from '../../constants/enum';
export default function ValidationStatus({ requiredInvoiceStatus }) {
  const invoiceStatusSelector = () => {
    switch (requiredInvoiceStatus) {
      case INVOICE_STATUS.NONE:
        return { bgcolor: STATUS_COLORS.GREY, color: 'text-gray-500', title: 'None' };
      case INVOICE_STATUS.VALID:
        return { bgcolor: STATUS_COLORS.LIGHT_GREEN, color: 'text-green-500', title: INVOICE_STATUS.VALID };
      case INVOICE_STATUS.INVALID:
        return { bgcolor: STATUS_COLORS.CORAL_PINK, color: 'text-red-alert', title: INVOICE_STATUS.INVALID };
      case INVOICE_STATUS.AWAITING_VALIDATION:
        return {
          bgcolor: STATUS_COLORS.GREY,
          color: 'text-gray-500',
          title: INVOICE_STATUS.AWAITING_VALIDATION.split(' ')[0]
        };
      default:
        return 'none';
    }
  };
  return (
    <div className="validation-status-container" style={{ backgroundColor: invoiceStatusSelector()?.bgcolor }}>
      <XSText
        fontWeight="text-bold"
        lineHeight="line-height-18"
        textColor={invoiceStatusSelector()?.color}
        title={invoiceStatusSelector()?.title}
      />
    </div>
  );
}
