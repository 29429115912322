import React from 'react';
import PropTypes from 'prop-types';

function H3HeaderText({ title, className, textColor, fontSize, textAlign, fontWeight, lineHeight, style }) {
  return (
    <div className={`${className}`}>
      <h3
        style={style}
        className={`${fontWeight} md:whitespace-pre-line ${fontSize} ${textColor} ${textAlign} ${lineHeight} letter-spacing-p`}
      >
        {title}
      </h3>
    </div>
  );
}

export default H3HeaderText;

H3HeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
  style: PropTypes.any
};

H3HeaderText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold'
};
