import React from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TextInput from '../ui/inputs/TextInput';
import { defaultValidationMessage } from '../../helper/helpers';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as userActions from '../invoice/reduxStore/action';
import PropTypes from 'prop-types';
function CustomText({ invoiceData, getInvoiceDetails, handleSelectPaymentDetails }) {
  const { register, handleSubmit, errors } = useForm();
  const onSubmitCustomText = (data) => {
    invoiceData({
      paymentDetails: {
        ...getInvoiceDetails.paymentDetails,
        customTextDetails: {
          ...getInvoiceDetails?.paymentDetails?.customText,
          customText: data.customText
        }
      }
    });
    handleSelectPaymentDetails();
  };
  return (
    <div>
      <form id="custom-details-form" onSubmit={handleSubmit(onSubmitCustomText)}>
        <TextInput
          defaultValue={
            getInvoiceDetails?.paymentDetails?.customTextDetails?.customText &&
            getInvoiceDetails?.paymentDetails?.customTextDetails?.customText
          }
          margin="w-full mt-4"
          name="customText"
          label="Custom text"
          inputRef={register({
            required: true
          })}
          error={errors.customLabel ? true : false}
          helperText={errors.customLabel && <ValidationMessage title={defaultValidationMessage('label')} />}
        />

        <PrimaryButton
          id="hsbc-pay-me-add-btn"
          onClick={handleSubmit(onSubmitCustomText)}
          className="modal-save-button mt-4 mr-4"
          caption="Add to invoice"
          fontWeight="text-bold"
          type="small"
        />
        <PrimaryButton
          id="hsbc-pay-me-cancel-btn"
          onClick={handleSelectPaymentDetails}
          bgColor="bg-white"
          className="modal-cancel-button mt-4"
          color="text-gray-300"
          caption="Cancel"
          fontWeight="text-bold"
          type="small"
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomText);

CustomText.propTypes = {
  invoiceData: PropTypes.func,
  handleSelectPaymentDetails: PropTypes.func
};
