import React, { useState } from 'react';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import { defaultValidationMessage } from '../../../helper/helpers';
import { connect } from 'react-redux';
import * as userProfileActions from '../../profile/reduxStore/action';
import PropTypes from 'prop-types';
import { API_CODE, API_ERROR_CODES, MUI_COLORS } from '../../../constants/enum';
import Snackbar from '../../../components/Snackbar';
import Success from '../../../images/icons/success-icon.svg';
import Image from '../../../components/Image';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import BaseText from '../../ui/typography/BaseText';

function ChangePassword({ changePassword, setButtonLoader }) {
  const { register, handleSubmit, errors } = useForm();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const onSubmitPasswordDetails = async (data) => {
    setButtonLoader(true);
    if (data?.newPassword === data?.confirmNewPassword) {
      let passwordPayload = {
        currentPassword: data?.currentPassword,
        newPassword: data?.newPassword
      };
      const changePasswordResponse = await changePassword(passwordPayload);
      if (changePasswordResponse?.status === API_CODE.STATUS_200) {
        setMessage({
          ...message,
          contentMessage: `Password changed successfully`,
          messageTitle: changePasswordResponse?.data?.message
        });
        setCurrentPasswordError(false);
        setOpen(true);
        setButtonLoader(false);
      } else {
        if (changePasswordResponse?.status === API_ERROR_CODES?.STATUS_400) {
          setCurrentPasswordError(true);
          setButtonLoader(false);
        }
      }
    } else {
      setConfirmPasswordError(true);
      setButtonLoader(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const PasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword?.currentPassword
    });
  };

  const newPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword?.newPassword
    });
  };

  const confirmPasswordClicked = () => {
    setShowPassword({
      ...showPassword,
      confirmPassword: !showPassword?.confirmPassword
    });
  };

  return (
    <>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.messageTitle}></XSText>
              <MSText textColor="text-gray-500" title={message?.contentMessage}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      <div className="setting-form-container md:ml-6 bg-white pt-6 pb-8 px-6 w-full">
        <MDSubtitleText className="mb-6" fontWeight="text-bold" textColor="text-gray-500" title="Security" />
        <BaseText textColor="text-gray-450" className="mb-4" fontSize="text-base" title="Change your password" />
        <form id="password-form" onSubmit={handleSubmit(onSubmitPasswordDetails)}>
          <div className="mb-4 company-security-input">
            <TextInput
              type={showPassword?.currentPassword ? 'text' : 'password'}
              name="currentPassword"
              label="Current password"
              inputRef={register({
                required: true
              })}
              onRightSideAdornment={PasswordClicked}
              endAdornment={showPassword?.currentPassword ? <Visibility /> : <VisibilityOff />}
              error={errors.currentPassword || currentPasswordError ? true : false}
              helperText={
                (errors.currentPassword || currentPasswordError) && (
                  <ValidationMessage title={defaultValidationMessage('Current Password')} />
                )
              }
            />
          </div>
          <div className="flex flex-col company-security-input gap-4">
            <TextInput
              type={showPassword?.newPassword ? 'text' : 'password'}
              name="newPassword"
              label="New password"
              textFieldContainerClass="w-full"
              inputRef={register({
                required: true
              })}
              onRightSideAdornment={newPasswordClicked}
              endAdornment={showPassword?.newPassword ? <Visibility /> : <VisibilityOff />}
              error={errors.newPassword ? true : false}
              helperText={errors?.newPassword && <ValidationMessage title={defaultValidationMessage('New Password')} />}
            />
            <TextInput
              type={showPassword?.confirmPassword ? 'text' : 'password'}
              name="confirmNewPassword"
              textFieldContainerClass="w-full"
              label="Confirm new password"
              inputRef={register({
                required: true
              })}
              onRightSideAdornment={confirmPasswordClicked}
              endAdornment={showPassword?.confirmPassword ? <Visibility /> : <VisibilityOff />}
              error={errors?.confirmNewPassword || confirmPasswordError ? true : false}
              helperText={
                (errors?.confirmNewPassword || confirmPasswordError) && (
                  <ValidationMessage title={'Both passwords must be same'} />
                )
              }
            />
          </div>
        </form>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload) => dispatch(userProfileActions.changePassword(payload))
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
ChangePassword.propTypes = {
  changePassword: PropTypes.func
};
